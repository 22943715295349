import {useState, useEffect} from 'react';
import { Form, Spinner, Modal } from 'react-bootstrap';
import Card from '../../ui/Card';
import { validateFormFields } from '../../../utils/index';
import DebtInstrumentMaintenanceService from "../../../services/DebtInstrumentMaintenanceService";
import FinalSaleReportService from '../../../services/FinalSaleReportService';
import CurrencyInput from '../../formElements/CurrencyInput';
import { MDBTooltip } from 'mdbreact';
import { AiFillQuestionCircle } from 'react-icons/ai';

const TypeOfDebt = (props) => {
    const { finalSaleDetails } = props;
    const [showOtherTextField, setShowOtherTextField] = useState(false);
    const [formErrors, setFormErrors]= useState({});
    const [debtGroups, setDebtGroups]= useState([]);
    const [debtInstruments, setDebtInstruments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isDebtInstrumentsLoading, setIsDebtInstrumentsLoading] = useState(false);
    const [finalSaleInfo, setFinalSaleInfo] = useState(finalSaleDetails);
    const [showRateReductionAmountField, setShowRateReductionAmountField] = useState(false);
    const [showHelpTextModal, setShowHelpTextModal] = useState(false);
    useEffect(() => {
        if(finalSaleDetails.debtInstrumentGroupCodeId) {
            getDebtInstruments(finalSaleDetails.debtInstrumentGroupCodeId);
        }

        if(['OTH', 'OTHB', 'OTHN'].includes(finalSaleDetails.debtInstrumentCode) ){
            setShowOtherTextField(true);
        }
        if(['RRB'].includes(finalSaleDetails.debtInstrumentCode) ){
            setShowRateReductionAmountField(true);
        }
    },[finalSaleDetails]);

    useEffect(() => {
        var obj = {
          msgId: "null",
          sysId: null,
          opr: "GetLookups",
          hdrStruct: null,
          queryParam: null,
          reqData: { cdiacCode: "DEBTG", active: "Y" },
        };
    
        DebtInstrumentMaintenanceService.getAllDebtGroups(obj)
          .then((res) => res)
          .then((result) => {
            setDebtGroups(result.data.responses[0]);
            setIsLoading(false);
          });
      }, []);
    
    const updateDebtInstrument = () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putFinalSale",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id": finalSaleInfo.finalSaleId,
                "finalSale": {...finalSaleInfo}}
        };
       
        FinalSaleReportService.updateFinalSale(obj)
          .then((res) => res)
          .then((result) => {
            if(result.data.responses[0]) {
                props.updateFinalSaleDetails(result.data.responses[0]);
                props.sectionValidated('typeOfDebt', true);
                props.openWidget('sourceOfRepayment');
            }
          });
    }
    
    const validateForm = (e) => {
        let form = e.target.closest('form#typeOfDebtForm');
        let formErrors = validateFormFields(form.elements);
      
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
            props.sectionValidated('typeOfDebt', false);
        } else {
            setFormErrors({});
            updateDebtInstrument();
        }
    }

    const getDebtInstruments = async (groupId) => {
            var obj = {
              msgId: null,
              sysId: null,
              opr: "getDebtInstrumentRefsByGroupId",
              hdrStruct: null,
              queryParam: null,
              reqData: { "id": groupId },
            };
        
            await DebtInstrumentMaintenanceService.getAllDebtGroups(obj)
              .then((res) => res)
              .then((result) => {
                const filteredList = result.data.responses[0].filter(el => el.activeInd === "Y");
                setDebtInstruments(filteredList);
                setIsDebtInstrumentsLoading(false);
              });
    }
    const handleChange = (e) => {
        const {name, value} = e.target;
        setFinalSaleInfo(prevState => ({
            ...prevState,
            [name]: value
        }));
        if(value !== "") {
            let debtGroupId = value;           
            setIsDebtInstrumentsLoading(true);
            getDebtInstruments(debtGroupId);
        } else {
            setDebtInstruments([]);
        }
    }

    const handleDebtTypeChange = (e) => {
        const { name, value } = e.target;
        if(['OTH', 'OTHB', 'OTHN'].includes(value) ){
            setShowOtherTextField(true);
        } else {
            setShowOtherTextField(false);
        }
        if(['RRB'].includes(value) ){
            setShowRateReductionAmountField(true);
        } else {
            setShowRateReductionAmountField(false);
        }

        setFinalSaleInfo(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    const handleOtherTextChange = (e) => {
        const { name, value } = e.target;

        setFinalSaleInfo(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    const handleRateReductionSavingAmtChange = (e) => {
        const { name, value } = e.target;

        setFinalSaleInfo(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    return (
        <Card>
            <form className="form" id="typeOfDebtForm">

                <label style={{fontSize: '16px', marginBottom: '0.5rem'}}><span className="required">*</span>Debt Group: 
                <MDBTooltip
                    domElement
                    tag="span"
                    placement="right"
                    >
                    <span>{"  "}<AiFillQuestionCircle onClick={()=>setShowHelpTextModal(true)} className="help-icon heading-help-icon"/></span>
                    <span>Click me for more info.</span>
                </MDBTooltip>
                {showHelpTextModal && 
                    <Modal show={true} backdrop="static" onHide={()=>setShowHelpTextModal(false)}>
                        <Modal.Body>
                            <div>
                               <p>Select the Debt Group, then the specific instrument Type. Instruments are grouped by notes, bonds, commercial paper,
certificates of participation and leases, and other types of debt. Only one type of debt instrument may be selected. For
descriptions of instruments, visit <a href="https://data.debtwatch.treasurer.ca.gov/glossary" rel="noopener noreferrer"  target="_blank">https://data.debtwatch.treasurer.ca.gov/glossary</a>.</p>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="custom-button-secondary btn-secondary" onClick={()=>setShowHelpTextModal(false)}>Close</button>
                        </Modal.Footer>
                    </Modal>
                    }
                </label>
                <br />
                {isLoading && <Spinner animation="border" variant="primary"/>}
                <Form.Select as="select" onChange={(e)=>handleChange(e)} value={finalSaleInfo.debtInstrumentGroupCodeId} data-validations="required" name="debtInstrumentGroupCodeId">
                        <option value="">select</option>
                        
                        {debtGroups.length !== 0 && debtGroups.map((debtGroup, i) =>{
                            return (<option key={i} value={debtGroup.cdiacCodeValueId} >{debtGroup.cdiacCodeValueDesc}</option>);
                        }
                        ) }
                </Form.Select>
                {formErrors['debtGroup'] && <p className="error">{formErrors['debtGroup']}</p>}
                <br />
                    
                {isDebtInstrumentsLoading && <Spinner animation="border" variant="primary"/>}
                <div style={{marginTop: '10px'}}>
                {debtInstruments.length !== 0  &&  <label> Type: </label>}
                {debtInstruments && debtInstruments.map((debtInstrument, i) => {
                        return(<Form.Check key={Math.random()} name="debtInstrumentCode" id={`debtInstrument_${i}`} type="radio"  value={debtInstrument.debtInstrumentCode} label={debtInstrument.debtInstrumentName} data-validations="required" checked={debtInstrument.debtInstrumentCode===finalSaleInfo.debtInstrumentCode ? true : false} onChange={(e)=>handleDebtTypeChange(e)}/>);
                    })
                }
                {formErrors['debtInstrumentCode'] && <p className="error">{formErrors['debtInstrumentCode']}</p>}
                </div>
                <br/>

                {showRateReductionAmountField &&  <div className="control">
                    <CurrencyInput placeholder="0.00" 
                    label="Rate Reduction Savings Amount" 
                    name="rateReductionSavingAmt" 
                    type="text" 
                    value={finalSaleInfo.rateReductionSavingAmt}
                    onChange={(e)=>handleRateReductionSavingAmtChange(e)} 
                    error={formErrors.rateReductionSavingAmt} 
                    validations={["required", "max:999999999999.99"]}
                    isrequired="true"
                   />
                    </div>}

                {showOtherTextField && <div form="control" style={{width: '50%'}}>
                    <label>Please specify if "Other" was checked above: </label><Form.Control type="text" name="debtInstrumentOtherText" value={finalSaleInfo.debtInstrumentOtherText? finalSaleInfo.debtInstrumentOtherText : "" } onChange={e=>handleOtherTextChange(e)}/> </div>}
                
                <div className="btn-div">
                    {/* <button className="custom-button">Back</button> */}
                    <button className="fright custom-button" type="button" onMouseDown={(e)=>validateForm(e)}>Save & Next</button>
                </div>    
            </form>
        </Card>
    )
}

export default TypeOfDebt

import React from "react";
import { DataTable } from "primereact/datatable";
import { useState } from "react";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import TooltipComponent from "../../common/Tooltip";
import { AiFillQuestionCircle } from "react-icons/ai";

const DataGrid = (props) => {
    const {dataSource, showPaginator, sortField} = props; 
    const showQuickSearch = props.showQuickSearch ?? true;
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        if((showQuickSearch || props.addNew)) {
            return (
            <div className="flex justify-content-end">
            {showQuickSearch &&
            <span className="p-input-icon-left p-0">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                <TooltipComponent
                title={props.searchToolTip ?? "Use the search feature to quickly retrieve the system values"}
                id="mlr-reports"
                placement="right">
                    <AiFillQuestionCircle className="help-icon"/>
                </TooltipComponent>
            </span>
            }
            {props.addNew &&
            <button
            className="btn bg-success text-light rounded"
            style={{ float: 'right' }}
            onClick={props.onAddNew}  
            disabled={props.btnDisable ?? false}>
            {props.addNewBtnText}
            </button>
            }
            {props.addActionBtn && props.actionBtnContent}
            </div>)
        }
        return null;
    };

    const header = renderHeader();
    
    return (
        <div>
            {props.title && 
            <div className="panel-heading">
                <div className="container">
                <h1 className="main-title">{props.title}</h1>
                </div> 
            </div> }
            <DataTable 
            // scrollable 
            // scrollHeight="600px"
            dataKey={props.dataKey}
            header={header}
            filters={filters}
            className="table table-striped no-row-select-style" 
            emptyMessage={props.emptyDataMessage ?? "Sorry, no data available to display."}
            paginator={showPaginator ?? true} 
            value={dataSource} 
            rows={10} 
            rowsPerPageOptions={[10, 50, 100, 500]} 
            // onPage={(e) => {
            //     debugger
            // }}
            sortField={sortField} 
            sortOrder={-1} 
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" 
            currentPageReportTemplate={`Showing {first} to {last} of {totalRecords} records`}
            selectionMode={props.selectionMode ?? 'single'}
            selection={props.selection}
            onSelectionChange={props.onSelectionChange}
            expandedRows={props.expandedRows}
            onRowToggle={props.onRowToggle}
            rowExpansionTemplate={props.rowExpansionTemplate}
            >
                {props.children} 
            </DataTable>
    </div>
    ); 
}

export default DataGrid;
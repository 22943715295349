import {useContext, useState} from 'react';
import Card from '../ui/Card';
import ReviewComponent from './reviewComponent'
import FinalSaleReportService from '../../services/FinalSaleReportService';
import { toast } from 'react-toastify';
import CommonService from '../../services/CommonService';
import { IssuanceDetailContext, UserContext } from "../../UseContext";
import { renderToString } from 'react-dom/server';
import EditReasonModal from '../reports/editReasonModal'
import swal from 'sweetalert'
import ReportsService from '../../services/ReportsService'

const MKRAuthorityIssueYFSReview = (props) => {
    const {markRoosAuthority, markRoosAuthorityDependencies, contact} = props;
    const markRoosAuthorityDetails = markRoosAuthorityDependencies.mkrAuthorityData;
    const user = useContext(UserContext);
    const issuanceDetail = useContext(IssuanceDetailContext);
    const [showEditReasonModal, setShowEditReasonModal] = useState(false);

    const validateMarkRoosReport = () => {
        props.toggleWidget('review');
        validateRequiredFields();
    }

    const updateFinalMarkRoosAuthority= async() => {
        const html = renderToString(<ReviewComponent issuanceDetail={issuanceDetail} markRoosAuthority={markRoosAuthority} markRoosAuthorityDependencies={markRoosAuthorityDependencies} contact={contact} />)

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "updateFinalMarkRoosAuthority",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id": markRoosAuthorityDetails.markRoosAuthorityId,
                "env": "E",
                "markRoosAuthority":{
                    "finalSaleId" : markRoosAuthorityDetails.finalSaleId,
                    "lastUpdateUser": CommonService.getUserName(user),
                    "emailId": user,
                    "statusFlag" : "S"
                },
                "reportType" : "MKR-A",
                "html" : html,
                "cdiacNbr" :  markRoosAuthority.cdiacNbr
            }
        };
        FinalSaleReportService.updateFinalMarkRoosAuthority(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message);
            }
            else{
                if(result.data && result.data.responses[0]) {
                    var base64EncodedPDF = result.data.responses[0].pdf;
                    var arrrayBuffer = CommonService.base64ToArrayBuffer(base64EncodedPDF); 
                    var blob = new Blob([arrrayBuffer], {type: "application/pdf"});
                    var url = window.URL.createObjectURL(blob);
                    window.open(url);
                } 
                toast.success("Mark Roos Authority updated successfully!");
            }
        });
    }

    const validateRequiredFields = async() => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "validateMarkRoosAuthoritySubmit",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id": markRoosAuthorityDetails.markRoosAuthorityId
            }
        };
        FinalSaleReportService.validateMarkRoosAuthoritySubmit(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                alert(result.data.errors[0].message)
            }
            else{
                if(result.data.responses[0].length > 0) {
                  let alertMessage = "";
                  result.data.responses[0].forEach(item => {
                    alertMessage +=  "- " + item.message + "\n";
                  });
                  alert(alertMessage);
                } else {
                    if(markRoosAuthorityDetails.statusFlag === "R"){
                        swal({
                            text: "Do you want to re-edit this record?",
                            icon: "warning",
                            buttons: [true, 'Yes'],
                            closeOnClickOutside: false
                        }).then((confirm) => {
                            if (confirm) {
                                setShowEditReasonModal(true)
                            } 
                        });
                    } else {
                        updateFinalMarkRoosAuthority()
                    }
                }
            }

        });
    }

    const saveEditReason = (editReason) => {
        let obj ={
             "msgId": null,
             "sysId": null,
             "opr": "postEditReason",
             "hdrStruct": null,
             "queryParam": null,
             "reqData": {
                 "editReason" : {
                     "editReasonCodeId" : editReason.editReasonCodeId,
                     "comment" : editReason.comment,
                     "mkrAuthId" : markRoosAuthorityDetails.markRoosAuthorityId,
                     "createUser" : CommonService.getUserName(user)
                 }
             }
         } 

         ReportsService.saveEditReason(obj)
         .then((res) => res)
         .then((result) => {
             if(result.data.errors.length > 0) {
 
             } else {
                 toast.success('Edit Reason saved successfully!')
                 setShowEditReasonModal(false)
                 updateFinalMarkRoosAuthority()
             }
         });
    }

    return (
        <Card className="form">
            <ReviewComponent issuanceDetail={issuanceDetail} markRoosAuthority={markRoosAuthority} markRoosAuthorityDependencies={markRoosAuthorityDependencies} contact={contact} />
            <div className="form">
            <div className="btn-div">
            {/* <button className="custom-button" type="button" onClick={()=>props.openWidget('comments')}>Back</button> */}
            <button className="fright custom-button" type="button" onClick={(e)=>validateMarkRoosReport(e)}>Validate & Submit</button>
            </div>
            </div> 
            {showEditReasonModal && <EditReasonModal  onModalClose={()=>setShowEditReasonModal(false)} handleSave={saveEditReason} cdiacReasonCode="MKAER"/>}          
        </Card>
    )
}

export default MKRAuthorityIssueYFSReview

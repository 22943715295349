import {useState, useContext, useEffect} from 'react'
import Card from '../ui/Card'
import {Modal, Table} from 'react-bootstrap'
import {AiFillQuestionCircle} from 'react-icons/ai'
import CommonService from '../../services/CommonService'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import { toast } from 'react-toastify'
import {IssuanceDetailContext, UserContext} from '../../UseContext'
import { AlertType, runValidations, validateFormFields } from '../../utils'
import CurrencyInput from '../formElements/CurrencyInput'
import Spinner from 'react-bootstrap/Spinner'

const FundBalanceTable = (props) => {
    const { markRoosLocalObligDetails } = props;
    const {onModalClose, linkClicked} = props;
    const [balance, setBalance] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        var obj = {
          msgId: null,
          sysId: null,
          opr: linkClicked === "ADTRFundBalances" ? "getAdtrFundBalance" : "getMKRLobProceeds",
          hdrStruct: null,
          queryParam: null,
          reqData:{
            "id" : markRoosLocalObligDetails.finalSaleId,
            "fiscalYrEndDate" : markRoosLocalObligDetails.fiscalYrEndDate
            }
        };
    
        FinalSaleReportService.getAdtrFundBalance(obj)
          .then((result) => {
            setBalance(result.data.responses);
            setIsLoading(false);
          });
          // eslint-disable-next-line 
      }, [linkClicked]);

      
    return (
        <Modal show={true} onHide={onModalClose} backdrop="static" keyboard={false}  size="lg">
            <Modal.Header closeButton>
                <h3>{linkClicked==="ADTRFundBalances" ? 
                "ADTR Fund Balances for the last reporting period" :
                "Fund Balances from Marks-Roos LOB Yearly Submissions"}</h3>
            </Modal.Header>

            <Modal.Body>
            {isLoading && <Spinner animation="border" variant="primary" />}
            {linkClicked !== "ADTRFundBalances" && Object.keys(balance).map((key) => {
                return (
                <div key={key}>

                    <Table bordered>
                    <thead>
                        <tr>
                        <th>Reporting Period</th>
                                <th>Principal Amount of Bonds Outstanding</th>
                                <th>Bond Reserve Fund</th>
                                <th>Capitalized Interest Fund</th>
                        </tr>
                    </thead>
                    <tbody>
                    {balance[key].map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{CommonService.dateFormatter(item.fiscalYrEndDate)}</td>
                                    <td>{CommonService.currencyFormatter(item.principalOutstdgAmt)}</td>
                                    <td>{CommonService.currencyFormatter(item.bondReserveFundAmt)}</td>
                                    <td>{CommonService.currencyFormatter(item.capitalizedIntFundAmt)}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                    </Table>
                </div>
                );
            })}

            {linkClicked === "ADTRFundBalances" && Object.keys(balance).map((key) => {
                return (
                <div key={key}>

                <Table bordered>
                  <thead>
                      <tr>
                      <th>Reporting Period</th>
                    <th>Rpt Period End Bal Amt</th>
                      </tr>
                  </thead>
                  <tbody>
                  {balance[key].map((item, index) => {
                      return (
                          <tr key={index}>
                            <td>{CommonService.dateFormatter(item.fiscalyearEndDate)}</td>
                                <td>{CommonService.currencyFormatter(item.rptPeriodEndBalAmt)}</td>
                          </tr>
                      );
                  })}
                  </tbody>
                </Table>

          </div>
                );
            })}
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn-secondary custom-button-secondary" onClick={()=>onModalClose()}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}
const FundBalance = (props) => {
    const { markRoosLocalObligDetails } = props;
    const issuanceDetail = useContext(IssuanceDetailContext);
    const [showInstructionsModal, setShowInstructionsModal] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const loginUser = useContext(UserContext);
    const [showBalancesTable, setShowBalancesTable] = useState(false);
    const [linkClicked, setLinkClicked] = useState(null);    

    const validateForm = (e) => {
        let form = e.target.closest('form#fundBalanceForm');

        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});
            const principleOutStandingAmount = CommonService.amountFormatter(stateValues.principalOutstdgAmt);
            if((issuanceDetail.retireFlag && issuanceDetail.retireFlag !== "N") && principleOutStandingAmount > 0){
                CommonService.displayServerMessage("In order for this issue to be marked as retired, the Principal Amount of Bonds Outstanding in this section must be $0.", AlertType.Error);
                return;
            }
            updateMKRRoosLocalObligFundBalance();
            props.openWidget('delinquentReporting')
        }

    }

    const updateMKRRoosLocalObligFundBalance = async() => {

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "updateMKRRoosLocalObligFundBalance",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {  "id": markRoosLocalObligDetails.marksRoosLocalObligorId, "marksRoosLocalObligor":{
                "capitalizedIntFundAmt" : stateValues.capitalizedIntFundAmt,
                "adminFeeChargeAmt" : stateValues.adminFeeChargeAmt,                
                "bondReserveFundAmt" : stateValues.bondReserveFundAmt,
                "principalOutstdgAmt" : stateValues.principalOutstdgAmt,
                "lastUpdateUser":  CommonService.getUserName(loginUser),
                "emailId": loginUser,
                "contactId": markRoosLocalObligDetails.contactId
            }
            }
        };
    
       await FinalSaleReportService.updateMKRRoosLocalObligFundBalance(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                alert(result.data.errors[0].message)
            }
            else{
                props.updateMkrLocalObligorDetails(result.data.responses[0]);
                toast.success("Fund Balance updated Successfully!!");
            }

        });
    }   

    const [stateValues, setStateValues] = useState({
        capitalizedIntFundAmt : markRoosLocalObligDetails.capitalizedIntFundAmt,
        adminFeeChargeAmt: markRoosLocalObligDetails.adminFeeChargeAmt,
        bondReserveFundAmt: markRoosLocalObligDetails.bondReserveFundAmt,
        principalOutstdgAmt: markRoosLocalObligDetails.principalOutstdgAmt,
    })

    const handleChange = (e) => {
        const {name, value} = e.target;

        setStateValues(prevState => ({
            ...stateValues, [name]: value
        })
        )
    }

    const validateField = (e) => {
        if ( e.target.dataset['validations'] )
            runValidations(e) 
    }
    const viewAdtrProceeds = () => {
        setShowBalancesTable(true);
        setLinkClicked('ADTRFundBalances');
    }
    const viewMKRLobProceeds = () => {
        setShowBalancesTable(true);
        setLinkClicked('getMKRLobProceeds');
    }
    return (
        <Card>
            <form className="form" id="fundBalanceForm">
                <h3>
                    Fund Balance <AiFillQuestionCircle onClick={()=>setShowInstructionsModal(true)} className="help-icon heading-help-icon"/>
                    {showInstructionsModal && 
                    <Modal show={true} backdrop="static" onHide={()=>setShowInstructionsModal(false)}>
                        <Modal.Body>
                            <div>
                                <span>The Principal Amount of Bonds/Loan Outstanding, the fund balances for the Bond Reserve Fund (if any) and the Capitalized Interest Fund (if any) must be reported as of June 30, the end of the fiscal year for the reporting period. If an Administrative Fee was charged by Authority, please report the amount charged.</span>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button variant="secondary" onClick={()=>setShowInstructionsModal(false)}>Close</button>
                        </Modal.Footer>
                    </Modal>
                    }
                </h3>
                <div className="control">
                    <CurrencyInput 
                    placeholder="0.00" 
                    label="Principal Amount of Bonds Outstanding" 
                    name="principalOutstdgAmt" 
                    type="text" 
                    value={stateValues.principalOutstdgAmt ? stateValues.principalOutstdgAmt  : ""}
                    onChange={(e)=>handleChange(e)} 
                    onBlur={e=>validateField(e)}
                    error={formErrors.principalOutstdgAmt} 
                    validations={["required", "max:999999999999.99"]}
                    isrequired="true"
                    helpText="Indicate the Principal Amount of Bonds Outstanding as of June 30 of the FY for which you are reporting."
                    />
                </div>
                <div className="control">
                    <CurrencyInput 
                    placeholder="0.00" 
                    label="Bond Reserve Fund" 
                    name="bondReserveFundAmt" 
                    type="text" 
                    value={stateValues.bondReserveFundAmt ? stateValues.bondReserveFundAmt  : ""}
                    onChange={(e)=>handleChange(e)} 
                    onBlur={e=>validateField(e)}
                    error={formErrors.bondReserveFundAmt} 
                    validations={["required", "max:999999999999.99"]}
                    isrequired="true"
                    helpText="Enter the Bond Reserve balance as of June 30 of FY for which you are reporting. If no balance, enter 0.00."
                    />
                </div>
                <div className="control">
                    <CurrencyInput 
                    placeholder="0.00" 
                    label="Capitalized Interest Fund" 
                    name="capitalizedIntFundAmt" 
                    type="text" 
                    value={stateValues.capitalizedIntFundAmt ? stateValues.capitalizedIntFundAmt  : ""}
                    onChange={(e)=>handleChange(e)} 
                    onBlur={e=>validateField(e)}
                    error={formErrors.capitalizedIntFundAmt} 
                    validations={["required", "max:999999999999.99"]}
                    isrequired="true"
                    helpText="Enter the Capitalized Interest Fund balance as of June 30 of FY for which you are reporting. If no balance, enter 0.00."
                    />
                </div>
                <div className="control">
                    <CurrencyInput 
                    placeholder="0.00" 
                    label="Administrative Fee Charged by Authority" 
                    name="adminFeeChargeAmt" 
                    type="text" 
                    value={stateValues.adminFeeChargeAmt ? stateValues.adminFeeChargeAmt  : ""}
                    onChange={(e)=>handleChange(e)} 
                    onBlur={e=>validateField(e)}
                    error={formErrors.adminFeeChargeAmt} 
                    validations={["max:999999999999.99"]}
                    helpText="If an Administrative Fee was charged by Authority, please report the amount charged. If no charge, enter 0.00."
                    />
                </div>

                <div className="control">
                    <button type="button" className="link-button dk-blue-color" style={{textDecoration: 'none', fontWeight: '500'}}  onClick={()=>viewAdtrProceeds()}>ADTR Fund Balances for the last reporting period</button><br />
                    <button type="button" className="link-button dk-blue-color" style={{textDecoration: 'none', fontWeight: '500'}} onClick={()=>viewMKRLobProceeds()}>Fund Balances from Marks-Roos LOB Yearly Submissions</button>
                </div>

                <div className="btn-div">
                {/* <button className="custom-button" type="button" onClick={()=>props.openWidget('issuance')}>Back</button> */}
                <button className="fright custom-button" type="button" onClick={e=>validateForm(e)}>Save & Next</button>
                </div>
            </form>
            {showBalancesTable && <FundBalanceTable onModalClose={()=>setShowBalancesTable(false)} linkClicked={linkClicked} markRoosLocalObligDetails={markRoosLocalObligDetails} />}

        </Card>
    )
}

export default FundBalance

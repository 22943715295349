import CommonService from '../../services/CommonService'

const ReviewComponent = (props) => {
    const { melloRoosDetails, contact, issuanceDetail, foreclosures, issueDocuments} = props;
    
      return (
        <>
        <html>
         <head>
         <meta charset="UTF-8"></meta>
         <style>{"\
          div.repeat-header {\
            padding-top: 0.2in !important;\
            display: block !important;\
            text-align: center;\
            position: running(repeat-header) !important;\
          }\
          .report-header {\
              text-align: center;\
              padding: 5px;\
              width: 100%;\
              height: 40px;\
          }\
          .report-heading-left {\
              font-size: 18px;\
              font-weight: 800;\
              color: darkblue;\
              text-align: left;\
              width: 20%;\
          }\
          .report-heading-center {\
              font-size: 12px;\
              font-weight: 800;\
              color: darkblue;\
              text-align: center;\
              width: 60%;\
          }\
          .report-heading-right {\
              font-size: 12px;\
              font-weight: 800;\
              color: darkblue;\
              text-align: right;\
              width: 20%;\
          }\
          .report-heading-title {\
              font-size: 18px;\
              font-weight: 800;\
              color: darkblue;\
              text-align: left;\
              min-width: 65%;\
          }\
          .report-heading-date {\
              font-size: 10px;\
              font-weight: 800;\
              color: darkblue;\
              text-align: right;\
          }\
          div.footer {\
            position: running(footer) !important;\
          }\
          #current-page-placeholder::before {\
            content: counter(page);\
          }\
          #total-pages-placeholder::before {\
            content: counter(pages);\
          }\
          @page {\
              margin-top: 90px;\
              @top-center {\
                  content: element(repeat-header) !important;\
              }\
          }\
          @page {\
              @bottom-right {\
                  content: element(footer) !important;\
                  font-size: 10px;\
              }\
          }\
          div.break-page {\
              page-break-after: always;\
          }\
          .table a {\
              color: black;\
              text-decoration: underline;\
          }\
          .table .tableheader th, .table tbody th {\
              color: #fff;\
              background-color: darkblue;\
              border-color: #454d55;\
              vertical-align: middle;\
              font-size: 1.0em;\
          }\
          .v-top {\
              vertical-align: top !important;\
          }\
          tr {\
              page-break-inside: avoid\
          }\
          .invoice-box{\
            max-width: 800px;\
            margin: auto;\
            padding: 20px;\
            border: 1px solid #eee;\
            box-shadow: 0 0 10px rgba(0, 0, 0, .15);\
            font-size: 13px;\
            line-height: 18px;\
            font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;\
            color: #555;\
          }\
          .invoice-box table {\
            width: 100%;\
            line-height: inherit;\
            text-align: left;\
          }\
          .invoice-box table td {\
            padding: 5px;\
            vertical-align: top;\
          }\
        .border-top {\
              border-top: 2px solid #eee;\
          }\
          .invoice-box table tr.top table td.title {\
              font-size: 13px;\
              line-height: 22px;\
              color: #333;\
          }\
          .invoice-box table tr.information table td {\
              padding-bottom: 10px;\
          }\
          .invoice-box table tr.heading td {\
              border-bottom: 1px solid #ddd;\
              font-weight: bold;\
          }\
          .invoice-box table tr.sub-heading td {\
            font-weight: bold;\
          }\
          .invoice-box table tr.item td {\
            border-bottom: 1px solid #eee;\
          }\
          .invoice-box table tr.item.last td {\
              border-bottom: none;\
          }\
          .invoice-box table tr.total {\
              border-top: 2px solid #eee;\
              font-weight: bold;\
          }\
          .invoice-box table tr.section-title td {\
            font-weight: bold;\
            font-size: 18px;\
            line-height: 18px;\
            color: #000;\
          }\
          .invoice-box table tr.information table.data-table {\
            border: 1px solid #ddd;\
         }\
          @media only screen and (max-width: 600px) {\
          .invoice-box table tr.top table td {\
              width: 100%;\
              display: block;\
              text-align: center;\
          }\
          .invoice-box table tr.information table td {\
              width: 100%;\
              display: block;\
              text-align: center;\
          }\
          }\
        "}</style>
         </head>
          <body className="pdf-box">
            <div className="invoice-box">
            <table cellPadding="0" cellSpacing="0">
              <tbody>
              <tr className="information">
                <td>
                  <table>
                    <tbody>
                    <tr className="fs-12"><td><i>Information as of Reporting Year End: <b>{CommonService.dateFormatter(melloRoosDetails.fiscalYrEndDate)}</b></i></td></tr>
                    <tr className="section-title"><td>Issuance</td></tr>
                    <tr>
                      <td>
                        <table>
                            <tr><td className="label">Issuer Name:</td><td align="right">{issuanceDetail.issuerName}</td></tr>
                            <tr><td className="label">Issue Name:</td><td align="right">{issuanceDetail.issueName}</td></tr>
                            <tr><td className="label">Project Name:</td><td align="right">{issuanceDetail.projectName}</td></tr>
                            <tr><td className="label">Actual Sale Date:</td><td align="right">{CommonService.dateFormatter(issuanceDetail.actualSaleDate)}</td></tr>
                            <tr><td className="label">Settlement Date:</td><td align="right">{CommonService.dateFormatter(issuanceDetail.settlementDate)}</td></tr>
                            <tr><td className="label">Original Principal Amount:</td><td align="right">{CommonService.currencyFormatter(issuanceDetail.principalSaleAmt)}</td></tr>
                            <tr><td className="label">Date of Filing:</td><td align="right">{melloRoosDetails.statusFlag !== 'D' ? CommonService.dateFormatter(melloRoosDetails.filingDate) : ""}</td></tr>
                            <tr><td className="label">Reserve Fund Minimum Balance:</td><td align="right">{melloRoosDetails.reserveMinBalInd === "Y" ? "Yes" : "No"}</td></tr>
                            <tr><td className="label">Reserve Fund Minimum Balance Amount:</td><td align="right">{CommonService.currencyFormatter(melloRoosDetails.reserveMinBalAmt)}</td></tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table>
                          <tbody>
                            <tr><td colSpan="2">Credit Rating from Report of Final Sale</td></tr>
                            <tr><td className="label">Credit Rating:</td><td align="right">{issuanceDetail.creditNotRatedInd ? (issuanceDetail.creditNotRatedInd === "N" ? "Rated" : "Not Rated") : "--"}</td></tr>
                            <tr><td className="label">Standard & Poor:</td><td align="right">{issuanceDetail.stdsPoorRate}</td></tr>
                            <tr><td className="label">Fitch:</td><td align="right">{issuanceDetail.fitchRate}</td></tr>
                            <tr><td className="label">Moody's:</td><td align="right">{issuanceDetail.moodyRate}</td></tr>
                            <tr><td className="label">Other:</td><td align="right">{issuanceDetail.otherRate}</td></tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table>
                          <tbody>
                            <tr><td colSpan="2">Credit Rating from Mello-Roos Last Yearly Fiscal Status Report</td></tr>
                            <tr><td className="label">Credit Rating:</td><td align="right">{issuanceDetail.creditNotRatedIndPrevious ? (issuanceDetail.creditNotRatedIndPrevious === "N" ? "Rated" : "Not Rated") : "--"}</td></tr>
                            <tr><td className="label">Standard & Poor:</td><td align="right">{issuanceDetail.stdsPoorRatePrevious}</td></tr>
                            <tr><td className="label">Fitch:</td><td align="right">{issuanceDetail.fitchRatePrevious}</td></tr>
                            <tr><td className="label">Moody's:</td><td align="right">{issuanceDetail.moodyRatePrevious}</td></tr>
                            <tr><td className="label">Other:</td><td align="right">{issuanceDetail.otherRatePrevious}</td></tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table>
                          <tbody>
                            <tr><td colSpan="2">Credit Rating for This Reporting Period</td></tr>
                            {/* <tr><td>Are values same as previous reporting period?</td><td>{issuanceDetail.creditNotRatedInd ? (issuanceDetail.creditNotRatedIndPrevious === "N" ? "Rated" : "Not Rated") : "--"}</td></tr> */}
                            <tr><td className="label">Credit Rating:</td><td align="right">{melloRoosDetails.creditNotRatedInd ? (melloRoosDetails.creditNotRatedInd === "N" ? "Rated" : "Not Rated") : "--"}</td></tr>
                            <tr><td className="label">Standard & Poor:</td><td align="right">{melloRoosDetails.stdsPoorRate}</td></tr>
                            <tr><td className="label">Fitch:</td><td align="right">{melloRoosDetails.fitchRate}</td></tr>
                            <tr><td className="label">Moody's:</td><td align="right">{melloRoosDetails.moodyRate}</td></tr>
                            <tr><td className="label">Other:</td><td align="right">{melloRoosDetails.otherRate}</td></tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                  </table>
                </td>
              </tr>

              <tr className="information">
                <td colSpan="12">
                  <table>
                    <tr className="section-title"><td>Fund Balance</td></tr>
                    <tr>
                      <td>
                        <table>
                            <tr><td>Principal Amount of Bonds Outstanding:</td><td align="right">{CommonService.currencyFormatter(melloRoosDetails.principalOutstdgAmt)}</td></tr>
                            <tr><td>Bond Reserve Fund:</td><td align="right">{CommonService.currencyFormatter(melloRoosDetails.reserveOutstdgAmt)}</td></tr>
                            <tr><td>Capitalized Interest Fund:</td><td align="right">{CommonService.currencyFormatter(melloRoosDetails.capitalizedIntFundAmt)}</td></tr>
                            <tr><td>Construction Fund(s):</td><td align="right">{CommonService.currencyFormatter(melloRoosDetails.constructionFundAmount)}</td></tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr className="information">
                <td colSpan="12">
                  <table>
                    <tr className="section-title"><td>Assessed Value</td></tr>
                    <tr>
                      <td>
                        <table>
                            <tr><td className="label">Assessed or Appraised Value Reported as of:</td><td align="right">{CommonService.dateFormatter(melloRoosDetails.assessmentDate)}</td></tr>
                            <tr><td className="label">Use Appraised Value only in first year or before annual tax roll billing commences:</td><td align="right">{melloRoosDetails.assessmentFlag && (melloRoosDetails.assessmentFlag === "E" ? "From Equalized Tax Roll" : "From Appraisal of Property")}</td></tr>
                            <tr><td className="label">Total Assessed Value of All Parcels:</td><td align="right">{CommonService.currencyFormatter(melloRoosDetails.assessmentAmt)}</td></tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr className="information">
                <td>
                  <table>
                    <tr className="section-title"><td>Tax Collection</td></tr>
                    <tr>
                      <td>
                        <table>
                            <tr><td className="label">Total Amount of Special Taxes Due Annually:</td><td align="right">{CommonService.currencyFormatter(melloRoosDetails.taxDueAmt)}</td></tr>
                            <tr><td className="label">Total Amount of Unpaid Special Taxes Annually:</td><td align="right">{CommonService.currencyFormatter(melloRoosDetails.taxUnpaidAmt)}</td></tr>
                            <tr><td className="label">Does this agency participate in the County's Teeter Plan? </td><td align="right">{melloRoosDetails.teeterIndicator && (melloRoosDetails.teeterIndicator === "Y" ? "Yes" : "No")}</td></tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr className="information">
                <td>
                  <table>
                    <tr className="section-title"><td>Delinquent Reporting</td></tr>
                    <tr>
                      <td>
                        <table>
                            <tr><td className="label">Delinquent Parcel Information Reported as of Equalized Tax Roll of:</td><td align="right">{CommonService.dateFormatter(melloRoosDetails.dlqParcelDate)}</td></tr>
                            <tr><td className="label">Total Number of Delinquent Parcels:</td><td align="right">{melloRoosDetails.delinquentNbr}</td></tr>
                            <tr><td className="label">Total Amount of Special Taxes Due on Delinquent Parcels:</td><td align="right">{CommonService.currencyFormatter(melloRoosDetails.delinquentAmt)}</td></tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              {/* Delinquency Parcel Reporting */}
              <tr className="information">
                <td colSpan="8">
                  <table>
                    <tr className="section-title"><td>Delinquency Parcel Reporting</td></tr>
                    <tr className="heading">
                      <td align="left">Document Type</td>
                      <td align="left">Document Name</td>
                      <td align="left">File Upload Date</td>
                    </tr>
                    {(issueDocuments && issueDocuments.length > 0) ? issueDocuments.map((document, i) => {
                      return (<tr key={i}>
                        <td>{document.documentDesc}</td><td>{document.fileName}</td><td>{CommonService.dateFormatter(document.createDatetime)}</td>
                      </tr>
                      )}) :
                      <tr><td>No data available to disaplay.</td></tr>
                    }
                  </table>
                </td>
              </tr>
              
              <tr className="information">
                <td>
                  <table>
                    <tr className="section-title"><td>Foreclosure</td></tr>

                    <tr className="heading">
                      <td align="left">Date Foreclosure Commenced</td>
                      <td align="left">Total Number of Foreclosure Parcels</td>
                      <td align="left">Total Amount of Tax on Foreclosure Parcels</td>
                    </tr>
                    {foreclosures && foreclosures.map((item, i) => {
                      return (<tr key={i}>
                        <td>{CommonService.dateFormatter(item.foreclosureDate)}</td>
                        <td>{item.foreclosureParcelInt}</td>
                        <td>{CommonService.currencyFormatter(item.totalTaxDueAmt)}</td>
                      </tr>
                      )})
                    }
                  </table>
                </td>
              </tr>
              </tbody>
            </table>
            <table>
              <tbody>
              <tr className="information">
                <td>
                  <table>
                    <tbody>
                    <tr className="section-title"><td colSpan="2">Retired Issues</td></tr>
                    <tr>
                      <td>
                        <table>
                          <tbody>
                            <tr><td className="label-text">Indicate Reason for Retirement:</td><td align="right">{issuanceDetail.retireFlagDesc}</td></tr>
                            {issuanceDetail.mlrRetireFlag === "R" && <>
                            <tr><td className="label-text">CDIAC #:</td><td align="right">{issuanceDetail.redeemCDIACNumber}</td></tr>
                            <tr><td className="label-text">Refunding Issue Name:</td><td align="right">{issuanceDetail.stateRefundingBondTitle}</td></tr>
                            <tr><td className="label-text">Is the CDIAC mentioned correct?:</td><td align="right">{melloRoosDetails.correctCdiacNbrInd && (melloRoosDetails.correctCdiacNbrInd === "Y" ? "Yes" : "No")}</td></tr>
                            {melloRoosDetails.correctCdiacNbrInd && melloRoosDetails.correctCdiacNbrInd === "N" && <tr><td className="label-text">Corrected CDIAC #:</td><td align="right">{melloRoosDetails.correctedRedeemCdiacNbr}</td></tr>}
                            <tr><td className="label-text">Redemption Date:</td><td align="right">{CommonService.dateFormatter(issuanceDetail.retireDate)}</td></tr></>
                            }
                            {issuanceDetail.mlrRetireFlag === "M" && <>
                            <tr><td className="label-text">Final Maturity Date:</td><td align="right">{CommonService.dateFormatter(issuanceDetail.retireDate)}</td></tr></>
                            }
                            {issuanceDetail.mlrRetireFlag === "O" && <>
                            <tr><td className="label-text">Other Text:</td><td align="right">{issuanceDetail.otherRetireText}</td></tr>
                            <tr><td className="label-text">Date:</td><td align="right">{CommonService.dateFormatter(issuanceDetail.retireDate)}</td></tr></>
                            }
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              
              <tr className="information">
                <td>
                  <table>
                    <tbody>
                      <tr className="section-title"><td>Filing Contact</td></tr>
                      <tr>
                        <td>
                          <table>
                            <tbody>
                              <tr><td className="label-text">Filing Contact Name:</td><td align="right">{contact.contactFirstName} {contact.contactLastName}</td></tr>
                              <tr><td className="label-text">Agency/Organization Name:</td><td align="right">{contact.issuerName}</td></tr>
                              <tr><td className="label-text">Address:</td><td align="right">{contact.contactAddressLine1}</td></tr>
                              <tr><td className="label-text">City:</td><td align="right">{contact.contactCityName}</td></tr>
                              <tr><td className="label-text">State:</td><td align="right">{contact.contactStateCode}</td></tr>
                              <tr><td className="label-text">Zip Code:</td><td align="right">{contact.contactZipCode}</td></tr>
                              <tr><td className="label-text">Telephone:</td><td align="right">{contact.contactPhoneAreaCode + "-" +contact.contactPhoneNumber}</td></tr>
                              <tr><td className="label-text">Fax Number:</td><td align="right">{contact.contactFaxNumber}</td></tr>
                              <tr><td className="label-text">E-mail:</td><td align="right">{contact.contactEmailId}</td></tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr className="information">
                <td>
                  <table>
                    <tbody>
                    <tr className="section-title"><td>Comments</td></tr>
                    <tr><td className="label-text">Issuer Comments:</td><td>{melloRoosDetails.issuerComment}</td></tr>
                    </tbody>  
                  </table>
                </td>
              </tr>

            </tbody>
            </table>
            <table>
              {melloRoosDetails.statusFlag !== 'D' &&
              <tr className="information">
                <td colSpan="8">Submission Date:</td><td colSpan="4" align="right">{CommonService.dateFormatter(melloRoosDetails.filingDate)}</td>
              </tr>
              }
              {melloRoosDetails.statusFlag === 'R' &&
              <tr className="information">
                <td colSpan="8">Review Date:</td><td colSpan="4" align="right">{CommonService.dateFormatter(melloRoosDetails.lastUpdateDatetime)}</td>
              </tr>
              }
            </table>

           </div>
          </body>
        </html>
       </>
      );
    }

  export default ReviewComponent;
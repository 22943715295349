import CommonService from '../../services/CommonService'

const ReviewComponent = (props) => {
    const { markRoosLocalObligDetails, contact, issuanceDetail } = props;

    return (
        <>
        <html>
         <head>
         <meta charset="UTF-8"></meta>
         <style>{"\
          div.repeat-header {\
            padding-top: 0.2in !important;\
            display: block !important;\
            text-align: center;\
            position: running(repeat-header) !important;\
          }\
          .report-header {\
              text-align: center;\
              padding: 5px;\
              width: 100%;\
              height: 40px;\
          }\
          .report-heading-left {\
              font-size: 18px;\
              font-weight: 800;\
              color: darkblue;\
              text-align: left;\
              width: 20%;\
          }\
          .report-heading-center {\
              font-size: 12px;\
              font-weight: 800;\
              color: darkblue;\
              text-align: center;\
              width: 60%;\
          }\
          .report-heading-right {\
              font-size: 12px;\
              font-weight: 800;\
              color: darkblue;\
              text-align: right;\
              width: 20%;\
          }\
          .report-heading-title {\
              font-size: 18px;\
              font-weight: 800;\
              color: darkblue;\
              text-align: left;\
              min-width: 65%;\
          }\
          .report-heading-date {\
              font-size: 10px;\
              font-weight: 800;\
              color: darkblue;\
              text-align: right;\
          }\
          div.footer {\
            position: running(footer) !important;\
          }\
          #current-page-placeholder::before {\
            content: counter(page);\
          }\
          #total-pages-placeholder::before {\
            content: counter(pages);\
          }\
          @page {\
              margin-top: 90px;\
              @top-center {\
                  content: element(repeat-header) !important;\
              }\
          }\
          @page {\
              @bottom-right {\
                  content: element(footer) !important;\
                  font-size: 10px;\
              }\
          }\
          div.repeat-header table {\
              page-break-inside: avoid;\
          }\
          div.repeat-header tr {\
              page-break-inside: avoid;\
              page-break-after: auto\
          }\
          div.repeat-header thead {\
              display: table-header-group\
          }\
          div.repeat-header tfoot {\
              display: table-footer-group\
          }\
          div.break-page {\
              page-break-after: always;\
          }\
          .table a {\
              color: black;\
              text-decoration: underline;\
          }\
          .table .tableheader th, .table tbody th {\
              color: #fff;\
              background-color: darkblue;\
              border-color: #454d55;\
              vertical-align: middle;\
              font-size: 1.0em;\
          }\
          .v-top {\
              vertical-align: top !important;\
          }\
          tr {\
              page-break-inside: avoid\
          }\
          .invoice-box{\
            max-width: 800px;\
            margin: auto;\
            padding: 20px;\
            border: 1px solid #eee;\
            box-shadow: 0 0 10px rgba(0, 0, 0, .15);\
            font-size: 13px;\
            line-height: 18px;\
            font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;\
            color: #555;\
          }\
          .invoice-box table {\
            width: 100%;\
            line-height: inherit;\
            text-align: left;\
          }\
          .invoice-box table td {\
            padding: 5px;\
            vertical-align: top;\
          }\
        .border-top {\
              border-top: 2px solid #eee;\
          }\
          .invoice-box table tr.top table td.title {\
              font-size: 13px;\
              line-height: 22px;\
              color: #333;\
          }\
          .invoice-box table tr.information table td {\
              padding-bottom: 10px;\
          }\
          .invoice-box table tr.heading td {\
              border-bottom: 1px solid #ddd;\
              font-weight: bold;\
          }\
          .invoice-box table tr.sub-heading td {\
            font-weight: bold;\
          }\
          .invoice-box table tr.item td {\
            border-bottom: 1px solid #eee;\
          }\
          .invoice-box table tr.item.last td {\
              border-bottom: none;\
          }\
          .invoice-box table tr.total {\
              border-top: 2px solid #eee;\
              font-weight: bold;\
          }\
          .invoice-box table tr.section-title td {\
            font-weight: bold;\
            font-size: 18px;\
            line-height: 18px;\
            color: #000;\
          }\
          @media only screen and (max-width: 600px) {\
          .invoice-box table tr.top table td {\
              width: 100%;\
              display: block;\
              text-align: center;\
          }\
          .invoice-box table tr.information table td {\
              width: 100%;\
              display: block;\
              text-align: center;\
          }\
          }\
        "}</style>
         </head>
          <body className="pdf-box">
            <div className="invoice-box">
            <table cellPadding="0" cellSpacing="0">
              <tr className="information">
                <td colSpan="12">
                  <table>
                    <tbody>
                    <tr className="fs-12"><td><i>Information as of Reporting Year End: <b>{CommonService.dateFormatter(markRoosLocalObligDetails.fiscalYrEndDate)}</b></i></td></tr>
                    <tr className="section-title"><td>Issuance</td></tr>
                    <tr>
                      <td>
                        <table>
                            <tr><td colSpan="8">Issuer Name:</td><td colSpan="4" align="right">{issuanceDetail.issuerName}</td></tr>
                            <tr><td colSpan="8">Issue Name:</td><td colSpan="4" align="right">{issuanceDetail.issueName}</td></tr>
                            <tr><td colSpan="8">Project Name:</td><td colSpan="4" align="right">{issuanceDetail.projectName}</td></tr>
                            <tr><td colSpan="8">Date of Bond Issue/Loans:</td><td colSpan="4" align="right">{CommonService.dateFormatter(issuanceDetail.actualSaleDate)}</td></tr>
                            <tr><td colSpan="8">Settlement Date:</td><td colSpan="4" align="right">{CommonService.dateFormatter(issuanceDetail.settlementDate)}</td></tr>
                            <tr><td colSpan="8">Original Principal Amount:</td><td colSpan="4" align="right">{CommonService.currencyFormatter(issuanceDetail.principalSaleAmt)}</td></tr>
                            <tr><td colSpan="8">Date of Filing:</td><td colSpan="4" align="right">{CommonService.dateFormatter(markRoosLocalObligDetails.filingDate)}</td></tr>
                            <tr><td colSpan="8">Total Issuance Costs:</td><td colSpan="4" align="right">{CommonService.currencyFormatter(markRoosLocalObligDetails.totalIssuanceCostAmt)}</td></tr>
                            <tr><td colSpan="8">Total Cost of Issuance from Report of Final Sale:</td><td colSpan="4" align="right">{CommonService.currencyFormatter(issuanceDetail.totalIssuanceAmt)}</td></tr>
                            <tr><td colSpan="8">Name of Authority that purchased debt:</td><td colSpan="4" align="right">{issuanceDetail.authorityName}</td></tr>
                            <tr><td colSpan="8">Date of Authority Bond(s) Issuance:</td><td colSpan="4" align="right">{CommonService.dateFormatter(issuanceDetail.settlementDate)}</td></tr>
                            <tr><td colSpan="8">Total Costs of Issuance from Authority Report of Final Sale:</td><td colSpan="4" align="right">{CommonService.currencyFormatter(issuanceDetail.authorityTotalIssuanceCost)}</td></tr>
                            <tr><td colSpan="8">Is the Reserve Fund held at Authority level?:</td><td colSpan="4" align="right">{markRoosLocalObligDetails.reserveMinBalInd ? (markRoosLocalObligDetails.reserveMinBalInd === "Y" ? "Yes" : "No") : ""}</td></tr>
                            <tr><td colSpan="8">Reserve Fund Minimum Balance Amount:</td><td colSpan="4" align="right">{CommonService.currencyFormatter(markRoosLocalObligDetails.reserveMinBalAmt)}</td></tr>
                            <tr><td colSpan="8">Part of Authority Reserve Fund:</td><td colSpan="4" align="right">{markRoosLocalObligDetails.authorityReserveFundInd ? (markRoosLocalObligDetails.authorityReserveFundInd === "Y" ? "Yes" : "No") : ""}</td></tr>
                            <tr><td colSpan="8">Percent of Reserve Fund:</td><td colSpan="4" align="right">{markRoosLocalObligDetails.percentReservedFund}</td></tr>

                        </table>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr className="information">
                <td colSpan="12">
                  <table>
                    <tr className="section-title"><td>Fund Balance</td></tr>
                    <tr>
                      <td>
                        <table>
                            <tr><td colSpan="8">Principal Amount of Bonds Outstanding:</td><td colSpan="4" align="right">{CommonService.currencyFormatter(markRoosLocalObligDetails.principalOutstdgAmt)}</td></tr>
                            <tr><td colSpan="8">Bond Reserve Fund:</td><td colSpan="4" align="right">{CommonService.currencyFormatter(markRoosLocalObligDetails.bondReserveFundAmt)}</td></tr>
                            <tr><td colSpan="8">Capitalized Interest Fund:</td><td colSpan="4" align="right">{CommonService.currencyFormatter(markRoosLocalObligDetails.capitalizedIntFundAmt)}</td></tr>
                            <tr><td colSpan="8">Administrative Fee Charged by Authority:</td><td colSpan="4" align="right">{CommonService.currencyFormatter(markRoosLocalObligDetails.adminFeeChargeAmt)}</td></tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr className="information">
                <td colSpan="12">
                  <table>
                    <tr className="section-title"><td>Delinquent Reporting</td></tr>
                    <tr>
                      <td>
                        <table>
                            <tr><td colSpan="8">Have Delinquent Taxes been reported?</td><td colSpan="4" align="right">{markRoosLocalObligDetails.dlqTaxIndicator}</td></tr>
                            <tr><td colSpan="8">Delinquent Parcel Information Reported as of Equalized Tax Roll of:</td><td colSpan="4" align="right">{CommonService.dateFormatter(markRoosLocalObligDetails.dlqParcelDate)}</td></tr>
                            <tr><td colSpan="8">Delinquency Rate:</td><td colSpan="4" align="right">{markRoosLocalObligDetails.dlqRate}</td></tr>
                            <tr><td colSpan="8">Does this agency participate in the County’s Teeter Plan?</td><td colSpan="4" align="right">{markRoosLocalObligDetails.teeterIndicator}</td></tr>
                            <tr><td colSpan="8">Taxes Due:</td><td colSpan="4" align="right">{CommonService.currencyFormatter(markRoosLocalObligDetails.dlqTaxDueAmt)}</td></tr>
                            <tr><td colSpan="8">Taxes Unpaid:</td><td colSpan="4" align="right">{CommonService.currencyFormatter(markRoosLocalObligDetails.dlqTaxUnpaidAmt)}</td></tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr className="information">
                <td colSpan="12">
                  <table>
                    <tr className="section-title"><td>Retired Issues</td></tr>
                    <tr>
                      <td>
                        <table>
                          <tbody>
                            <tr><td className="label-text">Indicate Reason for Retirement:</td><td align="right">{issuanceDetail.retireFlagDesc}</td></tr>
                            {issuanceDetail.retireFlag === "R" && <>
                            <tr><td className="label-text">CDIAC #:</td><td align="right">{issuanceDetail.redeemCDIACNumber}</td></tr>
                            <tr><td className="label-text">Refunding Issue Name:</td><td align="right">{issuanceDetail.stateRefundingBondTitle}</td></tr>
                            <tr><td className="label-text">Is the CDIAC mentioned correct?:</td><td align="right">{markRoosLocalObligDetails.correctCdiacNbrInd && (markRoosLocalObligDetails.correctCdiacNbrInd === "Y" ? "Yes" : "No")}</td></tr>
                            {markRoosLocalObligDetails.correctCdiacNbrInd && markRoosLocalObligDetails.correctCdiacNbrInd === "N" && <tr><td className="label-text">Corrected CDIAC #:</td><td align="right">{markRoosLocalObligDetails.correctedRedeemCdiacNbr}</td></tr>}
                            <tr><td className="label-text">Redemption Date:</td><td align="right">{CommonService.dateFormatter(issuanceDetail.retireDate)}</td></tr></>
                            }
                            {issuanceDetail.retireFlag === "M" && <>
                            <tr><td className="label-text">Final Maturity Date:</td><td align="right">{CommonService.dateFormatter(issuanceDetail.retireDate)}</td></tr></>
                            }
                            {issuanceDetail.retireFlag === "O" && <>
                            <tr><td className="label-text">Other Text:</td><td align="right">{issuanceDetail.otherRetireText}</td></tr>
                            <tr><td className="label-text">Date:</td><td align="right">{CommonService.dateFormatter(issuanceDetail.retireDate)}</td></tr></>
                            }
                          </tbody>
                           
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr className="information">
                <td colSpan="12">
                  <table>
                    <tr className="section-title"><td>Filing Contact</td></tr>
                    <tr>
                      <td>
                        <table>
                            <tr><td colSpan="8">Filing Contact Name:</td><td colSpan="4" align="right">{contact.contactFirstName} {contact.contactLastName}</td></tr>
                            <tr><td colSpan="8">Agency/Organization Name:</td><td colSpan="4" align="right">{contact.issuerName}</td></tr>
                            <tr><td colSpan="8">Address:</td><td colSpan="4" align="right">{contact.contactAddressLine1}</td></tr>
                            <tr><td colSpan="8">City:</td><td colSpan="4" align="right">{contact.contactCityName}</td></tr>
                            <tr><td colSpan="8">State:</td><td colSpan="4" align="right">{contact.contactStateCode}</td></tr>
                            <tr><td colSpan="8">Zip Code:</td><td colSpan="4" align="right">{contact.contactZipCode}</td></tr>
                            <tr><td colSpan="8">Telephone:</td><td colSpan="4" align="right">{contact.contactPhoneAreaCode + "-" +contact.contactPhoneNumber}</td></tr>
                            <tr><td colSpan="8">Fax Number:</td><td colSpan="4" align="right">{contact.contactFaxNumber}</td></tr>
                            <tr><td colSpan="8">E-mail:</td><td colSpan="4" align="right">{contact.contactEmailId}</td></tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              {/* Comments */}
              <tr className="information">
                <td colSpan="12">
                  <table>
                    <tr className="section-title"><td>Comments</td></tr>
                    <tr>
                        <td>
                        <table>
                            <tr><td className="label-text">Issuer Comments:</td><td>{markRoosLocalObligDetails.issuerComment}</td></tr>
                        </table>
                        </td>
                    </tr>
                  </table>
                </td>
              </tr>

            </table>
            <table>
              {markRoosLocalObligDetails.statusFlag !== 'D' &&
              <tr className="information">
                <td colSpan="8">Submission Date:</td><td colSpan="4" align="right">{CommonService.dateFormatter(markRoosLocalObligDetails.filingDate)}</td>
              </tr>
              }
              {markRoosLocalObligDetails.statusFlag === 'R' &&
              <tr className="information">
                <td colSpan="8">Review Date:</td><td colSpan="4" align="right">{CommonService.dateFormatter(markRoosLocalObligDetails.lastUpdateDatetime)}</td>
              </tr>
              }
            </table>
           </div>
          </body>
        </html>
       </>
    );

  }
  export default ReviewComponent;
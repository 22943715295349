import CommonService from "./CommonService";


const getStatutesByIssueId = (obj) => {
    return CommonService.apicall(obj)
};

const addStatute = (obj) => {
    return CommonService.apicall(obj)
}

const updateStatute = (obj) => {
    return CommonService.apicall(obj)
}

const deleteStatute = (obj) => {
    return CommonService.apicall(obj)
}
const getStatuteCodes = (obj) => {
    return CommonService.apicall(obj)
}

const IssueStatutesService = {
    getStatutesByIssueId,
    addStatute,
    updateStatute,
    deleteStatute,
    getStatuteCodes
};


export default IssueStatutesService;
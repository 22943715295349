import {useState} from 'react'
import {Form, Modal, Button} from 'react-bootstrap'
import classes from './reportProposedSale.module.css'
import { useNavigate } from 'react-router-dom'

const AcknowledgementModal = ({onModalClose, issueId}) => {
    const navigate = useNavigate();
    const [btnDisabled, setBtnDisabled] = useState(true);
    const handleChange = (e) => {
       if(e.target.checked) {
        setBtnDisabled(false);
       } else {
        setBtnDisabled(true);
       }
    }

    const handleSubmit = () => {
        onModalClose();
        navigate('/dashboard/report-proposed-sale/' + issueId);

    }

    return (
        <Modal show={true} onHide={onModalClose} backdrop="static" keyboard={false}  size="lg">
            <Modal.Header closeButton>
            <Modal.Title>REPORT OF PROPOSED DEBT ISSUANCE</Modal.Title>
            </Modal.Header>
            <Modal.Body className={classes.modalbody}>
               <div className="mb-10" >
                   <p>The RPDI, due to CDIAC no later than 30 days prior to sale, is the first in a series of reports that issuers of state and local debt in California are required to submit to the California Debt and Investment Advisory Commission (CDIAC) pursuant to California Government Code section 8855 and the California Code of Regulations Title 4, sections 6000 – 6062</p>
                   <p>Data fields marked with an asterisk are required fields and must be completed in order to successfully submit the report. Data submitted on the RPDI may be edited post-submission or updated on the Report of Final Sale (RFS), due to CDIAC no later than 21 days after sale.</p>
                   <p>The information submitted on the RPDI is precedent to the Report of Final Sale (RFS) which is precedent to all subsequent annual reports including the Annual Debt Transparency, Mark- and Mello-Roos Yearly Fiscal Status, and Default/Draw Reports. Due to the cumulative nature of reporting, care should be taken to submit accurate information.</p>
                   <p>The Data Portal is designed to accommodate and track each debt “Issue” [CCR Title 4, section 6000(q)] beginning with the RPDI and assignment of a unique CDIAC #. Each CDIAC# generates annual reporting requirements. <b>“Splitting” issues, submitting multiple RPDIs for a single issue, should be avoided</b>. Splitting issues will generate recurring annual reporting obligations that may be unnecessary. Contact CDIAC if you would like help to determine if “splitting” is appropriate.</p>
                   <p>CDIAC will conduct a review of submitted information to identify and correct errors and omissions, but given the sheer volume of reports, CDIAC cannot guarantee accuracy. Issuer’s and their authorized filing delegates are responsible for the accuracy of the data submitted and compliance with legal requirements.</p>
               </div>

               <div>
                <Form.Group className="mb-3">
                    <Form.Check inline type="checkbox" id="acknowledge" label="I Acknowledge" onChange={e=>handleChange(e)}/>
                </Form.Group>
               </div>
            </Modal.Body>
            <Modal.Footer>
            <Button className="custom-button-secondary btn-secondary" onClick={onModalClose}>
                Close
            </Button>
            <button type="button" className="custom-button" disabled={btnDisabled} onClick={handleSubmit}>
                Submit
            </button>
            </Modal.Footer>
        </Modal>
    )
}

export default AcknowledgementModal

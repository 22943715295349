import {useState, useContext} from 'react'
import { Row, Col, Modal } from 'react-bootstrap'
import Card from '../ui/Card'
import {AiFillQuestionCircle} from 'react-icons/ai'
import CurrencyInput from '../formElements/CurrencyInput'
import CommonService from '../../services/CommonService'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import { toast } from 'react-toastify';
import {UserContext} from '../../UseContext'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { FaCalendarAlt} from 'react-icons/fa'
import { validateFormFields, runValidations } from '../../utils/index'
import { MDBTooltip } from 'mdbreact'

const InvestmentContracts = (props) => {
    const { markRoosAuthorityDetails } = props;
    const [showInstructionsModal, setShowInstructionsModal] = useState(false);
    const [stateValues, setStateValues] = useState({
        invCtrctIntEarnAmt : markRoosAuthorityDetails !== undefined ? markRoosAuthorityDetails.invCtrctIntEarnAmt : '',
        invCtrctCommFeeAmt: markRoosAuthorityDetails !== undefined ? markRoosAuthorityDetails.invCtrctCommFeeAmt : '',
        invCtrctMaturityDate: markRoosAuthorityDetails.invCtrctMaturityDate  ? new Date(markRoosAuthorityDetails.invCtrctMaturityDate) : null,
    })

    const validateForm = (e) => {

        let form = e.target.closest('form#investmentContractForm');

        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});
            updateMarkRoosAuthorityInvestmentContract();
            props.openWidget('retiredIssues')
        }

    }

    const updateMarkRoosAuthorityInvestmentContract = async() => {

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "updateMarkRoosAuthorityInvestmentContract",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {  "id": markRoosAuthorityDetails.markRoosAuthorityId, "markRoosAuthority":{
                "invCtrctIntEarnAmt" : stateValues.invCtrctIntEarnAmt,
                "invCtrctCommFeeAmt" : stateValues.invCtrctCommFeeAmt,                
                "invCtrctMaturityDate" : stateValues.invCtrctMaturityDate,
                "lastUpdateUser":  CommonService.getUserName(loginUser),
                "emailId": loginUser
            }
            }
        };
    
       await FinalSaleReportService.updateMarkRoosAuthorityInvestmentContract(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                alert(result.data.errors[0].message)
            }
            else{
                props.updateMarkRoosAuthorityDependencies(result.data.responses[0], 'mkrAuthorityData');
                toast.success("Investment Contract updated Successfully!!");
            }

        });
    }



    const [formErrors, setFormErrors] = useState({});
    const loginUser = useContext(UserContext);

    const handleChange = (e) => {
        const {name, value} = e.target;
        
        setStateValues(prevState => ({
            ...stateValues, [name]: value
        })
        )
    }
    const handleDateChange = (e, key) => {        
        setStateValues(prevState => ({
            ...stateValues, [key]: e
        })
        )
    }

    const validateField = (e) => {
        if ( e.target.dataset['validations'] )
            runValidations(e) 
    }

    return (
        <Card>
            <form className="form" id="investmentContractForm">
                <h3>Investment Contracts <AiFillQuestionCircle onClick={()=>setShowInstructionsModal(true)} className="help-icon heading-help-icon"/>

                    {showInstructionsModal && 
                        <Modal show={true} backdrop="static" onHide={()=>setShowInstructionsModal(false)}>
                            <Modal.Body>
                                <div>
                                Report interest earnings, payment of commissions and fees as well as each contract’s maturity and other terms such as prematurity liquidity, collateralization, credit ratings and downgrades.
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button variant="secondary" onClick={()=>setShowInstructionsModal(false)}>Close</button>
                            </Modal.Footer>
                        </Modal>
                    }
                </h3>

                <Row>
                    <Col md={6}>
                    <div className="control">
                    <label>Final Maturity of the Investment Contract
                        <MDBTooltip domElement tag="span" placement="right">
                        <span>{"  "}<AiFillQuestionCircle className="help-icon heading-help-icon"/></span>
                        <span>Indicate Final Maturity of Investment Contract.</span>
                        </MDBTooltip>
                    </label>
                    <div>
                        <span>
                        <DatePicker 
                        selected={stateValues && stateValues.invCtrctMaturityDate ? stateValues.invCtrctMaturityDate : null}
                        dateFormat="M/d/yyyy"
                        maxDate= {new Date(9999, 12, 31)}
                        name="dlqParcelDate"
                        onChange={(e) => handleDateChange(e, "invCtrctMaturityDate")} 
                        error={formErrors && formErrors['invCtrctMaturityDate']}                                
                        />
                        <FaCalendarAlt className="date-picker-icon"/>
                        </span>

                    </div>
                </div>

                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                    <div className="control">
                        <CurrencyInput 
                        placeholder="0.00" 
                        label="Commission/Fee for Contract (total)" 
                        name="invCtrctCommFeeAmt" 
                        type="text" 
                        value={stateValues.invCtrctCommFeeAmt ? stateValues.invCtrctCommFeeAmt   : ""}
                        onChange={(e)=>handleChange(e)} 
                        onBlur={e=>validateField(e)}
                        error={formErrors.invCtrctCommFeeAmt} 
                        validations={["max:999999999999.99"]}
                        helpText="Indicate Fees paid during FY for which you are filing."
                        />
                  </div>
                    </Col>
                </Row>
                <Row >
                    <Col md={6}>
                    <div className="control">
                        <CurrencyInput 
                        placeholder="0.00" 
                        label="Interest Earnings on Contract (current)" 
                        name="invCtrctIntEarnAmt" 
                        type="text" 
                        value={stateValues.invCtrctIntEarnAmt ? stateValues.invCtrctIntEarnAmt   : ""}
                        onChange={(e)=>handleChange(e)} 
                        onBlur={e=>validateField(e)}
                        error={formErrors.invCtrctIntEarnAmt} 
                        validations={["max:999999999999.99"]}
                        helpText="Indicate Interest Earnings during FY for which you are filing."
                        />
                  </div>
                    </Col>
                </Row>
                <div className="btn-div">
                    {/* <button className="custom-button" type="button" onClick={() => props.openWidget('localObligor')} >Back</button> */}
                    <button className="fright custom-button" type="button" onClick={e=>validateForm(e)}>Save & Next</button>
                </div>
            </form>
        </Card>
    )
}

export default InvestmentContracts

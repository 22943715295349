import { Spinner, Modal } from "react-bootstrap";

const Loader = () => {
return(    <Modal show={true} className="modal bd-example-modal-lg" data-backdrop="static">
        <div className="modal-dialog modal-sm">
                <Spinner animation="border"/>
        </div>
    </Modal>
)
}

export default Loader;
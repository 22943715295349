import { Col, Row, Form} from 'react-bootstrap'
import {useState, useEffect} from 'react'
import Alert from 'react-bootstrap/Alert'
import AuthorizationService from '../../../services/AuthorizationService'
import moment from 'moment'
import Spinner from 'react-bootstrap/Spinner'
import { Typeahead } from 'react-bootstrap-typeahead'
import { toast } from 'react-toastify'
import { useContext } from 'react'
import { UserContext, IssuerContext } from "../../../UseContext"
import CommonService from "../../../services/CommonService"
import CurrencyInput from '../../formElements/CurrencyInput'
import { validateFormFields } from '../../../utils'
import DatePicker from 'react-datepicker'
import { FaCalendarAlt } from 'react-icons/fa'
import { MDBTooltip } from 'mdbreact'
import {AiFillQuestionCircle} from 'react-icons/ai'
const AddAuthorizationRef = (props) => {
    const user = useContext(UserContext);
    const issuerUid = useContext(IssuerContext);
    const {onModalClose} = props;
    const [errorMessage, setErrorMessage] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [issuers, setIssuers] = useState([]);
    const [selected, setSelected] = useState();
    const [electionDate, setElectionDate] = useState(null);
    const [formErrors, setFormErrors] = useState({});

    const [authInfo, setAuthInfo] = useState({
        issuerId: issuerUid,
        originType: '',
        authorizationName: '',
        authorizationAmt: null,
      })

    const handleChange = (e, key) => {
        let selectedValue = "";
        if(key === "issuerId" ) {
            selectedValue = e.length ? e[0][key] : "";
        } else {
            selectedValue = e.target.value;
        }
        setAuthInfo(prevState=>({
        ...authInfo, 
            [key]: selectedValue
        })
        )        
    }

    useEffect(() => {
        //Get Issuers   
        var obj = {
          msgId: null,
          sysId: null,
          opr: "getIssuers",
          hdrStruct: null,
          queryParam: null,
          reqData: { },
        };
    
        AuthorizationService.getIssuers(obj)
          .then((res) => res)
          .then((result) => {
            if (result.data.errors[0]) {
                toast.error(result.data.errors[0]);
            } else {
                setIssuers(result.data.responses[0]);
                const issuer = result.data.responses[0].filter(el => el.issuerId === issuerUid);
                setSelected(issuer);
                setAuthInfo(prevState=>({
                ...prevState, 
                    'issuerId': issuer[0].issuerId
                })
                )  
            }
           setIsLoading(false);
          });
      }, [issuerUid]);

    const saveAuthorization = () => {
        
        var obj = {
            msgId: null,
            sysId: null,
            opr: "postAuthorizationRef",
            hdrStruct: null,
            queryParam: null,
            reqData: { "authorizationRef":{
                "issuerId": authInfo.issuerId,
                "authorizationName": authInfo.authorizationName,
                "authorityOriginCode": authInfo.originType,
                "electionDate": moment(electionDate).format('YYYY-MM-DD'),
                "authorizationAmt":parseFloat(authInfo.authorizationAmt.replace(/\$|,/g, '')),
                "createUser":  CommonService.getUserName(user),
                "lastUpdateUser":  CommonService.getUserName(user)
            }
            },
          };

          AuthorizationService.addAuthorizationRef(obj)
            .then((res) => res)
            .then((result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    setErrorMessage(result.data.errors[0].message)
                }
                else{
                    toast.success("Authorization added successfully!");
                    onModalClose();
                }
                
            });
      }

    const handleSubmit = (e) => {
        const form = e.target.closest('form#authForm');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});
            saveAuthorization();
        }
    }
    return (     

        <>      
            {isLoading && <Spinner animation="border" variant="primary" />}

            {errorMessage && <Alert variant="danger">
                        <p>
                            {errorMessage}
                        </p>
              </Alert>}
            
            <form id="authForm">

                <Row>
                    <Col md={6} className="control">
                    <label><span className="required">*</span>Issuer Name:</label>
                    <Typeahead
                            placeholder="Choose an Issuer"
                            id="issurer"
                            labelKey="issuerName"
                            onChange={e=>handleChange(e, "issuerId")}
                            inputProps={{ 'data-validations': 'required', name: 'issuerName', maxLength: '10' }}
                            options={issuers}
                            disabled
                            selected={selected}
                    />
                    </Col>
                    <Col md={6} className="control">
                    <label><span className="required">*</span>Authorization Name:
                    <MDBTooltip domElement tag="span" placement="right">
                    <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                    <span>Enter the name of the voter-approved measure that provided the authorization for the issue, or the name of the resolution of the governing body, if not voter-approved debt (e.g. Measure P or Resolution 136-22).</span>
                    </MDBTooltip>
                    </label>
                    <input name="authorizationName" value={authInfo.authorizationName} onChange={(e)=>handleChange(e, 'authorizationName')} data-validations={["required"]}/>
                     {formErrors.authorizationName && <p style={{ color: 'red' }}>Authorization Name is required</p>}
                    </Col>
                </Row>
                      
                        <Row>
                        <Col md={6}>
                            <label><span className="required">*</span>Voter Measure:
                            <MDBTooltip domElement tag="span" placement="right">
                            <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                            <span>Was this authorization provided by vote of the electorate?</span>
                            </MDBTooltip></label>
                            <Form.Check
                                inline
                                label="No"
                                name="voterMeasureInd"
                                value="N"
                                type="radio"
                                id="voterMeasure_0"
                                checked={authInfo.originType === 'I'}
                                onChange={(e)=>setAuthInfo({...authInfo, 'originType' : 'I' })}
                            />
                            <Form.Check
                                inline
                                label="Yes"
                                name="voterMeasureInd"
                                value="Y"
                                type="radio"
                                id="voterMeasure_1"
                                checked={authInfo.originType === 'E'}
                                onChange={(e)=>setAuthInfo({...authInfo, 'originType' : 'E' })}
                            />
                            {formErrors.originType && <p className="error">{formErrors.originType}</p>}
                        </Col>
                        <Col md={6} className="control">
                            <label><span className="required">*</span>Authorization Date (By Election or Governing Body): (MM/DD/YYYY):
                            <MDBTooltip domElement tag="span" placement="right">
                                <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                                <span>The date of the election or act of the governing body.</span>
                                </MDBTooltip>
                            </label>
                            <span>
                            <DatePicker 
                            selected={electionDate}
                            dateFormat="M/d/yyyy"
                            type="text"
                            data-validations={['required']}
                            onChange={(e) => setElectionDate(e, "electionDate")} 
                            error={formErrors && formErrors["electionDate"]}                                
                            />
                            <input type="hidden" value={electionDate} data-validations={['required']} name="electionDate" />
                            <FaCalendarAlt className="date-picker-icon"/>
                            </span>
                           {formErrors.electionDate && <p style={{ color: 'red' }}>Election Date is required</p>}
                        </Col>
                        <Col md={6} className="control">
                            <div className="control">
                            <CurrencyInput placeholder="0.00" 
                                label="Authorization Amount" 
                                name="authorizationAmt" 
                                type="text" 
                                value={authInfo.authorizationAmt}
                                onChange={(e)=>handleChange(e, 'authorizationAmt')} 
                                validations={["required", "max:999999999999.99"]}
                                isrequired="true"
                                helpText="Enter the maximum amount of debt authorized by voters or action of governing body."/>
                            </div>
                            {formErrors.authorizationAmt && <p style={{ color: 'red' }}>Authorization Amount is required</p>}
                        </Col>
                    </Row> 
                    <div className="btn-div">
                        <div style={{float: 'right'}}>
                        <button type="button" className="custom-button-secondary  btn-secondary" onClick={onModalClose}>Cancel</button>{" "}
                        <button className="custom-button" type="button" onClick={(e)=>handleSubmit(e)}>Save</button>
                        </div>
                    </div>
                </form>               
        </>
       
    )
}

export default AddAuthorizationRef

import React from "react";
import {
  Table as BSTable, Form
} from "react-bootstrap";
import {FaPencilAlt} from 'react-icons/fa'
import {VscTrash} from 'react-icons/vsc'
import CommonService from "../services/CommonService";
import CurrencyInput from "./formElements/CurrencyInput";
// import { FiThumbsDown } from "react-icons/fi";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {FaCalendarAlt} from 'react-icons/fa'
import {MDBTooltip} from 'mdbreact'
import {AiFillQuestionCircle} from 'react-icons/ai'
const column = (
  x,
  y,
  i,
  handleChange
) => {
  
    switch(y.fieldType) {
      case("list") :
        return (
          <Form.Select name={y.prop} value={Object.keys(y.listOptions).find(key => y.listOptions[key] === x[y.prop])} onChange={(e)=>handleChange(e, y.prop, i)} disabled={y.editable === false}>
                <option>select</option>
                
              {(Object.keys(y.listOptions).length !== 0) && Object.entries(y.listOptions).map(([k, val]) =>{
                  return (<option key={k} value={k}>{val}</option>);
              }
              ) }
          </Form.Select>
        )
      case("currency") :
        return(
          <div className="control"><CurrencyInput name={y.prop}
          onChange={e => handleChange(e, y.prop, i, x)}
          value={x[y.prop]}
          disabled={y.editable === false}/></div>
        );
      case("date") :
        return(
          <div className="control">
            <span>
              <DatePicker 
                selected={x[y.prop] ? new Date(x[y.prop]) : null}
                dateFormat="M/d/yyyy"
                maxDate= {new Date(9999, 12, 31)}
                name="{y.prop}"
                type="date"
                onChange={(e)=>handleChange(e, y.prop, i, x)}
                disabled={y.editable === false} 
                />
              <FaCalendarAlt className="date-picker-icon"/>
            </span>
          </div>
        )
      case("number") :
        return(
          <Form.Control
          name={y.prop}
          type="number"
          onChange={e => handleChange(e, y.prop, i)}
          value={x[y.prop]}
          disabled={y.editable === false}
        />
        )
      default :
        return(
          <Form.Control
            name={y.prop}
            onChange={e => handleChange(e, y.prop, i)}
            value={x[y.prop]}
            disabled={y.editable === false}
          />
        )
    }
  
}
const row = (
  x,
  i,
  header,
  handleRemove,
  startEditing,
  editIdx,
  handleChange,
  stopEditing,
  handleSave
) => {
  const currentlyEditing = editIdx === i;
  return (
    <tr key={`tr-${i}`} selectable="false">
      {header.map((y, k) => (
        !y.hidden &&
        <td key={`trc-${k}`} className={y.prop}>
          {currentlyEditing ? (
              column(
                x,
                y,
                i,
                handleChange,
              )
          ) : (
            y.fieldType === 'currency' && typeof(x[y.prop]) === 'number' ? 
            (CommonService.currencyFormatter(x[y.prop])) :
           ( y.fieldType === 'date' ? CommonService.dateFormatter(x[y.prop]) : x[y.prop])
          )}
        </td>
      ))}
      <td style={{verticalAlign: 'middle'}}>
        {currentlyEditing ? (
          <>
          <button
          type="button"
          className="link-button dk-blue-color"
          style={{textDecoration: 'none', paddingRight: '1em'}}
          onClick={() => handleSave(i, x)}>Save</button>
          <button
            type="button"
            className="link-button dk-blue-color"
            style={{textDecoration: 'none', paddingLeft: '1em'}}
            onClick={() => stopEditing(i)}>Cancel</button></>
          // <FcCheckmark onClick={() => stopEditing(i, x)} />
        ) : (
          <>{!x.notEditable && <FaPencilAlt onClick={() => startEditing(i, x)} /> }</>
        )}
      </td>
      {handleRemove &&
      <td>
        <VscTrash onClick={() => handleRemove(i, x)} />
      </td>
      }
    </tr>
  );
};


const Table = ({
  data,
  header,
  handleRemove,
  startEditing,
  editIdx,
  handleChange,
  stopEditing,
  handleSave,
  isEditable,
  hidden
}) => {
  return(
  <BSTable>
    <thead>
      <tr>
        {header.map((x, i) => (
          !x.hidden && <td key={`thc-${i}`}>{x.name} {x.helpText && <MDBTooltip domElement tag="span" placement="right">
          <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
          <span>{x.helpText}</span>
      </MDBTooltip>}</td>
        ))}
        <td />
        <td />
      </tr>
    </thead>
    <tbody>
      {data.map((x, i) =>
        row(
          x,
          i,
          header,
          handleRemove,
          startEditing,
          editIdx,
          handleChange,
          stopEditing,
          handleSave,
          hidden
        )
      )}
    </tbody>
  </BSTable>
  );
};

export default Table;
import {useState, useContext} from 'react'
import { Collapse } from "react-bootstrap"
import Issuance from './issuance'
import FundBalance from './fundBalance'
import {FaPlus, FaMinus} from 'react-icons/fa'
import 'bootstrap/dist/css/bootstrap.min.css'
import ProfessionalFees from './professionalFees'
import LocalObligor from './localObligor'
import RetiredIssues from './retiredIssues'
import FilingContact from './filingContact'
import Comments from './comments'
import MKRAuthorityIssueYFSReview from './mkrAuthorityIssueYFSReview'
import InvestmentContracts from "./investmentContracts"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faCheck} from "@fortawesome/free-solid-svg-icons"
import { IssuanceDetailContext } from '../../UseContext'

const CollapsibleWidgets = ({openSectionObj, toggleWidget, openSection, markRoosAuthority, markRoosAuthorityDetails, contact, updateMarkRoosAuthorityDependencies, updateIssuanceDetails, markRoosAuthorityDependencies}) => {
    const issuanceDetail = useContext(IssuanceDetailContext);
    const [sectionValidated, setSectionValidated] = useState({
      issuance: markRoosAuthorityDetails?.totalIssuanceCostAmt ? true : false,
      fundBalance: markRoosAuthorityDetails?.principalOutstdgAmt ? true : false,
      retiredIssues: issuanceDetail.retireFlag ? true : false,
    });
    const updateSectionValidated = (sectionName, isValid) => {
      setSectionValidated(prevState=>({
        ...prevState, [sectionName]: isValid
      }))
    }
    return (
      <>
      <div className="panel panel-default">
        <div className="panel-heading" role="tab"> 
          <h5 className="panel-title icon-style" onClick={()=>toggleWidget('issuance')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}} 
             id="issuance" aria-expanded={openSectionObj.issuance} aria-controls="collapse_issuance" > {!openSectionObj.issuance ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Issuance </button> 
          {sectionValidated.issuance && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
          </h5>
          <Collapse in={openSectionObj.issuance}>
            <div id="collapse_issuance">
            {openSectionObj.issuance && <Issuance openWidget={openSection} markRoosAuthorityDetails={markRoosAuthorityDetails} updateMarkRoosAuthorityDependencies={updateMarkRoosAuthorityDependencies}/>}
            </div>
          </Collapse>
        </div>
      </div>

      <div className="panel panel-default">
       <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title icon-style" onClick={()=>toggleWidget('fundBalance')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}} 
            id="draw" aria-expanded={openSectionObj.fundBalance} aria-controls="collapse_fundBalance"> {!openSectionObj.fundBalance ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Fund Balance</button> 
          {sectionValidated.fundBalance && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
        </h5>
        <Collapse in={openSectionObj.fundBalance}>
          <div id="collapse_fundBalance">
          {openSectionObj.fundBalance && <FundBalance openWidget={openSection} markRoosAuthorityDetails={markRoosAuthorityDetails} updateMarkRoosAuthorityDependencies={updateMarkRoosAuthorityDependencies} />}
            </div>
        </Collapse>
      </div>
      </div>

      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title icon-style"  onClick={()=>toggleWidget('professionalFees')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
            id="professionalFees" aria-expanded={openSectionObj.professionalFees} aria-controls="collapse_professionalFees"> {!openSectionObj.professionalFees ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Professional Fees</button> 
        </h5>
        <Collapse in={openSectionObj.professionalFees}>
          <div id="collapse_professionalFees">
          {openSectionObj.professionalFees && <ProfessionalFees openWidget={openSection} markRoosAuthorityDetails={markRoosAuthorityDetails} updateMarkRoosAuthorityDependencies={updateMarkRoosAuthorityDependencies}/> }
          </div>
        </Collapse>
      </div>
      </div>

      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title icon-style" onClick={()=>toggleWidget('localObligor')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
            id="localObligor" aria-expanded={openSectionObj.localObligor} aria-controls="collapse_localObligor" onClick={()=>toggleWidget('localObligor')}> {!openSectionObj.localObligor ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Local Obligors</button> 
        </h5>
        <Collapse in={openSectionObj.localObligor}>
          <div id="collapse_localObligor">
          {openSectionObj.localObligor && <LocalObligor openWidget={openSection} markRoosAuthorityDetails={markRoosAuthorityDetails} markRoosAuthority={markRoosAuthority} updateMarkRoosAuthorityDependencies={updateMarkRoosAuthorityDependencies} /> }
            </div>
        </Collapse>
      </div>
      </div>
      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title icon-style" onClick={()=>toggleWidget('investmentContracts')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
            id="investmentContracts" aria-expanded={openSectionObj.investmentContracts} aria-controls="collapse_investmentContracts" onClick={()=>toggleWidget('investmentContracts')}> {!openSectionObj.investmentContracts ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Investment Contracts</button> 
        </h5>
        <Collapse in={openSectionObj.investmentContracts}>
          <div id="collapse_investmentContracts">
            <InvestmentContracts openWidget={openSection} markRoosAuthorityDetails={markRoosAuthorityDetails} updateMarkRoosAuthorityDependencies={updateMarkRoosAuthorityDependencies}  />
          </div>
        </Collapse>
      </div>
      </div>

      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title icon-style" onClick={()=>toggleWidget('retiredIssues')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
            id="retiredIssues" aria-expanded={openSectionObj.retiredIssues} aria-controls="collapse_retiredIssues"> {!openSectionObj.retiredIssues ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Retired Issues</button> 
          {sectionValidated.retiredIssues && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
        </h5>
        <Collapse in={openSectionObj.retiredIssues}>
          <div id="collapse_retiredIssues">
          {openSectionObj.retiredIssues && <RetiredIssues openWidget={openSection} sectionValidated={updateSectionValidated} previousWidget={"investmentContracts"} markRoosAuthority={markRoosAuthority} markRoosAuthorityDetails={markRoosAuthorityDetails} updateIssuanceDetails={updateIssuanceDetails} updateMarkRoosAuthorityDependencies={updateMarkRoosAuthorityDependencies}/>}
            </div>
        </Collapse>
      </div>
      </div>

      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title icon-style" onClick={()=>toggleWidget('filingContact')}>
        <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}} 
             id="filingContact" aria-expanded={openSectionObj.filingContact} aria-controls="collapse_filingContact"> {!openSectionObj.filingContact ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Filing Contact</button> 
        </h5>
        <Collapse in={openSectionObj.filingContact}>
          <div id="collapse_filingContact">
          {openSectionObj.filingContact && <FilingContact openWidget={openSection}/>}
          </div>
        </Collapse>
      </div>
      </div>
      
      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title icon-style" onClick={()=>toggleWidget('comments')}>
        <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}} 
             id="comments" aria-expanded={openSectionObj.comments} aria-controls="collapse_comments"> {!openSectionObj.comments ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Comments</button> 
        </h5>
        <Collapse in={openSectionObj.comments}>
          <div id="collapse_comments">
          {openSectionObj.comments && <Comments openWidget={openSection} sectionName={'review'} markRoosAuthority={markRoosAuthority} markRoosAuthorityDetails={markRoosAuthorityDetails} updateMarkRoosAuthorityDependencies={updateMarkRoosAuthorityDependencies}/>}
            </div>
        </Collapse>
      </div>
      </div>

      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title icon-style" onClick={()=>toggleWidget('review')}>
        <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}} 
             id="review" aria-expanded={openSectionObj.review} aria-controls="collapse_review" > {!openSectionObj.review ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Review</button> 
        </h5>
        <Collapse in={openSectionObj.review}>
          <div id="collapse_review">
            <MKRAuthorityIssueYFSReview openWidget={openSection} toggleWidget={toggleWidget} sectionName={'review'} sectionValidated={sectionValidated} markRoosAuthority={markRoosAuthority} markRoosAuthorityDependencies={markRoosAuthorityDependencies} contact={contact} />
            </div>
        </Collapse>
      </div>
      </div>
      </>
    );
}

export default CollapsibleWidgets

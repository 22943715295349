import Header from '../common/Header';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../configManager';

const Login = () => {
    const { instance, accounts } = useMsal();

    //const navigate = useNavigate();
    if(accounts[0]) {
        sessionStorage.setItem('user', JSON.stringify(accounts[0]));
       // navigate('/dashboard');
    }

    function handleLogin(instance) {
        instance.loginRedirect(loginRequest)
        .then(response => {
        })
        .catch(e => {
            console.error(e);
        });
    }

    return (
        <div>
            <Header />
            <div className="login">
                <form style={{ backgroundColor: '#fff' }}>
                    {/* <button type="button" className="link-button"><a href="/filerRegistrationForm">Register to File on Behalf of an Issuer</a></button>
                     { " | " }
                    <button type="button" className="link-button" ><a href="/issuerRegistrationForm">Register a New Issuer</a></button> 
                    {/* <br/> */}
                    <div style={{marginLeft: '30%', marginTop: '20px'}}>
                    <button type="button" className="custom-button" onClick={() => handleLogin(instance)}>Sign In</button>
                    </div>
                </form>
            </div>
            <div className="">
                <div className="user-note"> 
                <p>If you are NEW to the Data Portal please visit <a href="https://www.treasurer.ca.gov/cdiac/reporting.asp" target="_blank" rel="noreferrer" >https://www.treasurer.ca.gov/cdiac/reporting.asp</a> to view the Data Portal Sign In Guide.</p> 
                {/* <p>Click “Register to File on Behalf of an Issuer” ONLY if you are NEW to filing debt issuance reports or have an email that recently changed and are filing reports on behalf of an Issuer.<br/>
                If you have filed reports in which your existing email was listed as a Financing Participant contact prior to the launch of Data Portal, your email is likely pre-registered within the system. Do not complete this registration - click the “Sign In” button and click “Forgot your password” then follow the prompts.<br/>
                ISSUERS: If you are an Issuer (entity of State or Local Government) do NOT complete this registration - click the “Sign In” button and click “Forgot your password” then follow the prompts. If you receive an error message that “an account could not be found” contact CDIAC_Issuance@treasurer.ca.gov.  </p>                  */}
                </div>
            </div>
        </div>
    )
}

export default Login

import { Modal, Row, Col } from 'react-bootstrap'
import {useState, useEffect, useContext} from 'react'
import CurrencyInput from '../../formElements/CurrencyInput'
import {FormInput} from '../../formElements/FormInput'
import "react-datepicker/dist/react-datepicker.css"
import {validateFormFields} from '../../../utils'
import IssueAuthorizationService from '../../../services/IssueAuthorizationService'
import { UserContext } from '../../../UseContext'
import CommonService from '../../../services/CommonService'
import { toast } from 'react-toastify'

const EditIssuanceAuthorization = (props) => {
    const loginUser = useContext(UserContext);
    const {onModalClose, authInfo, issueId, isAdtrFirstYear} = props;
    const [formErrors,setFormErrors] = useState({});
    const [authorizationInfo, setAuthorizationInfo] = useState({...authInfo, authorizationDate: CommonService.dateFormatter(authInfo.authorizationDate)});
    const [debtAthorized, setDebtAuthorized] = useState(0);
    const [unIssuedAthorizedDebt, setUnIssuedAthorizedDebt] = useState(0);
    const [remainingTotal, setRemainingTotal] = useState(0);
        

    useEffect(()=> {
        let net = authInfo.beginAmt + authInfo.duringAmt;
        let unIssuedDebt = ( net + authInfo.authorityReplenishAmt) - authInfo.authorityReducedAmt;
        let endTotalAmt = ( net + authInfo.authorityReplenishAmt) - authInfo.authorityReducedAmt - authInfo.lapsedAmt;
        setDebtAuthorized(net);
        setUnIssuedAthorizedDebt(unIssuedDebt);
        setRemainingTotal(endTotalAmt);
    },[authInfo]);


    const handleAuthChange = (e) => {
        const {name, value} = e.target;
        let authArray = {...authorizationInfo};
        
        authArray[name] = value;
        
        setAuthorizationInfo(authArray);
    }

    const calculateAuthorizedDebt = () => {
        let authArray = {...authorizationInfo};
        const beginAmt = ( typeof authArray.beginAmt === 'string' ) 
            ? authArray['beginAmt'] !== "" ? parseFloat(authArray['beginAmt'].replace(/,/g, '')) : 0.00
            : authArray['beginAmt'] !== "" ? authArray['beginAmt'] : 0;    
    
        const duringAmt = ( typeof authArray.duringAmt === 'string' ) 
            ? authArray['duringAmt'] !== "" ? parseFloat(authArray['duringAmt'].replace(/,/g, '')) : 0.00
            : authArray['duringAmt'] !== "" ? authArray['duringAmt'] : 0;  

        const lapsedAmt = ( typeof authArray.lapsedAmt === 'string' ) 
            ? authArray['lapsedAmt'] !== "" ? parseFloat(authArray['lapsedAmt'].replace(/,/g, '')) : 0.00
            : authArray['lapsedAmt'] !== "" ? authArray['lapsedAmt'] : 0;    

        let total = (beginAmt*10 + duringAmt*10)/10;
        setDebtAuthorized(total);
        let unIssuedDebt = ((total + authArray.authorityReplenishAmt) - authArray.authorityReducedAmt);
        setUnIssuedAthorizedDebt(unIssuedDebt);

        let remainingTotal = ((total  + authArray.authorityReplenishAmt) - (authArray.authorityReducedAmt + lapsedAmt));
        setRemainingTotal(remainingTotal);

    }
  
    const updateAuthorization = async (issueAuthorization) => { 
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putAdtrAuthorization",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id" : issueAuthorization.adtrAuthorizationId,
                "adtrAuthorization": {
                    "adtrId": issueId,
                    "duringAmt" : issueAuthorization.duringAmt,
                    "lapsedAmt" : issueAuthorization.lapsedAmt,
                    "beginAmt" : issueAuthorization.beginAmt,
                    "lastUpdateDatetime": new Date(),
                    "lastUpdateUser": loginUser
                }
            }
        };
       
        await IssueAuthorizationService.updateAuthorization(obj)
        .then((res) => res)
        .then((result) => {
            if(result.data.errros && result.data.errors.length>0){
                console.log(result.data.errors[0].message);
            } else {
                toast.success("Issuance Authorization updated successfully");
            }
        });
    }

    const handleSubmit = (e) => {
        const form = e.target.closest('form#add-form');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});
            updateAuthorization(authorizationInfo);
            onModalClose();
        }
    }
    return (
        <Modal  show={true} onHide={onModalClose} className="authModal">
            <Modal.Header closeButton><h3>Edit Issuance Authorization</h3></Modal.Header>
            <Modal.Body>
                <form id="add-form">
                    <Row>
                        <Col md={12}>
                            <div className="control">
                                <FormInput 
                                    label="Authorization Name"
                                    name="authorizationName" 
                                    value={authorizationInfo.authorizationName}
                                    helpText="Read-only. Populated from data submitted on the RFS. Name of the voter-approved measure that provided the
                                    authorization for the issue, or the name of the resolution of the governing body, if not voter-approved debt."
                                    disabled
                                />
                            </div>
                        </Col>
                        <Col md={6}>    
                            <div className="control">
                                <CurrencyInput 
                                label="Original Authorized Amount" 
                                name="originalAuthorizedAmt" 
                                value={authorizationInfo.originalAuthorizedAmt} 
                                onChange={(e)=>handleAuthChange(e)}
                                validations={ ["required"]}
                                error={formErrors && formErrors['originalAuthorizedAmt']} 
                                helpText="Read-only. Populated from data submitted on the RFS. The maximum amount of debt authorized by voters or action of
                                the governing body."
                                disabled/>
                            </div>
                        </Col> 
                        <Col md={6}>
                            <div className="control">
                                <FormInput 
                                    label="Authorization Date (MM/DD/YYYY)"
                                    value={authorizationInfo.authorizationDate}
                                    dateFormat="M/d/yyyy"
                                    name="authorizationDate"
                                    helpText="Read-only. Populated from data submitted in the RFS. The date of the election or act of the governing body."
                                    disabled                                
                                />
                            </div>
                        </Col> 
                        <div className="control">
                            <CurrencyInput 
                                label="Amount Authorized - Beginning of the Reporting Period" 
                                name="beginAmt" 
                                value={authorizationInfo.beginAmt} 
                                onBlur={(e)=>calculateAuthorizedDebt(e, 'beginAmt')}
                                onChange={(e)=>handleAuthChange(e)}
                                validations={isAdtrFirstYear ? "required" : ""}
                                error ={ formErrors && formErrors['beginAmt'] }
                                disabled={isAdtrFirstYear ? false : true}
                                isrequired={isAdtrFirstYear ? true : false}
                                helpText="Enter the amount of the authorization that had not been issued at the beginning of the reporting period – 1 st year ADTR
                                only. After 1 st ADTR, this field will be read-only and populated with the Total Authorization Remaining from the prior
                                year’s ADTR." />
                        </div>
                        <div className="control">
                            <CurrencyInput 
                                label="Amount Authorized - During Reporting Period" 
                                name="duringAmt" 
                                value={authorizationInfo.duringAmt} 
                                onBlur={(e)=>calculateAuthorizedDebt(e, 'duringAmt')}
                                onChange={(e)=>handleAuthChange(e)}
                                validations={["required"]}
                                error ={ formErrors && formErrors['duringAmt'] }
                                isrequired="true"
                                helpText="If the Authorization Date was within the reporting period, enter the Original Authorized Amount. If the authorized
                                amount was increased during the reporting period not as a result of a replenishment reported to CDIAC on a Report of
                                Final Sale, enter the amount of the increase."/>
                        </div>
                        
                        <div className="control">
                            <FormInput 
                                label="Total Debt Authorized" 
                                value={CommonService.currencyFormatter(debtAthorized)} 
                                disabled readOnly
                                helpText="Read-only. Calculated field."/>
                        </div>
                        <div className="control">
                            <CurrencyInput 
                                label="Debt Issued During the Reporting Period" 
                                name="authorityReducedAmt" 
                                value={authorizationInfo.authorityReducedAmt} 
                                helpText="Read-only. Populated with the amount this authorization was reduced by any debt issued during the reporting period
                                and reported to CDIAC."
                                disabled readOnly/>
                        </div>                    
                        <div className="control">
                            <CurrencyInput
                            label="Replenishment Reported During the Reporting Period" 
                            name="authorityReplenishAmt" 
                            value={authorizationInfo.authorityReplenishAmt} 
                            helpText="Read-only. Populated with the amount this authorization was replenished during the reporting period from RFSs
                            reported to CDIAC."
                            disabled readOnly/>
                        </div>
                        <div className="control">
                            <FormInput
                            label="Total Debt Authorized but Unissued" 
                            value={CommonService.currencyFormatter(unIssuedAthorizedDebt)} 
                            disabled readOnly
                            helpText="Read-only. Calculated field."/>
                        </div>
                        <div className="control">
                            <CurrencyInput
                            label="Authorization Lapsed" 
                            name="lapsedAmt" 
                            value={authorizationInfo.lapsedAmt} 
                            onBlur={(e)=>calculateAuthorizedDebt(e, 'lapsedAmt')}
                            onChange={(e)=>handleAuthChange(e, 'lapsedAmt')}
                            validations={ ["required"]}
                            error={formErrors && formErrors['lapsedAmt']}
                            isrequired="true"
                            helpText="Enter the amount of the authorization that lapsed during the reporting period. A lapse is a permanent reduction of the
                            authorization for reasons including an expiration or a direct action of the governing body."
                            />
                        </div>
                        <div className="control">
                            <FormInput
                            label="Total Authorization Remaining - End of Reporting Period" 
                            value={CommonService.currencyFormatter(remainingTotal)} 
                            disabled readOnly
                            helpText="Read-only. Calculated field."/>
                        </div>
                    </Row>

                    <div className="btn-div">
                    <div className="fright">
                    <button type="button" className="custom-button-secondary" onClick={()=>onModalClose()}>Cancel</button>{" "}
                    <button type="button" className="custom-button" onClick={(e)=>handleSubmit(e)}>Save</button>
                    </div>
                </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default EditIssuanceAuthorization

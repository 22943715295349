import React, { useState, useContext } from 'react'
import Card from '../ui/Card'
import { Form, Row, Col } from "react-bootstrap"
import { validateFormFields } from '../../utils/index'
import {AiFillQuestionCircle} from 'react-icons/ai'
import { MDBTooltip } from 'mdbreact'
import { FaCalendarAlt} from 'react-icons/fa'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import { UserContext } from '../../UseContext'
import CommonService from '../../services/CommonService'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { toast } from 'react-toastify'

const Dates = (props) => {
    const {finalSaleDetails, updateFinalSaleDetails} = props;
    const loginUser = useContext(UserContext);
    const [finalSaleInfo, setFinalSaleInfo] = useState(finalSaleDetails ? 
        {...finalSaleDetails, 
            'actualSaleDate': finalSaleDetails.actualSaleDate ? new Date(finalSaleDetails.actualSaleDate) : null, 
            'settlementDate': finalSaleDetails.settlementDate ? new Date(finalSaleDetails.settlementDate) : null, 
            'datedDate': finalSaleDetails.datedDate ? new Date(finalSaleDetails.datedDate) : null ,
            'finalMaturityDate': finalSaleDetails.finalMaturityDate ? new Date(finalSaleDetails.finalMaturityDate) : null,
            'firstOptionalCallDate': finalSaleDetails.firstOptionalCallDate ? new Date(finalSaleDetails.firstOptionalCallDate) : null 
        } : 
        {});

    const [formErrors, setFormErrors] = useState({})
    

    const handleDate = (e, key) => {
        setFinalSaleInfo(prevState=>({
            ...prevState, 
            [key]: e
        })
        )
    }

    const saveFinalSaleInfo = () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putFinalSale",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id": finalSaleInfo.finalSaleId,
                "finalSale": {...finalSaleInfo,
                    'lastUpdateUser': CommonService.getUserName(loginUser)}
            }
        };
    
        FinalSaleReportService.updateFinalSale(obj)
        .then((res) => res)
        .then((result) => {
            if(result.data.responses[0]) {
                toast.success("Dates Updated Successfully!");
                updateFinalSaleDetails(result.data.responses[0]);
                props.sectionValidated('dates', true);
                props.openWidget('issuanceAuthorization');
            }
        });
        
    }
    
    const validateForm = (e) => {
        let form = e.target.closest('form#datesForm');

        let formErrors = validateFormFields(form.elements);

        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
            props.sectionValidated('dates', false);
        } else {
            setFormErrors({});
            saveFinalSaleInfo();
        }
    }

    return (
        <Card>
        <Form className="form" id="datesForm">
            <Row>
            <Col md={6} className="control">
            <label><span className="required">*</span>Proposed Sale Date: (MM/DD/YYYY)
                <MDBTooltip domElement tag="span" placement="right">
                    <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                    <span>Prepopulated with data submitted on the RPDI.</span>
                </MDBTooltip>
                </label> 
                <input name="proposedSaleDate" 
                value={CommonService.dateFormatter(finalSaleInfo.proposedSaleDate)}
                disabled={true}
                />
            </Col>
            <Col md={6}>
                <div className="control">
                    <label><span className="required">*</span>Actual Sale Date: (MM/DD/YYYY) 
                        <MDBTooltip domElement tag="span" placement="right">
                            <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                            <span>Enter the date this debt issue was sold.</span>
                        </MDBTooltip>
                    </label>
                    <div>
                        <span>
                        <DatePicker 
                        selected={finalSaleInfo && finalSaleInfo.actualSaleDate ? finalSaleInfo.actualSaleDate : null}
                        dateFormat="M/d/yyyy"
                        maxDate= {new Date(9999, 12, 31)}
                        name="actualSaleDate"
                        onChange={(e) => handleDate(e, "actualSaleDate")} 
                        error={formErrors && formErrors['actualSaleDate']}                                
                        />
                        <FaCalendarAlt className="date-picker-icon"/>
                        </span>
                        <input type="hidden" data-validations={["required"]} value={finalSaleInfo.actualSaleDate ? finalSaleInfo.actualSaleDate : ""} name="actualSaleDate" />
                        {formErrors.actualSaleDate && <p className="error">{formErrors.actualSaleDate}</p>}
                    </div>
                </div>
            </Col>
            <Col md={6}>
                <div className="control">
                    <label><span className="required">*</span>Settlement Date: (MM/DD/YYYY) 
                        <MDBTooltip domElement tag="span" placement="right">
                            <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                            <span>Enter the closing date of the transfer of assets or rights to use from creditor to the issuer in exchange for
delivery of the instruments or evidence of indebtedness.</span>
                        </MDBTooltip>
                    </label>
                    <div>
                        <span>
                        <DatePicker 
                        selected={finalSaleInfo && finalSaleInfo.settlementDate ? finalSaleInfo.settlementDate : null}
                        dateFormat="M/d/yyyy"
                        maxDate= {new Date(9999, 12, 31)}
                        name="settlementDate"
                        onChange={(e) => handleDate(e, "settlementDate")} 
                        error={formErrors && formErrors['settlementDate']} 
                        data-validations={["required"]}                               
                        />
                        <FaCalendarAlt className="date-picker-icon"/>
                        </span>
                        {formErrors.settlementDate && <p className="error">{formErrors.settlementDate ? finalSaleInfo.settlementDate : ""}</p>}
                        <input type="hidden" data-validations={["required"]} value={finalSaleInfo.settlementDate} name="settlementDate" />
                    </div>
                </div>
            </Col>
            <Col md={6}>
                <div className="control">
                    <label><span className="required">*</span>Dated Date: (MM/DD/YYYY)
                        <MDBTooltip domElement tag="span" placement="right">
                            <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                            <span>Enter the date on which interest on the issue begins to accrue.</span>
                        </MDBTooltip>
                    </label>
                    <div>
                        <span>
                        <DatePicker 
                        selected={finalSaleInfo && finalSaleInfo.datedDate ? finalSaleInfo.datedDate : null}
                        dateFormat="M/d/yyyy"
                        maxDate= {new Date(9999, 12, 31)}
                        name="datedDate"
                        onChange={(e) => handleDate(e, "datedDate")} 
                        error={formErrors && formErrors['datedDate']} 
                        data-validations={["required"]}                               
                        />
                        <FaCalendarAlt className="date-picker-icon"/>
                        </span>
                        <input type="hidden" data-validations={["required"]} value={finalSaleInfo.datedDate ? finalSaleInfo.datedDate : ""} name="datedDate" />
                        {formErrors.datedDate && <p className="error">{formErrors.datedDate}</p>}

                    </div>
                </div>
            </Col>
            <Col md={6}>
                <div className="control">
                    <label><span className="required">*</span>Final Maturity Date: (MM/DD/YYYY)
                        <MDBTooltip domElement tag="span" placement="right">
                            <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                            <span>Enter the date of the issuer’s final payment obligation to repay the entire issue without exercise of any option
to cause the debt to be repaid earlier.</span>
                        </MDBTooltip>
                    </label>
                    <div>
                        <span>
                        <DatePicker 
                        selected={finalSaleInfo && finalSaleInfo.finalMaturityDate ? finalSaleInfo.finalMaturityDate : null}
                        dateFormat="M/d/yyyy"
                        maxDate= {new Date(9999, 12, 31)}
                        name="finalMaturityDate"
                        onChange={(e) => handleDate(e, "finalMaturityDate")} 
                        error={formErrors && formErrors['finalMaturityDate']} 
                        data-validations="required"                              
                        />
                        <FaCalendarAlt className="date-picker-icon"/>
                        </span>
                        <input type="hidden" data-validations={["required"]} value={finalSaleInfo.finalMaturityDate ? finalSaleInfo.finalMaturityDate : ""} name="finalMaturityDate" />
                        {formErrors.finalMaturityDate && <p className="error">{formErrors.finalMaturityDate}</p>}
                    </div>
                </div>
            </Col>
            <Col md={6}>
                <div className="control">
                <label><span className="required">*</span>First Optional Call Date: (MM/DD/YYYY) 
                <MDBTooltip domElement tag="span" placement="right">
                            <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                            <span>The first date on which the issuer has the option to prepay all or part of the debt, if applicable. For variable rate
demand bonds, enter the first remarketing date. If there is NO Call Date – enter the Final Maturity Date.</span>
                        </MDBTooltip>
                </label>
                <span>
                    <DatePicker 
                    selected={finalSaleInfo && finalSaleInfo.firstOptionalCallDate ? finalSaleInfo.firstOptionalCallDate : null}
                    dateFormat="M/d/yyyy"
                    maxDate= {new Date(9999, 12, 31)}
                    name="firstOptionalCallDate"
                    onChange={(e) => handleDate(e, "firstOptionalCallDate")} 
                    error={formErrors && formErrors['firstOptionalCallDate']} 
                    data-validations="required"                              
                    />
                    <FaCalendarAlt className="date-picker-icon"/>
                </span>
                <input type="hidden" data-validations={["required"]} value={finalSaleInfo.firstOptionalCallDate ? finalSaleInfo.firstOptionalCallDate : ""} name="firstOptionalCallDate" />
                {formErrors['firstOptionalCallDate'] && <p className="error">{formErrors['firstOptionalCallDate']}</p>}
                </div>
            </Col>
            </Row>

            <div className="btn-div">
            {/* <button className="custom-button">Back</button> */}
            <button className="custom-button fright" type="button" onMouseDown={(e)=>validateForm(e)}>Save & Next</button>
            </div>
            
        </Form> 
        </Card>
    )
}

  
export default Dates;
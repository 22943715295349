import CommonService from '../../services/CommonService'

const PrintPdfComponent = (props) => {
      const {proposedDebtDetails, proposeDebtDependencies} = props; 

      return (
       <>
        <html>
         <head>
         <meta charset="UTF-8"></meta>
         <style>{"\
          div.repeat-header {\
            padding-top: 0.2in !important;\
            display: block !important;\
            text-align: center;\
            position: running(repeat-header) !important;\
          }\
          .report-header {\
              text-align: center;\
              padding: 5px;\
              width: 100%;\
              height: 40px;\
          }\
          .report-heading-left {\
              font-size: 18px;\
              font-weight: 800;\
              color: darkblue;\
              text-align: left;\
              width: 20%;\
          }\
          .report-heading-center {\
              font-size: 12px;\
              font-weight: 800;\
              color: darkblue;\
              text-align: center;\
              width: 60%;\
          }\
          .report-heading-right {\
              font-size: 12px;\
              font-weight: 800;\
              color: darkblue;\
              text-align: right;\
              width: 20%;\
          }\
          .report-heading-title {\
              font-size: 18px;\
              font-weight: 800;\
              color: darkblue;\
              text-align: left;\
              min-width: 65%;\
          }\
          .report-heading-date {\
              font-size: 10px;\
              font-weight: 800;\
              color: darkblue;\
              text-align: right;\
          }\
          div.footer {\
            position: running(footer) !important;\
          }\
          #current-page-placeholder::before {\
            content: counter(page);\
          }\
          #total-pages-placeholder::before {\
            content: counter(pages);\
          }\
          @page {\
              margin-top: 90px;\
              @top-center {\
                  content: element(repeat-header) !important;\
              }\
          }\
          @page {\
              @bottom-right {\
                  content: element(footer) !important;\
                  font-size: 10px;\
              }\
          }\
          div.repeat-header table {\
              page-break-inside: avoid;\
          }\
          div.repeat-header tr {\
              page-break-inside: avoid;\
              page-break-after: auto\
          }\
          div.repeat-header thead {\
              display: table-header-group\
          }\
          div.repeat-header tfoot {\
              display: table-footer-group\
          }\
          div.break-page {\
              page-break-after: always;\
          }\
          .table a {\
              color: black;\
              text-decoration: underline;\
          }\
          .table .tableheader th, .table tbody th {\
              color: #fff;\
              background-color: darkblue;\
              border-color: #454d55;\
              vertical-align: middle;\
              font-size: 1.0em;\
          }\
          .v-top {\
              vertical-align: top !important;\
          }\
          tr {\
              page-break-inside: avoid\
          }\
          .invoice-box{\
            max-width: 800px;\
            margin: auto;\
            padding: 20px;\
            border: 1px solid #eee;\
            box-shadow: 0 0 10px rgba(0, 0, 0, .15);\
            font-size: 13px;\
            line-height: 18px;\
            font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;\
            color: #555;\
          }\
          .invoice-box table {\
            width: 100%;\
            line-height: inherit;\
            text-align: left;\
          }\
          .invoice-box table td {\
            padding: 5px;\
            vertical-align: top;\
          }\
        .border-top {\
              border-top: 2px solid #eee;\
          }\
          .invoice-box table tr.top table td.title {\
              font-size: 13px;\
              line-height: 22px;\
              color: #333;\
          }\
          .invoice-box table tr.information table td {\
              padding-bottom: 10px;\
          }\
          .invoice-box table tr.heading td {\
              border-bottom: 1px solid #ddd;\
              font-weight: bold;\
          }\
          .invoice-box table tr.item td {\
            border-bottom: 1px solid #eee;\
          }\
          .invoice-box table tr.item.last td {\
              border-bottom: none;\
          }\
          .invoice-box table tr.total {\
              border-top: 2px solid #eee;\
              font-weight: bold;\
          }\
          .invoice-box table tr.section-title td {\
            font-weight: bold;\
            font-size: 18px;\
            line-height: 18px;\
            color: #000;\
          }\
          @media only screen and (max-width: 600px) {\
          .invoice-box table tr.top table td {\
              width: 100%;\
              display: block;\
              text-align: center;\
          }\
          .invoice-box table tr.information table td {\
              width: 100%;\
              display: block;\
              text-align: center;\
          }\
          }\
        "}</style>
         </head>
          <body className="pdf-box">
            <div className="invoice-box">
            <table cellPadding="0" cellSpacing="0">
              <tr className="information">
                <td colSpan="12">
                  <table>
                    <tr className="section-title"><td>Issuance</td></tr>
                    <tr>
                      <td>
                        <table>
                            <tr><td colSpan="8">Issuer Name:</td><td colSpan="6" align="right">{proposedDebtDetails.issuerName}</td></tr>
                            <tr><td colSpan="8">Issue Name:</td><td colSpan="6" align="right">{proposedDebtDetails.issueName}</td></tr>
                            <tr><td colSpan="8">Project Name:</td><td colSpan="6" align="right">{proposedDebtDetails.projectName}</td></tr>
                            <tr><td colSpan="8">Proposed Sale Date:</td><td colSpan="6"  align="right">{CommonService.dateFormatter(proposedDebtDetails.proposedSaleDate)}</td></tr>
                            <tr><td colSpan="8">Principal to be Sold:</td><td colSpan="6" align="right">{CommonService.currencyFormatter(proposedDebtDetails.principalSaleAmt)}</td></tr>
                            <tr><td colSpan="8">Is any portion of the principal to refund, redeem, paydown, or refinance outstanding debt?</td><td colSpan="6" align="right">{proposedDebtDetails.refndRedeemPaydnRefinInd !== null ? (proposedDebtDetails.refndRedeemPaydnRefinInd === 'N' ? 'No' :'Yes') : ''}</td></tr>
                            {(proposedDebtDetails.refndRedeemPaydnRefinInd !== null && proposedDebtDetails.refndRedeemPaydnRefinInd === 'Y') && 
                                <tr><td colSpan="8">Refunding Amount:</td><td colSpan="6" align="right">{CommonService.currencyFormatter(proposedDebtDetails.refndRedeemPaydnRefinAmt)}</td></tr>
                              }
                            {(proposedDebtDetails.refndRedeemPaydnRefinInd !== null && proposedDebtDetails.refndRedeemPaydnRefinInd === 'Y') && 
                                <tr><td colSpan="12"><table>
                                  <tr><td colSpan="12">CDIAC# of the debt to be refunded, redeemed, paid down, or refinanced: </td></tr>
                                <tr className="heading">
                                  <td align="left" colSpan="6">CDIAC #</td>
                                  <td align="left" colSpan="6">Amount</td>
                                </tr>
                              {proposeDebtDependencies.issueRefundAssocs.length > 0 ? proposeDebtDependencies.issueRefundAssocs.map((refundAssoc, i) => {
                                return (<tr key={i}>
                                  <td colSpan="6">{refundAssoc.linkedCdiacNbr}</td><td colSpan="6">{CommonService.currencyFormatter(refundAssoc.redeemAmt)}</td>
                                </tr>
                                )}) :
                                <tr><td>No data available to disaplay.</td></tr>
                              }
                              </table></td></tr>
                             }
                            <tr><td colSpan="8">Issuer certifies that it has complied with Gov. Code section 8855(i) with respect to local debt policies:</td><td colSpan="6" align="right">{proposedDebtDetails.debtPolicyFlag !== null ? (proposedDebtDetails.debtPolicyFlag === 'N' ? 'No' : (proposedDebtDetails.debtPolicyFlag === 'Y' ? 'Yes' : 'Not Applicable') ) : ''}</td></tr>
                            
                            {proposeDebtDependencies.issueSocEnvImpacts.length > 0 && <tr className="heading">
                              <td align="left" colSpan="8">
                              Environmental / Social Impact Type
                              </td>
                              <td align="left">
                                  Amount
                              </td>
                            </tr>
                            }
                            {proposeDebtDependencies.issueSocEnvImpacts.map((impact, i) => {
                              return (<tr key={i}>
                                <td colSpan="8">{impact.envSocImpactDisplayName}</td><td>{CommonService.currencyFormatter(impact.envSocialImpactAmt)}</td>
                              </tr>
                              )})
                            }
                            
                            <tr><td colSpan="8">Marks-Roos Authority/TRAN Pool Authority:</td><td align="right">{proposedDebtDetails.marksRoosAuthorityInd !== null ? (proposedDebtDetails.marksRoosAuthorityInd === 'N' ? 'No' :'Yes') : ''}</td></tr>

                            <tr><td colSpan="8">Marks-Roos Local Obligor/TRAN Participant:</td><td align="right">{proposedDebtDetails.marksRoosLocalObligInd !== null ? (proposedDebtDetails.marksRoosLocalObligInd === 'N' ? 'No' :'Yes') : ''}</td></tr>
                            {(proposedDebtDetails.marksRoosLocalObligInd !== null && proposedDebtDetails.marksRoosLocalObligInd === 'Y') && 
                                <tr><td colSpan="12"><table>
                                  <tr><td colSpan="12">CDIAC# of the related Authorities: </td></tr>
                                <tr className="heading">
                                  <td align="left" colSpan="6">CDIAC #</td>
                                </tr>
                              {proposeDebtDependencies.issueMkrAuthAssocs.length > 0 ? proposeDebtDependencies.issueMkrAuthAssocs.map((mkrAuthAssoc, i) => {
                                return (<tr key={i}>
                                  <td colSpan="6">{mkrAuthAssoc.authCdiacNbr}</td>
                                </tr>
                                )}) :
                                <tr><td>No data available to disaplay.</td></tr>
                              }
                              </table></td></tr>
                             }
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr className="information">
                <td>
                  <table>
                    <tr className="section-title"><td>Issuance Authorization</td></tr>
                    {proposeDebtDependencies.issueAuthorizations.map((auth, i) => {
                      return (<tr key={i}><td><table>
                      <tr><td><b>Authorization ({i+1}): </b></td></tr>
                                                   
                      <tr><td>Authorization Name:</td><td align="right">{auth.authorizationName}</td></tr>
                      <tr><td>Original Authorized Amount:</td><td align="right">{CommonService.currencyFormatter(auth.originalAuthorizedAmt)}</td></tr>
                      <tr><td>Authorization Date (MM/DD/YYYY):</td><td align="right">{CommonService.dateFormatter(auth.authorizationDate)}</td></tr>
                      <tr><td>Amount Authorization Replenished (By this Issue):</td><td align="right">{CommonService.currencyFormatter(auth.authorityReplenishAmt)}</td></tr>
                      <tr><td>Amount Authorization Reduced (By this Issue):</td><td align="right">{CommonService.currencyFormatter(auth.authorityReducedAmt)}</td></tr>
                      <tr><td>Net Change (By this Issue):</td><td align="right">{CommonService.currencyFormatter(auth.authorityReplenishAmt - auth.authorityReducedAmt)}</td></tr>
                      </table></td></tr>
                      )})
                    }
                  </table>
                </td>
              </tr>
              {/* Statutory Authority */}
              <tr className="information">
                <td>
                  <table>
                    <tr className="section-title"><td>Statutory Authority</td></tr>

                    <tr><td colSpan="3">Will a Validation Action be Pursued?</td><td align="right">{proposedDebtDetails.validationActionFlag !== null ? (proposedDebtDetails.validationActionFlag === 'N' ? 'No' : 'Yes'): ''}</td></tr>
                  </table>
                  <table>
                    <tr><td colSpan="3"><b>Statutory Authorities</b></td></tr>
                    {proposeDebtDependencies.issueStatutes.length > 0 ? proposeDebtDependencies.issueStatutes.map((statute, i) => {
                      return (<tr key={i}><td colSpan="3"><table>
                      <tr><b>Statute ({i+1}): </b></tr>
                                                   
                                <tr><td>Statute:</td><td>{statute.displayStatuteName}</td></tr>
                                {statute.statuteId === 'OTH' &&
                                <>
                                <tr><td>Statute Code:</td><td>{statute.otherStatuteCode}</td></tr>
                                <tr><td>Statute Section:</td><td>{statute.otherStatuteText}</td></tr>
                                <tr><td>Statute Description:</td><td>{statute.otherStatuteBriefDesc}</td></tr>
                                </>}
                              
                      </table></td></tr>
                      )})
                      :
                      <tr><td>No data available to display.</td></tr>
                    }
                  </table>
                </td>
              </tr>
              
              <tr className="information">
                <td colSpan="8">
                  <table>
                    <tr className="section-title"><td>Tax Status/Interest</td></tr>
                    <tr>
                      <td>
                        <table>
                            <tr><td className="total currency">Under State Law:</td><td align="right">{proposedDebtDetails.stateTaxFlag != null ? proposedDebtDetails.stateTaxFlag === "Y" ? "Taxable" : (proposedDebtDetails.stateTaxFlag === "N" ? "Tax-Exempt" : "Taxable, Tax-Exempt") : ''}</td></tr>
                            <tr><td className="total currency">Taxable Principal Amount:</td><td align="right">{CommonService.currencyFormatter(proposedDebtDetails.stateTaxPrincipalAmt)}</td></tr>
                            <tr><td className="total currency">Tax-Exempt Principal Amount:</td><td align="right">{CommonService.currencyFormatter(proposedDebtDetails.stateTaxExemptAmt)}</td></tr>
                            <tr><td className="total currency">Under Federal Law:</td><td align="right">{proposedDebtDetails.federalLawDisplayText}</td></tr>
                            <tr><td className="total currency">Taxable Principal Amount:</td><td align="right">{CommonService.currencyFormatter(proposedDebtDetails.federalTaxPrincipalAmt)}</td></tr>
                            <tr><td className="total currency">Tax-exempt (No AMT) Principal Amount:</td><td align="right">{CommonService.currencyFormatter(proposedDebtDetails.federalTaxExemptAmt)}</td></tr>
                            <tr><td className="total currency">Tax-exempt (AMT) Principal Amount:</td><td align="right">{CommonService.currencyFormatter(proposedDebtDetails.altMinTaxAmt)}</td></tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr className="information">
                <td colSpan="8">
                  <table>
                    <tr className="section-title"><td>Type of Debt Instrument</td></tr>
                    <tr>
                      <td>
                        <table>
                            <tr><td className="total">{proposedDebtDetails.debtInstrumentName ? proposedDebtDetails.debtInstrumentName : "No Data available to display."}</td></tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              {/* Source of Repayment */}
              <tr className="information">
                <td>
                  <table>
                    <tr className="section-title"><td>Source of Repayment</td></tr>

                    <tr><td>Is debt repayable in non-US currency?</td><td align="right">{proposedDebtDetails.debtRepayNonUsCurrFlag ? (proposedDebtDetails.debtRepayNonUsCurrFlag === 'N' ? 'No' : (proposedDebtDetails.debtRepayNonUsCurrFlag === 'Y' ? 'Yes' : 'Unknown')) : ''}</td></tr>                      
                    <tr className="heading">
                      <td align="left">
                          Source of Repayment Type
                      </td>
                    </tr>
                    {proposeDebtDependencies.issueRepaymentSources.length > 0 ? 
                    proposeDebtDependencies.issueRepaymentSources.map((source, i) => {
                      return (
                        <tr key={i}><td>{source.displayName}{source.repymtSrcCodeValueCode === "OTHS" && (" ("+source.otherText+")")}</td></tr>
                      )
                    }) :
                    <tr><td>No data available to display.</td></tr>
                    }
                     
                   
                  </table>
                </td>
              </tr>

              {/* Purpose of Financing */}
              <tr className="information">
                <td>
                  <table>
                    <tr className="section-title"><td>Purpose of Financing</td></tr>

                    <tr className="heading">
                      <td align="left">
                          Purpose Name
                      </td>
                      <td align="left">
                          Percentage
                      </td>
                    </tr>
                    {proposeDebtDependencies.issueFinancingPurposes.length > 0 ? proposeDebtDependencies.issueFinancingPurposes.map((purpose, i) => {
                      return (<tr key={i}>
                        <td>{purpose.financePurposeName}</td><td>{purpose.financePurposePct}</td>
                      </tr>
                      )}) :
                      <tr><td>No data available to disaplay.</td></tr>
                    }
                  </table>
                </td>
              </tr>
              {/* Financing participants */}
              <tr className="information">
                <td colSpan="12">
                  <table>
                    <tr className="section-title"><td>Financing Participants</td></tr>
                    <tr>
                      <td>
                        <table>
                          {proposeDebtDependencies.issueContacts && 
                          <tr>
                            <td colSpan="12">
                              <table className="table-border">
                                <tr className="heading">
                                  <td align="left">Role</td>
                                  <td align="left">Organization</td>
                                  <td align="left">Contact First Name</td>
                                  <td align="left">Contact Last Name</td>
                                </tr>
                                { proposeDebtDependencies.issueContacts ? proposeDebtDependencies.issueContacts.map((el, i)=>{
                                  return (<tr key={i}><td>{el.roleCodeDescription}</td><td>{el.organizationName}</td><td>{el.contactFirstName}</td><td>{el.contactLastName}</td></tr>)
                                }) :
                                <tr><td>No data available to display.</td></tr>
                                }
                              </table>
                            </td>
                          </tr> 
                        }
                          
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              {/* Type of Sale */}
              <tr className="information">
                <td colSpan="8">
                  <table>
                    <tr className="section-title"><td>Type of Sale</td></tr>
                    <tr>
                      <td>
                        <table>
                            <tr><td className="total currency">Type:</td><td align="right">{proposedDebtDetails.saleTypeCode && (proposedDebtDetails.saleTypeCode === "N" ? "Negotiated" : "Competitive")}</td></tr>
                            <tr><td className="total currency">Is financing a private placement?</td><td align="right">{proposedDebtDetails.pvtPlacementFinanceInd && (proposedDebtDetails.pvtPlacementFinanceInd === "N" ? "No" : "Yes")}</td></tr>
                        </table>
                      </td>
                    </tr>
                    {proposedDebtDetails.saleTypeCode && proposedDebtDetails.saleTypeCode === "N" &&
                      <tr className="sub-heading"><td><b>Reason(s) for Negotiated Sale:</b></td></tr>
                    }
                    {proposeDebtDependencies.issuePrivateSaleReasons.length > 0 ? 
                      proposeDebtDependencies.issuePrivateSaleReasons.map((el, i)=>{
                        return (<tr key={i}><td>{el.displayName}<br/>{el.reasonOtherText && el.reasonOtherText}</td></tr>)
                      }) 
                      :
                      <tr><td>No data available to display.</td></tr>
                    }                   
                  </table>
                </td>
              </tr>

              {/* Document Submittal */}
              <tr className="information">
                <td>
                  <table>
                    <tr className="section-title"><td>Document Submittal</td></tr>

                    <tr className="heading">
                      <td align="left">Document Type</td>
                      <td align="left">Document Name</td>
                      <td align="left">File Upload Date</td>
                    </tr>
                    {proposeDebtDependencies.issueDocuments.length > 0 ? proposeDebtDependencies.issueDocuments.map((document, i) => {
                      return (<tr key={i}>
                        <td>{document.documentDesc}</td><td>{document.fileName}</td><td>{CommonService.dateFormatter(document.createDatetime)}</td>
                      </tr>
                      )}) :
                      <tr><td>No data available to disaplay.</td></tr>
                    }
                  </table>
                </td>
              </tr>

            </table>
            <table>
              {proposedDebtDetails.issueStatusFlag !== 'D' &&
              <tr>
                <td>
                  <table>
                    <tr><td colSpan="8">Submission Date</td><td align="right">{CommonService.dateFormatter(proposedDebtDetails.filingDate)}</td></tr>
                  </table>
                </td>
              </tr>
              }
            </table>
           </div>
          </body>
        </html>
       </>
      );
  }

  export default PrintPdfComponent;
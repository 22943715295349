import { useContext, useState } from 'react'
import Card from '../../ui/Card'
//import { Link } from 'react-router-dom'
import { Form, Modal } from 'react-bootstrap'
import FinalSaleReportService from '../../../services/FinalSaleReportService'
import { UserContext } from '../../../UseContext'
import { toast } from 'react-toastify'
//import {useNavigate} from 'react-router-dom'
import PrintPdfComponent from '../printPdfComponent'
import CommonService from '../../../services/CommonService'
import { renderToString } from 'react-dom/server'
import EditReasonModal from '../../reports/editReasonModal'
import ReportsService from '../../../services/ReportsService'
import swal from 'sweetalert'

const AcknowledgementModal = (props) => {
    const [stateValues, setStateValues] = useState({
        acknowledgement: false,
    });
    const handleChange = (e) => {
        setStateValues(prevState=>({
            ...stateValues, 
                'acknowledgement': !prevState.acknowledgement,
        })
        )
    }
    return (
       <Modal show="true">
            <Modal.Body>
                <Form.Check inline type="checkbox" name="acknowledgement" onChange={(e)=>handleChange(e)} label="I acknowledge and understand that all issues of debt reported after January 21, 2017 require the Issuer to submit an annual debt transparency report (ADTR) consisting of the information outlined in GOV 8855(k)"/>
            </Modal.Body>
            <Modal.Footer>
                <button className="fright swal-button" onClick={(e)=>props.handleSubmit(e)}  disabled={stateValues.acknowledgement===true ? false : true }>Save</button>
            </Modal.Footer>
        </Modal>
    )
}

const FinalSaleReview = (props) => {
    const {finalSaleDetails, finalSaleDependencies} = props
    const {finalSaleId} = props.finalSaleDetails
    const loginUserId = useContext(UserContext)
    const [showAcknowledgementModal, setShowAcknowledgementModal] = useState(false)
    const [showEditReasonModal, setShowEditReasonModal] = useState(false)
    //const history = useNavigate();

    const validateFinancingParticipantsFees = async() => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "validateFinalSaleFinancingParticipantsFees",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "finalSaleId": finalSaleId
            }
        };
        FinalSaleReportService.validateFinancingParticipantsFees(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                alert(result.data.errors[0].message)
            }
            else if(result.data.responses[0].length > 0){
                let alertMessage = "";
                (result.data.responses[0]).forEach((el, i) => {
                alertMessage +=  "- " + el.message + "\n";
                });
                alert(alertMessage); 
            } else {
                if(finalSaleDetails.issueStatusFlag === "R") {
                    swal({
                        text: "Do you want to re-edit this record?",
                        icon: "warning",
                        buttons: [true, 'Yes'],
                        closeOnClickOutside: false
                    }).then((confirm) => {
                        if (confirm) {
                            setShowEditReasonModal(true)
                        } 
                    });
                } else {
                    finalSaleFinalSubmit()
                }
            }

        });
    }

    const finalSaleFinalSubmit = async() => {
        CommonService.showHideSpinner(true);
        const html = renderToString(<PrintPdfComponent finalSaleDetails={finalSaleDetails} finalSaleDependencies={finalSaleDependencies} />)

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "finalSaleFinalSubmit",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "finalSaleId": finalSaleId,
                "user": CommonService.getUserName(loginUserId),
                "env" : "E",
                "html": html,
                "reportType" : "RFS"
            }
        };
    
       await FinalSaleReportService.finalSaleFinalSubmit(obj)
        .then((res) => res)
        .then(async (result) => {
            CommonService.showHideSpinner(false);
            if (result.data.errors && result.data.errors.length > 0){
                alert(result.data.errors[0].message)
            }
            else{
                if(result.data && result.data.responses[0]) {
                    await props.updateFinalSaleDetails(result.data.responses[0].finalSaleDetails);
                    var base64EncodedPDF = result.data.responses[0].pdf;
                    var arrrayBuffer = CommonService.base64ToArrayBuffer(base64EncodedPDF); 
                    var blob = new Blob([arrrayBuffer], {type: "application/pdf"});
                    var url = window.URL.createObjectURL(blob);
                    window.open(url);
                } 
                toast.success("Report of Final Sale form has been successfully validated and submitted!");
            }

        });
    }

    const validateRequiredFields = async() => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "validateFinalSale",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "finalSaleId": finalSaleId
            }
        };
        FinalSaleReportService.getValidationMessages(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                alert(result.data.errors[0].message)
            }
            else{
                if(result.data.responses[0].length > 0) {
                  let alertMessage = "";
                  result.data.responses[0].forEach(item => {
                    alertMessage +=  "- " + item.message + "\n";
                  });
                  alert(alertMessage);
                } else {
                    validateFinancingParticipantsFees();       
                }
            }

        });
    }

    const saveEditReason = (editReason) => {
        let obj ={
             "msgId": null,
             "sysId": null,
             "opr": "postEditReason",
             "hdrStruct": null,
             "queryParam": null,
             "reqData": {
                 "editReason" : {
                     "editReasonCodeId" : editReason.editReasonCodeId,
                     "comment" : editReason.comment,
                     "finalSaleId" : finalSaleId,
                     "createUser" : CommonService.getUserName(loginUserId)
                 }
             }
         } 

         ReportsService.saveEditReason(obj)
         .then((res) => res)
         .then((result) => {
             if(result.data.errors.length > 0) {
 
             } else {
                 toast.success('Edit Reason saved successfully!')
                 setShowEditReasonModal(false)
                 finalSaleFinalSubmit()
             }
         });
    }
    const validateFinalSale =  () => {
        props.toggleWidget('review');
        validateRequiredFields();
    }

   /* const validateProposedDebt = () => {
        let sections = {...sectionValidated};
        
        const filtered = Object.entries(sections).filter(([key, value]) => value === false );
        const filteredObj = Object.fromEntries(filtered);
        let alertMessage = "You must enter the values in the following sections: \n";
        if(Object.entries(filteredObj).length === 0) {
            Object.entries(filteredObj).forEach(([k,v])=> {
            k=k.replace(/([A-Z])/g, ' $1').trim()
            let s = k.charAt(0).toUpperCase() + k.slice(1)
            alertMessage +=  "- " + s + "\n";
            })
            alert(alertMessage);
        } else {
            setShowAcknowledgementModal(true);
        }
         
    }*/

    const handleSubmit = () => {
        setShowAcknowledgementModal(false);
    }

    return (
        <Card>
            
            <PrintPdfComponent finalSaleDetails={finalSaleDetails} finalSaleDependencies={finalSaleDependencies}/> 
            <div className="form">
                <div className="btn-div">
                {/* <Link to="/dashboard/report-proposed-sale/upload-documents"><button className="custom-button">Back</button></Link> */}
                <button className="fright custom-button" type="button" onClick={()=>validateFinalSale()}>Validate & Submit</button>
                </div>

            </div>
            {showAcknowledgementModal && <AcknowledgementModal handleSubmit={handleSubmit}/>}
            {showEditReasonModal && <EditReasonModal  onModalClose={()=>setShowEditReasonModal(false)} handleSave={saveEditReason} cdiacReasonCode="RFSER"/>}
        </Card>
    )
}

export default FinalSaleReview

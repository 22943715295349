import { useState } from 'react'
import { Spinner, Modal, Table } from 'react-bootstrap'
import CommonService from '../../services/CommonService'
import BaseService from '../../services/BaseService'
import { toast } from 'react-toastify'
import DataGrid from '../GenericComponents/DataGrid'
import { Column } from 'primereact/column'

const BatchUploadList = (props) => {
    const { files } = props;
    const [showSpinner, setShowSpinner] = useState(false);
    const [error, setError] = useState(null);
    const [viewError, setViewError] = useState(false);
    function base64ToArrayBuffer(base64) {
      let binaryString = window.atob(base64);
      let binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
          var ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
      }
      return bytes;
    }

    const handleViewDocument = (e, adtrBatchId) => {
      setShowSpinner(true);
      let obj = {
          "msgId": null,
          "sysId": null,
          "opr": "getPdfDocumentFromBlob",
          "hdrStruct": null,
          "queryParam": null,
          "reqData": { 
              "adtrBatchId" : adtrBatchId,
              "type" : "adtr_batch"
          }
      };
      BaseService.processRequest(obj)
        .then((res) => {
          var base64EncodedPDF = res.data.responses[0];
          var arrrayBuffer = base64ToArrayBuffer(base64EncodedPDF); 
          
          setShowSpinner(false);
          var blob = new Blob([arrrayBuffer], {type: "text/csv"});
          var url = window.URL.createObjectURL(blob);
          window.open(url);
      });
    }

    const fileNameFormatter = (row) => { 
      return ( 
          <div style={{lineHeight: "normal" }}>
              <button
                  type="button"
                  className="link-button dk-blue-color"
                  onClick={(e)=>handleViewDocument(e, row.adtrBatchId)}>{row.uploadFileName}
              </button>
          </div> 
      ); 
    }
    const getAdtrBatchErrors = (adtrBatchId) => {
      var obj = {
        msgId: null,
        sysId: null,
        opr: "getAdtrBatchError",
        hdrStruct: null,
        queryParam: null,
        reqData: {
          "adtrBatchId" : adtrBatchId
        }
      };
  
      BaseService.processRequest(obj)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0) {
              toast.error(result.data.errors[0].message);
            }
            else {
              setError(result.data.responses[0]);
              setViewError(true);
            }
        });
    }

    const handleErrorModalClose = () => {
      setViewError(false);
      setError(null);
    }

    const processStatusFormatter = (row) => {
      let status = "";
      if(row.deleteInd === "Y") {
        status = "Cancelled";
      } else {
        if(row.processStatusFlag === "E") {
          status = <button
          type="button"
          className="link-button dk-blue-color"
          onClick={()=>getAdtrBatchErrors(row.adtrBatchId)}
          >
              Error
          </button>
        } else {
          status = row.processStatusFlagDesc;
        }
      }
      return status;
    }

    const uploadStatusFormatter = (row) => {
      return ( 
        (row.uploadErrorInd === "Y") ? 
        "Error"
        : "" 
      ); 
    }
    const columns = [{
        dataField: 'uploadFileName',
        text: 'File Name',
        formatter: fileNameFormatter,
        sort: true
      },{
        dataField: 'adtrFileType',
        text: 'File Type',
        sort: true
      },{
        dataField: 'uploadDatetime',
        text: 'Upload Date',
        sort: true,
        formatter: (row) => CommonService.dateFormatter(row['uploadDatetime'])
      },{
        dataField: 'processStatusFlagDesc',
        text: 'Process Status',
        sort: true,
        formatter: processStatusFormatter
      },{
        dataField: 'uploadErrorInd',
        text: 'Upload Error',
        sort: true,
        formatter: uploadStatusFormatter
      },{
        dataField: 'processDatetime',
        text: 'Process Date',
        sort: true,
        formatter: (row) => CommonService.dateFormatter(row['processDatetime'])
      },{
        dataField: 'createUser',
        text: 'Uploaded By'
      }
    ];

    return (
        <div className='form'>
          <h1 className="main-title">Previously Uploaded Files</h1>
          <DataGrid
            dataSource={files}
            sortField='issuerId'
            emptyDataMessage='There is no data to display'
            searchToolTip="Use the search feature to find a specific issue within the dashboard. Click on the dashboard column headings to sort."
            >
            {columns.map((column, i) => {
                return (
                <Column 
                key={i}
                field={column.dataField}
                header={column.text}
                sortable={column.sort} 
                body={column.formatter}
                hidden={column.hidden}
                />)
                }) 
            } 
            </DataGrid>
            {showSpinner && <Modal show={true} className="modal bd-example-modal-lg" data-backdrop="static">
                <div className="modal-dialog modal-sm">
                        <Spinner animation="border"/>
                </div>
            </Modal>
            }
            {viewError && <ErrorModal errors={error} onModalClose={handleErrorModalClose}/>}
        </div>
    )
}

export default BatchUploadList

const ErrorModal = (props) => {
  const {errors, onModalClose} = props;
  return (
    <Modal show={true} backdrop="static" onHide={onModalClose}>
      <Modal.Header closeButton><h3>Error</h3></Modal.Header>
      <Modal.Body>
        <Table>
          <thead>
            <tr>
              <td>CDIAC #</td>
              <td>Fiscal Year End Date</td>
              <td>Error Desc</td>
            </tr>
          </thead>
          <tbody>
            {errors && errors.map((error, i) => {
              return <tr key={i}>
                <td>{error.cdiacNbr}</td>
                <td>{error.fiscalYrEndDate}</td>
                <td>{error.errorDesc}</td>
              </tr>
            })}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <button className="custom-button-secondary btn-secondary"onClick={()=>onModalClose()}>Close</button>
      </Modal.Footer>
    </Modal>
  )
}
import React from "react";
import {FaPencilAlt} from 'react-icons/fa';
import {VscTrash} from 'react-icons/vsc';
import CommonService from "../../services/CommonService";

const field = (
  col,
  data
) => {
  
    switch(col.fieldType) {
      case("currency") :
        return(
          CommonService.currencyFormatter(data[col.prop]) 
        );
      case("date") :
        return(
          CommonService.dateFormatter(data[col.prop])
        )
      case("formatted") :
        return(
          col.value
        )
      default :
        return(
          data[col.prop] 
        )
    }
  
}
const ReadOnlyRow = ({ data, handleEditClick, handleDeleteClick, columns, isActionsRequired, tableName}) => {
  return (
    <tr style={{backgroundColor: (tableName && tableName === 'issueRefund' && !handleEditClick) ? '#ced4da' : ''}}>
        {columns.map((col, i)=>(
          <td key={i}>
            {field(
            col,
            data
            )}
          </td>
        ))}
        {isActionsRequired && 
        <td>
            {handleEditClick && <FaPencilAlt  onClick={(event) => handleEditClick(event, data)} /> }{" "}
            {handleDeleteClick && <VscTrash onClick={(event)=>handleDeleteClick(event, data)} />}
        </td>
        }
    </tr>
  );
};

export default ReadOnlyRow;
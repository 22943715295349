import CommonService from "../../../services/CommonService";
import Spinner from 'react-bootstrap/Spinner'
import SeniorSubordinateService from '../../../services/SeniorSubordinateService'
import {useEffect, useState} from 'react'
import DataGrid from "../../GenericComponents/DataGrid";
import { Column } from "primereact/column";
import { useMsal } from "@azure/msal-react";

const ParentAssociations = (props) => {
  const {instance} = useMsal();
    const { getChildrenAssociations, parentTitle, searchReq } = props;
    const [seniors, setSeniors] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedAssoc, setSelectedAssoc] = useState(null);

    const handleOnRowSelect = (row) => {
        getChildrenAssociations(row);
    }
    
    const columns = [
      {
        dataField: 'issuerName',
        text: 'Issuer Name',
        sort: true
      }, 
      {
        dataField: 'cdiacNbr',
        text: 'CDIAC #',
        sort: true
      }, {
        dataField: 'issueName',
        text: 'Issue Name',
        sort: true
      }, {
        dataField: 'actualSaleDate',
        text: 'Actual Sale Date',
        sort: true,
        formatter: (row) => CommonService.dateFormatter(row['actualSaleDate']),
      }, {
        dataField: 'projectName',
        text: 'Project Name',
        sort: true
      },{
        dataField: 'principalSaleAmt',
        text: 'Principal Amount',
        sort: true,
        formatter: (row) => CommonService.currencyFormatter(row['principalSaleAmt'])
      }
    ];
    
    useEffect(() => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "searchSenior",
            hdrStruct: null,
            queryParam: null,
            reqData: {
              "searchSenior" :{
                "issuerName": searchReq.issuerName,
                "cdiacNumber": searchReq.cdiacNumber,
                "associationType": searchReq.associationType
            }
            },
          };
      
          SeniorSubordinateService.searchSenior(obj)
            .then((res) => res)
            .then((result) => {
              if (result.data.errors && result.data.errors.length > 0){
                CommonService.handleServerResponse(result.data, instance)
              }
              else{
                Object.keys(result.data.responses).forEach((key) => {
                  setSeniors(result.data.responses[key]);
                });
              }
              setIsLoading(false);
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
    },[searchReq]);

    return (
        <>
          <h3 className="menu-title">{parentTitle}</h3>
          {isLoading && <Spinner animation="border" variant="primary" />}
          <DataGrid
          dataSource={seniors}
          sortField='cdiacNbr'
          selectionMode='radiobutton'
          selection={selectedAssoc}
          onSelectionChange={(e)=>{ setSelectedAssoc(e.value); handleOnRowSelect(e.value)}}
          dataKey='cdiacNbr'
          >
            <Column selectionMode='single' headerStyle={{"width": "3rem"}} />
          {columns.map((column) => {
              return (
              <Column 
              key={column.cdiacNbr}
              field={column.dataField}
              header={column.text}
              sortable={column.sort} 
              body={column.formatter}
              />)
            }) 
          } 
          </DataGrid>
        </>
    )
}

export default ParentAssociations

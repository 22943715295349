import Card from '../../ui/Card'
import {Form, Spinner,Modal} from 'react-bootstrap'
import React, { useState, useEffect, useCallback, useContext } from 'react'
import AddStatutoryCodeForm from './addStatutoryCode'
import Table from '../../Table'
import IssueStatutesService from '../../../services/IssueStatuteService'
import StatuteMaintenanceService from "../../../services/StatuteMaintenanceService"
import { toast } from 'react-toastify'
import CommonService from '../../../services/CommonService'
import { UserContext } from '../../../UseContext'
import ReportOfProposedDebtService from '../../../services/ReportOfProposedDebtService'
import { MDBTooltip } from 'mdbreact'
import { AiFillQuestionCircle } from 'react-icons/ai'
import { validateFormFields } from '../../../utils'
const Statutory = (props) => {
    const {issueId} = props;
    const loginUser = useContext(UserContext);
    const [proposedDebtDetails, setProposedDebtDetails] = useState(props.proposedDebtDetails ? props.proposedDebtDetails : {});
    const [addStatutoryCode, setAddStatutoryCode] = useState(false);
    const [statutories, setStatutories] = useState([]);
    const [statuteRefs, setstatuteRefs] = useState([]);
    const [editIdx, setEditIndex] = useState(-1);
    const [canEdit, setCanEdit ] = useState(false);  
    const [isLoading, setIsLoading] = useState(true);
    const [formErrors, setFormErrors] = useState({});
    const [showHelpTextModal, setShowHelpTextModal] = useState(false);

    const getStatutesByIssueId = useCallback(async (statuteRefs) => {
      let obj = {
          "msgId": null,
          "sysId": null,
          "opr": "getIssueStatutesByIssueId",
          "hdrStruct": null,
          "queryParam": null,
          "reqData": { "id" : issueId, "issueType" : "RPD" }
      };
     await IssueStatutesService.getStatutesByIssueId(obj)
        .then((res) => res)
        .then((result) => {
          let issueStatutesArr = [];
          result.data.responses[0].forEach((item, i) => {
            let statuteText = statuteRefs.filter(ref => ref.statuteId === item.statuteId);
            
            let statute = {...item, 
              'statute' : statuteText.length && statuteText !== undefined ? statuteText[0]['label'] : item.displayStatuteName,
              'notEditable': item.statuteId !== "OTH" ? true : false
            }
            issueStatutesArr.push(statute);
          });
          
          setStatutories(issueStatutesArr);
          props.updateProposeDebtDependencies(result.data.responses[0], 'issueStatutes');

          setIsLoading(false);
        });
        // eslint-disable-next-line
    },[issueId]);

    useEffect(()=>{
      const getAllStatuteRefs = async () => {
        var obj = {
          msgId: null,
          sysId: null,
          opr: "getStatuteRefs",
          hdrStruct: null,
          queryParam: null,
          reqData: {},
        };
   
        StatuteMaintenanceService.getAllStatutes(obj)
          .then((res) => res)
          .then((result) => {
           let statutesArr = [];
           const filteredList = result.data.responses[0].filter(el => el.activeInd === "Y");
           filteredList.forEach((item, i) => {
                let Obj = {};
                Obj['value'] = item.statuteCode;
                Obj['label'] =  item.statuteDesc+" - "+ item.statuteCode + " - (" + item.statuteSection + ")";
                Obj['statuteId'] = item.statuteId;
                statutesArr.push(Obj);
            });
            setstatuteRefs(statutesArr);
            getStatutesByIssueId(statutesArr)
          });
      }
    
    getAllStatuteRefs();
    
    }, [getStatutesByIssueId, issueId]);

    const handleChange = (e) => {  
      const {name, value} = e.target;
      setProposedDebtDetails({
          ...proposedDebtDetails,
          [name]: value
      });
    }

    const handleModalClose = () => {
        setAddStatutoryCode(false);
        setTimeout(() => {
        getStatutesByIssueId(statuteRefs);
      }, 1000);
    }

    const validateForm = (e) => {
      let form = e.target.closest('form#statutoriesForm');
      let formErrors = validateFormFields(form.elements);
      if(Object.keys(formErrors).length > 0) {
          setFormErrors(formErrors);
          props.sectionValidated('statutes', false);
      } else {
          setFormErrors({});
          if(statutories.length !== 0) {
            updateProposedDebt();
          } else {
            props.sectionValidated('statutes', false);
            alert("Please select at least one Statutory Code.");
          } 
      }       
    }

    const handleRemove = (i, statuteData) => {
      const issueStatuteId = statuteData.issueStatuteId;
      deleteStatute(issueStatuteId);
      // setStatutories(
      //   statutories.filter((row, j) => j !== i)
      // );
    }
    const startEditing = (i, rowData) => {
      if(rowData.statuteId === 'OTH') {
        setCanEdit(true);
      } else {
        setCanEdit(false);
      }
      setEditIndex(i);
    }
    const handleSave = (i, statuteData) => {
      updateStatute(statuteData);
      setEditIndex(-1);
    }
    const stopEditing = (i) => {
      setEditIndex(-1);
    }

    const updateStatute = (statuteData)  => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putIssueStatute",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id" : statuteData.issueStatuteId,
                "issueStatute": statuteData
            }
        };

        IssueStatutesService.addStatute(obj)
        .then((res) => res)
        .then((result) => {
          getStatutesByIssueId(statuteRefs);
        });
    }

    const deleteStatute = (issueStatuteId)  => {
      let obj = {
          "msgId": null,
          "sysId": null,
          "opr": "deleteIssueStatute",
          "hdrStruct": null,
          "queryParam": null,
          "reqData": {
            "id" : issueStatuteId,
          }
      };

      IssueStatutesService.deleteStatute(obj)
      .then((res) => res)
      .then((result) => {
          toast.success(
            'Statute Deleted Successfully'
          )
          getStatutesByIssueId(statuteRefs);
      });
    }
    const handleStatutoryChange = (e, name, i) => {
      const { value } = e.target;
      const statutoriesArr =[...statutories];
      statutoriesArr[i][name] = value;
      setStatutories(statutoriesArr);
    }

    const updateProposedDebt = () => {
      let obj = {
          "msgId": null,
          "sysId": null,
          "opr": "putProposeDebt",
          "hdrStruct": null,
          "queryParam": null,
          "reqData": {
              "id": issueId,
              "proposeDebt": {...proposedDebtDetails,
                  'lastUpdateUser': CommonService.getUserName(loginUser)}
          }
      };
  
      ReportOfProposedDebtService.updateProposedDebt(obj)
      .then((res) => res)
      .then((result) => {
          if(result.data.responses[0]) {
              toast.success("Statutory Authority updated successfully");
              props.updateProposedDebtDetails(result.data.responses[0]);
              props.sectionValidated('statutes', true);
              props.openWidget('filingContact');
          }
      });
      
    }
    
    return (
        <Card>
            <Form className="form" id="statutoriesForm">
            <div className="mb-3">
                <label htmlFor="validateAction"><span className="required">*</span>Will a Validation Action be Pursued?
                    <MDBTooltip
                    domElement
                    tag="span"
                    placement="right"
                    >
                    <span>{"  "}<AiFillQuestionCircle className="help-icon heading-help-icon"/></span>
                    <span>Will a court issue a judgment declaring the validity of the financing?</span>
                    </MDBTooltip>
                </label>
                <Form.Check
                    inline
                    label="No"
                    name="validationActionFlag"
                    type="radio"
                    value="N"
                    id="validationActionFlag-1"
                    checked={proposedDebtDetails.validationActionFlag === 'N'}
                    onChange={(e)=>handleChange(e)}
                    data-validations="required"
                />
                <Form.Check
                    inline
                    label="Yes"
                    name="validationActionFlag"
                    type="radio"
                    value="Y"
                    id="validationActionFlag-2"
                    checked={proposedDebtDetails.validationActionFlag === 'Y'}
                    onChange={(e)=>handleChange(e)}
                    data-validations="required"
                />
                <Form.Check
                    inline
                    label="Unknown"
                    name="validationActionFlag"
                    value="U"
                    type="radio"
                    id="validationActionFlag-3"
                    checked={proposedDebtDetails.validationActionFlag === 'U'}
                    onChange={(e)=>handleChange(e)}
                    data-validations="required"
                />
                {formErrors['validationActionFlag'] && <p className="error">{formErrors['validationActionFlag']}</p>}
            </div>           
            <span className="required" style={{fontSize: '24px'}}>* </span><button type="button" className="custom-button" onClick={()=>setAddStatutoryCode(true)}>Add Statute</button>
              <MDBTooltip
                  domElement
                  tag="span"
                  placement="right"
                  >
                  <span>{"  "}<AiFillQuestionCircle onClick={()=>setShowHelpTextModal(true)} className="help-icon heading-help-icon"/></span>
                  <span>Click me for more info.</span>
              </MDBTooltip>

            {showHelpTextModal && 
            <Modal show={true} backdrop="static" onHide={()=>setShowHelpTextModal(false)}>
                <Modal.Body>
                    <div>
                        <p>Indicate the statutory authority or charter under which the debt will be issued. If the debt is issued under multiple statutory authorities, add as necessary.</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="custom-button-secondary btn-secondary" onClick={()=>setShowHelpTextModal(false)}>Close</button>
                </Modal.Footer>
            </Modal>
            }
            {isLoading ? <Spinner /> : statutories.length !== 0 && <Table 
                    handleRemove={handleRemove}
                    startEditing={startEditing}
                    editIdx={editIdx}
                    stopEditing={stopEditing}
                    handleSave={handleSave}
                    handleChange={handleStatutoryChange}
                    data={statutories}
                    header={[
                      {
                        name: "Statute",
                        prop: "statute",
                        fieldType: "input",
                        editable: false
                      },
                      {
                        name: "Other Code",
                        prop: "otherStatuteCode",
                        fieldType: "input",
                        editable: false
                      },
                      {
                        name: "Other Section",
                        prop: "otherStatuteText",
                        fieldType: "input",
                        editable: canEdit
                      },
                      {
                        name: "Other Desc",
                        prop: "otherStatuteBriefDesc",
                        fieldType: "input",
                        editable: canEdit
                      }
                    ]}
                />
            }

           
            {addStatutoryCode && <AddStatutoryCodeForm onModalClose={handleModalClose} issueId={issueId} statuteRefs={statuteRefs}/>}
            
            <div className="btn-div">
            {/* <button className="custom-button">Back</button> */}
            <button className="custom-button fright" type="button" onClick={(e)=>validateForm(e)}>Save & Next</button>
            </div>
            </Form>
        </Card>
    )
}

export default Statutory

import {useState, useContext} from 'react'
import Card from '../ui/Card'
import {Row, Col, Form} from 'react-bootstrap'
import { validateFormFields } from '../../utils/index'
import { IssuanceDetailContext } from '../../UseContext'
import CommonService from '../../services/CommonService'
import CurrencyInput from '../formElements/CurrencyInput'
import { runValidations } from '../../utils'
import { UserContext } from '../../UseContext'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import { toast } from 'react-toastify'
import { FormInput } from '../formElements/FormInput'
import {MDBTooltip} from 'mdbreact'
import {AiFillQuestionCircle} from 'react-icons/ai'

const Issuance = (props) => {
    const { melloRoosDetails } = props;
    const user = useContext(UserContext);
    const [disableField, setDisableField] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const issuanceDetail = useContext(IssuanceDetailContext);
    const [issuance, setIssuanceValues] = useState({
        'creditRatingFromFinalSale': {
            'creditNotRatedInd': issuanceDetail.creditNotRatedInd,
            'stdsPoorRate': issuanceDetail.stdsPoorRate,
            'fitchRate': issuanceDetail.fitchRate,
            'moodyRate': issuanceDetail.moodyRate,
            'otherRate': issuanceDetail.otherRate
        },
        'creditRatingFromLastYear': {
            'creditNotRatedInd': issuanceDetail.creditNotRatedIndPrevious,
            'stdsPoorRate': issuanceDetail.stdsPoorRatePrevious,
            'fitchRate': issuanceDetail.fitchRatePrevious,
            'moodyRate': issuanceDetail.moodyRatePrevious,
            'otherRate': issuanceDetail.otherRatePrevious
        },
        'creditRatingThisYear': {
            'creditNotRatedInd': melloRoosDetails.creditNotRatedInd,
            'stdsPoorRate': melloRoosDetails.stdsPoorRate,
            'fitchRate': melloRoosDetails.fitchRate,
            'moodyRate': melloRoosDetails.moodyRate,
            'otherRate': melloRoosDetails.otherRate
        },
        'previousPeriod': 'N',
    })

    const [stateValues, setStateValues] = useState({
        reserveMinBalInd : melloRoosDetails.reserveMinBalInd,
        reserveMinBalAmt: melloRoosDetails.reserveMinBalAmt
    })

    const handleChange = (e) => {
        const {name, value} = e.target;
        
        setStateValues(prevState => ({
            ...stateValues, [name]: value
        })
        )
    }

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = mm + '/' + dd + '/' + yyyy;

    const handleIsPreviousPeriod = (e) => {
        const {name, value} = e.target;

        issuance[name] = value;
        setIssuanceValues(prevState=>({
            ...prevState,
            [name]: value
            })
        )
        if(value === 'Y') {
            let cr = { ...issuance };
            cr.creditRatingThisYear = melloRoosDetails.isFirstYear ? Object.assign({}, cr.creditRatingFromFinalSale) : Object.assign({}, cr.creditRatingFromLastYear)
            setIssuanceValues(cr);
            setDisableField(true);
        } else {
            let cr = { ...issuance };
            //cr.creditRatingThisYear = Object.assign({});
            cr.creditRatingThisYear.creditNotRatedInd = melloRoosDetails.creditNotRatedInd;
            cr.creditRatingThisYear.stdsPoorRate = melloRoosDetails.stdsPoorRate;
            cr.creditRatingThisYear.fitchRate = melloRoosDetails.fitchRate;
            cr.creditRatingThisYear.moodyRate = melloRoosDetails.moodyRate;
            cr.creditRatingThisYear.otherRate = melloRoosDetails.otherRate;
            setIssuanceValues(cr);
            setDisableField(false);
        }
    }

    const validateForm = (e) => {
        let form = e.target.closest('form#issuanceForm');
        let formErrors = validateFormFields(form.elements);
       
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
            props.sectionValidated('issuance', false);
        } else {
            setFormErrors({});
            if(stateValues.reserveMinBalInd === 'Y' && stateValues.reserveMinBalAmt === ''){
                toast.error('Reserve Fund Minimum Balance Amount is mandatory if the Reserve Fund Minimum Balance selected is Yes');
                return;
            }

            updateMelloRoosReserve();
            updateMelloRoosCreditRating();

            async function updateMelloRoosCreditRating() {
                var anotherobj = {
                    msgId: null,
                    sysId: null,
                    opr: "updateMelloRoosCreditRating",
                    hdrStruct: null,
                    queryParam: null,
                    reqData: {  
                        "id": melloRoosDetails.melloRoosId,
                        "env" : "E",
                        "melloRoos":{
                            "creditNotRatedInd" : issuance.creditRatingThisYear.creditNotRatedInd,
                            "stdsPoorRate" : issuance.creditRatingThisYear.stdsPoorRate,
                            "fitchRate" : issuance.creditRatingThisYear.fitchRate,
                            "moodyRate" : issuance.creditRatingThisYear.moodyRate,
                            "otherRate" : issuance.creditRatingThisYear.otherRate,
                            "melloRoosId" : melloRoosDetails.melloRoosId,
                            "lastUpdateUser": CommonService.getUserName(user),
                            "emailId": user
                        }
                    },
                };
            
                await FinalSaleReportService.updateMelloRoosCreditRating(anotherobj)
                .then((result) => {
                    if (result.data.errors && result.data.errors.length > 0){
                        toast.error(result.data.errors[0].message);
                    }
                    else{
                        props.updateMelloRoosDetails(result.data.responses[0]);
                    }
                });
            }

            async function updateMelloRoosReserve() {
                var obj = {
                    msgId: null,
                    sysId: null,
                    opr: "updateMelloRoosReserve",
                    hdrStruct: null,
                    queryParam: null,
                    reqData: {  
                        "env" : "E",
                        "melloRoos":{
                            "reserveMinBalInd" : stateValues.reserveMinBalInd,
                            "reserveMinBalAmt" : stateValues.reserveMinBalAmt,
                            "melloRoosId" : melloRoosDetails.melloRoosId,
                            "lastUpdateUser": CommonService.getUserName(user),
                            "emailId": user
                        }
                    },
                };    
          
                await FinalSaleReportService.updateMelloRoosReserve(obj)
                .then((result) => {
                    if (result.data.errors && result.data.errors.length > 0){
                        toast.error(result.data.errors[0].message);
                    }
                    else{
                        toast.success("Issuance Details updated successfully!");
                    }
                    
                });
            }
            props.openWidget('fundBalance');
        }
        
    }

    const handleCurrentYearRatingChange = (e) => {

        const {name, value} = e.target;
        let cr = {...issuance};

        cr.creditRatingThisYear[name] = value;
        
        setStateValues(prevState => ({
            ...stateValues, [name]: value
        })
        )
        setIssuanceValues(cr);
    }

    const validateField = (e) => {
        if ( e.target.dataset['validations'] )
            runValidations(e) 
    }

    return (
        <Card>
            <form className="form" id="issuanceForm">
                <h3>Issuance</h3>
            <Row>
                <Col>
                    <FormInput label="Issuer" type="text" value={issuanceDetail.issuerName} disabled 
                    helpText="Prepopulated from the Issuer Name of the Report of Final Sale."/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormInput label="Project Name" type="text" value={issuanceDetail.projectName} disabled
                    helpText="Prepopulated from data submitted on the Report of Final Sale, this field includes the name of the project(s) for which the debt proceeds will be used."/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormInput label="Issue Name" type="text" value={issuanceDetail.issueName} disabled
                    helpText="Prepopulated from data submitted on the Report of Final Sale."/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormInput label="Actual Sale Date" type="text" value={CommonService.dateFormatter(issuanceDetail.actualSaleDate)} disabled
                    helpText="Prepopulated from the Report of Final Sale, this is the actual sale date of the Mello-Roos bond."/> 
                </Col>
                <Col>
                    <FormInput label="Settlement Date" type="text" value={CommonService.dateFormatter(issuanceDetail.settlementDate)} disabled/> 
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormInput label="Original Principal Amount" type="text" value={CommonService.currencyFormatter(issuanceDetail.principalSaleAmt)} disabled
                    helpText="Prepopulated from the Report of Final Sale, this is the original principal amount of the bonds."/>
                </Col>
                <Col>
                    <FormInput label="Date of Filing" type="text" value={melloRoosDetails.statusFlag !== 'D' ? CommonService.dateFormatter(melloRoosDetails.filingDate) : ""} disabled/>
                </Col>
            </Row>

            <Row>
                <label><span className="required" > * </span>Reserve Fund Minimum Balance
                    <MDBTooltip domElement tag="span" placement="right">
                        <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                        <span>Indicate whether a Reserve Fund Minimum Balance is required.</span>
                    </MDBTooltip>
                </label>
                <div style={{display: 'flex'}}>
                <Form.Check inline type="radio" name="reserveMinBalInd" id="Y" checked={stateValues.reserveMinBalInd === "Y"} label="Yes" value="Y" data-validations="required" onChange={(e)=>handleChange(e)}/>
                <Form.Check inline type="radio" name="reserveMinBalInd" id="N" checked={stateValues.reserveMinBalInd === "N" } label="No" value="N" data-validations="required" onChange={(e)=>handleChange(e)}/>
                { formErrors['reserveMinBalInd'] && <p className="error">{ formErrors['reserveMinBalInd'] }</p>}
                </div>
            </Row>
            {stateValues.reserveMinBalInd === "Y" &&
            <Row>
                < Col md={6}>
                    <div className="control">
                        <CurrencyInput 
                        placeholder="0.00" 
                        label="Reserve Fund Minimum Balance Amount" 
                        name="reserveMinBalAmt" 
                        type="text" 
                        value={stateValues.reserveMinBalAmt}
                        onChange={(e)=>handleChange(e)} 
                        onBlur={e=>validateField(e)}
                        validations={["max:999999999999.99"]}
                        helpText="Indicate Reserve Fund Minimum Amount if a reserve fund minimum balance is required"
                        />
                    </div>
                </Col>
            </Row>
            }
           
            <div>
            <Row>
                <label><b>Credit Rating from Report of Final Sale:</b>
                    <MDBTooltip domElement tag="span" placement="right">
                        <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                        <span>Prepopulated from the Report of Final Sale, this indicates whether the Mello-Roos bond was rated and the rating if the bond was rated.</span>
                    </MDBTooltip>
                </label>
                <Col>
                <label>Credit Rating</label>
                <Form.Check inline type="radio" name="creditNotRatedIndFinalSale" id="rated_1" checked={issuanceDetail.creditNotRatedInd === "N"} value="N" label="Rated" disabled  />
                <Form.Check inline type="radio" name="creditNotRatedIndFinalSale" id="not_rated_1" checked={issuanceDetail.creditNotRatedInd === "Y"} value="Y" label="Not Rated" disabled  />
                </Col>
            </Row>
            <Row>
                <Col>
                    <label>Standard & Poor</label>
                    <Form.Control type="text" value={issuanceDetail.stdsPoorRate} disabled/>
                </Col>
                <Col>
                    <label>Fitch</label>
                    <Form.Control type="text" value={issuanceDetail.fitchRate} disabled/>
                </Col>
                <Col>
                    <label>Moody's</label>
                    <Form.Control type="text" value={issuanceDetail.moodyRate} disabled/>
                </Col>
                <Col>
                    <label>Other</label>
                    <Form.Control type="text" value={issuanceDetail.otherRate} disabled/>
                </Col>

            </Row>
            </div>
            <div>
            <Row>
                <label><b>Credit Rating from Mello-Roos Last Yearly Fiscal Status Report:</b>
                    <MDBTooltip domElement tag="span" placement="right">
                        <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                        <span>Prepopulated from the previous Yearly Fiscal Status Report, this indicates whether the Mello-Roos bond was rated and the rating if the bond was rated.</span>
                    </MDBTooltip>
                </label>
                <Col>
                <label>Credit Rating</label>
                <Form.Check inline type="radio" name="creditNotRatedIndPrevious" id="rated_2" checked={issuanceDetail.creditNotRatedIndPrevious === "N"} value="N" label="Rated" disabled />
                <Form.Check inline type="radio" name="creditNotRatedIndPrevious" id="not_rated_2" checked={issuanceDetail.creditNotRatedIndPrevious === "Y"} value="Y" label="Not Rated" disabled />
                </Col>
            </Row>
            <Row>
                <Col>
                    <label>Standard & Poor</label>
                    <Form.Control type="text" value={issuanceDetail.stdsPoorRatePrevious} readOnly/>
                </Col>
                <Col>
                    <label>Fitch</label>
                    <Form.Control type="text" value={issuanceDetail.fitchRatePrevious} readOnly/>
                </Col>
                <Col>
                    <label>Moody's</label>
                    <Form.Control type="text" value={issuanceDetail.moodyRatePrevious} readOnly/>
                </Col>
                <Col>
                    <label>Other</label>
                    <Form.Control type="text" value={issuanceDetail.otherRatePrevious} readOnly/>
                </Col>

            </Row>
            </div>
            <div>
            <Row>
                <label><b>Credit Rating for This Reporting Period:</b>
                    <MDBTooltip domElement tag="span" placement="right">
                        <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                        <span>Indicate whether the values (ratings) are the same as the previous reporting period. If yes, credit rating information will auto-populate. If no, indicate whether the bond is rated and, if it is rated, indicate the rating from the appropriate rating agency.</span>
                    </MDBTooltip>
                </label>
                <div>
                    <label><span className="required">*</span>Are values same as previous reporting period?</label>
                    <Form.Check inline type="radio" name="previousPeriod" id="previousPeriod_y" label="Yes" value="Y" checked={issuance.previousPeriod === 'Y'} onChange={(e)=>handleIsPreviousPeriod(e)}/>
                    <Form.Check inline type="radio" name="previousPeriod" id="previousPeriod_n" label="No" value="N" checked={issuance.previousPeriod === 'N'} onChange={(e)=>handleIsPreviousPeriod(e)}/>
                </div>
                <Col>
                <label><span className="required">*</span>Credit Rating</label>
                <Form.Check inline type="radio" name="creditNotRatedInd" id="rated_3" label="Rated" value="N" checked={issuance.creditRatingThisYear.creditNotRatedInd === 'N'}  onChange={(e)=>handleCurrentYearRatingChange(e)} />
                <Form.Check inline type="radio" name="creditNotRatedInd" id="not_rated_3" label="Not Rated" value="Y" checked={issuance.creditRatingThisYear.creditNotRatedInd === 'Y'}  onChange={(e)=>handleCurrentYearRatingChange(e)} />
                { formErrors['creditNotRatedInd'] && <p className="error">{ formErrors['creditNotRatedInd'] }</p>}
                </Col>
            </Row>
            <Row>
                <Col>
                    <label>Standard & Poor</label>
                    <Form.Control type="text" name="stdsPoorRate" value={issuance.creditRatingThisYear.stdsPoorRate} readOnly={disableField || (issuance.creditRatingThisYear.creditNotRatedInd === 'Y')}  onChange={(e)=>handleCurrentYearRatingChange(e)}/>
                    { formErrors['stdsPoorRate'] && <p className="error">{ formErrors['stdsPoorRate'] }</p>}
                </Col>
                <Col>
                    <label>Fitch</label>
                    <Form.Control type="text" name="fitchRate" value={issuance.creditRatingThisYear.fitchRate} readOnly={disableField || (issuance.creditRatingThisYear.creditNotRatedInd === 'Y')}  onChange={(e)=>handleCurrentYearRatingChange(e)}/>
                    { formErrors['fitchRate'] && <p className="error">{ formErrors['fitchRate'] }</p>}
                </Col>
                <Col>
                    <label>Moody's</label>
                    <Form.Control type="text" name="moodyRate" value={issuance.creditRatingThisYear.moodyRate} readOnly={disableField || (issuance.creditRatingThisYear.creditNotRatedInd === 'Y')}  onChange={(e)=>handleCurrentYearRatingChange(e)}/>
                    { formErrors['moodyRate'] && <p className="error">{ formErrors['moodyRate'] }</p>}
                </Col>
                <Col>
                    <label>Other</label>
                    <Form.Control type="text" name="otherRate" value={issuance.creditRatingThisYear.otherRate} readOnly={disableField || (issuance.creditRatingThisYear.creditNotRatedInd === 'Y')}  onChange={(e)=>handleCurrentYearRatingChange(e)}/>
                    { formErrors['otherRate'] && <p className="error">{ formErrors['otherRate'] }</p>}
                </Col>

            </Row>
            </div>

            <div className="btn-div">
            <button className="fright custom-button" type="button" onClick={e=>validateForm(e)}>Save & Next</button>
            </div>
            </form>
        </Card>
    )
}

export default Issuance

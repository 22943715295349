import {useState, useContext} from 'react'
import { Collapse } from "react-bootstrap"
import Issuance from './issuance'
import FundBalance from './fundBalance'
import {FaPlus, FaMinus} from 'react-icons/fa'
import 'bootstrap/dist/css/bootstrap.min.css'
import DelinquentReporting from './delinquentReporting'
import RetiredIssues from './retiredIssues'
import FilingContact from './filingContact'
import Comments from './comments'
import MKRLocalObligorsYFSReview from './mkrLocalObligorsYFSReview'
import { IssuanceDetailContext } from '../../UseContext'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faCheck} from "@fortawesome/free-solid-svg-icons"

const CollapsibleWidgets = ({
  openSectionObj, 
  toggleWidget, 
  openSection, 
  markRoosLocalOblig, 
  markRoosLocalObligDetails, 
  contact, 
  updateIssuanceDetails,
  updateMkrLocalObligorDetails
}) => {
    const issuanceDetail = useContext(IssuanceDetailContext);
    const [sectionValidated, setSectionValidated] = useState({
      fundBalance:  markRoosLocalObligDetails.principalOutstdgAmt ? true : false,
      delinquentReporting: markRoosLocalObligDetails.dlqTaxIndicator ? true : false,
      retiredIssues: issuanceDetail.retireFlag ? true : false
    });
    const updateSectionValidated = (sectionName, isValid) => {
      setSectionValidated(prevState=>({
        ...prevState, [sectionName]: isValid
      }))
    }

    return (
      <>
      <div className="panel panel-default">
        <div className="panel-heading" role="tab"> 
          <h5 className="panel-title icon-style" onClick={()=>toggleWidget('issuance')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}} 
             id="issuance" aria-expanded={openSectionObj.issuance} aria-controls="collapse_issuance"> {!openSectionObj.issuance ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Issuance </button> 
          </h5>
          <Collapse in={openSectionObj.issuance}>
            <div id="collapse_issuance">
            {openSectionObj.issuance && <Issuance openWidget={openSection} markRoosLocalObligDetails={markRoosLocalObligDetails} updateMkrLocalObligorDetails={updateMkrLocalObligorDetails}/>}
            </div>
          </Collapse>
        </div>
      </div>

      <div className="panel panel-default">
       <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title icon-style" onClick={()=>toggleWidget('fundBalance')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}} 
            id="draw" aria-expanded={openSectionObj.fundBalance} aria-controls="collapse_fundBalance"> {!openSectionObj.fundBalance ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Fund Balance</button> 
        {sectionValidated.fundBalance && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
        </h5>
        <Collapse in={openSectionObj.fundBalance}>
          <div id="collapse_fundBalance">
          {openSectionObj.fundBalance && <FundBalance openWidget={openSection} markRoosLocalObligDetails={markRoosLocalObligDetails} updateMkrLocalObligorDetails={updateMkrLocalObligorDetails}/>}
            </div>
        </Collapse>
      </div>
      </div>

      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title icon-style" onClick={()=>toggleWidget('delinquentReporting')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
            id="delinquentReporting" aria-expanded={openSectionObj.delinquentReporting} aria-controls="collapse_delinquentReporting"> {!openSectionObj.delinquentReporting ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Delinquent Reporting</button> 
          {sectionValidated.delinquentReporting && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
        </h5>
        <Collapse in={openSectionObj.delinquentReporting}>
          <div id="collapse_delinquentReporting">
          {openSectionObj.delinquentReporting && <DelinquentReporting openWidget={openSection} sectionValidated={updateSectionValidated} markRoosLocalObligDetails={markRoosLocalObligDetails} updateMkrLocalObligorDetails={updateMkrLocalObligorDetails}/>}
            </div>
        </Collapse>
      </div>
      </div>

      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title icon-style" onClick={()=>toggleWidget('retiredIssues')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
            id="retiredIssues" aria-expanded={openSectionObj.retiredIssues} aria-controls="collapse_retiredIssues"> {!openSectionObj.retiredIssues ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Retired Issues</button> 
        </h5>
        <Collapse in={openSectionObj.retiredIssues}>
          <div id="collapse_retiredIssues">
          {openSectionObj.retiredIssues && <RetiredIssues openWidget={openSection} sectionValidated={updateSectionValidated} previousWidget={"delinquentReporting"} markRoosLocalOblig={markRoosLocalOblig} markRoosLocalObligDetails={markRoosLocalObligDetails} updateIssuanceDetails={updateIssuanceDetails} updateMkrLocalObligorDetails={updateMkrLocalObligorDetails}/> }
            </div>
        </Collapse>
      </div>
      </div>

      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title icon-style" onClick={()=>toggleWidget('filingContact')}>
        <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}} 
             id="filingContact" aria-expanded={openSectionObj.filingContact} aria-controls="collapse_filingContact"> {!openSectionObj.filingContact ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Filing Contact</button> 
        </h5>
        <Collapse in={openSectionObj.filingContact}>
          <div id="collapse_filingContact">
          {openSectionObj.filingContact && <FilingContact openWidget={openSection} contact={contact}/> }
            </div>
        </Collapse>
      </div>
      </div>
      
      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title icon-style" onClick={()=>toggleWidget('comments')}>
        <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}} 
             id="comments" aria-expanded={openSectionObj.comments} aria-controls="collapse_comments" > {!openSectionObj.comments ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Comments</button> 
        </h5>
        <Collapse in={openSectionObj.comments}>
          <div id="collapse_comments">
          {openSectionObj.comments && <Comments openWidget={openSection} sectionName={'review'} markRoosLocalOblig={markRoosLocalOblig} markRoosLocalObligDetails={markRoosLocalObligDetails} updateMkrLocalObligorDetails={updateMkrLocalObligorDetails}/> }
            </div>
        </Collapse>
      </div>
      </div>

      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title icon-style" onClick={()=>toggleWidget('review')}>
        <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}} 
             id="review" aria-expanded={openSectionObj.review} aria-controls="collapse_review"> {!openSectionObj.review ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Review</button> 
        </h5>
        <Collapse in={openSectionObj.review}>
          <div id="collapse_review">
          <MKRLocalObligorsYFSReview openWidget={openSection} toggleWidget={toggleWidget} sectionName={'review'} sectionValidated={sectionValidated} markRoosLocalOblig={markRoosLocalOblig} markRoosLocalObligDetails={markRoosLocalObligDetails} contact={contact} />
          </div>
        </Collapse>
      </div>
      </div>
      </>
    );
}

export default CollapsibleWidgets

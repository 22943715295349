import {useState} from 'react'
import StepNavigation from '../layout/StepNavigation'
import { useLocation} from 'react-router-dom'
import { FaUsers, FaUser} from 'react-icons/fa'
import {GrDocumentUpload} from 'react-icons/gr'
import { VscPreview} from 'react-icons/vsc'

function FinalSaleReportProgressBar({openSection}) {
    const {pathname} = useLocation();
    const [selectedWidget, setSelectedWidget] = useState('issuance');
    const progressBarData = [
        {
            title: "Issuance",
            path: "",
            section: "issuance",
            icon: <img src="/assets/images/issuance.jpg" style={{width:'2rem', height: '2rem'}} alt="" />
        },
        {
            title: "Dates",
            path: "",
            section: "dates",
            icon: <img src="/assets/images/dates.jpg" style={{width:'2rem', height: '2rem'}} alt="" />
        },
        {
            title: "Issuance Authorization",
            path: "",
            section: "issuanceAuthorization",
            icon: <img src="/assets/images/issuance.authorization.jpg" style={{width:'2rem', height: '2rem'}} alt="" />
        },
        {
            title: "Statutory Authority",
            path: "",
            section: "statutories",
            icon: <img src="/assets/images/statutory_authority.jpg" style={{width:'2rem', height: '2rem'}} alt="" />
        },
        {
            title: "Filing Contact",
            path: "",
            section: "filingContact",
            icon: <FaUser style={{width:'1.5rem', height: '2rem'}}/>
        },
        {
            title: "Credit",
            path: "",
            section: "credit",
            icon: <img src="/assets/images/credit.jpg" style={{width:'2rem', height: '2rem'}} alt="" />
        },
        {
            title: "Financing Participants/Costs",
            path: "",
            section: "financingParticipants",
            icon: <FaUsers style={{width:'1.5rem', height: '2rem'}} />
        },
        {
            title: "Tax Status/Interest",
            path: "",
            section: "interestDebtExempt",
            icon: <img src="/assets/images/tax.status.interest.jpg" style={{width:'2rem', height: '2rem'}} alt="" />
        },
        {
            title: "Type of Debt Instrument",
            path: "",
            section: "typeOfDebt",
            icon: <img src="/assets/images/type_of_debt_instrument.jpg" style={{width:'2rem', height: '2rem'}} alt="" />
        },
        {
            title: "Source of Repayment",
            path: "",
            section: "sourceOfRepayment",
            icon: <img src="/assets/images/source_of_repayment.jpg" style={{width:'2rem', height: '2rem'}} alt="" />
        },
        {
            title: "Purpose of Financing",
            path: "",
            section: "purposeOfFinancing",
            icon: <img src="/assets/images/purpose_of_financing.jpg" style={{width:'2rem', height: '2rem'}} alt="" />
        },
        {
            title: "Type of Sale",
            path: "",
            section: "typeOfSale",
            icon: <img src="/assets/images/type.of.sale.jpg" style={{width:'2rem', height: '2rem'}} alt="" />
        },
        {
            title: "Document Submittal",
            path: "",
            section: "uploadDoc",
            icon: <GrDocumentUpload style={{width:'1.5rem', height: '2rem'}}/>
        },
        {
            title: "Review",
            path: "",
            section: "review",
            icon: <VscPreview style={{width:'1.5rem', height: '2rem'}}/>
        },
    ]

    function updateStep(step, section) {
        setSelectedWidget(section);
        openSection(section);

    }
    return (
        <div style={{textAlign: 'center'}}>
           <StepNavigation labelArray={progressBarData} updateStep={updateStep} pathName={pathname} selectedWidget={selectedWidget}/>
        </div>
    )
}

export default FinalSaleReportProgressBar
import { Modal, Row, Col, Form } from 'react-bootstrap'
import {useState, useEffect, useContext} from 'react'
import CurrencyInput from '../formElements/CurrencyInput'
import {FormInput} from '../formElements/FormInput'
import {AiFillQuestionCircle} from 'react-icons/ai'
import "react-datepicker/dist/react-datepicker.css"
import { MDBTooltip } from 'mdbreact'
import { validateFormFields} from '../../utils'
import AuthorizationService from '../../services/AuthorizationService'
import IssueAuthorizationService from '../../services/IssueAuthorizationService'
import { IssuerContext, UserContext } from '../../UseContext'
import AddAuthorizationRef from './addAuthorizationRef'
import CommonService from '../../services/CommonService'
import { toast } from 'react-toastify'
import { useMsal } from '@azure/msal-react'

const AddIssuanceAuthorization = (props) => {
    const {instance} = useMsal();
    const loginUser = useContext(UserContext);
    const issuerId = useContext(IssuerContext);
    const {onModalClose, issueId} = props;
    const [formErrors,setFormErrors] = useState({});
    const [authorizationNames, setAuthorizationNames] = useState([]);
    const [addNewAuthorization, setAddNewAuthorization] = useState(false);
    const [authorizationInfo, setAuthorizationInfo] = useState({
        'authorizationName': '',
        'otherAuthorizationName': '',
        'authorityReducedAmount': '',
        'originalAuthorizedAmt': '',
        'replenishedAuthAmount': '',
        'authorizationDate': '',
        'netChange': '',
        'outstandingAmt': ''
    })

    const getAuthorizationRefLookups = () => {
        var obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getAuthorizationRefLookupsByIssuerId",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {"id": issuerId}
        };
    
        AuthorizationService.getAuthorizationsByIssuerId(obj)
          .then((res) => res)
          .then((result) => {
            if(result.data.statusCode === 401 || result.data.statusCode === 500) {
                CommonService.handleServerResponse(result.data, instance)
            } else {
                setAuthorizationNames(result.data.responses[0]);
            }
        });
    }
    useEffect(() => {
        getAuthorizationRefLookups();
        // eslint-disable-next-line
    }, []);

    const getAuthorizationRef = (authorizationId, authArray) => {
        var obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getAuthorizationRef",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {"authorizationId": authorizationId}
        };
    
        AuthorizationService.getAuthorizationsByIssuerId(obj)
          .then((res) => res)
          .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message)
            } else {
                setAuthorizationInfo({...authArray, 
                    'originalAuthorizedAmt': result.data.responses[0].authorizationAmt,
                    'authorizationDate': result.data.responses[0].electionDate
                });
            }
        });
    }

    const handleAuthChange = (e) => {
        const {name, value} = e.target;
        let authArray = {...authorizationInfo};
        if(name === 'authorizationName'){
            authArray['authorizationName'] = value;
            const outstandingAmount = authorizationNames.filter(el => el.authorizationId === parseInt(value))[0].outstandingAmount;
            authArray['outstandingAmt'] = outstandingAmount;
            getAuthorizationRef(parseInt(value), authArray);
        } else {
            authArray[name] = value;
            if(name==="authorityReducedAmount" || name === "replenishedAuthAmount") {
                const replenishedAuthAmount = authArray['replenishedAuthAmount'] ? parseFloat(authArray['replenishedAuthAmount'].replace(/,/g, '')) : 0;
                const authorityReducedAmount = authArray['authorityReducedAmount'] ? parseFloat(authArray['authorityReducedAmount'].replace(/,/g, '')) : 0;
                let netChangeTotal = (replenishedAuthAmount*10 - authorityReducedAmount*10)/10;
                authArray['netChange'] = netChangeTotal;
            }
        }

        setAuthorizationInfo(authArray);
    }

    const handleAddNewAuthorization = () => {
        setAddNewAuthorization(true);
    }

    const handleAddNewAuthClose = () => {
        setAddNewAuthorization(false);
        getAuthorizationRefLookups();
    }
  
    const createAuthorization = (issueAuthorization) => {

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "postIssueAuthorization",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "issueAuthorization": {
                    "authorizationId": issueAuthorization.authorizationName,
                    "finalSaleId": null,
                    "proposeDebtId": issueId,
                    "originalAuthorizedAmt": issueAuthorization.originalAuthorizedAmt,
                    "authorizationDate": issueAuthorization.authorizationDate,
                    "otherAuthorizationName": issueAuthorization.otherAuthorizationName,
                    "authorityReplenishAmt": issueAuthorization.replenishedAuthAmount,
                    "authorityReducedAmt": issueAuthorization.authorityReducedAmount,
                    "createDatetime": new Date(),
                    "createUser": loginUser,
                    "lastUpdateDatetime": new Date(),
                    "lastUpdateUser": loginUser
                }
            }
        };
        
        IssueAuthorizationService.addAuthorization(obj)
        .then((res) => res)
        .then((result) => {
        });
    }

    const handleSubmit = (e) => {
        const form = e.target.closest('form#add-form');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});
            createAuthorization(authorizationInfo);
            onModalClose();
        }
    }
    return (
        <Modal  show={true} onHide={onModalClose} backdrop="static" className="authModal">
            {addNewAuthorization ?
                <><Modal.Header closeButton><h3>Add Authorization</h3></Modal.Header>
                <Modal.Body><AddAuthorizationRef onModalClose={handleAddNewAuthClose} /></Modal.Body></> : 
                <><Modal.Header closeButton><h3>Select Issuance Authorization</h3></Modal.Header>
                <Modal.Body>
                <form id="add-form">
                    <Row>
                        <Col md={12}>
                            <div className="control">
                                <label><span className="required">*</span>Authorization Name:
                                    <MDBTooltip domElement tag="span" placement="right">
                                    <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                                    <span>Select a previously established authorization or select Add New Authorization below.</span>
                                    </MDBTooltip>
                                </label>
                                <Form.Select name="authorizationName" 
                                    onChange={(e)=>handleAuthChange(e)} 
                                    data-validations="required"
                                    value={authorizationInfo.authorizationName}
                                    style={formErrors['authorizationName'] && {border: `solid 1px red`}}
                                >
                                    <option value="">Select</option>
                                    {authorizationNames.length !== 0 && authorizationNames.map((auth, i) => {
                                        return <option key={`authname_${i}`} value={auth.authorizationId}>{auth.authorizationLookupName}</option>
                                    })}
                                </Form.Select>
                                { formErrors && formErrors['authorizationName'] && <p className="error">{ formErrors['authorizationName'] }</p>}
                            </div>
                            <button type="button" className="link-button" onClick={()=>handleAddNewAuthorization()} id="add_new"><small>Add New Authorization</small></button>
                            {addNewAuthorization && <AddAuthorizationRef onModalClose={handleAddNewAuthClose} />}
                        </Col>
                        <Col md={6}>
                            <div className="control">
                                <FormInput 
                                label="Current Remaining Authorization" 
                                name="outstandingAmt" 
                                value={authorizationInfo.outstandingAmt} 
                                helpText="Read-only. The amount of the authorization that is unissued as of the last reviewed Report of Final Sale that reported use of this authorization."
                                disabled="disabled"/>
                            </div>
                        </Col>
                        </Row>
                        <Row>
                        <Col md={6}>
                            <div className="control">
                                <FormInput 
                                label="Original Authorized Amount" 
                                name="originalAuthorizedAmt" 
                                value={(authorizationInfo.originalAuthorizedAmt !== '' ? CommonService.currencyFormatter(authorizationInfo.originalAuthorizedAmt) : '')} 
                                helpText="Read-only. The original maximum amount of debt authorized by the vote of the electorate or action of the governing body."
                                disabled="disabled"/>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="control"><span className="required">*</span>
                                <CurrencyInput 
                                    label="Amount Authorization Reduced (By this Issue)" 
                                    name="authorityReducedAmount" 
                                    defaultValue={authorizationInfo.authorityReducedAmt} 
                                    onBlur={(e)=>handleAuthChange(e)}
                                    helpText="The amount this authorization is reduced by this debt issue."
                                    validations={["required"]}
                                    error ={ formErrors && formErrors['authorityReducedAmount'] }/>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="control">
                                <FormInput 
                                label="Authorization Date: (MM/DD/YYYY)" 
                                name="originalAuthorizionDate" 
                                value={authorizationInfo.authorizationDate !== '' ? CommonService.dateFormatter(authorizationInfo.authorizationDate) : ''} 
                                helpText="Read-only. The date of the election or action of the governing body."
                                disabled="disabled"/>
                            </div>
                        </Col>    
                        <Col md={6}>
                            <div className="control">
                                <span className="required">*</span>
                                <CurrencyInput
                                label="Amount Authorization Replenished (By this Issue)" 
                                name="replenishedAuthAmount" 
                                defaultValue={authorizationInfo.authorityReplenishAmt} 
                                onBlur={(e)=>handleAuthChange(e)}
                                validations={["required"]}
                                error={formErrors && formErrors['replenishedAuthAmount']}
                                helpText="The amount this authorization is replenished by this debt issue (e.g. commercial paper paydown)"/>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="control">
                                <FormInput type="text" label="Net Change in Authorization (By this Issue)" 
                                name="netChange" 
                                value={(authorizationInfo.netChange !== '' ? CommonService.currencyFormatter(authorizationInfo.netChange) : '')}
                                readOnly="true"
                                disabled="disabled" 
                                helpText="Calculated. Negative number is a decrease in this authorization, positive number is an increase in this authorization resulting from this debt issue"/>
                            </div>
                        </Col>                    
                    </Row>

                    <div className="btn-div">
                    <div className="fright">
                    <button type="button" className="custom-button-secondary btn-secondary" onClick={()=>onModalClose()}>Cancel</button>{" "}
                    <button type="button" className="custom-button" onClick={(e)=>handleSubmit(e)}>Save</button>
                    </div>
                </div>
                </form>
            </Modal.Body></>
            }
        </Modal>
    )
}

export default AddIssuanceAuthorization

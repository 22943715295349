import { useEffect, useState } from 'react'
import {Row, Form, Modal} from 'react-bootstrap'
import CodeMaintenanceService from '../../services/CodeMaintenanceService'
import { validateFormFields } from '../../utils/index'
import CommonService from '../../services/CommonService'
import { useMsal } from '@azure/msal-react'

const EditReasonModal = (props) => { 
    const {instance} = useMsal();
    const {onModalClose, handleSave, cdiacReasonCode} = props;
    const [codes, setCodes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [stateValues, setStateValues] = useState({
        editReasonCodeId: null,
        comment: ''
    })

    useEffect(()=>{
        async function fetchEditReasons() {
            setIsLoading(true);
            var obj = {
                msgId: "null",
                sysId: null,
                opr: "GetLookups",
                hdrStruct: null,
                queryParam: null,
                reqData: { cdiacCode: cdiacReasonCode, active: "Y" },
            };
        
            await CodeMaintenanceService.getAllCodes(obj)
                .then((res) => res)
                .then((result) => {
                    setIsLoading(false);
                    if(result.data.statusCode === 401 || result.data.statusCode === 500) {
                        CommonService.handleServerResponse(result.data, instance);
                    } else {
                        setCodes(result.data.responses[0]);
                    }
                });
        }
        fetchEditReasons();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[cdiacReasonCode]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setStateValues(prevState => ({
            ...stateValues, [name]: value
        })
        )
    }

    const handleSubmit = (e) => {
        let form = e.target.closest('form#editReasonModal');
        let formErrors = validateFormFields(form.elements);

        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});
           handleSave(stateValues);
        }
    }

    return (
            <Modal show={true} onHide={onModalClose} >
                <Modal.Body>
                    <form style={{textAlign: 'left'}} id="editReasonModal">
                        <Row>
                        <div style={{whiteSpace:"nowrap"}}>
                            <label><span className="required">*</span>Reason for edit:</label>
                            <Form.Select name="editReasonCodeId"
                                    onChange={(e)=>handleChange(e)}
                                    data-validations={["required"]}
                                    value={stateValues.editReasonCodeId}
                                    isloading={isLoading}
                                    >
                                <option value="">select</option>
                                {codes.map((item, i) => {
                                    return <option key={i} value={item.cdiacCodeValueId}>{item.cdiacCodeValueDesc}</option>
                                })}
                            </Form.Select>
                            {formErrors['editReasonCodeId'] && <span className="error">{formErrors['editReasonCodeId']} </span>}
                        </div>
                        </Row>
                        <Row>
                        <div>
                            <label style={{float: 'left'}}><span className="required">*</span>Comment</label>
                            <textarea name="comment" id="comments-input"  data-validations={["required"]}
                                className="form-control" rows="5" maxLength="4000"  value = {stateValues.comment} onChange={(e)=>handleChange(e)} placeholder="Add a comment..."/>
                            {formErrors['comment'] && <span className="error">{formErrors['comment']} </span>}
                        </div>
                        </Row>
                        <Modal.Footer>
                            <button type="button" className="custom-button-secondary  btn-secondary" onClick={onModalClose}>Cancel</button>
                            <button className="custom-button" type="button" onClick={(e)=>handleSubmit(e)}>Save</button>
                        </Modal.Footer>
                    </form>
                </Modal.Body>
            </Modal>
    ) 
}

export default EditReasonModal

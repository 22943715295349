import CommonService from "./CommonService";


const getIssueSocEnvImpactsByIssueId = (obj) => {
    return CommonService.apicall(obj)

};

const addSocialEnvImpact = (obj) => {
    return CommonService.apicall(obj)

};

const updateSocialEnvImpact = (obj) => {
    return CommonService.apicall(obj)

};

const deleteSocialEnvImpact = (obj) => {
    return CommonService.apicall(obj)

};

const IssueSocialImpactService = {
    getIssueSocEnvImpactsByIssueId,
    addSocialEnvImpact,
    updateSocialEnvImpact,
    deleteSocialEnvImpact
};


export default IssueSocialImpactService;
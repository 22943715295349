import CommonService from "./CommonService";

const processRequest = (obj) => {
    return CommonService.apicall(obj);
}

const BaseService = {
    processRequest
};

export default BaseService;
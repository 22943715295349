import {useState, useEffect, useCallback} from 'react'
import SeniorSubordinateService from '../../../services/SeniorSubordinateService'
import CommonService from "../../../services/CommonService";
import DataGrid from '../../GenericComponents/DataGrid';
import { Column } from 'primereact/column';

const ChildAssociations = (props) => {
    const {childTitle, associationType} = props;
    const [reports, setSeniorSubordinates]= useState([]);

    const fetchSeniorSubordinates = useCallback(() => { 
        var obj = {
            msgId: null,
            sysId: null,
            opr: "searchSeniorSubordinate",
            hdrStruct: null,
            queryParam: null,
            reqData: {
              "searchSeniorSubordinate" :{
                "issuerId": props.selectedRow.issuerId,
                "subordinateCdiacNbr": props.selectedRow.cdiacNbr,
                "associationType" : associationType
            }
            },
          };
      
          SeniorSubordinateService.searchSenior(obj)
            .then((res) => res)
            .then((result) => {
              Object.keys(result.data.responses).forEach((key) => {
                setSeniorSubordinates(result.data.responses[key]);
              });
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedRow]);
    useEffect(() => {
        fetchSeniorSubordinates();
        // eslint-disable-next-line
    },[props.selectedRow, props.showAddAssociationModal]);

    const columns = [
      {
        dataField: 'issuerName',
        text: 'Issuer Name',
        sort: true
      },{
        dataField: 'subordinateCdiacNbr',
        text: 'CDIAC #',
        sort: true
      }, {
        dataField: 'issueName',
        text: 'Issue Name',
        sort: true
      }, {
        dataField: 'actualSaleDate',
        text: 'Actual Sale Date',
        sort: true,
        formatter: (row) => CommonService.dateFormatter(row['actualSaleDate']),
      }, {
        dataField: 'projectName',
        text: 'Project Name',
        sort: true
      },{
        dataField: 'principalSaleAmt',
        text: 'Principal Amount',
        sort: true,
        formatter: (row) => CommonService.currencyFormatter(row['principalSaleAmt'])
      }
    ];

    return (
        <>
        <h3 className="menu-title">{childTitle}</h3>
        <DataGrid
        dataSource={reports}
        sortField='issuerName'
        emptyDataMessage='No Associations to Display'
        >
        {columns.map((column, i) => {
            return (
            <Column 
            key={i}
            field={column.dataField}
            header={column.text}
            sortable={column.sort} 
            body={column.formatter}
            />)
            }) 
        } 
        </DataGrid>
        </>
    )
}

export default ChildAssociations

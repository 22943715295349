import { useState } from 'react'
import Card from '../ui/Card'
import { Modal } from 'react-bootstrap'
import { AiFillQuestionCircle } from 'react-icons/ai'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import CommonService from '../../services/CommonService'
import { UserContext } from '../../UseContext'
import { useContext } from 'react'
import { toast } from 'react-toastify'

const Comments = (props) => {
    const {adtrDetails, updateAdtrDependencies} = props;
    const [count, setCount] = useState(0);
    const [bgcolor, setBgcolor] = useState('yellowgreen');
    const [stateValues, setStateValues] = useState({
        issuerComment : adtrDetails.issuerComment
    });

    const [showInstructionsModal, setShowInstructionsModal] = useState(false);

    const user = useContext(UserContext);

    const handleChange = (e) => {
        const {name, value} = e.target;

        setStateValues(prevState => ({
            ...stateValues, [name]: value
        })
        )
        setCount(e.target.value.length);
        const color = (e.target.value.length === 4000) ? 'orangered' : 'yellowgreen';
        setBgcolor(color);
    }

    const updateAdtrDetails = () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putAdtr",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "adtrId" : adtrDetails.adtrId,
                "adtr" : {...adtrDetails,
                    'issuerComment': stateValues.issuerComment, 
                    'lastUpdateUser' : CommonService.getUserName(user)
                }
            }
        };
    
        FinalSaleReportService.updateAdtr(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                console.log(result.data.errors[0].message)
            } else{
                updateAdtrDependencies(result.data.responses[0], 'adtrInfo');
                toast.success("Comments updated successfully!");
            }
        });
    }
    const validateForm = async(e) => {
        await updateAdtrDetails();
        props.openWidget('retiredIssues');
    }
    return (
        <Card>
            <div className="form">
                <h3>Comments <AiFillQuestionCircle onClick={() => setShowInstructionsModal(true)} className="help-icon heading-help-icon" />

                    {showInstructionsModal &&
                        <Modal show={true} backdrop="static" onHide={() => setShowInstructionsModal(false)}>
                            <Modal.Body>
                                <div>
                                Add any comments about this submission that were not otherwise captured in the report. Comments may be publicly viewable.
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button variant="secondary" className="custom-button custom-button-secondary" onClick={() => setShowInstructionsModal(false)}>Close</button>
                            </Modal.Footer>
                        </Modal>
                    }</h3>
                <div className="comments-box">

                    <textarea name="issuerComment" value={stateValues.issuerComment}
                        id="issuerComment" onChange={(e)=>handleChange(e)}
                        className="form-control" rows="5" maxLength="4000" placeholder="Add a comment..." />
                    <span style={{ position: 'relative', left: '45%', backgroundColor: bgcolor }}>{count} out of 4000 max</span>


                </div>

                <div className="btn-div">
                    {/* <button className="custom-button" type="button" onClick={() => props.openWidget('useOfProceeds')}>Back</button> */}
                    <button className="fright custom-button" type="button" onClick={() => validateForm()}>Save & Next</button>
                </div>
            </div>
        </Card>
    )
}

export default Comments

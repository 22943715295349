import {useState, useEffect, useContext} from 'react'
import Card from '../ui/Card'
import {Row, Col, Form, Modal} from 'react-bootstrap'
import {MDBTooltip} from 'mdbreact'
import {AiFillQuestionCircle} from 'react-icons/ai'
import CommonService from "../../services/CommonService";
import { IssuanceDetailContext } from '../../UseContext';
import FinalSaleReportService from "../../services/FinalSaleReportService";
import { UserContext } from "../../UseContext"
import { toast } from 'react-toastify';
import CurrencyInput from '../formElements/CurrencyInput'
import { FormInput } from '../formElements/FormInput'

const IssuerDetails = (props) => {
    const issuanceDetail = useContext(IssuanceDetailContext);
    const [issuer, setIssuerDetails] = useState(false);

    useEffect(() => {
        var obj = {
          msgId: null,
          sysId: null,
          opr: "getIssuerDetailsByIssuerId",
          hdrStruct: null,
          queryParam: null,
          reqData:{"id" : issuanceDetail.issuerId}
        };
    
        FinalSaleReportService.getIssuerDetails(obj)
          .then((result) => {
            setIssuerDetails(result.data.responses[0]);
          });
      }, [issuanceDetail.issuerId]);
    
    return (
        <Modal show={true} onHide={props.onModalClose}>
            <Modal.Header closeButton>
                <h1>Issuer Details</h1>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <label>Address Line1</label>
                        <Form.Control type="text" value={issuer.contactAddressLine1} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>Address Line2</label>
                        <Form.Control type="text" value={issuer.contactAddressLine2} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>Address Line3</label>
                        <Form.Control type="text" value={issuer.contactAddressLine3} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>Address Line4</label>
                        <Form.Control type="text" value={issuer.contactAddressLine4} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>City</label>
                        <Form.Control type="text" value={issuer.contactCityName} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>State</label>
                        <Form.Control type="text" value={issuer.contactStateCode} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>ZipCode</label>
                        <Form.Control type="text" value={issuer.contactZipCode} readOnly/>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="custom-button" onClick={props.onModalClose}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}
const Issuance = (props) => {
    const { isAdtrFirstYear, updateIssuanceDetails } = props;
    const [issuanceDetail, setIssuanceDetail] = useState(useContext(IssuanceDetailContext));
    const [showIssuerDetails, setShowIssuerDetails] = useState(false);
    const [totalReportableProceeds, setTotalReportableProceeds] = useState(0.00);
    const user = useContext(UserContext);
    
    useEffect(() => {
        async function getMarksRoosProceedAmt() {
            var obj = {
                msgId: null,
                sysId: null,
                opr: "getProceedsUsed",
                hdrStruct: null,
                queryParam: null,
                reqData:{"finalSaleId" : issuanceDetail.finalSaleId}
            };
        
            await FinalSaleReportService.getIssuerDetails(obj)
                .then((result) => {
                    setIssuanceDetail(prevState=>({
                        ...issuanceDetail, 'marksRoosProceedAmt' : result.data.responses[0] 
                    }))
            });
        }

        isAdtrFirstYear && !issuanceDetail.marksRoosProceedAmt && getMarksRoosProceedAmt();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{
        calculateTotalReportableProceeds();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [issuanceDetail]);

    const calculateTotalReportableProceeds = () => {
        const finalSaleInfo = {...issuanceDetail};
        let mkrProceedAmt = typeof(finalSaleInfo.marksRoosProceedAmt) === "string" ? Number(finalSaleInfo.marksRoosProceedAmt.replace(/,/g, '')) : finalSaleInfo.marksRoosProceedAmt;
        let totalProceeds = (finalSaleInfo.principalSaleAmt + finalSaleInfo.netOriginalPremium - mkrProceedAmt);
        setTotalReportableProceeds(totalProceeds)
    }
    const validateForm = () => {
        if(totalReportableProceeds < 0) {
            toast.error("Total Reportable Proceeds cannot be less than zero.");
            return;
        }
        var obj = {
            msgId: null,
            sysId: null,
            opr: "updateMarksRoosProceedAmt",
            hdrStruct: null,
            queryParam: null,
            reqData: { 
                "id": issuanceDetail.finalSaleId,
                "finalSale": {...issuanceDetail,
                    'lastUpdateUser': CommonService.getUserName(user)}
            },
          };
      
          FinalSaleReportService.updateMarksRoosProceedAmt(obj)
            .then((result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    toast.error(result.data.errors[0].message);
                }
                else{
                    updateIssuanceDetails(result.data.responses[0]);
                    toast.success("Issuance Details updated successfully!");
                    props.openWidget('issuanceAuthorization');
                }
                
            });
       
    }
    
    const handleProceedAmtChange = (e) => {
        const {name, value} = e.target;
        setIssuanceDetail(prevState => ({
            ...issuanceDetail, [name]: value
        })
        )
    }

    return (
        <Card>
            <div className="form">
                <h3>Issuance</h3>
            <Row>
            <Col>
                {/* <div style={{float: 'right', marginTop: '10px'}}>
                    <button
                        type="button"
                        className="dk-blue-color link-button" 
                        onClick={()=>setShowIssuerDetails(true)}
                        >View Issuer details</button>
                </div> */}
                <label>Issuer Name 
                    <MDBTooltip domElement tag="span" placement="right">
                        <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                        <span>Read-only. Prepopulated from data submitted on the RFS. If data is incorrect, return to the dashboard to edit the RFS
for this issue – 1 st year ADTR only. After the ADTR has been reviewed, the RFS may not be edited.</span>
                    </MDBTooltip>
                </label>
                <Form.Control type="text" value={issuanceDetail.issuerName} disabled readOnly/>
            </Col>
            </Row>
            
            <Row>
                <Col>
                    <label>Issue Name
                    <MDBTooltip domElement tag="span" placement="right">
                        <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                        <span>Read-only. Prepopulated from data submitted on the RFS. If data is incorrect, return to the dashboard to edit the RFS
for this issue – 1 st year ADTR only. After the ADTR has been reviewed, the RFS may not be edited.</span>
                    </MDBTooltip>
                    </label>
                    <Form.Control type="text" value={issuanceDetail.issueName} disabled readOnly/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <label>Project Name
                    <MDBTooltip domElement tag="span" placement="right">
                        <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                        <span>Read-only. Prepopulated from data submitted on the RFS. If data is incorrect, return to the dashboard to edit the RFS
for this issue – 1 st year ADTR only. After the ADTR has been reviewed, the RFS may not be edited.</span>
                    </MDBTooltip>
                    </label>
                    <Form.Control type="text" value={issuanceDetail.projectName} disabled readOnly/>
                </Col>
            </Row>
           <Row>
                <Col>
                    <label>Actual Sale Date
                    <MDBTooltip domElement tag="span" placement="right">
                        <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                        <span>Read-only. Prepopulated from data submitted on the RFS. If data is incorrect, return to the dashboard to edit the RFS
for this issue – 1 st year ADTR only. After the ADTR has been reviewed, the RFS may not be edited.</span>
                    </MDBTooltip>
                    </label>
                    <Form.Control type="text" value={CommonService.dateFormatter(issuanceDetail.actualSaleDate)} disabled readOnly/> 
                </Col>
                <Col>
                    <label>Settlement Date
                    <MDBTooltip domElement tag="span" placement="right">
                        <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                        <span>Read-only. Prepopulated from data submitted on the RFS. If data is incorrect, return to the dashboard to edit the RFS
for this issue – 1 st year ADTR only. After the ADTR has been reviewed, the RFS may not be edited.</span>
                    </MDBTooltip>
                    </label>
                    <Form.Control type="text" value={CommonService.dateFormatter(issuanceDetail.settlementDate)} disabled readOnly/> 
                </Col>
            </Row>
            <Row>
                <div className="control">
                    <label>Original Principal Amount
                    <MDBTooltip domElement tag="span" placement="right">
                        <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                        <span>Read-only. Prepopulated from data submitted on the RFS. If data is incorrect, return to the dashboard to edit the RFS
for this issue – 1 st year ADTR only. After the ADTR has been reviewed, the RFS may not be edited.</span>
                    </MDBTooltip>
                    </label>
                    <Form.Control type="text" value={CommonService.currencyFormatter(issuanceDetail.principalSaleAmt)} disabled readOnly/>
                </div>
                <div className="control">
                    <label>Net Original Issue Premium/Discount
                    <MDBTooltip domElement tag="span" placement="right">
                        <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                        <span>Read-only. Prepopulated from data submitted on the RFS. If data is incorrect, return to the dashboard to edit the RFS
for this issue – 1 st year ADTR only. After the ADTR has been reviewed, the RFS may not be edited.</span>
                    </MDBTooltip>
                    </label>
                    <Form.Control type="text" value={CommonService.currencyFormatter(issuanceDetail.netOriginalPremium)} disabled readOnly/>
                </div>
                <div className="control">
                    <CurrencyInput 
                        placeholder="0.00" 
                        label="Proceeds Used to Acquire Local Obligations (Marks-Roos Only)" 
                        name="marksRoosProceedAmt" 
                        type="text" 
                        onChange={(e)=>handleProceedAmtChange(e)}
                        value={issuanceDetail.marksRoosProceedAmt}
                        validations={["required", "max:999999999999.99"]}
                        disabled={isAdtrFirstYear ? false : true}
                        helpText="Enter amount used to acquire a local obligation(s) under a Marks-Roos financing structure – 1 st year ADTR only. Read-
                        only in ADTR filings beyond 1 st year."
                    />
                </div>
                <div className="control">
                    <FormInput 
                    placeholder="0.00" 
                    label="Total Reportable Proceeds" 
                    value={CommonService.currencyFormatter(totalReportableProceeds)}
                    disabled
                    helpText="Calculated field. Read-only. This total must equal the total amounts reported for all Fund Categories reported in the Use
                    of Proceeds section."
                    />
                </div>
            </Row>
            
            
            <div className="btn-div">
            <button className="fright custom-button" type="button" onClick={()=>validateForm()}>Save & Next</button>
            </div>
            </div>
            {showIssuerDetails && <IssuerDetails onModalClose={()=>setShowIssuerDetails(false)}/>}
        </Card>
    )
}

export default Issuance

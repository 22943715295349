import { useState, useCallback, useEffect, useContext } from 'react';
import Card from '../ui/Card';
import {Form, Col, Row, Spinner, Modal} from 'react-bootstrap';
import { validateFormFields } from '../../utils/index';
import CodeMaintenanceService from '../../services/CodeMaintenanceService';
import FinalSaleReportService from '../../services/FinalSaleReportService';
import { toast } from 'react-toastify';
import { UserContext } from '../../UseContext';
import CommonService from '../../services/CommonService';
import BlobService from '../../services/BlobService';
import { VscTrash } from 'react-icons/vsc';
import swal from 'sweetalert';
import ReportOfProposedDebtService from '../../services/ReportOfProposedDebtService';
import { MDBTooltip } from 'mdbreact';
import {AiFillQuestionCircle} from 'react-icons/ai';
import DataGrid from '../GenericComponents/DataGrid';
import { Column } from 'primereact/column';

const DocumentUpload = (props) => {
    const {finalSaleDetails, updateFinalSaleDetails, updateFinalSaleDependencies} = props;
    const [finalSaleInfo, setFinalSaleInfo] = useState(finalSaleDetails);
    const [formErrors, setFormErrors] = useState({});
    const [docTypes, setDocTypes] = useState([]);
    const loginUser = useContext(UserContext);
    const [finalSaleDocuments, setFinalSaleDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showSpinner, setShowSpinner] = useState(false);
    const [file, setFile] = useState(null);
    const [showHelpTextModal, setShowHelpTextModal] = useState(false);
    const [selectedDocType, setSelectedDocType] = useState({
        docTypeId: '',
        docType: ''
    });

    const fetchData = useCallback(() => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "getIssueDocumentsByIssueId",
            hdrStruct: null,
            queryParam: null,
            reqData: { "id": finalSaleDetails.finalSaleId,
                "reportType" : "RFS"},
          };
      
          FinalSaleReportService.getDocumentsByFinalSaleId(obj)
            .then((res) => res)
            .then((result) => {
                setFinalSaleDocuments(result.data.responses[0]);
                updateFinalSaleDependencies(result.data.responses[0], "issueDocuments");
                setIsLoading(false);
            });
            // eslint-disable-next-line 
    }, [props.finalSaleDetails])

    useEffect(() => {
        var obj = {
          msgId: null,
          sysId: null,
          opr: "GetLookups",
          hdrStruct: null,
          queryParam: null,
          reqData: {cdiacCode: "DOCT", active: "Y"},
        };
    
        CodeMaintenanceService.getAllCodes(obj)
          .then((res) => res)
          .then((result) => {
            const filteredList = result.data.responses[0].filter(el => el.activeInd === "Y");
            setDocTypes(filteredList);
            setIsLoading(false);
          });
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleDocTypeChange = (e) => {
        let index = e.nativeEvent.target.selectedIndex;
        let value = e.nativeEvent.target[index].value;
        let label = e.nativeEvent.target[index].text;
        setSelectedDocType({
            docTypeId: value,
            docType: label
        });
    }

    const handleChange = e => {
        const {name, value} = e.target;
        setFinalSaleInfo(prevState=>({
            ...finalSaleInfo, [name] : value
        })
        )
    }

    const onChangeHandler = (e) => {
        let file = e.target.files[0];
        if(file.size > 100000000){
            toast.error("File size exceeds a maximum limit of 100 MB. Please try with a small file size or contact CDIAC.");
            e.target.value = null;
        }
        else if(!file.type.match('application/pdf')){
            toast.error("Only PDF files can be uploaded");
            e.target.value = null;
        }
        else{
            setFile(file);
        }
    }

    const updateFinalSaleInfo = () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putFinalSale",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id": finalSaleInfo.finalSaleId,
                "finalSale": {...finalSaleInfo,
                    'lastUpdateUser': CommonService.getUserName(loginUser)}
            }
        };
    
        FinalSaleReportService.updateFinalSale(obj)
        .then((res) => res)
        .then((result) => {
            if(result.data.responses[0]) {
                toast.success("Document Submittal Updated Successfully!");
                updateFinalSaleDetails(result.data.responses[0]);
                props.sectionValidated('uploadDoc', true);
                props.openWidget('review');       
            }
        });
        
    }

    const validateForm = (e) => {
        let form = e.target.closest('form#docUploadForm');

        let formErrors = validateFormFields(form.elements);    
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
            props.sectionValidated('uploadDoc', false);
        } else {
            if(finalSaleDocuments.length === 0){
                alert("Please upload at least one document.")
            } else {
                setFormErrors({});
                updateFinalSaleInfo();
            }         
        }
     
    }

    const handleDeleteFile = (data) => {
        
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this document!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                setShowSpinner(true);
                var obj = {
                    msgId: null,
                    sysId: null,
                    opr: "deleteBlob",
                    hdrStruct: null,
                    queryParam: null,
                    reqData: {
                        "issueDocId" : data.issueDocId              
                    },
                  };
              
                  BlobService.deleteFile(obj)
                    .then((res) => res)
                    .then((result) => {
                        if(result.data.errors && result.data.errors.length > 0){
                            setShowSpinner(false);
                            toast.error(result.data.errors[0].message)
                        }
                        else{
                            setShowSpinner(false);
                            toast.success("Document removed successfully!");
                            fetchData();
                        }
                        
                    });
            } 
          });
        
      }

    const handleUpload = (e) => {
        setFormErrors({});
    if ( file && selectedDocType.docTypeId !== "" ){
        setShowSpinner(true);
        let formData = new FormData();
        
        formData.append('file', file);
        formData.append('docTypeCodeId', selectedDocType.docTypeId.toString());
        formData.append('createUser', loginUser);
        formData.append('proposedDebtId', "");
        formData.append('mimeType', "pdf");
        formData.append('finalSaleId', finalSaleInfo.finalSaleId);
        formData.append('fileName', file.name);

        BlobService.uploadFile(formData)
        .then((res) => { 
            if (res.data.errors && res.data.errors.length > 0){
                setShowSpinner(false);
                toast.error(res.data.errors[0].message);
            }
            else{
                setShowSpinner(false);
                e.target.value = null;
                toast.success("File uploaded successfully!");
                fetchData()
            }

        }) // Handle the response from backend here
          .catch((err) => { }); // Catch errors if any
        } else {
            let formErrorsArr = {};
            if( selectedDocType.docTypeId === "" ) formErrorsArr['docType'] = "This field is required." ;
            if(!file) formErrorsArr['inputFile'] = "This field is required." ;
            setFormErrors(formErrorsArr);
        }
    }

    const handleViewDocument = (e, issueDocId) => {
        setShowSpinner(true);
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getPdfDocumentFromBlob",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": { 
                "issueDocId" : issueDocId,
                "type" : "issue_document"
            }
        };
        ReportOfProposedDebtService.getHistory(obj)
          .then((res) => {
            var base64EncodedPDF = res.data.responses[0];
            var arrrayBuffer = base64ToArrayBuffer(base64EncodedPDF); 
            function base64ToArrayBuffer(base64) {
                let binaryString = window.atob(base64);
                let binaryLen = binaryString.length;
                var bytes = new Uint8Array(binaryLen);
                for (var i = 0; i < binaryLen; i++) {
                    var ascii = binaryString.charCodeAt(i);
                    bytes[i] = ascii;
                }
                return bytes;
            }
            setShowSpinner(false);
            var blob = new Blob([arrrayBuffer], {type: "application/pdf"});
            var url = window.URL.createObjectURL(blob);
            window.open(url);
        });
    }

    const viewFormatter = (row) => { 
        return ( 
            <div style={{lineHeight: "normal" }}>
                <VscTrash className="button" onClick={(e) => handleDeleteFile(row)}/>
            </div> 
        ); 
    }
    const fileNameFormatter = (row) => { 
        return ( 
            <div style={{lineHeight: "normal" }}>
                <button
                    type="button"
                    className="link-button dk-blue-color"
                    onClick={(e)=>handleViewDocument(e, row.issueDocId)}>{row.fileName}
                </button>
            </div> 
        ); 
    }

    const columns = [{
        dataField: 'documentDesc',
        text: 'Document Type',
        sort: true
      }, {
        dataField: 'fileName',
        text: 'Document Name',
        formatter: fileNameFormatter,
        sort: true,
      }, {
        dataField: 'createDatetime',
        text: 'File Upload Date',
        sort: true,
        formatter: (row) => CommonService.dateFormatter(row['createDatetime'])
      },
      { 
        dataField: "",
        text: "Action", 
        formatter: viewFormatter
        }
    ];

    return (
        <Card>
            <form className="form" id="docUploadForm">
            <Row>
                <label style={{marginBottom: '0.5rem'}}><span className="required">*</span>Official Statement/Offering Memorandum:
                <MDBTooltip
                    domElement
                    tag="span"
                    placement="right"
                    >
                    <span>{"  "}<AiFillQuestionCircle onClick={()=>setShowHelpTextModal(true)} className="help-icon heading-help-icon"/></span>
                    <span>Click me for more info.</span>
                </MDBTooltip>
                {showHelpTextModal && 
                <Modal show={true} backdrop="static" onHide={()=>setShowHelpTextModal(false)}>
                    <Modal.Body>
                        <div>
                            <span>Document submission is required to validate and submit the Report of Final Sale and must include a copy of the
                                final official statement and maturity schedule for the issue. If there is no official statement, the issuer shall
                                submit each of the following documents, if they exist, with the Report of Final Sale:</span>
                            <ul style={{listStyle: 'numeric'}}>
                                <li> Other disclosure document.</li>
                                <li> Indenture.</li>
                                <li> Installment sales agreement.</li>
                                <li> Loan agreement.</li>
                                <li> Promissory note.</li>
                                <li> Bond purchase contract.</li>
                                <li> Resolution authorizing the issue.</li>
                                <li> Bond specimen.</li>
                            </ul>
                            <span>This information will be displayed for public viewing. The issuer is advised to redact confidential information contained in the documents if the redacted information is not information that is otherwise required to be reported to CDIAC.</span>
                            
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button variant="secondary" onClick={()=>setShowHelpTextModal(false)}>Close</button>
                    </Modal.Footer>
                </Modal>
                }
                </label>
            </Row>
            <Row>
                <Col>
                <Form.Check
                        inline
                        label="Enclosed"
                        id="enclosed"
                        value="E"
                        name="officialStmtRcvdFlag"
                        type="radio"
                        checked={finalSaleInfo.officialStmtRcvdFlag === 'E'}
                        onChange={(e)=>handleChange(e)}
                        data-validations="required"
                    />
                <Form.Check
                        inline
                        label="None Prepared"
                        id="none"
                        value="N"
                        name="officialStmtRcvdFlag"
                        type="radio"
                        checked={finalSaleInfo.officialStmtRcvdFlag === 'N'}
                        onChange={(e)=>handleChange(e)}
                        data-validations="required"
                    />
                {formErrors['officialStmtRcvdFlag'] && <p className="error">{formErrors['officialStmtRcvdFlag']}</p>}
            </Col>
            </Row>

            <Row>
                <Col>
                    <label style={{marginBottom: '0.5rem'}}><span className="required">*</span>Maturity Schedule:
                    <MDBTooltip domElement tag="span" placement="right">
                    <span>{"  "}<AiFillQuestionCircle className="help-icon heading-help-icon"/></span>
                    <span>Indicate if the maturity schedule is a stand-alone document or if it is included in the Official Statement or Offering Memorandum.</span>
                    </MDBTooltip>
                    </label>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check
                            inline
                            label="Attached"
                            name="maturityScheduleCode"
                            value="A"
                            type="radio"
                            id="attached"
                            onChange={(e)=>handleChange(e)}
                            checked={finalSaleInfo.maturityScheduleCode === "A"}
                            data-validations="required"
                        />
                    <Form.Check
                            inline
                            label="Included in Official Statement"
                            name="maturityScheduleCode"
                            value="I"
                            type="radio"
                            id="included"
                            onChange={(e)=>handleChange(e)}
                            checked={finalSaleInfo.maturityScheduleCode === "I"}
                            data-validations="required"
                        />
                    {formErrors['maturityScheduleCode'] && <p className="error">{formErrors['maturityScheduleCode']}</p>}
                </Col>
            </Row>

            <div>
                <i>
                    <ul  className="docrequirements">
                        <li>(1) Only pdf documents are accepted.</li>
                        <li>(2) You must select "DOCUMENT TYPE" from the document type list.</li>
                        <li>(3) More than one document can be uploaded for each document type, but please DO NOT upload duplicate files.</li>
                        <li>(4) Maximum file size for each document is 100 MB. If your document is larger than 100 MB, please contact CDIAC.</li>
                        <li>(5) Please ensure that all sensitive/confidential information is redacted.</li>
                    </ul>
                </i>
            </div>

            <Row className="mb-20">
                <label className="mb-10">Document Type:</label>

                <Col md={3}>
                <Form.Select onChange={(e)=>handleDocTypeChange(e)} defaultValue={selectedDocType.docTypeId}>
                        <option>Select</option>
                        
                        {(docTypes.length !== 0) && docTypes.map((docType, i) =>{
                            return (<option key={i} value={docType.cdiacCodeValueId}>{docType.cdiacCodeValueDesc}</option>);
                        }
                        ) }
                </Form.Select>{isLoading && <Spinner animation="border" variant="primary" />}
                </Col>
                {formErrors['docType'] && <p className="error">{formErrors['docType']}</p>}
            </Row>

            <Row className="mb-20">
                <label className="mb-10">Choose a document to upload (DO NOT UPLOAD CHECKS OR INVOICES)</label>
                <div className="mb-10">
                <input className="form-control" type="file" name="file" accept="application/pdf" onChange={(e)=>onChangeHandler(e)}/>
                </div>
                {formErrors['inputFile'] && <p className="error">{formErrors['inputFile']}</p>}
                <div className="mb-10">
                <button id="color-2" type="button" onClick={(e) => handleUpload(e)}>Upload File</button>
                </div>
            </Row>  
            {showSpinner && <Modal show={true} className="modal bd-example-modal-lg" data-backdrop="static">
                <div className="modal-dialog modal-sm">
                        <Spinner animation="border"/>
                </div>
            </Modal>
            } 

            <div className="mb-20">
                <label className="mb-10">Documents Already Uploaded</label>
                <DataGrid
                dataSource={finalSaleDocuments}
                sortField='createDatetime'
                emptyDataMessage='No data available to display'
                showPaginator={false}
                showQuickSearch={false}
                >
                {columns.map((column, i) => {
                    return (
                    <Column 
                    key={i}
                    field={column.dataField}
                    header={column.text}
                    sortable={column.sort} 
                    body={column.formatter}
                    hidden={column.hidden}
                    />)
                    }) 
                } 
                </DataGrid>

            </div>

            <div className="btn-div">
            <button className="fright custom-button" type="button" onClick={(e)=>validateForm(e)}>Save & Next</button>
            </div>

            </form>
        </Card>
    )
}

export default DocumentUpload

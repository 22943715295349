import { FaUsers, FaUser} from 'react-icons/fa'
import {GrDocumentUpload} from 'react-icons/gr'
import { VscPreview} from 'react-icons/vsc'

export const ProposedProgressBarData = [
    {
        title: "Issuance",
        path: "",
        section: "issuance",
        icon: <img src="/assets/images/issuance.jpg" style={{width:'2rem', height: '2rem'}} alt="" />
    },
    {
        title: "Issuance Authorization",
        path: "",
        section: "issuanceAuthorization",
        icon: <img src="/assets/images/issuance.authorization.jpg" style={{width:'2rem', height: '2rem'}} alt="" />
    },
    {
        title: "Statutory Authority",
        path: "",
        section: "statutories",
        icon: <img src="/assets/images/statutory_authority.jpg" style={{width:'2rem', height: '2rem'}} alt="" />
    },
    {
        title: "Filing Contact",
        path: "",
        section: "filingContact",
        icon: <FaUser style={{width:'1.5rem', height: '2rem'}}/>
    },
    {
        title: "Tax Status/Interest",
        path: "",
        section: "interestDebtExempt",
        icon: <img src="/assets/images/tax.status.interest.jpg" style={{width:'2rem', height: '2rem'}} alt="" />
    },
    {
        title: "Type of Debt Instrument",
        path: "",
        section: "typeOfDebt",
        icon: <img src="/assets/images/type_of_debt_instrument.jpg" style={{width:'2rem', height: '2rem'}} alt="" />
    },
    {
        title: "Source of Repayment",
        path: "",
        section: "sourceOfRepayment",
        icon: <img src="/assets/images/source_of_repayment.jpg" style={{width:'2rem', height: '2rem'}} alt="" />
    },
    {
        title: "Purpose of Financing",
        path: "",
        section: "purposeOfFinancing",
        icon: <img src="/assets/images/purpose_of_financing.jpg" style={{width:'2rem', height: '2rem'}} alt="" />
    },
    {
        title: "Financing Participants",
        path: "",
        section: "financingParticipants",
        icon: <FaUsers style={{width:'1.5rem', height: '2rem'}} />
    },
    {
        title: "Type of Sale",
        path: "",
        section: "typeOfSale",
        icon: <img src="/assets/images/type.of.sale.jpg" style={{width:'2rem', height: '2rem'}} alt="" />
    },
    {
        title: "Document Submittal",
        path: "",
        section: "uploadDoc",
        icon: <GrDocumentUpload style={{width:'1.5rem', height: '2rem'}}/>
    },
    {
        title: "Review",
        path: "",
        section: "review",
        icon: <VscPreview style={{width:'1.5rem', height: '2rem'}}/>
    },
]
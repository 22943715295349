import {useState, useEffect, useContext} from 'react'
import Card from '../ui/Card'
import {Row, Col, Form, Modal} from 'react-bootstrap'
import {MDBTooltip} from 'mdbreact'
import {AiFillQuestionCircle} from 'react-icons/ai'
import CommonService from '../../services/CommonService'
import { IssuanceDetailContext } from '../../UseContext'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import { UserContext } from '../../UseContext'
import { toast } from 'react-toastify'
import CurrencyInput from '../formElements/CurrencyInput'
import { runValidations, validateFormFields } from '../../utils'
import { FormInput } from '../formElements/FormInput'

const IssuerDetails = (props) => {
    const issuanceDetail = useContext(IssuanceDetailContext);
    const [issuer, setIssuerDetails] = useState(false);

    useEffect(() => {
        var obj = {
          msgId: null,
          sysId: null,
          opr: "getIssuerDetailsByIssuerId",
          hdrStruct: null,
          queryParam: null,
          reqData:{"id" : issuanceDetail.issuerId}
        };
    
        FinalSaleReportService.getIssuerDetails(obj)
          .then((result) => {
            setIssuerDetails(result.data.responses[0]);
          });
          // eslint-disable-next-line
      }, []);
    
    return (
        <Modal show={true} onHide={props.onModalClose}>
            <Modal.Header closeButton>
                <h1>Issuer Details</h1>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <label>Address Line1</label>
                        <Form.Control type="text" value={issuer.contactAddressLine1} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>Address Line2</label>
                        <Form.Control type="text" value={issuer.contactAddressLine2} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>Address Line3</label>
                        <Form.Control type="text" value={issuer.contactAddressLine3} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>Address Line4</label>
                        <Form.Control type="text" value={issuer.contactAddressLine4} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>City</label>
                        <Form.Control type="text" value={issuer.contactCityName} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>State</label>
                        <Form.Control type="text" value={issuer.contactStateCode} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>ZipCode</label>
                        <Form.Control type="text" value={issuer.contactZipCode} readOnly/>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="custom-button" onClick={props.onModalClose}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}
const Issuance = (props) => {
    const { markRoosLocalObligDetails } = props;
    const issuanceDetail = useContext(IssuanceDetailContext);
    const [showIssuerDetails, setShowIssuerDetails] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const user = useContext(UserContext);

    const validateForm = (e) => {

        if(stateValues.reserveMinBalInd === 'Y' && stateValues.reserveMinBalAmt === ''){
            toast.error('Reserve Fund Minimum Balance Amount is mandatory if the Reserve Fund Minimum Balance selected is Yes');
            return;
        }

        if(stateValues.authorityReserveFundInd === 'Y' && stateValues.percentReservedFund === undefined){
            toast.error('Percent of Reserve Fund  is mandatory if the part of Authority Reserve Fund selected is Yes');
            return;
        }

        let form = e.target.closest('form#issuanceForm');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});
        var obj = {
            msgId: null,
            sysId: null,
            opr: "updateMKRRoosLocalObligReserve",
            hdrStruct: null,
            queryParam: null,
            reqData: {  
                "env" : "E",
                "marksRoosLocalObligor":{
                    "reserveMinBalInd" : stateValues.reserveMinBalInd,
                    "reserveMinBalAmt" : stateValues.reserveMinBalAmt,
                    "authorityReserveFundInd" : stateValues.authorityReserveFundInd,
                    "percentReservedFund" : stateValues.percentReservedFund,
                    "marksRoosLocalObligorId" : markRoosLocalObligDetails.marksRoosLocalObligorId,
                    "totalIssuanceCostAmt" : stateValues.totalIssuanceCostAmt,
                    "lastUpdateUser": CommonService.getUserName(user),
                    "emailId": user
                }
            },
          };
      
          FinalSaleReportService.updateMKRRoosLocalObligReserve(obj)
            .then((result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    toast.error(result.data.errors[0].message);
                }
                else{
                    props.updateMkrLocalObligorDetails(result.data.responses[0]); 
                    toast.success("Issuance Details updated successfully!");
                }
                
            });
            props.openWidget('fundBalance');
        }
    }

    const [stateValues, setStateValues] = useState({
        reserveMinBalInd : markRoosLocalObligDetails.reserveMinBalInd,
        reserveMinBalAmt: markRoosLocalObligDetails.reserveMinBalAmt,
        authorityReserveFundInd: markRoosLocalObligDetails.authorityReserveFundInd,
        percentReservedFund: markRoosLocalObligDetails.percentReservedFund,
        totalIssuanceCostAmt:  markRoosLocalObligDetails.totalIssuanceCostAmt
    })

    const handleChange = (e) => {
        const {name, value} = e.target;
        
        setStateValues(prevState => ({
            ...stateValues, [name]: value
        })
        )
    }

    const validateField = (e) => {
        if ( e.target.dataset['validations'] )
            runValidations(e) 
    }

    return (
        <Card>
            <form className="form" id="issuanceForm">
                <h3>Issuance</h3>
            <Row>
            <Col>
                {/* <div style={{float: 'right', marginTop: '10px'}}>
                    <button
                        type="button"
                        className="dk-blue-color link-button" 
                        onClick={()=>setShowIssuerDetails(true)}
                        >View Issuer details</button>
                </div> */}
                <FormInput label="Issuer Name" type="text" value={issuanceDetail.issuerName} disabled helpText="Prepopulated from the Issuer Name of the Report of Final Sale."/>
            </Col>
            </Row>
            
            <Row>
            <Col>
                <FormInput label="Issue Name" type="text" value={issuanceDetail.issueName} disabled helpText="Prepopulated from data submitted on the Report of Final Sale."/>
            </Col>
            </Row>
            <Row>
            <Col>
                <FormInput label="Project Name" type="text" value={issuanceDetail.projectName} disabled
                helpText="Prepopulated from data submitted on the Report of Final Sale, this field includes the name of the project(s) for which the debt proceeds will be used."/>
            </Col>
            </Row>
           <Row>
                <Col>
                    <FormInput label="Date of Bond Issue/Loans" type="text" value={CommonService.dateFormatter(issuanceDetail.actualSaleDate)} disabled
                    helpText="Prepopulated from the Report of Final Sale, this is the actual sale date of the Marks-Roos Local Obligor bond."/> 
                </Col>
                <Col>
                    <FormInput label="Settlement Date" type="text" value={CommonService.dateFormatter(issuanceDetail.settlementDate)} disabled
                    helpText="Prepopulated from the Report of Final Sale, this is the closing date of the transfer of assets or rights to use from creditor to the issuer in exchange for delivery of the instruments or evidence of indebtedness."/> 
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormInput label="Original Principal Amount" type="text" value={CommonService.currencyFormatter(issuanceDetail.principalSaleAmt)} disabled
                    helpText="Prepopulated from the Report of Final Sale, this is the original principal amount of the bonds."/>
                </Col>
                <Col>
                    <FormInput label="Date of Filing" type="text" value={CommonService.dateFormatter(markRoosLocalObligDetails.filingDate)} disabled
                    helpText="Prepopulated from the Report of Final Sale, this is the date the Report of Final Sale was filed."/>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <div className="control">
                        <CurrencyInput 
                        placeholder="0.00" 
                        label="Total Issuance Costs" 
                        name="totalIssuanceCostAmt" 
                        type="text" 
                        value={stateValues.totalIssuanceCostAmt ? stateValues.totalIssuanceCostAmt  : ""}
                        onChange={(e)=>handleChange(e)} 
                        onBlur={e=>validateField(e)}
                        error={formErrors.totalIssuanceCostAmt} 
                        validations={[!markRoosLocalObligDetails.isFirstYear && "required", "max:999999999999.99"]}
                        isrequired={!markRoosLocalObligDetails.isFirstYear}
                        disabled={markRoosLocalObligDetails.isFirstYear}
                        helpText="Indicate the Total Issuance Costs (underwriter’s discount, bond counsel and financial advisor fees, printing, insurance and ratings) for the initial Marks-Roos Local Obligor Yearly Fiscal Status report that were paid from the bond proceeds, if not reported on the Authority issue. These costs are only to be reported in the initial filing. This field is left blank in subsequent report filings."
                        />
                    </div>
                </Col>
                <Col md={6}>
                    <div className="control">
                        <CurrencyInput 
                        placeholder="0.00" 
                        label="Total Cost of Issuance from Report of Final Sale" 
                        name="totalIssuanceAmt" 
                        type="text" 
                        defaultValue={issuanceDetail.totalIssuanceAmt ? issuanceDetail.totalIssuanceAmt  : ""}
                        validations={["max:999999999999.99"]}
                        disabled
                        helpText="Read-only. Prepopulated from cost of issuance data submitted on the RFS for this issue."
                        />
                    </div>
                </Col>
            </Row>

            <div className="control">
                <FormInput label="Name of Authority that purchased debt" type="text" value={issuanceDetail.authorityName} disabled
                helpText="Prepopulated from the Report of Final Sale, this is the Marks-Roos Authority that purchased debt from the Local Obligor (LOB)."/>
            </div>
            <Row>
                <Col md={6}>
                    <FormInput label="Date of Authority Bond(s) Issuance" type="text" value={CommonService.dateFormatter(issuanceDetail.authorityBondIssuanceDate)} disabled
                    helpText="Prepopulated from the Report of Final Sale, this is the actual sale date of the Marks-Roos Authority bond."/>
                </Col>
                <Col md={6}>
                    <div className="control">
                        <CurrencyInput 
                        placeholder="0.00" 
                        label="Total Costs of Issuance from Authority Report of Final Sale" 
                        name="authorityTotalIssuanceCost" 
                        type="text" 
                        defaultValue={issuanceDetail.authorityTotalIssuanceCost ? issuanceDetail.authorityTotalIssuanceCost  : ""}
                        validations={["max:999999999999.99"]}
                        disabled
                        helpText="Read-only. Prepopulated from cost of issuance data submitted on the RFS for the Authority issue."
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                <div>
                    <label><span className="required">*</span>Is the Reserve Fund held at Authority level?
                    <MDBTooltip domElement tag="span" placement="right">
                    <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                    <span>Indicate whether a Reserve Fund Minimum Balance is required if the LOB funded a reserve using the proceeds of its bond issue or from the loan received.</span>
                    </MDBTooltip>
                    </label>
                    <div style={{display: 'flex'}}>
                        <Form.Check inline type="radio" name="reserveMinBalInd" id="reserveMinBalInd_Y" checked={stateValues.reserveMinBalInd === "Y"} label="Yes" value="Y" data-validations="required" onChange={(e)=>handleChange(e)}/>
                        <Form.Check inline type="radio" name="reserveMinBalInd" id="reserveMinBalInd_N" checked={stateValues.reserveMinBalInd === "N" } label="No" value="N" data-validations="required" onChange={(e)=>handleChange(e)}/>
                        {formErrors['reserveMinBalInd'] && <p className="error">{formErrors['reserveMinBalInd']}</p>}
                    </div>
 
                </div>
                </Col>
            </Row>
            <Row>
                < Col md={6}>
                    <div className="control">
                        <CurrencyInput 
                        placeholder="0.00" 
                        label="Reserve Fund Minimum Balance Amount" 
                        name="reserveMinBalAmt" 
                        type="text" 
                        value={stateValues.reserveMinBalAmt}
                        onChange={(e)=>handleChange(e)} 
                        onBlur={e=>validateField(e)}
                        error={formErrors.reserveMinBalAmt} 
                        validations={[stateValues.reserveMinBalInd === "Y" && "required", "max:999999999999.99"]}
                        isrequired={stateValues.reserveMinBalInd === "Y"}
                        helpText="Indicate Reserve Fund Minimum Amount if a reserve fund minimum balance is required."
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                <div>
                    <label><span className="required">*</span>Part of Authority Reserve Fund:
                    <MDBTooltip domElement tag="span" placement="right">
                    <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                    <span>Indicate whether the LOB was required to contribute to a reserve fund held by the Authority.</span>
                    </MDBTooltip>
                    </label>
                    <div style={{display: 'flex'}}>
                        <Form.Check inline type="radio" name="authorityReserveFundInd" id="authorityReserveFundInd_Y" checked={stateValues.authorityReserveFundInd === "Y"} label="Yes" value="Y" data-validations="required" onChange={(e)=>handleChange(e)}/>
                        <Form.Check inline type="radio" name="authorityReserveFundInd" id="authorityReserveFundInd_N" checked={stateValues.authorityReserveFundInd === "N" } label="No" value="N" data-validations="required" onChange={(e)=>handleChange(e)}/>
                        {formErrors['authorityReserveFundInd'] && <p className="error">{formErrors['authorityReserveFundInd']}</p>}
                    </div>
 
                </div>
                </Col>
            </Row>
            <Row>
                < Col md={6}>
                    <div className="control">
                        <CurrencyInput 
                        placeholder="0.00" 
                        label="Percent of Reserve Fund" 
                        name="percentReservedFund" 
                        type="text" 
                        value={stateValues.percentReservedFund}
                        onChange={(e)=>handleChange(e)} 
                        onBlur={e=>validateField(e)}
                        error={formErrors.percentReservedFund} 
                        validations={[stateValues.authorityReserveFundInd === "Y" && "required", "max:99.99"]}
                        isrequired={stateValues.authorityReserveFundInd === "Y"}
                        helpText="Indicate the percentage of the reserve fund contributed."
                        />
                    </div>
                </Col>
            </Row>
            
            
            <div className="btn-div">
            <button className="fright custom-button" type="button" onClick={(e)=>validateForm(e)}>Save & Next</button>
            </div>
            </form>
            {showIssuerDetails && <IssuerDetails onModalClose={()=>setShowIssuerDetails(false)}/>}
        </Card>
    )
}

export default Issuance

import { useEffect, useState, useContext } from 'react'
import { Spinner} from 'react-bootstrap'
import {MDBTooltip} from 'mdbreact'
import {AiFillQuestionCircle} from 'react-icons/ai'
import {useNavigate} from 'react-router-dom'
import swal from 'sweetalert'
import ReportsService from '../../services/ReportsService'
import CommonService from '../../services/CommonService'
import { toast } from 'react-toastify'
import { UserContext } from '../../UseContext'
import DataGrid from '../GenericComponents/DataGrid'
import { Column } from 'primereact/column'
import { configManager } from '../../configManager'
import { useMsal } from '@azure/msal-react'
import { SplitButton } from 'primereact/splitbutton'

const DrawReports = (props) => {
    const {instance} = useMsal();
    const loginUserId = useContext(UserContext)
    const {issuerId, reportStatus, searchCriteria} = props;
    const navigate = useNavigate();
    const [draws, setDraws] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getPendingDraws(issuerId);
        // eslint-disable-next-line
    }, [issuerId, searchCriteria]);
    const getPendingDraws = (issuerId) => {
        setIsLoading(true);
        let obj;
        if(reportStatus === "view-previous") {
             obj ={
                "msgId": null,
                "sysId": null,
                "opr": "getOldDraws",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "id" : issuerId,
                    "env" : "E",
                    "searchCriteria": searchCriteria
                }
            } 
        } else {
             obj ={
                "msgId": null,
                "sysId": null,
                "opr": "getPendingDraws",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "id" : issuerId,
                    "env" : "E"
                }
            } 
        }
        ReportsService.getReports(obj)
        .then((res) => res)
        .then((result) => {
            setIsLoading(false);
            if(result.data.statusCode === 401 || result.data.statusCode === 500) {
                CommonService.handleServerResponse(result.data, instance);
            } else {
                setDraws(result.data.responses[0]);
            }
        });
    }
    const actions = {
        'D' : [
            {value:'E', label: 'Edit'},
            {value:'C', label: 'Cancel'},
            {value:'SH', label: 'View History'}
        ],
        'S' : [
            {value:'E', label: 'Edit'},
            {value:'C', label: 'Cancel'},
            {value:'SH', label: 'View History'}
        ],
        'R' : [
            {value:'E', label: 'Edit'},
            {value:'SH', label: 'View History'},
            {value:'DW', label: 'View in DebtWatch'}
        ]
    }
    const viewFormatter = (row) => { 
        const items =  actions[row['issueStatusFlag']].map((action, i)=> {
            return ( {
                label: action.label,
                key: i,
                command: (e) => {
                    handleActionClick(e, action.value, row )
                }
            })
            
         });
        return ( 
            <div style={{ textAlign: "center", lineHeight: "normal" }}>
                {reportStatus === "view-previous" && row['retiredFlag'] === "No" ?
                <span>Not Applicable</span> :
                <div>
                    <SplitButton 
                        label="Action" 
                        model={items} 
                        severity='secondary' 
                        size='small' 
                    />
                </div>
                }
            </div> 
        ); 
    }

    const cancelDraw = (drawId) => {
        var obj ={
            "msgId": null,
            "sysId": null,
            "opr": "cancelDraw",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id" : drawId,
                "user" : loginUserId,
                "env" : 'E'
            }
          } ;
      
        ReportsService.cancelReport(obj)
        .then((res) => res)
        .then(async (result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message)
            }
            else{
                toast.success("The transaction has been cancelled and an acknowledgement email has been sent.");
                getPendingDraws(issuerId);
            }
        });
    }

    const handleActionClick = (e, action, row) => {
        const drawId = row.drawId;
        if( action === 'C' ) {
            swal("Are you sure you want to permanently cancel this transaction?", {
                buttons: ["No", "Yes"],
            }).then((value) => {
               if(value){
                cancelDraw(drawId);
               }
            });
        } else if( action === 'E') {      
            navigate(`/dashboard/draw-on-reserve/${drawId}`);     
        } else if( action === 'SH') {
            navigate(`/status-history-upload-documents/${row.cdiacNbr}`);
        } else if( action === 'DW') {
            window.open( `${configManager.debtWatchUrl}/issue-level-detail?cdiacNumber=${row.cdiacNbr}`);
        }
    }  
    const viewHeaderFormatter = (row, column) => {
        return (<>{column.text}
        { column.headerFormatter && <MDBTooltip domElement tag="span" placement="right" >
            <span>{"  "}<AiFillQuestionCircle className="help-icon heading-help-icon"/></span>
            <span>Click on the Action button to view the actions that may be taken with the specific report and CDIAC # listed.</span>
        </MDBTooltip>
        }
        </>);
    }
    const columns = [{
        dataField: 'cdiacNbr',
        text: 'CDIAC #',
        sort: true
      }, {
        dataField: 'issueStatus',
        text: 'Status',
        sort: true
      },{
        dataField: 'issueName',
        text: 'Issue Name',
        sort: true
      }, {
        dataField: 'issuerName',
        text: 'Issuer Name',
        sort: true
      }, {
        dataField: 'projectName',
        text: 'Project Name',
        sort: true
      }, {
        dataField: 'actualSaleDate',
        text: 'Actual Sale Date',
        sort: true,
        formatter: (row) => CommonService.dateFormatter(row['actualSaleDate'])
      },{
        dataField: 'principalSaleAmt',
        text: 'Principal Amount',
        sort: true,
        formatter: (row) => CommonService.currencyFormatter(row['principalSaleAmt'])
      },{
        dataField: 'retiredFlag',
        text: 'Ongoing Reportable',
        sort: true,
        hidden: reportStatus === "view-previous" ? false : true
      },{ 
        dataField: "",
        text: "Action", 
        formatter: viewFormatter,
        headerFormatter: viewHeaderFormatter
    }
    
    ]
    return (
        <div>
            {isLoading ? <Spinner animation="border" variant="primary" /> :
             <DataGrid
             title="Draw on Reserve/Default/Replenishment"
             dataSource={draws}
             sortField='cdiacNbr'
             searchToolTip="Use the search feature to find a specific issue within the dashboard. Click on the dashboard column headings to sort."
             emptyDataMessage='No data available to display'
             >
             {columns.map((column, i) => {
                 return (
                 <Column 
                 key={i}
                 field={column.dataField}
                 header={(row) =>viewHeaderFormatter(row, column)}
                 sortable={column.sort} 
                 body={column.formatter}
                 hidden={column.hidden}
                 />)
                 }) 
             } 
             </DataGrid>
            }
        </div>
    )
}

export default DrawReports

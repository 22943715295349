import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
const TooltipComponent = ({ id, children, title, placement, className }) => (
            <OverlayTrigger 
            placement={placement} 
            overlay={<Tooltip id={id} style={{zIndex: 9999}}>{title}</Tooltip>}
            >
              <span className={className ? className : 'ml-2'}>{children}</span>
            </OverlayTrigger>
);
export default TooltipComponent;
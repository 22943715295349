import { LogLevel } from "@azure/msal-browser";

export const configManager = {
    apiUrl: process.env.NODE_ENV==="development"? "http://localhost:7071/api":process.env.REACT_APP_API_GATEWAY_URL,
    fileUploadApiUrl: process.env.NODE_ENV==="development"? "http://localhost:7071/api/UploadFile":process.env.REACT_APP_UPLOAD_URL,
    clientId: process.env.NODE_ENV==="development"? "1f3129dc-0184-425a-bf22-d9b9fbb88ed3":process.env.REACT_APP_AD_CLIENT_ID,
    b2cDomainName:  process.env.NODE_ENV==="development"? "extcaltreasurer":process.env.REACT_APP_B2C_DOMAIN_NAME,
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
    debtWatchUrl: process.env.NODE_ENV==="development"? "https://debtwatch-dev.treasurer.ca.gov":process.env.REACT_APP_DEBT_WATCH_URL,    
}

/**
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
    names: {
        signUpSignIn: "b2c_1_signupsignin",
        signIn: "b2c_1_signin"
    },
    authorities: {
        signUpSignIn: {
            authority: `https://${configManager.b2cDomainName}.b2clogin.com/${configManager.b2cDomainName}.onmicrosoft.com/b2c_1_signupsignin`,
        },
        signIn: {
            authority: `https://${configManager.b2cDomainName}.b2clogin.com/${configManager.b2cDomainName}.onmicrosoft.com/b2c_1_signin`,
        }
    },
    authorityDomain: `${configManager.b2cDomainName}.b2clogin.com`
}

/**
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig = {
    auth: {
        clientId: configManager.clientId, 
        authority: b2cPolicies.authorities.signIn.authority, 
        knownAuthorities: [b2cPolicies.authorityDomain], 
        redirectUri: "/",
        postLogoutRedirectUri: "/logout",
        navigateToLoginRequestUrl: false, 
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level, message, containsPii) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {		
                    case LogLevel.Error:		
                        console.error(message);		
                        return;		
                    case LogLevel.Info:		
                        console.info(message);		
                        return;		
                    case LogLevel.Verbose:		
                        console.debug(message);		
                        return;		
                    case LogLevel.Warning:		
                        console.warn(message);		
                        return;
                    default:
                        return;
                }	
            }	
        }	
    }
};

/**
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: []
};

import { useState } from 'react'
import Card from '../ui/Card'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import CommonService from '../../services/CommonService'
import { UserContext } from '../../UseContext'
import { useContext } from 'react'
import { toast } from 'react-toastify'
import {MDBTooltip} from 'mdbreact'
import {AiFillQuestionCircle} from 'react-icons/ai'

const Comments = (props) => {
    const {drawDetails} = props;
    const user = useContext(UserContext);
    const [count, setCount] = useState(0);
    const [bgcolor, setBgcolor] = useState('yellowgreen');
    const [commentValue, setCommentValue] = useState(drawDetails ? drawDetails.issuerComment : "");
    
    const handleCommentValue = (e) => {
        setCount(e.target.value.length);
        const color = (e.target.value.length === 4000) ? 'orangered' : 'yellowgreen';
        setBgcolor(color);
        setCommentValue(e.target.value);
    };

    const validateForm = (e) => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "updateDrawIssuerCdiacComment",
            hdrStruct: null,
            queryParam: null,
            reqData: {  "id": props.drawId,
                "env" : "E",
                "draw":{
                    "issuerComment" : commentValue,
                    "lastUpdateUser": CommonService.getUserName(user),
                    "emailId": user
                }
            },
          };
      
          FinalSaleReportService.updateDrawIssuerComment(obj)
            .then((result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    toast.error(result.data.errors[0].message)
                }
                else{
                    props.updateDraw(result.data.responses[0]);
                    toast.success("Comment updated successfully!");
                }
                
            });
        props.openWidget('review');
    }
    return (
        <Card>
            <div className="form">
                <h3>Comments
                <MDBTooltip domElement tag="span" placement="right">
                <span>{"  "}<AiFillQuestionCircle className="help-icon heading-help-icon"/></span>
                <span>Please include any additional comments that relate to this filing.</span>
                </MDBTooltip>
                </h3>
                <div className="comments-box">
                    <textarea name="comment" value={commentValue} 
                    id="comments-input" onChange={handleCommentValue}
                     className="form-control" rows="5" maxLength="4000" placeholder="Add a comment..."/>
                     <span style={{position: 'relative', left: '45%' , backgroundColor: bgcolor }}>{count} out of 4000 max</span>
                </div>

                <div className="btn-div">
                {/* <button className="custom-button" type="button">Back</button> */}
                <button className="fright custom-button" type="button" onClick={e=>validateForm(e)}>Save & Next</button>
                </div>
            </div>  
        </Card>
    )
}

export default Comments

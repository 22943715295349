import {useState, useContext} from 'react'
import Card from '../ui/Card'
import {Form, Row, Col} from 'react-bootstrap'
import {MDBTooltip} from'mdbreact'
import {AiFillQuestionCircle} from 'react-icons/ai'
import { validateFormFields, runValidations } from '../../utils/index'
import CurrencyInput from '../formElements/CurrencyInput'
import CommonService from '../../services/CommonService'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import { toast } from 'react-toastify'
import {UserContext} from '../../UseContext'

const TaxCollection = (props) => {
    const {melloRoosDetails} = props;
    const loginUser = useContext(UserContext);
    const [formErrors, setFormErrors] = useState({});
    const validateForm = (e) => {
        let form = e.target.closest('form#taxCollectionForm');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
            props.sectionValidated('taxCollection', false);
        } else {
            setFormErrors({});
            updateMelloRoosTax();;
            props.openWidget('delinquentReporting');
        }
    }

    const [stateValues, setStateValues] = useState({
        teeterIndicator : melloRoosDetails.teeterIndicator,
        taxUnpaidAmt: melloRoosDetails.taxUnpaidAmt,
        taxDueAmt: melloRoosDetails.taxDueAmt
    })

    const updateMelloRoosTax = async() => {

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "updateMelloRoosTax",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {  "id": melloRoosDetails.melloRoosId, "melloRoos":{
                "teeterIndicator" : stateValues.teeterIndicator,
                "taxUnpaidAmt" : stateValues.taxUnpaidAmt,                
                "taxDueAmt" : stateValues.taxDueAmt,
                "lastUpdateUser":  CommonService.getUserName(loginUser),
                "emailId": loginUser
            }
            }
        };
    
       await FinalSaleReportService.updateMelloRoosAssessment(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message);
            }
            else{
                props.updateMelloRoosDetails(result.data.responses[0]);
                toast.success("Mello Roos Tax updated Successfully!!");
            }

        });
    }

    const validateField = (e) => {
        if ( e.target.dataset['validations'] )
            runValidations(e) 
    }

    const handleChange = (e) => {
        const {name, value} = e.target;

        setStateValues(prevState => ({
            ...stateValues, [name]: value
        })
        )
    }

    return (
        <Card>
            <form className="form" id="taxCollectionForm">
                <h4>Tax Collection
                    <MDBTooltip domElement tag="span" placement="right">
                    <span>{"  "}<AiFillQuestionCircle className="help-icon heading-help-icon"/></span>
                    <span>Indicate the total amount of special taxes due and unpaid for the district in the FY.</span>
                    </MDBTooltip>
                </h4>
                <Row>
                    <Col md={6}>
                        <div className="control">
                            <CurrencyInput 
                            placeholder="0.00" 
                            label="Total Amount of Special Taxes Due Annually" 
                            name="taxDueAmt" 
                            type="text" 
                            value={stateValues.taxDueAmt ? stateValues.taxDueAmt  : ""}
                            onChange={(e)=>handleChange(e)} 
                            onBlur={e=>validateField(e)}
                            error={formErrors.taxDueAmt} 
                            validations={["required", "max:999999999999.99"]}
                            isrequired="true"
                            helpText="Indicate taxes due to the district as of June 30 of FY for which you are reporting."
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>                        
                        <div className="control">
                            <CurrencyInput 
                            placeholder="0.00" 
                            label="Total Amount of Unpaid Special Taxes Annually" 
                            name="taxUnpaidAmt" 
                            type="text" 
                            value={stateValues.taxUnpaidAmt ? stateValues.taxUnpaidAmt  : ""}
                            onChange={(e)=>handleChange(e)} 
                            onBlur={e=>validateField(e)}
                            error={formErrors.taxUnpaidAmt} 
                            validations={["required", "max:999999999999.99"]}
                            isrequired="true"
                            helpText="Indicate Taxes unpaid/uncollected by the district as of June 30 of FY for which you are reporting."
                            />
                        </div>
                    </Col>
                </Row>
                <div>
                    <label><span className="required" >*</span>Does This Agency Participate in the County's Teeter Plan? <MDBTooltip domElement tag="span" placement="right">
                        <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                        <span>Indicate whether the district is covered under its county Teeter Plan.</span>
                    </MDBTooltip></label>
                    <div>
                    <Form.Check inline type="radio" name="teeterIndicator" id="teeterIndicator_Y" checked={stateValues.teeterIndicator === "Y"} data-validations="required" label="Yes" value="Y" onChange={(e)=>handleChange(e)}/>
                    <Form.Check inline type="radio" name="teeterIndicator" id="teeterIndicator_N" checked={stateValues.teeterIndicator === "N"} data-validations="required" label="No" value="N" onChange={(e)=>handleChange(e)}/>
                    </div>
                    { formErrors['teeterIndicator'] && <p className="error">{ formErrors['teeterIndicator'] }</p>}
                </div>

                <div className="btn-div">
                {/* <button className="custom-button" type="button" onClick={() => props.openWidget('assessedValue')} >Back</button> */}
                <button className="fright custom-button" type="button" onClick={e=>validateForm(e)}>Save & Next</button>
                </div>

            </form>
        </Card>
    )
}

export default TaxCollection

import { useContext, useState} from 'react'
import Card from '../ui/Card'
import ReviewComponent from './reviewComponent'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import CommonService from '../../services/CommonService'
import { UserContext } from '../../UseContext'
import { toast } from 'react-toastify'
import { renderToString } from 'react-dom/server'
import { IssuanceDetailContext } from '../../UseContext'
import swal from 'sweetalert'
import EditReasonModal from '../reports/editReasonModal'
import ReportsService from '../../services/ReportsService'
import Loader from '../../common/Loader'

const MelloRoosYFSReview = (props) => {
    const {melloRoos, melloRoosDetails, contact, foreclosures, issueDocuments} = props;
    const user = useContext(UserContext);
    const issuanceDetail = useContext(IssuanceDetailContext);
    const [showEditReasonModal, setShowEditReasonModal] = useState(false);
    const [showSpinner,setShowSpinner] =  useState(false);

    let html = "";
    const updateFinalMelloRoos= async() => {
        html = renderToString(<ReviewComponent issuanceDetail={issuanceDetail} melloRoos={melloRoos} melloRoosDetails={melloRoosDetails} contact={contact} foreclosures={foreclosures} issueDocuments={issueDocuments}/>)

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "updateFinalMelloRoos",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id": melloRoos.melloRoosId,
                "env": "E",
                "melloRoos":{
                    "finalSaleid" : melloRoos.finalSaleId,
                    "lastUpdateUser": CommonService.getUserName(user),
                    "emailId": user,
                    "statusFlag" : "S"
                },
                "reportType" : "MLR",
                "html" : html,
                "cdiacNbr" :  melloRoos.cdiacNbr
            }
        };
        FinalSaleReportService.updateFinalMelloRoos(obj)
        .then((res) => res)
        .then((result) => {
            setShowSpinner(false);

            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message);
            }
            else{
                if(result.data && result.data.responses[0]) {
                    var base64EncodedPDF = result.data.responses[0].pdf;
                    var arrrayBuffer = CommonService.base64ToArrayBuffer(base64EncodedPDF); 
                    var blob = new Blob([arrrayBuffer], {type: "application/pdf"});
                    var url = window.URL.createObjectURL(blob);
                    window.open(url);
                } 
                toast.success("Mello Roos updated successfully!");
            }
        });
    }

    const validateRequiredFields = async() => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "validateMelloRoosSubmit",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                //Change Later
                "id": melloRoosDetails.melloRoosId
            }
        };
        FinalSaleReportService.validateMelloRoosSubmit(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                setShowSpinner(false);
                alert(result.data.errors[0].message)
            }
            else{
                if(result.data.responses[0].length > 0) {
                    setShowSpinner(false);
                  let alertMessage = "";
                  result.data.responses[0].forEach(item => {
                    alertMessage +=  "- " + item.message + "\n";
                  });
                  alert(alertMessage);
                } else {
                    if(melloRoosDetails.statusFlag === "R") {
                        setShowSpinner(false);
                        swal({
                            text: "Do you want to re-edit this record?",
                            icon: "warning",
                            buttons: [true, 'Yes'],
                            closeOnClickOutside: false
                          })
                          .then((confirm) => {
                            if (confirm) {
                                setShowEditReasonModal(true)
                            } 
                          });
                    } else {
                        updateFinalMelloRoos()  
                    }        
                }
            }

        });
    }
    const saveEditReason = (editReason) => {
        let obj ={
             "msgId": null,
             "sysId": null,
             "opr": "postEditReason",
             "hdrStruct": null,
             "queryParam": null,
             "reqData": {
                 "editReason" : {
                     "editReasonCodeId" : editReason.editReasonCodeId,
                     "comment" : editReason.comment,
                     "melloRoosId" : melloRoosDetails.melloRoosId,
                     "createUser" : CommonService.getUserName(user)
                 }
             }
         } 

         ReportsService.saveEditReason(obj)
         .then((res) => res)
         .then((result) => {
             if(result.data.errors.length > 0) {
 
             } else {
                 toast('Edit Reason saved successfully!')
                 setShowEditReasonModal(false)
                 updateFinalMelloRoos() 
             }
         });
    }
    const validateMelloRoosReport = () => {
        setShowSpinner(true);
        props.toggleWidget('review');
        validateRequiredFields();
         
    }
    return (
        <Card>
                <ReviewComponent issuanceDetail={issuanceDetail} melloRoos={melloRoos} melloRoosDetails={melloRoosDetails} contact={contact} foreclosures={foreclosures} issueDocuments={issueDocuments}/>
                <div className="form">
                <div className="btn-div">
                {/* <button className="custom-button" type="button" onClick={()=>props.openWidget('comments')}>Back</button> */}
                <button className="fright custom-button" type="button" onClick={()=>validateMelloRoosReport()}>Validate & Submit</button>
                </div>
                </div>
                {showSpinner && <Loader />} 
                {showEditReasonModal && <EditReasonModal  onModalClose={()=>setShowEditReasonModal(false)} handleSave={saveEditReason} cdiacReasonCode="MLRER"/>}
        </Card>
    )
}

export default MelloRoosYFSReview

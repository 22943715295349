import Card from '../ui/Card'
import {useState, useContext} from 'react'
import {Form, Modal} from 'react-bootstrap'
import {AiFillQuestionCircle} from 'react-icons/ai'
import CommonService from '../../services/CommonService'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import { toast } from 'react-toastify';
import {UserContext} from '../../UseContext'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { FaCalendarAlt} from 'react-icons/fa'
import {MDBTooltip} from 'mdbreact'
import { runValidations, validateFormFields } from '../../utils'
import CurrencyInput from '../formElements/CurrencyInput'
import { useMsal } from '@azure/msal-react'

const DelinquentReporting = (props) => {
    const {instance} = useMsal();
    const { markRoosLocalObligDetails } = props;
    const [showInstructionsModal, setShowInstructionsModal] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const loginUser = useContext(UserContext);

    const [stateValues, setStateValues] = useState({
        dlqTaxDueAmt : markRoosLocalObligDetails.dlqTaxDueAmt,
        dlqTaxUnpaidAmt: markRoosLocalObligDetails.dlqTaxUnpaidAmt,
        dlqTaxIndicator: markRoosLocalObligDetails.dlqTaxIndicator,
        teeterIndicator: markRoosLocalObligDetails.teeterIndicator,
        dlqRate: markRoosLocalObligDetails.dlqRate,
        dlqParcelDate: markRoosLocalObligDetails.dlqParcelDate ? new Date(markRoosLocalObligDetails.dlqParcelDate) : null,
    })

    const handleChange = (e) => {
        const {name, value} = e.target;
        
        setStateValues(prevState => ({
            ...stateValues, [name]: value
        })
        )
    }
    const handleDateChange = (e, key) => {        
        setStateValues(prevState => ({
            ...stateValues, [key]: e
        })
        )
    }

    const validateForm = (e) => {
        let form = e.target.closest('form#delinquentReportingForm');

        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
            props.sectionValidated('delinquentReporting', false);
        } else {
            setFormErrors({});
            updateMKRRoosLocalObligDlq();
            props.sectionValidated('delinquentReporting', true);
            props.openWidget('retiredIssues')
        }
    }

    const updateMKRRoosLocalObligDlq = async() => {

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "updateMKRRoosLocalObligDlq",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {  "id": markRoosLocalObligDetails.marksRoosLocalObligorId, "marksRoosLocalObligor":{
                "dlqTaxIndicator" : stateValues.dlqTaxIndicator,
                "teeterIndicator" : stateValues.teeterIndicator, 
                "dlqTaxDueAmt" : stateValues.dlqTaxDueAmt,
                "dlqTaxUnpaidAmt": stateValues.dlqTaxUnpaidAmt,               
                "dlqRate" : stateValues.dlqRate,
                "dlqParcelDate" : stateValues.dlqParcelDate,
                "lastUpdateUser":  CommonService.getUserName(loginUser),
                "emailId": loginUser
            }
            }
        };
    
       await FinalSaleReportService.updateMKRRoosLocalObligDlq(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                CommonService.handleServerResponse(result.data, instance);
            }
            else{
                props.updateMkrLocalObligorDetails(result.data.responses[0]);
                toast.success("Delinquent Reporting updated Successfully!!");
            }

        });
    }

    const validateField = (e) => {
        if ( e.target.dataset['validations'] )
            runValidations(e) 
    }
    return (
        <Card>
            <form className="form" id="delinquentReportingForm">
                <h3>Delinquent Reporting <AiFillQuestionCircle onClick={()=>setShowInstructionsModal(true)} className="help-icon heading-help-icon"/>

                {showInstructionsModal && 
                <Modal show={true} backdrop="static" onHide={()=>setShowInstructionsModal(false)}>
                    {/* <Modal.Header closeButton><h3>Issuance Authorization</h3></Modal.Header> */}
                    <Modal.Body>
                        <div>
                        Indicate the delinquency rate for taxes and assessments supporting the local obligations. This should be reported as the percentage of the total applicable tax increment, special tax, or benefit assessment installment due for the year. Indicate the tax collection date, if the district participates in the county’s Teeter Plan, the amount of taxes due and the amount of taxes unpaid.
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button variant="secondary" onClick={()=>setShowInstructionsModal(false)}>Close</button>
                    </Modal.Footer>
                </Modal>
                }</h3>

                <div>
                    <label><span className="required">*</span>Have Delinquent Taxes Been Reported:
                    <MDBTooltip domElement tag="span" placement="right">
                    <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                    <span>Indicate whether delinquent taxes have been reported during current FY.</span>
                    </MDBTooltip>
                    </label>
                    <div style={{display: 'flex'}}>
                        <Form.Check inline type="radio" name="dlqTaxIndicator" id="dlqTaxIndicator_Y" checked={stateValues.dlqTaxIndicator === "Y"} label="Yes" value="Y" data-validations="required" onChange={(e)=>handleChange(e)}/>
                        <Form.Check inline type="radio" name="dlqTaxIndicator" id="dlqTaxIndicator_N" checked={stateValues.dlqTaxIndicator === "N" } label="No" value="N" data-validations="required" onChange={(e)=>handleChange(e)}/>
                    </div>
                    {formErrors['dlqTaxIndicator'] && <p className="error">{formErrors['dlqTaxIndicator']}</p>}
                </div>

                <div className="control">
                    <label><span className="required">*</span>Delinquent Parcel Information Reported as of Equalized Tax Roll of:
                    <MDBTooltip domElement tag="span" placement="right">
                    <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                    <span>Enter June 30 of FY for which you are reporting.</span>
                    </MDBTooltip>
                    </label>
                    <div>
                        <span>
                        <DatePicker 
                        selected={stateValues && stateValues.dlqParcelDate ? stateValues.dlqParcelDate : null}
                        dateFormat="M/d/yyyy"
                        maxDate= {new Date(9999, 12, 31)}
                        onChange={(e) => handleDateChange(e, "dlqParcelDate")} 
                        />
                        <FaCalendarAlt className="date-picker-icon"/>
                        </span>
                        <input type="hidden" data-validations={["required"]} value={stateValues.dlqParcelDate ? stateValues.dlqParcelDate : ""} name="dlqParcelDate" />
                        {formErrors['dlqParcelDate'] && <p className="error">{formErrors['dlqParcelDate']}</p>}
                    </div>
                </div>
                <div>
                    <label><span className="required">*</span>Delinquent Rate
                    <MDBTooltip domElement tag="span" placement="right">
                    <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                    <span>Indicate the percentage of taxes unpaid compared to taxes due.</span>
                    </MDBTooltip>
                    </label>
                    <Form.Control type="text" placeholder="XX.XXXXX" name="dlqRate" data-validations="required" value={stateValues.dlqRate}
                    style={formErrors['dlqRate'] && {border: `solid 1px red`}} onChange={(e)=>handleChange(e)} />
                    {formErrors['dlqRate'] && <p className="error">{formErrors['dlqRate']}</p>}
                </div>
                <div>
                    <label>Does this agency participate in the County's Teeter Plan?
                    <MDBTooltip domElement tag="span" placement="right">
                    <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                    <span>Indicate whether the agency participates in the Teeter Plan.</span>
                    </MDBTooltip>
                    </label>
                    <div style={{display: 'flex'}}>
                        <Form.Check inline type="radio" name="teeterIndicator" id="teeterIndicator_Y" checked={stateValues.teeterIndicator === "Y"} label="Yes" value="Y" onChange={(e)=>handleChange(e)}/>
                        <Form.Check inline type="radio" name="teeterIndicator" id="teeterIndicator_N" checked={stateValues.teeterIndicator === "N"} label="No" value="N" onChange={(e)=>handleChange(e)}/>
                    </div>
                </div>
                <div className="control">
                    <CurrencyInput 
                    placeholder="0.00" 
                    label="Taxes Due" 
                    name="dlqTaxDueAmt" 
                    type="text" 
                    value={stateValues.dlqTaxDueAmt ? stateValues.dlqTaxDueAmt  : ""}
                    onChange={(e)=>handleChange(e)} 
                    onBlur={e=>validateField(e)}
                    validations={["max:999999999999.99"]}
                    helpText="Indicate Taxes due as of June 30 of FY for which you are reporting."
                    />
                </div>

                <div className="control">
                    <CurrencyInput 
                    placeholder="0.00" 
                    label="Taxes Unpaid" 
                    name="dlqTaxUnpaidAmt" 
                    type="text" 
                    value={stateValues.dlqTaxUnpaidAmt ? stateValues.dlqTaxUnpaidAmt  : ""}
                    onChange={(e)=>handleChange(e)} 
                    onBlur={e=>validateField(e)}
                    validations={["max:999999999999.99"]}
                    helpText="Indicate Taxes unpaid as of June 30 of FY for which you are reporting."
                    />
                </div>

                <div className="btn-div">
                {/* <button className="custom-button" type="button" onClick={()=>props.openWidget('fundBalance')}>Back</button> */}
                <button className="fright custom-button" type="button" onClick={e=>validateForm(e)}>Save & Next</button>
                </div>

            </form>
        </Card>
    )
}

export default DelinquentReporting

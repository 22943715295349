import {useState, useContext} from 'react'
import { Form, Modal } from 'react-bootstrap';
import Card from '../ui/Card'
import {AiFillQuestionCircle} from 'react-icons/ai'
import { MDBTooltip } from 'mdbreact'
import { validateFormFields } from '../../utils/index'
import FinalSaleReportService from "../../services/FinalSaleReportService";
import { toast } from 'react-toastify';
import {UserContext} from "../../UseContext";
import CommonService from '../../services/CommonService'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {FaCalendarAlt} from 'react-icons/fa'
import CurrencyInput from '../formElements/CurrencyInput';

const Draw = (props) => {
    const { drawDetails } = props;
    
    const [formErrors, setFormErrors] = useState({});
    const getFieldLabel = (drawFlag) => {
        let label = "Draw Amount";
        if(drawFlag === 'D') {
            label = "Amount of Draw on Reserve";
        }else if(drawFlag === 'F') {
            label = "Amount not paid but due in the Default";
        } else if(drawFlag === 'R') {
            label = "Amount of Replenishment";
        }
        return label;
    }

    const [amountFieldLabel, setAmountFieldLabel] = useState(drawDetails.drawFlag ? getFieldLabel(drawDetails.drawFlag) : 'Draw Amount');
    const [showAmountField, setShowAmountField] = useState(drawDetails.drawCode ? true : false);
    const [showInstructionsModal, setShowInstructionsModal] = useState(false);
    const loginUser = useContext(UserContext);
    const drawOptions = [
        {value: 'D', label: 'Draw on Reserve'},
        {value: 'F', label: 'Default'},
        {value: 'R', label: 'Replenishment of Draw on Reserve (Voluntary Disclosure)'},
    ]

    const [stateValues, setStateValues] = useState({
        drawCode: drawDetails.drawCode,
        drawFlag: drawDetails.drawFlag,
        drawAmt : drawDetails.drawAmt,
        drawDate: drawDetails.drawDate ? new Date(drawDetails.drawDate) : null
    })


    const handleDrawTypeSelection = (e) => {
        const {value} = e.target;
        const fieldLabel = getFieldLabel(value);
        setAmountFieldLabel(fieldLabel);
        setShowAmountField(true);
        setStateValues(prevState => ({
            ...stateValues, 'drawFlag': value
        })
        )
    }

    const validateForm = (e) => {
        let form = e.target.closest('form#drawForm');

        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});
            updateDrawDetails();
            props.openWidget('filingContact')
        }
    }

    const updateDrawDetails = async() => {

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "updateDrawDetails",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {  "id": props.drawId,
                "env": "E",
                 "draw":{
                "drawCode" : stateValues.drawCode,
                "drawFlag" : stateValues.drawFlag,                
                "drawAmt" : stateValues.drawAmt,
                "drawDate" : stateValues.drawDate,
                "lastUpdateUser":  CommonService.getUserName(loginUser),
                "emailId": loginUser
            }
            }
        };
    
       await FinalSaleReportService.updateDraw(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                alert(result.data.errors[0].message)
            }
            else{
                props.updateDraw(result.data.responses[0]);
                toast.success("Draw Details updated Successfully!!");
            }

        });
    }

    const handleChange = (e) => {
        const {name, value} = e.target;

        setStateValues(prevState => ({
            ...stateValues, [name]: value
        })
        )
    }

    const handleDrawDateChange = (e) => {
        setStateValues(prevState=>({
            ...stateValues, 
            'drawDate': e
        })
        )
    }

    return (
        <Card>
            <form className="form" id="drawForm">
                <div>
                    <div>
                    <label><span className="required">*</span>This form is submitted in accordance with: 
                        <AiFillQuestionCircle onClick={()=>setShowInstructionsModal(true)} className="help-icon heading-help-icon"/>

                        {showInstructionsModal && 
                            <Modal show={true} backdrop="static" onHide={()=>setShowInstructionsModal(false)}>
                                {/* <Modal.Header closeButton><h3>Issuance Authorization</h3></Modal.Header> */}
                                <Modal.Body>
                                    <div>
                                        <ul style={{listStyleType: 'lower-alpha'}}>
                                            <li> Draw on the Reserve Fund: Statute defines this as any withdrawal of funds from a reserve to pay principal and interest on the bonds issued by the authority or any bonds acquired by the authority.</li>

                                            <li>Default: Statute defines this as non-payment or partial payment of principal and/or interest.</li>

                                            <li>Replenishment of Draw on Reserve (Voluntary Disclosure) -  is defined as the replenishment of a previous draw on reserve.</li>
                                        </ul>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button variant="secondary" onClick={()=>setShowInstructionsModal(false)}>Close</button>
                                </Modal.Footer>
                            </Modal>
                        }
                        </label>
                        <Form.Check type="radio" name="drawCode"  id="MLR" value="MLR" checked={stateValues.drawCode === "MLR"} label="Section 53359.5(c) of the California Government Code Mello-Roos Community Facilities Districts Default or Draw on Reserve Reporting." data-validations="required" onChange={(e)=>handleChange(e)}/>
                        <Form.Check type="radio" name="drawCode"  id="MKR" value="MKR" checked={stateValues.drawCode === "MKR"} label="Section 6599.1(c) of the California Government Code Marks-Roos Bond Pooling Act Participants Default or Draw on Reserve Reporting." data-validations="required" onChange={(e)=>handleChange(e)}/>
                    </div>
                    {formErrors['drawCode'] && <p className="error">{formErrors['drawCode']}</p>}

                    <div>
                        <label><span className="required">*</span>Type of Event: <MDBTooltip domElement tag="span" placement="right">
                            <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                            <span><div>
                            <ul style={{listStyleType: 'lower-alpha'}}>
                                <li> Draw on the Reserve Fund: Statute defines this as any withdrawal of funds from a reserve to pay principal and interest on the bonds issued by the authority or any bonds acquired by the authority.</li>

                                <li>Default: Statute defines this as non-payment or partial payment of principal and/or interest.</li>

                                <li>Replenishment of Draw on Reserve (Voluntary Disclosure) -  is defined as the replenishment of a previous draw on reserve.</li>
                            </ul>
                        </div></span>
                        </MDBTooltip>
                        </label>
                        <Form.Select name="drawFlag" onChange={(e)=>handleDrawTypeSelection(e)} data-validations="required" value={stateValues.drawFlag}>
                            <option value="">Select</option>
                            {drawOptions.map((item, i) =>{
                                return (<option key={i} value={item.value}>{item.label}</option>)
                            })}
                        </Form.Select>
                        {formErrors['drawFlag'] && <p className="error">{formErrors['drawFlag']}</p>}

                    </div>
                    <div className="control">
                        <label><span className="required">*</span>Date Draw on Reserve/Default/Replenishment Occurred:
                        <MDBTooltip domElement tag="span" placement="right">
                            <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                            <span>The date the default, draw on the reserve fund, or replenishment occurred</span>
                        </MDBTooltip>
                        </label>
                        <div>
                            <span>
                            <DatePicker 
                            selected={stateValues && stateValues.drawDate ? stateValues.drawDate : null}
                            dateFormat="M/d/yyyy"
                            maxDate= {new Date(9999, 12, 31)}
                            onChange={(e) => handleDrawDateChange(e)} 
                            />
                            <FaCalendarAlt className="date-picker-icon"/>
                            </span>
                            {formErrors['drawDate'] && <p className="error">{formErrors['drawDate']}</p>}
                            <input type="hidden" data-validations={["required"]}  value={CommonService.dateFormatterDom(stateValues.drawDate)} name="drawDate" />
                        </div>
                    </div>
                    { showAmountField && <div className="control">
                        <CurrencyInput 
                        placeholder="0.00" 
                        label={amountFieldLabel} 
                        name="drawAmt" 
                        type="text" 
                        isrequired="true"
                        onChange={(e)=>handleChange(e)}
                        value={stateValues.drawAmt}
                        validations={["required", "max:999999999999.99"]}
                        helpText="The amount of the default, draw on the reserve fund, or replenishment."
                        />
                        {formErrors['drawAmt'] && <p className="error">{formErrors['drawAmt']}</p>}
                    </div>
                    }
                </div>

                <div className="btn-div">
                {/* <button className="custom-button" type="button">Back</button> */}
                <button className="fright custom-button" type="button" onClick={e=>validateForm(e)}>Save & Next</button>
                </div>

            </form>
        </Card>
    )
}

export default Draw

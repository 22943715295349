import CommonService from "./CommonService";

const getFinancePurposesByIssueId = (obj) => {
    return CommonService.apicall(obj)

};

const addFinancePurpose = (obj) => {
    return CommonService.apicall(obj)

};

const updateFinancePurpose = (obj) => {
    return CommonService.apicall(obj)

};

const deleteFinancePurpose = (obj) => {
    return CommonService.apicall(obj)

};

const IssueFinancePurposeService = {
    getFinancePurposesByIssueId,
    addFinancePurpose,
    updateFinancePurpose,
    deleteFinancePurpose
};


export default IssueFinancePurposeService;
import Card from '../ui/Card'
import {useState, useContext} from 'react'
import {Form, Modal, Row, Col} from 'react-bootstrap'
import {AiFillQuestionCircle} from 'react-icons/ai'
import { runValidations, validateFormFields } from '../../utils/index'
import CommonService from '../../services/CommonService'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import { toast } from 'react-toastify'
import {UserContext} from '../../UseContext'
import CurrencyInput from '../formElements/CurrencyInput'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { FaCalendarAlt} from 'react-icons/fa'
import {MDBTooltip} from 'mdbreact'

const DelinquentReporting = (props) => {
    const {melloRoosDetails} = props
    const [showInstructionsModal, setShowInstructionsModal] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const loginUser = useContext(UserContext);
    const validateForm = (e) => {
        let form = e.target.closest('form#delinquentReportingForm');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
            props.sectionValidated('delinquentReporting', false);
        } else {
            setFormErrors({});
            updateMelloRoosDlq();
            props.openWidget('docUpload');
        }
    }

    const [stateValues, setStateValues] = useState({
        delinquentNbr : melloRoosDetails.delinquentNbr,
        dlqParcelDate: melloRoosDetails.dlqParcelDate? new Date(melloRoosDetails.dlqParcelDate) : null,
        delinquentAmt: melloRoosDetails.delinquentAmt
    })

    const updateMelloRoosDlq = async() => {

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "updateMelloRoosDlq",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {  "id": melloRoosDetails.melloRoosId, "melloRoos":{
                "delinquentNbr" : stateValues.delinquentNbr,
                "dlqParcelDate" : stateValues.dlqParcelDate,                
                "delinquentAmt" : stateValues.delinquentAmt,
                "lastUpdateUser":  CommonService.getUserName(loginUser),
                "emailId": loginUser
            }
            }
        };
    
       await FinalSaleReportService.updateMelloRoosDlq(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message)
            }
            else{
                props.updateMelloRoosDetails(result.data.responses[0]);
                toast.success("Mello Roos Deliquent Reporting updated Successfully!!");
            }

        });
    }


    const handleChange = (e) => {
        const {name, value} = e.target;

        setStateValues(prevState => ({
            ...stateValues, [name]: value
        })
        )
    }

    const validateField = (e) => {
        if ( e.target.dataset['validations'] )
            runValidations(e) 
    }

    const handleDlqParcelDate = (e) => {
        setStateValues(prevState=>({
            ...stateValues, 
            'dlqParcelDate': e
        })
        )
    }

    return (
        <Card>
            <form className="form" id="delinquentReportingForm">
                <h4>Delinquent Total Reporting 
                <MDBTooltip domElement tag="span" placement="right">
                    <span>{"  "}<AiFillQuestionCircle onClick={()=>setShowInstructionsModal(true)} className="help-icon heading-help-icon"/></span>
                    <span>Click me for more info.</span>
                </MDBTooltip>

                {showInstructionsModal && 
                <Modal show={true} backdrop="static" onHide={()=>setShowInstructionsModal(false)}>
                    <Modal.Body>
                        <div>
                            <ul>
                                <li>Report delinquencies as of the latest equalized tax roll within the reporting year if the issuing CFD has delinquent parcels.</li>
                                <li>Report the total number of delinquent parcels and the total dollar amount of delinquent taxes by reporting the number of parcels which are delinquent with respect to their special tax payments, the amount that each parcel is delinquent, parcel number, and the length of time that each parcel has been delinquent per Government Code Section 53359.5 (b).</li>
                                <li>Submitters may redact a property owner’s confidential information before uploading the Delinquent Parcel Detail Report.</li>
                            </ul>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn-secondary custom-button-secondary" onClick={()=>setShowInstructionsModal(false)}>Close</button>
                    </Modal.Footer>
                </Modal>
                }</h4>
                <div className="control">
                    <label><span className="required" >*</span>Delinquent Parcel Information Reported as of Equalized Tax Roll of</label>
                    <div>
                        <span>
                        <DatePicker 
                        selected={stateValues && stateValues.dlqParcelDate ? stateValues.dlqParcelDate : null}
                        dateFormat="M/d/yyyy"
                        maxDate= {new Date(9999, 12, 31)}
                        name="dlqParcelDate"
                        onChange={(e) => handleDlqParcelDate(e)} 
                        />
                        <FaCalendarAlt className="date-picker-icon"/>
                        </span>
                        {formErrors['dlqParcelDate'] && <p className="error">{formErrors['dlqParcelDate']}</p>}
                        <input type="hidden" data-validations={["required"]}  value={CommonService.dateFormatterDom(stateValues.dlqParcelDate)} name="dlqParcelDate" />
                    </div>
                </div>
                <div>
                    <label><span className="required" >*</span>Total Number of Delinquent Parcels</label>
                    <Form.Control type="text" name="delinquentNbr" data-validations="required" value={stateValues.delinquentNbr} onChange={(e)=>handleChange(e)} />
                    {formErrors['delinquentNbr'] && <p className="error">{formErrors['delinquentNbr']}</p>}
                </div>

                <div className="mt-10">
                    <Row>
                        <Col md={6}>
                            <div className="control">
                                <CurrencyInput 
                                placeholder="0.00" 
                                label="Total Amount of Special Taxes Due on Delinquent Parcels" 
                                name="delinquentAmt" 
                                type="text" 
                                value={stateValues.delinquentAmt ? stateValues.delinquentAmt  : ""}
                                onChange={(e)=>handleChange(e)} 
                                onBlur={e=>validateField(e)}
                                error={formErrors.delinquentAmt} 
                                validations={["required", "max:999999999999.99"]}
                                isrequired="true"
                                />
                            </div>
                        </Col>
                    </Row>
                </div> 

                <div className="btn-div">
                    {/* <button className="custom-button" type="button" onClick={()=>props.openWidget('taxCollection')}>Back</button> */}
                    <button className="fright custom-button" type="button" onClick={e=>validateForm(e)}>Save & Next</button>
                </div>

            </form>
        </Card>
    )
}

export default DelinquentReporting

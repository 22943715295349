import {useState, useContext, useEffect} from 'react'
import Card from '../ui/Card'
import {Form, Modal, Row, Col} from 'react-bootstrap'
import {AiFillQuestionCircle} from 'react-icons/ai'
import {validateFormFields} from '../../utils'
import CurrencyInput from '../formElements/CurrencyInput'
import Table from '../Table'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import CommonService from '../../services/CommonService'
import {UserContext} from '../../UseContext'
import { toast } from 'react-toastify'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { FaCalendarAlt} from 'react-icons/fa'
import swal from 'sweetalert'
import {MDBTooltip} from 'mdbreact'

const AddForeClosureInformation = (props) => {
    const {onModalClose, melloRoosDetails } = props;

    const [formErrors,setFormErrors] = useState({});
    const loginUser = useContext(UserContext);

    const [stateValues, setStateValues] = useState({
        foreclosureParcelCnt : '',
        foreclosureDate: '',
        totalTaxDueAmt: ''
    })

    const handleSave = (form) => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "createForeclosure",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {  "id": melloRoosDetails.melloRoosId, "melloRoos":{
                "foreclosureParcelCnt" : stateValues.foreclosureParcelCnt,
                "foreclosureDate" : stateValues.foreclosureDate,                
                "totalTaxDueAmt" : stateValues.totalTaxDueAmt,
                "lastUpdateUser":  CommonService.getUserName(loginUser),
                "emailId": loginUser,
                "createUser":  CommonService.getUserName(loginUser)
            }
            }
        };
    
       FinalSaleReportService.createForeclosure(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message)
            }
            else{
                //props.updateDraw(result.data.responses[0]);
                toast("Mello Roos Foreclosure added Successfully!!");
                onModalClose();

            }

        });
    }
    const handleSubmit = (e) => {
        const form = e.target.closest('form#add-form');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});
           handleSave(form);
        }
    }

    const handleForeclosureDate = (e) => {
        setStateValues(prevState=>({
            ...stateValues, 
            'foreclosureDate': e
        })
        )
    }

    const handleChange = (e) => {
        const {name, value} = e.target;

        setStateValues(prevState => ({
            ...stateValues, [name]: value
        })
        )
    }

    return (
        <Modal  show={true} onHide={onModalClose}>
            <Modal.Header closeButton><h3>Add Foreclosure Information for Fiscal Year</h3></Modal.Header>
            <Modal.Body>
                <form id="add-form">
                    <Row>
                        <Col md={6}>
                            <div className="control">
                                <label><span className="required">*</span>Date Foreclosure Commenced:</label>
                                <div>
                                <span>
                                <DatePicker 
                                selected={stateValues && stateValues.foreclosureDate ? stateValues.foreclosureDate : null}
                                dateFormat="M/d/yyyy"
                                maxDate= {new Date(9999, 12, 31)}
                                onChange={(e) => handleForeclosureDate(e)} 
                                />
                                <FaCalendarAlt className="date-picker-icon"/>
                                </span>
                                {formErrors['foreclosureDate'] && <p className="error">{formErrors['foreclosureDate']}</p>}
                                <input type="hidden" data-validations={["required"]} value={stateValues.foreclosureDate ? stateValues.foreclosureDate : ""} name="foreclosureDate" />
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="control">
                                <label><span className="required">*</span>Total Number of Foreclosure Parcels:</label>
                                <Form.Control type="number" name="foreclosureParcelCnt" onChange={(e)=>handleChange(e)} data-validations="required" style={formErrors['foreclosureParcelCnt'] && {border: '1px solid red'}}/>
                                { formErrors && formErrors['foreclosureParcelCnt'] && <p className="error">{ formErrors['foreclosureParcelCnt'] }</p>}
                            </div>
                        </Col>
                        
                        <Col md={6}>
                            <div className="control">
                                <CurrencyInput 
                                label="Total Amount of Tax on Foreclosure Parcels" 
                                name="totalTaxDueAmt" 
                                defaultValue="0.00"
                                validations={ ["required", "max:999999999999.99"]}
                                error={formErrors && formErrors['totalTaxDueAmt']} 
                                isrequired="true"
                                onChange={(e)=>handleChange(e)} 
                               />
                            </div>
                        </Col>

                    </Row>
                    <div className="btn-div">
                        <div className="fright">
                            <button type="button" className="custom-button" onClick={()=>onModalClose()}>Cancel</button>{" "}
                            <button type="button" className="custom-button" onClick={(e)=>handleSubmit(e)}>Save</button>
                    </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

const Foreclosure = (props) => {
    const {melloRoosDetails, updateForeClosures} = props;
    const [showInstructionsModal, setShowInstructionsModal] = useState(false);
    const [showHelpTextModal, setShowHelpTextModal] = useState(false);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [editIdx, setEditIndex] = useState(-1);
    const loginUser = useContext(UserContext);

    const [foreclosures, setForeclosures] = useState([]);

    const addForeclosure = () => {
        setOpenAddModal(true);
    }

    const handleChange = (e, name, i) => {
        const { value } = e.target;
        const foreclosuresArr =[...foreclosures];
        foreclosuresArr[i][name] = value;
        setForeclosures(foreclosuresArr);
        sessionStorage.setItem('foreclosures',JSON.stringify(foreclosuresArr) )

    }

    const removeMelloRoosForeclosure = (foreclosureId)  => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "deleteMelloRoosForeclosure",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
              "id" : foreclosureId,
            }
        };
  
        FinalSaleReportService.removeMelloRoosForeclosure(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message);
                return;
            }
            else{
                toast.success("Mello Roos Foreclosure removed successfully");
                getForeclosures();
            }
        });
    }

    const handleRemove = (i, rowData) => {

        swal("Are you sure you want to remove this foreclosure?", {
            buttons: ["No", "Yes"],
        }).then((value) => {
           if(value){
            const foreclosureId = rowData.foreclosureId;
            removeMelloRoosForeclosure(foreclosureId);
            

           }
        });


    }

    const startEditing = i => {
        setEditIndex(i);
    };
    
    const stopEditing = () => {
        setEditIndex(-1);
    };

    const handleModalClose = () => {
        setOpenAddModal(false);
        const newForeclosures = sessionStorage.getItem('foreclosures') ? JSON.parse(sessionStorage.getItem('foreclosures')) : [];
        setForeclosures(newForeclosures);
    }

    const validateForm = () => {
        props.openWidget('retiredIssues');
    }

    const updateMelloRoosForeclosure = (rowData)  => {

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "updateMelloRoosForeclosure",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {  "id": rowData.foreclosureId, "melloRoosForeclosure":{
                "totalTaxDueAmt" : rowData.totalTaxDueAmt,
                "foreclosureParcelInt" : rowData.foreclosureParcelInt,                
                "lastUpdateUser":  CommonService.getUserName(loginUser),
                "emailId": loginUser
            },
            "env": "E"
            }
        };

        FinalSaleReportService.updateMelloRoosForeclosure(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message);
                return;
            }
            else{
                toast.success("Mello Roos Foreclosure updated successfully");
                getForeclosures();
            }
        });
    }

    const getForeclosures = async () => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "getForeclosures",
            hdrStruct: null,
            queryParam: null,
            reqData:{"id" : melloRoosDetails.melloRoosId}
          };
          FinalSaleReportService.getForeclosures(obj)
            .then((result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    toast.error(result.data.errors[0].message);
                    return;
                }
                else{
                    result.data.responses[0].forEach((item, i) => {
                        item.foreclosureDate = CommonService.dateFormatter(item.foreclosureDate);
                    });
                    setForeclosures(result.data.responses[0]);
                    updateForeClosures(result.data.responses[0]);
                }

            });
    }

    const handleOnSave = (i, rowData) => {
        updateMelloRoosForeclosure(rowData);
        getForeclosures();
        setEditIndex(-1);
    }

    useEffect(() => {
        getForeclosures();
        // eslint-disable-next-line
    } , [openAddModal]);

    return (
        <Card>
            <div className="form">
                <h3>Foreclosure Information for Fiscal  Year 
                <MDBTooltip domElement tag="span" placement="right">
                <span>{"  "}<AiFillQuestionCircle onClick={()=>setShowInstructionsModal(true)} className="help-icon heading-help-icon"/></span>
                <span>click me for more info.</span>
                </MDBTooltip>
                {showInstructionsModal && 
                <Modal show={true} backdrop="static" onHide={()=>setShowInstructionsModal(false)}>
                    <Modal.Body>
                        <div>
                            <span>Foreclosure commences on the date the CFD agency notifies the property owner of the foreclosure. Issuers should report the date foreclosure commenced on any parcel(s), the number of foreclosed parcels and the total dollar amount of taxes due for foreclosures for that date. Report all foreclosures by date for the fiscal year.</span>
                            
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn-secondary custom-button-secondary" onClick={()=>setShowInstructionsModal(false)}>Close</button>
                    </Modal.Footer>
                </Modal>
                }</h3>

                <div>
                {foreclosures.length !==0 && <>
                    <label>(Aggregate totals, if foreclosures commenced on same date)</label>
                    <Table 
                            handleRemove={handleRemove}
                            startEditing={startEditing}
                            editIdx={editIdx}
                            stopEditing={stopEditing}
                            handleChange={handleChange}
                            handleSave={handleOnSave}
                            data={foreclosures}
                            header={[
                            {
                                name: "Date Foreclosure Commenced",
                                prop: "foreclosureDate",
                                editable: false
                            },
                            {
                                name: "Total Number of Foreclosure Parcels",
                                prop: "foreclosureParcelInt",
                                fieldType: "number",
                                editable: true
                            },
                            {
                                name: "Total Amount of Tax on Foreclosure Parcels",
                                prop: "totalTaxDueAmt",
                                fieldType: "currency",
                                type:"amount",
                                editable: true
                            }
                            ]}
                            /></>
                    }                    
                </div>
                {openAddModal && <AddForeClosureInformation onModalClose={handleModalClose} melloRoosDetails = {melloRoosDetails} /> }
                <button type="button" className="custom-button" onClick={() => addForeclosure()}>Add Foreclosure Information</button>
                <MDBTooltip domElement tag="span" placement="right">
                  <span>{"  "}<AiFillQuestionCircle onClick={()=>setShowHelpTextModal(true)} className="help-icon heading-help-icon"/></span>
                  <span>Click me for more info.</span>
                </MDBTooltip>
                {showHelpTextModal && 
                <Modal show={true} backdrop="static" onHide={()=>setShowHelpTextModal(false)}>
                    <Modal.Body>
                        <div>
                            <p>Additional foreclosures may be added.</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="custom-button-secondary btn-secondary" onClick={()=>setShowHelpTextModal(false)}>Close</button>
                    </Modal.Footer>
                </Modal>
                }

                <div className="btn-div">
                {/* <button className="custom-button" type="button" onClick={()=>props.openWidget('docUpload')}>Back</button> */}
                <button className="fright custom-button" type="button" onClick={()=>validateForm()}>Save & Next</button>
                </div>
            </div>
        </Card>
    )
}

export default Foreclosure

import { Modal, Row, Col, Form } from 'react-bootstrap'
import {useState, useEffect, useContext} from 'react'
import CurrencyInput from '../../formElements/CurrencyInput'
import {FormInput} from '../../formElements/FormInput'
import "react-datepicker/dist/react-datepicker.css"
import {validateFormFields} from '../../../utils'
import AuthorizationService from '../../../services/AuthorizationService'
import IssueAuthorizationService from '../../../services/IssueAuthorizationService'
import { IssuerContext, UserContext } from '../../../UseContext'
import CommonService from '../../../services/CommonService'
import AddAuthorizationRef from './addAuthorizationRef'
import { toast } from 'react-toastify'
import { MDBTooltip } from 'mdbreact'
import {AiFillQuestionCircle} from 'react-icons/ai'

const EditIssuanceAuthorization = (props) => {
    const loginUser = useContext(UserContext);
    const issuerId = useContext(IssuerContext);
    const {onModalClose, authInfo, issueId} = props;
    const [formErrors,setFormErrors] = useState({});
    const [authorizationNames, setAuthorizationNames] = useState([]);
    const [authorizationInfo, setAuthorizationInfo] = useState({...authInfo, 'outstandingAmt' : ''});
    const [netChange, setNetChange] = useState(0.00);
    const [addNewAuthorization, setAddNewAuthorization] = useState(false);

    useEffect(() => {
        var obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getAuthorizationRefLookupsByIssuerId",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {"id": issuerId}
        };
    
        AuthorizationService.getAuthorizationsByIssuerId(obj)
          .then((res) => res)
          .then((result) => {
            setAuthorizationNames(result.data.responses[0])
            setAuthorizationInfo({...authorizationInfo, 'outstandingAmt' : result.data.responses[0].filter(el => el.authorizationId === authorizationInfo.authorizationId)[0].outstandingAmount})
          });
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [issuerId]);

    useEffect(()=> {
        const net = (authInfo.authorityReplenishAmt - authInfo.authorityReducedAmt);
        setNetChange(net);
    },[authInfo]);

    const getAuthorizationRef = (authorizationId, authArray) => {
        var obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getAuthorizationRef",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {"authorizationId": authorizationId}
        };
    
        AuthorizationService.getAuthorizationsByIssuerId(obj)
            .then((res) => res)
            .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message)
            } else {
                setAuthorizationInfo({...authArray, 
                    'originalAuthorizedAmt': result.data.responses[0].authorizationAmt,
                    'authorizationDate': result.data.responses[0].electionDate
                });
            }
        });
    }
    const handleAuthChange = (e) => {
        const {name, value} = e.target;
        let authArray = {...authorizationInfo};
        if(name === 'authorizationName'){
            authArray['authorizationId'] = value;
            const outstandingAmount = authorizationNames.filter(el => el.authorizationId === parseInt(value))[0].outstandingAmount;
            authArray['outstandingAmt'] = outstandingAmount;
            getAuthorizationRef(parseInt(value), authArray);
        } else {
            authArray[name] = value;
        }
        setAuthorizationInfo(authArray);
    }

    const handleAddNewAuthorization = () => {
        setAddNewAuthorization(true);
    }

    const handleAddNewAuthClose = () => {
        setAddNewAuthorization(false);
    }

    const calculateNetChange = () => {
        let authArray = {...authorizationInfo};
        const authorityReplenishAmt = ( typeof authArray.authorityReplenishAmt === 'string' ) 
            ? authArray['authorityReplenishAmt'] !== "" ? parseFloat(authArray['authorityReplenishAmt'].replace(/,/g, '')) : 0.00
            : authArray['authorityReplenishAmt'] !== "" ? authArray['authorityReplenishAmt'] : 0;    
    
        const authorityReducedAmt = ( typeof authArray.authorityReducedAmt === 'string' ) 
            ? authArray['authorityReducedAmt'] !== "" ? parseFloat(authArray['authorityReducedAmt'].replace(/,/g, '')) : 0.00
            : authArray['authorityReducedAmt'] !== "" ? authArray['authorityReducedAmt'] : 0;    

        let netChangeTotal = (authorityReplenishAmt*10 - authorityReducedAmt*10)/10;
        setNetChange(netChangeTotal);
    }
  
    const updateAuthorization = async (issueAuthorization) => {  
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putIssueAuthorization",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id" : issueAuthorization.issuerAuthorizationId,
                "issueAuthorization": {
                    "authorizationId": issueAuthorization.authorizationId,
                    "finalSaleId": issueId,
                    "proposeDebtId": null,
                    "originalAuthorizedAmt": issueAuthorization.originalAuthorizedAmt,
                    "authorizationDate": issueAuthorization.authorizationDate,
                    "otherAuthorizationName": issueAuthorization.otherAuthorizationName,
                    "authorityReplenishAmt": issueAuthorization.authorityReplenishAmt,
                    "authorityReducedAmt": issueAuthorization.authorityReducedAmt,
                    "createDatetime": new Date(),
                    "createUser": loginUser,
                    "lastUpdateDatetime": new Date(),
                    "lastUpdateUser": loginUser
                }
            }
        };
       
        await IssueAuthorizationService.updateAuthorization(obj)
        .then((res) => res)
        .then((result) => {
        });
    }

    const handleSubmit = (e) => {
        const form = e.target.closest('form#add-form');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});
            updateAuthorization(authorizationInfo);
            onModalClose();
        }
    }
    return (
        <Modal  show={true} onHide={onModalClose} className="authModal">
            {addNewAuthorization ?
            <><Modal.Header closeButton><h3>Add New Authorization</h3></Modal.Header>
                <Modal.Body><AddAuthorizationRef onModalClose={handleAddNewAuthClose} /></Modal.Body></> : 
                <>
            <Modal.Header closeButton><h3>Edit Issuance Authorization</h3></Modal.Header>
            <Modal.Body>
                <form id="add-form">
                    <Row>
                        <Col md={12}>
                            <div className="control">
                                <label><span className="required">*</span>Authorization Name:
                                    <MDBTooltip domElement tag="span" placement="right">
                                    <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                                    <span>Select a previously established authorization or select Add New Authorization below.</span>
                                    </MDBTooltip>
                                </label>
                                <Form.Select name="authorizationName" 
                                    onChange={(e)=>handleAuthChange(e)} 
                                    data-validations="required"
                                    value={authorizationInfo.authorizationId}
                                    style={formErrors['authorizationName'] && {border: `solid 1px red`}}
                                >
                                    <option value="">select</option>
                                    {authorizationNames.length !== 0 && authorizationNames.map((auth, i) => {
                                        return <option key={i} value={auth.authorizationId}>{auth.authorizationLookupName}</option>
                                    })}
                                </Form.Select>
                                { formErrors && formErrors['authorizationName'] && <p className="error">{ formErrors['authorizationName'] }</p>}
                            </div>
                            <button type="button" className="link-button" onClick={()=>handleAddNewAuthorization()} id="add_new"><small>Add New Authorization</small></button>
                            {addNewAuthorization && <AddAuthorizationRef onModalClose={handleAddNewAuthClose}/>}
                        </Col>
                        <Col md={6}>
                            <div className="control">
                                <FormInput 
                                label="Current Remaining Authorization" 
                                name="outstandingAmt" 
                                value={authorizationInfo.outstandingAmt} 
                                helpText="Read-only. The amount of the authorization that is unissued as of the last reviewed Report of Final Sale that reported use of this authorization."
                                disabled="disabled"/>
                            </div>
                        </Col> 
                        </Row>
                        <Row>
                        <Col md={6}>
                            <div className="control">
                                <FormInput 
                                label="Original Authorized Amount" 
                                name="originalAuthorizedAmt" 
                                value={authorizationInfo.originalAuthorizedAmt !== '' ? CommonService.currencyFormatter(authorizationInfo.originalAuthorizedAmt) : ''} 
                                helpText="Read-only. The original maximum amount of debt authorized by the vote of the electorate or action of the governing body."
                                disabled="disabled"/>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="control"><span className="required">*</span>
                                <CurrencyInput 
                                    label="Amount Authority Reduced (By this Issue)" 
                                    name="authorityReducedAmt" 
                                    value={authorizationInfo.authorityReducedAmt} 
                                    onBlur={(e)=>calculateNetChange(e)}
                                    onChange={(e)=>handleAuthChange(e)}
                                    helpText="The amount this authorization is reduced by this debt issue."
                                    validations={["required"]}
                                    error ={ formErrors && formErrors['authorityReducedAmt'] }/>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="control">
                                <FormInput 
                                label="Authorization Date: (MM/DD/YYYY)" 
                                name="authorizationDate" 
                                value={authorizationInfo.authorizationDate !== '' ? CommonService.dateFormatter(authorizationInfo.authorizationDate) : ''} 
                                helpText="Read-only. The date of the election or action of the governing body."
                                disabled="disabled"/>
                            </div>
                        </Col>    
                        <Col md={6}>
                            <div className="control">
                                <span className="required">*</span>
                                <CurrencyInput
                                label="Amount Authority Replenished (By this Issue)" 
                                name="authorityReplenishAmt" 
                                value={authorizationInfo.authorityReplenishAmt} 
                                onBlur={(e)=>calculateNetChange(e)}
                                onChange={(e)=>handleAuthChange(e)}
                                validations={["required"]}
                                error={formErrors && formErrors['authorityReplenishAmt']}
                                helpText="Amount Authority Replenished(By this Issue)"/>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="control">
                                <FormInput type="text" label="Net Change (By this Issue)" 
                                name="netChange" 
                                value={(netChange !== '' ? CommonService.currencyFormatter(netChange) : '')}
                                readOnly
                                disabled="disabled" 
                                helpText="Net Change"/>
                            </div>
                        </Col>                    
                    </Row>

                    <div className="btn-div">
                    <div className="fright">
                    <button type="button" className="custom-button" onClick={()=>onModalClose()}>Cancel</button>{" "}
                    <button type="button" className="custom-button" onClick={(e)=>handleSubmit(e)}>Save</button>
                    </div>
                </div>
                </form>
            </Modal.Body></>
            }
        </Modal>
    )
}

export default EditIssuanceAuthorization

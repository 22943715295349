import CommonService from "./CommonService";


const getPrivateSaleReasonsByIssueId = (obj) => {
    return CommonService.apicall(obj)
};

const saveIssuePrivateSaleReasons = (obj) => {
    return CommonService.apicall(obj)
};

const IssuePrivateSaleReasonService = {
    getPrivateSaleReasonsByIssueId,
    saveIssuePrivateSaleReasons
};


export default IssuePrivateSaleReasonService;
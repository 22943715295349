import { useState, useEffect } from 'react'
import {Spinner} from 'react-bootstrap'
import {MDBTooltip} from 'mdbreact'
import {AiFillQuestionCircle} from 'react-icons/ai'
import ReportsService from '../../services/ReportsService'
import CommonService from '../../services/CommonService'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import { toast } from 'react-toastify';
import { useContext } from 'react';
import { UserContext } from "../../UseContext";
import { useNavigate } from 'react-router-dom'
import swal from 'sweetalert'
import ReportOfProposedDebtService from '../../services/ReportOfProposedDebtService'
import DataGrid from '../GenericComponents/DataGrid'
import { Column } from 'primereact/column'
import { configManager } from '../../configManager'
import { SplitButton } from 'primereact/splitbutton'

export const checkIfReviewedFinalSaleCanEdit = async (finalSaleId, navigate) => {
    var obj ={
        "msgId": null,
        "sysId": null,
        "opr": "isReviewedFinalSaleCanEdit",
        "hdrStruct": null,
        "queryParam": null,
        "reqData": {
            "finalSaleId" : finalSaleId
        }
      } ;
  
    await FinalSaleReportService.isReviewedFinalSaleCanEdit(obj)
    .then((res) => res)
    .then(async (result) => {
        if (result.data.errors && result.data.errors.length > 0){
            toast.error(result.data.errors[0].message)
        } else {
            const response = result.data.responses[0];
            if("reviewedReportsExists" in response) {
                swal(response["reviewedReportsExists"]);
            } else if("inProcessReportsExists" in response) {
                swal({
                    text: response["inProcessReportsExists"] + "Do you want to continue?",
                    icon: "warning",
                    buttons: [true, 'Yes'],
                    closeOnClickOutside: false
                  })
                  .then((confirm) => {
                    if (confirm) {
                        navigate(`/dashboard/report-final-sale/${finalSaleId}`);
                    } 
                  });
            } else {
                navigate(`/dashboard/report-final-sale/${finalSaleId}`);
            }
        }
    });
}

export const saveFilingContact = (finalSaleId, user, callback) => {
    var obj ={
        "msgId": null,
        "sysId": null,
        "opr": "postIssueFilingContactAssoc",
        "hdrStruct": null,
        "queryParam": null,
        "reqData": {
            "issueContactAssoc": {
                "finalSaleId": finalSaleId,
                "proposeDebtId": null,
                "userId" : user,
                "roleCode" : 'FC',
                "feeAmt": null,
                "createUser": CommonService.getUserName(user),
                "lastUpdateUser": CommonService.getUserName(user),
            }
        }
      } ;
  
    ReportOfProposedDebtService.saveFilingContact(obj)
    .then((res) => res)
    .then(async (result) => {
        if (result.data.errors && result.data.errors.length > 0){
            toast.error(result.data.errors[0].message)
        }
        else{
            callback();
        }
    });
}

const FinalSaleReports = (props) => {
    const {issuerId, reportStatus, searchCriteria} = props;
    const [finalSaleReports, setFinalSaleReports] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const user = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        getFinalSaleWorkLoad();
        // eslint-disable-next-line
    }, [issuerId, searchCriteria]);

    const getFinalSaleWorkLoad = () => {
        setIsLoading(true);
        let obj;
        if(reportStatus === "view-previous") {
             obj ={
                "msgId": null,
                "sysId": null,
                "opr": "getOldFinalSaleReportsByIssuerId",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "id" : issuerId,
                    "env" : "E",
                    "searchCriteria": searchCriteria
                }
            } 
        } else {
             obj ={
                "msgId": null,
                "sysId": null,
                "opr": "getFinalSaleReportsByIssuerId",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "id" : issuerId,
                    "env" : "E"
                }
            } 
        }
        ReportsService.getReports(obj)
        .then((res) => res)
        .then((result) => {
            setIsLoading(false);
            setFinalSaleReports(result.data.responses[0]);
        });
    }

    const actions = {
        'D' : [
            {value:'E', label: 'Edit'},
            {value:'C', label: 'Cancel'},
            {value:'SH', label: 'View History'}
        ],
        'S' : [
            {value:'E', label: 'Edit'},
            {value:'C', label: 'Cancel'},
            {value:'SH', label: 'View History'}
        ],
        'R' : [
            {value:'E', label: 'Edit'},
            {value:'SH', label: 'View History'},
            {value:'DW', label: 'View in DebtWatch'}
        ]
    }
    
    const viewFormatter = (row) => { 
        const items =  actions[row['issueStatusFlag']].map((action, i)=> {
            return ( {
                label: action.label,
                key: i,
                command: (e) => {
                    handleActionClick(e, action.value, row )
                }
            })
            
         });
         
        if(reportStatus === "view-previous" && row['issueStatusFlag'] === 'R' ){
             items.push({
                label: 'Draw on Reserve/ Default/ Replenishment',
                key: items.length,
                command: (e) => {
                    handleActionClick(e, 'DRAW', row )
                }
            });
        }
        return ( 
              <div 
                  style={{ textAlign: "center", lineHeight: "normal" }}>
                    {reportStatus === "view-previous" && row['retiredFlag'] === "No" ?
                        <span>Not Applicable</span> :
                        <div>
                            <SplitButton 
                                label="Action" 
                                model={items} 
                                severity='secondary' 
                                size='small' 
                            />
                        </div>
                    }
         </div> 
        ); 
    }

    const addDraw = (row) => {
        var obj ={
            "msgId": null,
            "sysId": null,
            "opr": "postDraw",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {"draw": {
                "finalSaleId": row.finalSaleId,
                "statusFlag": "D",
                "contactId": null,
                "deliveryTypeCode": "I",
                 "createUser": CommonService.getUserName(user),
                "lastUpdateUser": CommonService.getUserName(user),
                "emailId": user

            }

            }
          } ;
      
        FinalSaleReportService.addDraw(obj)
        .then((res) => res)
        .then(async (result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message)
            }
            else{
                toast.success("The Draw was created  successfully.");
                const drawId = result.data.responses[0];
                navigate(`/dashboard/draw-on-reserve/${drawId}`);
            }
        });
    }

    const cancelFinalSaleReport = (finalSaleId) => {
        var obj ={
            "msgId": null,
            "sysId": null,
            "opr": "cancelFinalSale",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id" : finalSaleId,
                "user" : CommonService.getUserName(user),
                "env" : 'E'
            }
          } ;
      
        FinalSaleReportService.cancelFinalSale(obj)
        .then((res) => res)
        .then(async (result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message)
            }
            else{
                toast.success("The transaction has been cancelled and an acknowledgement email has been sent.");
                getFinalSaleWorkLoad();
            }
        });
    }

    const handleActionClick = (e, action, row) => {
        let finalSaleId = row.finalSaleId;
        switch (action) {
            case "C":
                swal('By clicking "Yes" this action will permanently cancel this Report of Final Sale, its assigned CDIAC #, and the precedent Report of Proposed Debt Issuance. Are you sure you want to permanently cancel this report?', {
                    buttons: ["No", "Yes"],
                }).then((value) => {
                   if(value){
                   cancelFinalSaleReport(finalSaleId);
                   }
                });
                break;
            case "E":
                const status = row.issueStatusFlag;
                if(status === 'R') {
                    checkIfReviewedFinalSaleCanEdit(finalSaleId, navigate);
                } else {
                    saveFilingContact(finalSaleId, user, function() {
                    navigate(`/dashboard/report-final-sale/${finalSaleId}`);
                    });
                }
                break;
            case "DRAW":
                addDraw(row);
                break;
            case "SH": 
                navigate(`/status-history-upload-documents/${row.cdiacNbr}`);
                break;
            case "DW" :
                window.open( `${configManager.debtWatchUrl}/issue-level-detail?cdiacNumber=${row.cdiacNbr}`);
                break;
        default:
            break;
        }
    }
    const viewHeaderFormatter = (row, column) => {
        return (<>{column.text}
        { column.headerFormatter && <MDBTooltip domElement tag="span" placement="right" >
            <span>{"  "}<AiFillQuestionCircle className="help-icon heading-help-icon"/></span>
            <span>Click on the Action button to view the actions that may be taken with the specific report and CDIAC # listed.</span>
        </MDBTooltip>
        }
        </>);
      }
    const columns = [{
        dataField: 'cdiacNbr',
        text: 'CDIAC #',
        sort: true
      }, {
        dataField: 'issueStatus',
        text: 'Status',
        sort: true
      }, {
        dataField: 'issueName',
        text: 'Issue Name',
        sort: true
      }, {
        dataField: 'issuerName',
        text: 'Issuer Name',
        sort: true
      }, {
        dataField: 'projectName',
        text: 'Project Name',
        sort: true
      }, {
        dataField: 'actualSaleDate',
        text: 'Actual Sale Date',
        sort: true,
        formatter: (row) => CommonService.dateFormatter(row['actualSaleDate'])
      },{
        dataField: 'principalSaleAmt',
        text: 'Principal Amount',
        sort: true,
        formatter: (row) => CommonService.currencyFormatter(row['principalSaleAmt'])
      },{
        dataField: 'retiredFlag',
        text: 'Ongoing Reportable',
        sort: true,
        hidden: reportStatus === "view-previous" ? false : true
      },{ 
        dataField: "",
        text: "Action", 
        formatter: viewFormatter,
        headerFormatter: viewHeaderFormatter
    }
    
    ]
    return (
        <div>
            {isLoading ? <Spinner animation="border" variant="primary" /> : 
            <DataGrid
            title='Final Sale Reports'
            dataSource={finalSaleReports}
            sortField='cdiacNbr'
            searchToolTip="Use the search feature to find a specific issue within the dashboard. Click on the dashboard column headings to sort."
            emptyDataMessage='No data available to display'
            >
            {columns.map((column, i) => {
                return (
                <Column 
                key={i}
                field={column.dataField}
                header={(row) => viewHeaderFormatter(row, column)}
                sortable={column.sort} 
                body={column.formatter}
                hidden={column.hidden}
                />)
                }) 
            } 
            </DataGrid>
            }
        </div>
    )
}

export default FinalSaleReports

import {useState, useContext} from 'react'
import {Row, Col} from 'react-bootstrap'
import Card from '../ui/Card'
import AcknowledgementModal from './acknowledgementModal'
import { useNavigate, useParams,useLocation } from 'react-router-dom'
import moment from 'moment';
import { toast } from 'react-toastify';
import { UserContext } from "../../UseContext";
import FinalSaleReportService from "../../services/FinalSaleReportService";
import CommonService from '../../services/CommonService';

const MelloRoos = (props) => {
    const [openAcknowledgement, setOpenAcknowledgement] = useState(false);
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();

    const user = useContext(UserContext);
    const cdiacNo = params.cdiac;
    let melloRoos = location.state.reportData;

    if(!location.state.reportData){
        melloRoos = location.state.melloRoos
    }

    const [stateValues, setStateValues] = useState({
        fiscalYrEndDate : melloRoos.fiscalYrEndDate
    })

    const handleChange = (e) => {
        const {name, value} = e.target;

        setStateValues(prevState => ({
            ...stateValues, [name]: moment('6/30/' + value).format('MM/DD/YYYY')
        })
        )
    }
    const handleSubmit = () => {
        if(melloRoos.melloRoosId === null ||
            CommonService.getFullYear(stateValues.fiscalYrEndDate) !== CommonService.getFullYear(melloRoos.fiscalYrEndDate) ){

            var obj ={
                "msgId": null,
                "sysId": null,
                "opr": "createMelloRoos",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {"cdiacNbr": cdiacNo, "melloRoos": {
                    "fiscalYrEndDate" : stateValues.fiscalYrEndDate,
                    "finalSaleId": melloRoos.finalSaleId,
                     "createUser": CommonService.getUserName(user),
                    "lastUpdateUser": CommonService.getUserName(user),
                    "emailId": user
                }
    
                }
              } ;
          
            FinalSaleReportService.createMelloRoos(obj)
            .then((res) => res)
            .then(async (result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    toast.error(result.data.errors[0].message);
                    return;
                }
                else{
                    toast.success("The Mello Roos was created  successfully.");
                    melloRoos.melloRoosId = result.data.responses[0].melloRoosId;
                    setOpenAcknowledgement(true);
                  }
            });

            
        }
        else{

            var anotherobj ={
                "msgId": null,
                "sysId": null,
                "opr": "validateMelloRoos",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {"cdiacNbr": cdiacNo, "melloRoos": {
                    "fiscalYrEndDate" : stateValues.fiscalYrEndDate
                }
    
                }
              } ;
          
            FinalSaleReportService.validateMelloRoos(anotherobj)
            .then((res) => res)
            .then(async (result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    toast.error(result.data.errors[0].message);
                    return;
                }
                else{
                    var obj ={
                        "msgId": null,
                        "sysId": null,
                        "opr": "createMelloRoos",
                        "hdrStruct": null,
                        "queryParam": null,
                        "reqData": {"cdiacNbr": cdiacNo, "melloRoos": {
                            "fiscalYrEndDate" : stateValues.fiscalYrEndDate,
                            "finalSaleId": melloRoos.finalSaleId,
                             "createUser": CommonService.getUserName(user),
                            "lastUpdateUser": CommonService.getUserName(user),
                            "emailId": user
                        }
            
                        }
                      } ;
                  
                    FinalSaleReportService.createMelloRoos(obj)
                    .then((res) => res)
                    .then(async (result) => {
                        if (result.data.errors && result.data.errors.length > 0){
                            toast.error(result.data.errors[0].message);
                            return;
                        }
                        else{
                            toast.success("The Mello Roos was created  successfully.");
                            melloRoos.melloRoosId = result.data.responses[0].melloRoosId;
                            setOpenAcknowledgement(true);
                          }
                    });
                  }
            });


        }
       
    }

    const backToDashboard = () => {
        const dashboardSelectionObj = JSON.parse(sessionStorage.getItem('dashboardSelection'));
        navigate("/dashboard", {state: {dashboardSelection: dashboardSelectionObj}});
    }


    return (
        <Card>
            {/* <div><button>Back to Admin Home</button></div> */}
            <div>
                <div className="panel panel-default">
                    <div className="panel-heading" role="tab"> 
                        <div className="container">
                            <div className="panel-title">
                                <h1 className="main-title">Mello Roos Yearly Fiscal Status Report</h1>
                            </div>
                        </div>
                    </div>
                    <div className="panel-body" style={{padding: '10px 0'}}>
                        <div className="container">
                            <div>
                                <Row>
                                    <Col>
                                        <div>
                                            <label> CDIAC #: </label>
                                            <input type="text" defaultValue={cdiacNo} disabled/>
                                        </div>
                                        <div>
                                            <label><span className="required">* </span>Balances Reported as of June 30, <input type="number" name="fiscalYrEndDate" disabled="true" onChange={(e)=>handleChange(e)} maxLength="4" defaultValue={CommonService.getFullYear(stateValues.fiscalYrEndDate)} /></label>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{overflow: 'auto'}}>
                                <button style={{float: 'right'}}type="button" onClick={(e)=>handleSubmit(e)}>Submit</button>

                                <button 
                                type="button"
                                className="button" 
                                onClick={()=>backToDashboard()}>Back to Dashboard
                                </button>

                            </div>                           
                        </div>
                    </div> 
                </div>     
            </div>
            {openAcknowledgement && <AcknowledgementModal onModalClose={()=>setOpenAcknowledgement(false)} cdiacNo={cdiacNo} finalSaleId = {melloRoos.finalSaleId}  melloRoos={melloRoos}/>
            }
            
        </Card>
    )
}

export default MelloRoos

import axios from "axios";
import { configManager } from "../configManager";

const uploadFile = (obj) => {
    return apicall(obj)

};

const deleteFile = (obj) => {
    return deleteapicall(obj)

};

const BlobService = {
    uploadFile,
    deleteFile
};


export default BlobService;

function apicall(obj) {
    const idToken = localStorage.getItem("token");
    const headers = {
        Authorization: `Bearer ${idToken}`,
        AppId: 'External'
    };
    return axios({
        method: "post",
        headers: headers,
        url: `${configManager.fileUploadApiUrl}`,
        data: obj
    });
}

function deleteapicall(obj) {
    const idToken = localStorage.getItem("token");
    const headers = {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json',
        AppId: 'External'
    };
    return axios({
        method: "post",
        headers: headers,
        url: `${configManager.apiUrl}/CdiacAdmin`,
        data: obj
    });
}
import CommonService from "./CommonService";

const getAuthorizationsByIssueId = (obj) => {
    return CommonService.apicall(obj)
};

const addAuthorization = (obj) => {
    return CommonService.apicall(obj)
};

const updateAuthorization = (obj) => {
    return CommonService.apicall(obj)
};

const deleteAuthorization = (obj) => {
    return CommonService.apicall(obj)
};

const validateAdtrAuthorizations = (obj) => {
    return CommonService.apicall(obj)
};

const IssueAuthorizationService = {
    getAuthorizationsByIssueId,
    addAuthorization,
    updateAuthorization,
    deleteAuthorization,
    validateAdtrAuthorizations
};


export default IssueAuthorizationService;
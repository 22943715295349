import React from 'react';
import {AiFillQuestionCircle} from 'react-icons/ai'
import { MDBTooltip } from 'mdbreact';
export const FormInput = ({
  name,
  type,
  placeholder,
  onBlur,
  onChange,
  className,
  value,
  error,
  children,
  label,
  validations,
  isrequired,
  disabled,
  readOnly,
  helpText,
  maxLength,
  ...props
}) => {
  return (
    <div className="control" >
      {label && <label htmlFor={name}>{isrequired && <span className="required">*</span>}{label +':'} 
       {helpText && <MDBTooltip domElement tag="span" placement="right">
            <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
            <span>{helpText}</span>
        </MDBTooltip>
        }
      </label>}
      <input
        id={name}
        name={name}
        type={type}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        className={`${className} form-control`}
        data-validations={validations}
        //style={error && {border: `solid 1px red`}}
        disabled = {disabled}
        maxLength = {maxLength}
        readOnly = {readOnly}
      />
      { error && <p className="error">{ error }.</p>}
    </div>
  )
}


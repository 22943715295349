import React from 'react'
import CommonService from "../../services/CommonService"

const ReviewComponent = (props) => {
    const { adtrDependenciesData, contact } = props;
    const {adtrInfo, 
      issuanceDetails, 
      issueAuthorizations, 
      issueRefundingAssocs,
      issueRefundedAssocs, 
      fiscalYrProceedsSummary, 
      proceedsSummary,
      currentPeriodexpenditures,
      expenditureSummary,
      netProceedsBalance} = adtrDependenciesData;

    const principalOutstandingAmt = (adtrInfo.rptPeriodBeginBalAmt + adtrInfo.accretedInterestAmt) - (adtrInfo.principalOtherPaidAmt + adtrInfo.principalRedeemAmt);
    const totalPrincipalAccretedInt = (adtrInfo.rptPeriodBeginBalAmt + adtrInfo.accretedInterestAmt);
    const totalReportableProceeds = (issuanceDetails.principalSaleAmt + issuanceDetails.netOriginalPremium - issuanceDetails.marksRoosProceedAmt);
    const adtrReportableFlag = netProceedsBalance === 0 && principalOutstandingAmt === 0 ? 'No' : 'Yes';
   
    return (
        <>
        <html>
         <head>
         <meta charset="UTF-8"></meta>
         <style>{"\
          div.repeat-header {\
            padding-top: 0.2in !important;\
            display: block !important;\
            text-align: center;\
            position: running(repeat-header) !important;\
          }\
          .report-header {\
              text-align: center;\
              padding: 5px;\
              width: 100%;\
              height: 40px;\
          }\
          .report-heading-left {\
              font-size: 18px;\
              font-weight: 800;\
              color: darkblue;\
              text-align: left;\
              width: 20%;\
          }\
          .report-heading-center {\
              font-size: 12px;\
              font-weight: 800;\
              color: darkblue;\
              text-align: center;\
              width: 60%;\
          }\
          .report-heading-right {\
              font-size: 12px;\
              font-weight: 800;\
              color: darkblue;\
              text-align: right;\
              width: 20%;\
          }\
          .report-heading-title {\
              font-size: 18px;\
              font-weight: 800;\
              color: darkblue;\
              text-align: left;\
              min-width: 65%;\
          }\
          .report-heading-date {\
              font-size: 10px;\
              font-weight: 800;\
              color: darkblue;\
              text-align: right;\
          }\
          div.footer {\
            position: running(footer) !important;\
          }\
          #current-page-placeholder::before {\
            content: counter(page);\
          }\
          #total-pages-placeholder::before {\
            content: counter(pages);\
          }\
          @page {\
              margin-top: 90px;\
              @top-center {\
                  content: element(repeat-header) !important\
              }\
          }\
          @page {\
              @bottom-right {\
                  content: element(footer) !important;\
                  font-size: 10px;\
              }\
          }\
          div.break-page {\
              page-break-after: always;\
          }\
          .table a {\
              color: black;\
              text-decoration: underline;\
          }\
          .table .tableheader th, .table tbody th {\
              color: #fff;\
              background-color: darkblue;\
              border-color: #454d55;\
              vertical-align: middle;\
              font-size: 1.0em;\
          }\
          .v-top {\
              vertical-align: top !important;\
          }\
          .fs-12 {\
            font-size: 12px;\
          }\
          .fs-14 {\
              font-size: 14px;\
          }\
          tr {\
              page-break-inside: avoid\
          }\
          .invoice-box{\
            max-width: 800px;\
            margin: auto;\
            padding: 20px;\
            border: 1px solid #eee;\
            box-shadow: 0 0 10px rgba(0, 0, 0, .15);\
            font-size: 13px;\
            line-height: 18px;\
            font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;\
            color: #555;\
          }\
          .invoice-box table {\
            width: 100%;\
            line-height: inherit;\
            text-align: left;\
          }\
          .invoice-box table td {\
            padding: 5px;\
            vertical-align: top;\
          }\
        .border-top {\
              border-top: 2px solid #eee;\
          }\
          .invoice-box table tr.top table td.title {\
              font-size: 13px;\
              line-height: 22px;\
              color: #333;\
          }\
          .invoice-box table tr.information table td {\
              padding-bottom: 10px;\
          }\
          .invoice-box table tr.heading td {\
              border-bottom: 1px solid #ddd;\
          }\
          .invoice-box table tr.sub-heading td {\
            font-weight: bold;\
          }\
          .invoice-box table tr.item td {\
            border-bottom: 1px solid #eee;\
          }\
          .invoice-box table tr.item.last td {\
              border-bottom: none;\
          }\
          .invoice-box table tr.total {\
              border-top: 2px solid #eee;\
              font-weight: bold;\
          }\
          .invoice-box table tr.section-title td {\
            font-weight: bold;\
            font-size: 18px;\
            line-height: 18px;\
            color: #000;\
          }\
          .invoice-box table tr.information table.data-table {\
            border: 1px solid #ddd;\
          }\
          @media only screen and (max-width: 600px) {\
          .invoice-box table tr.top table td {\
              width: 100%;\
              display: block;\
              text-align: center;\
          }\
          .invoice-box table tr.information table td {\
              width: 100%;\
              display: block;\
              text-align: center;\
          }\
          }\
        "}</style>
         </head>
          <body className="pdf-box">
            <div className="invoice-box">
            <table cellPadding="0" cellSpacing="0">
              <tbody>
              <tr className="information">
                <td colSpan="12">
                  <table>
                    <tr className="fs-12"><td><i>Information as of Reporting Year End: <b>{CommonService.dateFormatter(adtrInfo.fiscalYrEndDate)}</b></i></td></tr>
                    <tr className="section-title"><td>Issuance Information</td></tr>
                    <tr>
                      <td>
                        <table>
                            <tr><td colSpan="8">Issuer Name:</td><td colSpan="4" align="right">{issuanceDetails.issuerName}</td></tr>
                            <tr><td colSpan="8">Issue Name:</td><td colSpan="4" align="right">{issuanceDetails.issueName}</td></tr>
                            <tr><td colSpan="8">Project Name:</td><td colSpan="4" align="right">{issuanceDetails.projectName}</td></tr>
                            <tr><td colSpan="8">Actual Sale Date:</td><td colSpan="4" align="right">{CommonService.dateFormatter(issuanceDetails.actualSaleDate)}</td></tr>
                            <tr><td colSpan="8">Settlement Date:</td><td colSpan="4" align="right">{CommonService.dateFormatter(issuanceDetails.settlementDate)}</td></tr>
                            <tr><td colSpan="8">Original Principal Amount:</td><td colSpan="4" align="right">{CommonService.currencyFormatter(issuanceDetails.principalSaleAmt)}</td></tr>
                            <tr><td colSpan="8">Net Original Issue Premium/Discount:</td><td colSpan="4" align="right">{CommonService.currencyFormatter(issuanceDetails.netOriginalPremium - issuanceDetails.premiumDiscountAmt)}</td></tr>
                            <tr><td colSpan="8">Proceeds Used to Acquire Local Obligations (Marks-Roos Only):</td><td colSpan="4" align="right">{CommonService.currencyFormatter(issuanceDetails.marksRoosProceedAmt)}</td></tr>
                            <tr><td colSpan="8">Total Reportable Proceeds:</td><td colSpan="4" align="right">{CommonService.currencyFormatter(totalReportableProceeds)}</td></tr>
                            <tr><td colSpan="8">Total cost of issuance from Report of Final Sale:</td><td colSpan="4" align="right">{CommonService.currencyFormatter(issuanceDetails.totalIssuanceAmt)}</td></tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr className="information">
                <td>
                  <table>
                    <tr className="section-title"><td>Issuance Authorization</td></tr>
                    {issueAuthorizations.length === 0 ? <tr><td>No Data Available</td></tr> :
                    issueAuthorizations.map((auth, i) => {
                      return (<tr key={i}><td><table>
                      <tr className="fs-12"><b>Authorization ({i+1}): </b></tr>
                                                   
                      <tr><td>Authorization Name:</td><td align="right">{auth.authorizationName}</td></tr>
                      <tr><td>Original Authorized Amount:</td><td align="right">{CommonService.currencyFormatter(auth.originalAuthorizedAmt)}</td></tr>
                      <tr><td>Authorization Date:</td><td align="right">{CommonService.dateFormatter(auth.authorizationDate)}</td></tr>
                      <tr><td>Amount Authorized - Beginning of the Reporting Period:</td><td align="right">{CommonService.currencyFormatter(auth.beginAmt)}</td></tr>
                      <tr><td>Amount Authorized - During the Reporting Period:</td><td align="right">{CommonService.currencyFormatter(auth.duringAmt)}</td></tr>
                      <tr><td>Total Debt Authorized:</td><td align="right">{CommonService.currencyFormatter(auth.beginAmt + auth.duringAmt)}</td></tr>
                      <tr><td>Debt Issued During the Reporting Period:</td><td align="right">{CommonService.currencyFormatter(auth.authorityReducedAmt)}</td></tr>
                      <tr><td>Replenishment Reported During the Reporting Period:</td><td align="right">{CommonService.currencyFormatter(auth.authorityReplenishAmt)}</td></tr>
                      <tr><td>Total Debt Authorized but Unissued:</td><td align="right">{CommonService.currencyFormatter((auth.beginAmt + auth.duringAmt + auth.authorityReplenishAmt)- auth.authorityReducedAmt)}</td></tr>
                      <tr><td>Authorization Lapsed:</td><td align="right">{CommonService.currencyFormatter(auth.lapsedAmt)}</td></tr>
                      <tr><td>Total Authorization Remaining - End of Reporting Period:</td><td align="right">{CommonService.currencyFormatter((auth.beginAmt + auth.duringAmt + auth.authorityReplenishAmt) - auth.lapsedAmt - auth.authorityReducedAmt)}</td></tr>
                      </table></td></tr>
                      )
                    })
                    }
                  </table>
                </td>
              </tr>

              <tr className="information">
                <td colSpan="">
                  <table>
                    <tr className="section-title"><td>Principal Outstanding</td></tr>
                    <tr>
                      <td>
                        <table>
                            <tr><td colSpan="8">Principal Balance Upon Sale or at Beginning of the Reporting Period:</td><td colSpan="4" align="right">{CommonService.currencyFormatter(adtrInfo.rptPeriodBeginBalAmt)}</td></tr>
                            <tr><td colSpan="8">Accreted Interest – During Reporting Period:</td><td colSpan="4" align="right">{CommonService.currencyFormatter(adtrInfo.accretedInterestAmt)}</td></tr>
                            <tr><td colSpan="8">Total Principal and Accreted Interest:</td><td colSpan="4" align="right">{CommonService.currencyFormatter(totalPrincipalAccretedInt)}</td></tr>
                            <tr><td colSpan="8">Principal Paid with Proceeds from Other Debt Issues – During the Reporting Period:</td><td colSpan="4" align="right">{CommonService.currencyFormatter(adtrInfo.principalOtherPaidAmt)}</td></tr>
                            <tr><td colSpan="8">Principal Payments - During the Reporting Period (not reported as payments above):</td><td colSpan="4" align="right">{CommonService.currencyFormatter(adtrInfo.principalRedeemAmt)}</td></tr>
                            <tr><td colSpan="8">Principal Outstanding – End of Reporting Period:</td><td colSpan="4" align="right">{CommonService.currencyFormatter(principalOutstandingAmt)}</td></tr>
                        </table>
                      </td>
                    </tr>
                    <tr className="fs-14"><td colSpan="12"><b>Refunding/Refinancing Issues</b></td></tr>
                    <tr><td>
                      <table className="data-table">
                      <tr className="heading">
                        <td align="left">
                            CDIAC #
                        </td>
                        <td align="right">
                            Refunding/refinancing Amount
                        </td>
                        <td align="right">
                            Redemption/Payment Date
                        </td>
                      </tr>
                      {(issueRefundedAssocs && issueRefundedAssocs.length !== 0) ? issueRefundedAssocs.map((assoc, i) => {
                        return (<tr key={i}>
                          <td>{assoc.cdiacNbr}</td><td align="right">{CommonService.currencyFormatter(assoc.redeemAmt)}</td><td align="right">{CommonService.dateFormatter(assoc.redeemDate)}</td>
                        </tr>
                        )}) :
                        <tr><td colSpan="3" style={{textAlign: 'center'}}>No data available to display.</td></tr>
                      }
                      </table></td></tr>
                    </table>
                    
                </td>
              </tr>
              
              {/* use of proceeds */}
              <tr className="information">
                <td colSpan="">
                  <table>
                  <tr className="section-title"><td>Use of Proceeds</td></tr>
                  <tr>
                    <td colSpan="12">
                    <table className="data-table">
                      <tr className="heading">
                        <td align="left">Report End Date</td>
                        <td align="right">Begin Amount</td>
                        <td align="right">Spent Amount</td>
                        <td align="right">Remain Amount</td>
                      </tr>
                      {fiscalYrProceedsSummary.length > 0 ? fiscalYrProceedsSummary.map((assoc, i) => {
                        return (<tr key={i}>
                          <td align="left">{CommonService.dateFormatter(assoc.fiscalYrEndDate)}</td>
                          <td align="right">{CommonService.currencyFormatter(assoc.proceedAvailableAmt)}</td>
                          <td align="right">{CommonService.currencyFormatter(assoc.proceedSpentAmt)}</td>
                          <td align="right">{CommonService.currencyFormatter(assoc.proceedAvailableAmt - assoc.proceedSpentAmt)}</td>
                        </tr>
                        )}):
                        <tr><td colSpan="4" style={{textAlign: 'center'}}>No data available to display.</td></tr>
                      }
                    </table>
                    </td>
                  </tr>

                  <tr className="fs-12"><td colSpan="12"><b>Proceeds Spent/Unspent (Fund Level)</b></td></tr>
                  <tr>
                    <td colSpan="12">
                  <table className="data-table">
                    <tr className="heading">
                      <td align="left">Fund Category</td>
                      <td align="right">Total Reportable Proceeds Available</td>
                      <td align="right">Proceeds Spent Current Reporting Period</td>
                      <td align="right">Proceeds Spent Prior Reporting Period(s)</td>
                      <td align="right">Proceeds Unspent / Remaining</td>
                    </tr>
                    {proceedsSummary.length > 0 ? proceedsSummary.map((proceed, i) => {
                      return (<tr key={i}>
                        <td>{proceed.fundCategory}</td>
                        <td align="right">{CommonService.currencyFormatter(proceed.proceedAvlAmt)}</td>
                        <td align="right">{CommonService.currencyFormatter(proceed.currSpentAmt)}</td>
                        <td align="right">{CommonService.currencyFormatter(proceed.prevSpentAmt)}</td>
                        <td align="right">{CommonService.currencyFormatter(proceed.proceedAvlAmt - (proceed.currSpentAmt+proceed.prevSpentAmt) )}</td>
                      </tr>
                      )}) :
                      <tr><td colSpan="5" style={{textAlign: 'center'}}>No data available to display.</td></tr>
                    }
                    <tr>
                      <td><b>TOTAL:</b></td>
                      <td align="right">{CommonService.currencyFormatter(proceedsSummary && proceedsSummary.reduce((a, v) => a + (v.proceedAvlAmt), 0))}</td>
                      <td align="right">{CommonService.currencyFormatter(proceedsSummary && proceedsSummary.reduce((a, v) => a + (v.currSpentAmt), 0))}</td>
                      <td align="right">{CommonService.currencyFormatter(proceedsSummary && proceedsSummary.reduce((a, v) => a + (v.prevSpentAmt), 0))}</td>
                      <td align="right">{CommonService.currencyFormatter(proceedsSummary && proceedsSummary.reduce((a, v) => a + (v.balance), 0))}</td>
                    </tr>
                  </table>
                  </td>
                  </tr>

                  <tr className="fs-12"><td colSpan="12"><b>Expenditures of Current Reporting Period</b></td></tr>
                  <tr>
                    <td colSpan="12">
                  <table className="data-table">
                    <tbody>
                    <tr className="heading">
                      <td align="left">Fund Category</td>
                      <td align="left">Purpose</td>
                      <td align="right">Expenditure Amount</td>
                    </tr>
                    {currentPeriodexpenditures.length !== 0 ? currentPeriodexpenditures.map((proceed, i) => {
                      return (<tr key={i}>
                        <td>{proceed.fundCategory}</td>
                        <td>{proceed.purposeDesc}</td>
                        <td align="right">{CommonService.currencyFormatter(proceed.currSpentAmt)}</td>
                      </tr>
                      )}) :
                      <tr>
                        <td colSpan="3" style={{textAlign: 'center'}}>No data available to display.</td>
                      </tr>
                    }
                    <tr>
                        <td><b>TOTAL:</b></td>
                        <td></td>
                        <td align="right">{CommonService.currencyFormatter(currentPeriodexpenditures && currentPeriodexpenditures.reduce((a, v) => a + (v.currSpentAmt), 0))}</td>
                    </tr>
                    </tbody>
                  </table>
                  </td>
                  </tr>

                  <tr className="fs-12"><td colSpan="12"><b>Expenditure Summary</b></td></tr>
                  <tr>
                    <td colSpan="12">
                  <table className="data-table">
                    <tbody>
                    <tr className="heading">
                      <td align="left">Fund Category</td>
                      <td align="left">Purpose</td>
                      <td align="right">Expenditure In Current Reporting Period</td>
                      <td align="right">Expenditure In Prior Reporting Period(s)</td>
                      <td align="right">Total Expenditure All Periods</td>
                    </tr>
                    {expenditureSummary.map((expenditure, i) => {
                      return (<tr key={i}>
                        <td>{expenditure.fundCategory}</td>
                        <td>{expenditure.purposeDesc}</td>
                        <td align="right">{CommonService.currencyFormatter(expenditure.currSpentAmt)}</td>
                        <td align="right">{CommonService.currencyFormatter(expenditure.prevSpentAmt)}</td>
                        <td align="right">{CommonService.currencyFormatter(expenditure.currSpentAmt + expenditure.prevSpentAmt)}</td>
                      </tr>
                      )})
                    }
                    <tr>
                        <td><b>TOTAL:</b></td>
                        <td></td>
                        <td align="right">{CommonService.currencyFormatter(expenditureSummary && expenditureSummary.reduce((a, v) => a + (v.currSpentAmt), 0))}</td>
                        <td align="right">{CommonService.currencyFormatter(expenditureSummary && expenditureSummary.reduce((a, v) => a + (v.prevSpentAmt), 0))}</td>
                        <td align="right">{CommonService.currencyFormatter(expenditureSummary && expenditureSummary.reduce((a, v) => a + (v.currSpentAmt+v.prevSpentAmt), 0))}</td>
                    </tr>
                    </tbody>
                  
                  </table>
                  </td>
                  </tr>

                  <tr className="fs-12"><td colSpan="12"><b>Refunded/Refinanced Issues</b></td></tr>
                  <tr>
                    <td colSpan="12">
                      <table className="data-table">
                      <tr className="heading">
                        <td align="left">
                            CDIAC #
                        </td>
                        <td align="right">
                            Refunding/refinancing Amount
                        </td>
                        <td align="right">
                            Redemption/Payment Date
                        </td>
                      </tr>
                      {(issueRefundingAssocs && issueRefundingAssocs.length !== 0) ? issueRefundingAssocs.map((assoc, i) => {
                        return (<tr key={i}>
                          <td>{assoc.linkedCdiacNbr}</td><td align="right">{CommonService.currencyFormatter(assoc.redeemAmt)}</td><td align="right">{CommonService.dateFormatter(assoc.redeemDate)}</td>
                        </tr>
                        )}) :
                        <tr><td colSpan="3" style={{textAlign: 'center'}}>No data available to display.</td></tr>
                      }
                      </table>
                    </td>
                  </tr>
                  </table>
                </td>
              </tr>

              <tr className="information">
                <td colSpan="12">
                  <table>
                    <tr className="section-title"><td>Filing Contact</td></tr>
                    <tr>
                      <td>
                        <table>
                            <tr><td colSpan="8">Filing Contact Name:</td><td colSpan="4" align="right">{contact.contactFirstName} {contact.contactLastName}</td></tr>
                            <tr><td colSpan="8">Agency/Organization Name:</td><td colSpan="4" align="right">{contact.issuerName}</td></tr>
                            <tr><td colSpan="8">Address:</td><td colSpan="4" align="right">{contact.contactAddressLine1}</td></tr>
                            <tr><td colSpan="8">City:</td><td colSpan="4" align="right">{contact.contactCityName}</td></tr>
                            <tr><td colSpan="8">State:</td><td colSpan="4" align="right">{contact.contactStateCode}</td></tr>
                            <tr><td colSpan="8">Zip Code:</td><td colSpan="4" align="right">{contact.contactZipCode}</td></tr>
                            <tr><td colSpan="8">Telephone:</td><td colSpan="4" align="right">{contact.contactPhoneAreaCode + "-" +contact.contactPhoneNumber}</td></tr>
                            <tr><td colSpan="8">Fax Number:</td><td colSpan="4" align="right">{contact.contactFaxNumber}</td></tr>
                            <tr><td colSpan="8">E-mail:</td><td colSpan="4" align="right">{contact.contactEmailId}</td></tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr className="information">
                <td colSpan="12">
                  <table>
                    <tbody>
                    <tr className="section-title"><td>Comments</td></tr>
                    <tr><td colSpan="8">Issuer Comments:</td><td colSpan="4">{adtrInfo.issuerComment}</td></tr>
                    </tbody>  
                  </table>
                </td>
              </tr>

              <tr className="information">
                <td colSpan="12">
                  <table>
                    <tr className="section-title"><td>ADTR Reportable</td></tr>
                    <tr>
                      <td>
                        <table>
                            <tr><td colSpan="8">Principal Outstanding – End of Reporting Period:</td><td colSpan="4" align="right">{CommonService.currencyFormatter(principalOutstandingAmt)}</td></tr>
                            <tr><td colSpan="8">Proceeds Unspent – End of Reporting Period:</td><td colSpan="4" align="right">{CommonService.currencyFormatter(netProceedsBalance)}</td></tr>
                            <tr><td colSpan="8">ADTR Reportable Next Reporting Year:</td><td colSpan="4" align="right">{adtrReportableFlag}</td></tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

            </tbody>
            </table>
            <table>
              {adtrInfo.statusFlag !== 'D' &&
              <tr className="information">
                <td colSpan="8">Submission Date:</td><td colSpan="4" align="right">{CommonService.dateFormatter(adtrInfo.filingDate)}</td>
              </tr>
              }
              {adtrInfo.statusFlag === 'R' &&
              <tr className="information">
                <td colSpan="8">Review Date:</td><td colSpan="4" align="right">{CommonService.dateFormatter(adtrInfo.lastUpdateDatetime)}</td>
              </tr>
              }
            </table>
           </div>
          </body>
        </html>
       </>
    );

  }
  export default ReviewComponent;
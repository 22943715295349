import {useCallback, useEffect, useState, useContext} from 'react'
import {useNavigate, Link, useLocation} from 'react-router-dom'
import { Form, Row, Col, Collapse, Spinner, Dropdown} from 'react-bootstrap'
import {FaPlus, FaMinus} from 'react-icons/fa'
import {AiFillQuestionCircle} from 'react-icons/ai'
import {MDBTooltip} from 'mdbreact'
import {VscTrash} from 'react-icons/vsc'
import DashboardSearchModal from './dashboardSearchModal'
import AddContactModal from './addContactModal'
import EditContactModal from './editContactModal'
import ProposedAcknowledgementModal from '../proposedSaleReport/acknowledgementModal'
import FinalAcknowledgementModal from '../finalSaleReport/acknowledgementModal'
import Select from 'react-select'
import Card from '../ui/Card'
import Reports from '../reports'
import IssuerService from '../../services/IssuerService'
import IssuerFilerService from '../../services/IssuerFilerService'
import { toast } from 'react-toastify'
import { Typeahead } from 'react-bootstrap-typeahead'
import CommonService from '../../services/CommonService'
import ReportOfProposedDebtService from '../../services/ReportOfProposedDebtService'
import swal from 'sweetalert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import EditIssuer from './editIssuer'
import { LoggedInUserContactContext } from '../../UseContext'
import DataGrid from '../GenericComponents/DataGrid'
import { Column } from 'primereact/column'
import { useMsal } from '@azure/msal-react'

const Dashboard = (props) => {
    const { accounts, instance } = useMsal();
    const location = useLocation();
    const previousSelection = location.state?.dashboardSelection;
    const user = accounts[0];
    const loggedInUserInfo = useContext(LoggedInUserContactContext);
    const [issuer, setIssuer] = useState(previousSelection ? previousSelection.issuer :"");
    const [openIssuerAssoctions, setOpenIssuerAssociations] =useState(false);
    const [openContactDetails, setOpenContactDetails] = useState(true);
    const [openDashboard, setOpenDashboard] =useState(false);
    const [openQuickActions, setOpenQuickActions] =useState(false);
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [showDashBoardDetails, setShowDashBoardDetails] = useState(false);
    const [showAddContactModal, setShowAddContactModal] = useState(false);
    const [showEditContactModal, setShowEditContactModal] = useState(false);
    const editContactInfo = {};
    const [selectedPath, setSelectedPath] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [issuers, setIssuers] = useState(null);
    const [showAknowledgeModal, setShowAknowledgeModal] = useState(false);
    const [issuanceType, setIssuanceType] = useState(previousSelection ? previousSelection.issuanceType : 'issuance');
    const [reportType, setReportType] = useState(previousSelection ? previousSelection.reportType : null);
    const [reportStatus, setReportStatus] = useState(previousSelection ? previousSelection.reportStatus : null);
    const [showReports, setShowReports] = useState(false);
    const [issueId, setIssueId] = useState(null);
    const [errors, setErrors] = useState({});
    const [searchCriteria, setSearchCriteria] = useState(null);
    const [issuerOrgs, setIssuerOrgs] = useState([]);
    const [issuerAssocs, setIssuerAssocs] = useState([]);
    const [issuerOrgsLoading, setIssuerOrgsLoading] = useState(false);
    const [issuerAssocsLoading, setIssuerAssocsLoading] = useState(true);
    const userOrgType = loggedInUserInfo ? loggedInUserInfo.orgType : "";
    const [addNewIssuer, setAddNewIssuer] = useState(false);
    const [issuersList, setIssuersList] = useState([]);
    const [issuersListLoading, setIssuersListLoading] = useState(false);
    const [selectedNewIssuerAssoc, setSelectedNewIsuerAssoc] = useState(null);
    const [authorizedFilers, setAuthorizedFilers] = useState([]);
    const [requestedIssuerAssocs, setRequestedIssuerAssocs] = useState([]);
    const [editIssuer, setEditIssuer] = useState(false);
    const [selectedIssuerAssoc, setSelectedIssuerAssoc] = useState([]);
    const navigate = useNavigate();

    async function getIssuersOrgs(issuerId) {
      setIssuerOrgsLoading(true);
      var obj = {
          msgId: "null",
          sysId: null,
          opr: "GetIssuerOrgAssocsByIssuerId",
          hdrStruct: null,
          queryParam: null,
          reqData: {"issuerId": issuerId},
      };
  
      await IssuerService.getIssuersOrgs(obj)
      .then((res) => res)
      .then((result) => {
        if(result.data.errors && result.data.errors.length > 0){
          console.log(result.data.errors[0])
        } else {
          if(userOrgType === "I") {
            setIssuerOrgs(result.data.responses[0]);
          } else {
            setAuthorizedFilers(result.data.responses[0]);
          }
        }
        setIssuerOrgsLoading(false);
      })
    }

    async function getIssuerAssocs() {
      setIssuerAssocsLoading(true);
      var obj = {
          msgId: "null",
          sysId: null,
          opr: "getIssuerAssocs",
          hdrStruct: null,
          queryParam: null,
          reqData: {"userId": user.username},
      };
  
      await IssuerService.getIssuersOrgs(obj)
      .then((res) => res)
      .then((result) => {
        if(result.data.errors && result.data.errors.length > 0){
          alert(result.data.errors[0])
        } else {
        setIssuerAssocs(result.data.responses[0]);
        }
        setIssuerAssocsLoading(false);
      })
    }

    async function getRequestedIssuerAssocsByUserId() {
      var obj = {
          msgId: "null",
          sysId: null,
          opr: "getRequestedIssuerAssocsByUserId",
          hdrStruct: null,
          queryParam: null,
          reqData: {"userId": user.username},
      };
  
      await IssuerService.getIssuersOrgs(obj)
      .then((res) => res)
      .then((result) => {
        if(result.data.errors && result.data.errors.length > 0){
          alert(result.data.errors[0])
        } else {
        setRequestedIssuerAssocs(result.data.responses[0]);
        }
      })
    }

    async function getFilerOrgs() {
      var obj = {
          msgId: "null",
          sysId: null,
          opr: "getIssuersOrgByFilerId",
          hdrStruct: null,
          queryParam: null,
          reqData: {"userId": user.username},
      };
  
      await IssuerService.getIssuersOrgs(obj)
      .then((res) => res)
      .then((result) => {
        if(result.data.errors && result.data.errors.length > 0){
          console.log(result.data.errors[0])
        } else {
          setIssuerOrgs(result.data.responses[0]);
        }
        setIssuerOrgsLoading(false);
      })
    }

    async function getIssuers() {
      setIssuersListLoading(true);
      var obj = {
          msgId: null,
          sysId: null,
          opr: "getIssuers",
          hdrStruct: null,
          queryParam: null,
          reqData: { },
        };
    
      await IssuerFilerService.getIssuers(obj)
          .then((res) => res)
          .then((result) => {
            Object.keys(result.data.responses).forEach((key) => {
                setIssuersList(result.data.responses[key]);
            });
            setIssuersListLoading(false);
          });
    }

    async function getIssuersByUserId() {
      let obj = {
        "msgId": null,
        "sysId": null,
        "opr": "getIssuersByUserId",
        "hdrStruct": null,
        "queryParam": null,
        "reqData": {
            "userId": user.username 
        }
      };
    
      await IssuerService.getIssuersByUserId(obj)
        .then((res) => res)
        .then(async (result) => {
          if(result.data.statusCode === 401 || result.data.statusCode === 500) {
            CommonService.handleServerResponse(result.data, instance);
          } else {
            if(result.data.responses.length > 0 && result.data.responses !== null) {
              setIssuers(result.data.responses[0]);
              getIssuers();

              if(userOrgType === "F") {
                await getFilerOrgs();
              }
              else{
                await getIssuerAssocs();
                await getRequestedIssuerAssocsByUserId();
              }
            
              setIssuerOrgsLoading(false);
            }
            else{
              toast.error("No Issuers associated with the Login Id");
            }
          }
            setIsLoading(false);
      });
    }

    useEffect(()=>{
      getIssuersByUserId();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user.username]);

    const handleShowReports = useCallback(() => {
      const dashboardSelection = {
      'issuer': issuer,
      'issuanceType': issuanceType,
      'reportType': reportType,
      'reportStatus' : reportStatus
      };
      sessionStorage.setItem('dashboardSelection', JSON.stringify(dashboardSelection));
      if(reportStatus === 'new-pending') {
        setShowReports(true);
      } else {
        //setShowReports(false);
        setShowSearchModal(true);
      }
    },[issuer,issuanceType, reportType,reportStatus ])

    useEffect(()=>{
      if(previousSelection) {
        setShowDashBoardDetails(true);
        setOpenDashboard(true);
        handleShowReports();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const handleNewReportLinkClick = (path, issueId) => {
      issueId && setIssueId(issueId);
      if(path === '/dashboard/report-proposed-sale' || path === '/dashboard/report-final-sale'){
        setSelectedPath(path);
        setShowAknowledgeModal(true);
      } else {
          navigate(path);
      }
    }

    const handleChange = (e) => {
      if(e.target.value !== "") {
        setErrors({});
        setIssuer(e.target.value);
      }
    }

    const handleSubmit = (e) => {
      const issuerVal = document.getElementById('issuer').value;

      setReportType(null);
      setReportStatus(null);
      if( issuerVal !== "") {
        setShowDashBoardDetails(true);
        setOpenDashboard(true);
        setOpenQuickActions(true)
        setShowReports(false);
        getIssuersOrgs(issuerVal);
      } else {
        let errorsObj = {};
        errorsObj.issuer = "Please select Issuer.";
        setErrors(errorsObj);
        setShowDashBoardDetails(false);
      }
      
      let issuerSelect = document.getElementById("issuer");
      let issuerName = issuerSelect.options[issuerSelect.selectedIndex].text;
      const userInfo = {...user, issuerName: issuerName, issuerId: issuerVal}
      sessionStorage.setItem('user', JSON.stringify(userInfo));
    }
    

    const ReportTypes = [
      {value: 'PD', label: 'Report of Proposed Debt', type: 'issuance'},
      {value: 'FS', label: 'Report of Final Sale', type: 'issuance'},
      {value: 'DRAW', label: 'Draw on Reserve/Default/Replenishment', type: 'yearlyStatus'},
      {value: 'ADTR', label: 'Annual Debt Transparency Report',type: 'yearlyStatus'},
      {value: 'MLR', label: 'Mello Roos',type: 'yearlyStatus'},
      {value: 'MKRA', label: 'Marks Roos (Authority)',type: 'yearlyStatus'},
      {value: 'MKRL', label: 'Marks Roos (Local Obligor)', type: 'yearlyStatus'},
    ]
    
    const handleReportTypeChange = (e) => {
      setShowReports(false);
      setReportType(e.value);
      setReportStatus(null);
    }
    const handleReportStatusChange = (e) => {
      setReportStatus(e.target.value);
      setShowReports(false);
    }
    const handleIssuanceTypeChange = (e) => {
      setIssuanceType(e.target.value);
      setReportType(null);
    }

    const handleSearchCriteria = (searchContext) => {
      setSearchCriteria(searchContext);
      setShowSearchModal(false);
      setShowReports(true);
    }

    const actions = {
      'Pending' : [
          {value:'approve', label: 'Approve'},
          {value:'deny', label: 'Deny'}
      ],
      'Denied' : [
        {value:'approve', label: 'Approve'},
      ]
      
    }

    const handleActionClick = (e, action, row) => {
      const verifiedInd = (action === "approve") ? 'Y' : 'D';
      var obj = {
        msgId: "null",
        sysId: null,
        opr: "putRequestIssuer",
        hdrStruct: null,
        queryParam: null,
        reqData: { 
          "id" : row.issuerOrgAssociationId,
          "verifiedInd" : verifiedInd,
          "user" : CommonService.getUserName(user.username)
         },
        };
        
        IssuerFilerService.updateFilerIssuerAssoc(obj)
        .then((res) => res)
        .then((result) => {
            toast.success("Filer Issuer Association Updated Successfully");
            let issuerVal = document.getElementById('issuer').value;
            getIssuersOrgs(issuerVal);
        })

    }

    const createIssuerAssociation = async(assoc) => {
      // const issuerAssocsCount = issuerAssocs.length;
      // if(issuerAssocsCount >= 10) {
      //   toast.error("A maximum of 10 issuers are allowed to be associated.");
      //   return;
      // }
      
      var obj = {
        msgId: "null",
        sysId: null,
        opr: "postIssuerAssoc",
        hdrStruct: null,
        queryParam: null,
        reqData: { 
          "userEmail": user.username,
          "issuerAssoc" : {
            "linkedIssuerId" : selectedNewIssuerAssoc.issuerId,
            "verifiedFlag" : "N",
            "createUser" : CommonService.getUserName(user.username)
          } 
        },
      };
  
      await IssuerFilerService.createFilerIssuerAssoc(obj)
      .then((res) => res)
      .then((result) => {
        if (result.data.errors && result.data.errors.length > 0){
          toast.error(result.data.errors[0].message);
        } else {
            toast.success("Issuer Association Created Successfully");
            setAddNewIssuer(false);
            getIssuersByUserId();
        }
      })
    }

    const deleteIssuerAssociation = (assoc) => {
      swal({
        title: "Are you sure?",
        text: "Deleting this Associated Issuer will prevent you from submitting, editing, or viewing reports of the deleted Issuer.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          var obj = {
            msgId: "null",
            sysId: null,
            opr: "deleteLinkedIssuerAssoc",
            hdrStruct: null,
            queryParam: null,
            reqData: { 
              "issuerAssocId" : assoc.issuerAssocId
            },
          };
      
          IssuerFilerService.createFilerIssuerAssoc(obj)
          .then((res) => res)
          .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
              toast.error(result.data.errors[0].message);
            } else {
                toast.success("Issuer Association Deleted Successfully");
                setAddNewIssuer(false);
                getIssuersByUserId();
            }
          })
        }
      });
    }

    const deleteFilerAssociation = (assoc) => {
      let warningMsg = "";
      if(userOrgType === "I"){
        warningMsg = "Deleting this filer organization will prevent this organization from submitting, editing, or viewing reports on behalf of the selected issuer.";
      }else {
        warningMsg = "Deleting this Associated Issuer will prevent you from submitting, editing, or viewing reports of the deleted Issuer."
      }
      swal({
        title: "Are you sure?",
        text: warningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          var obj = {
            msgId: "null",
            sysId: null,
            opr: "deleteIssuerOrgAssoc",
            hdrStruct: null,
            queryParam: null,
            reqData: { 
              "issuerOrgAssocId" : assoc.issuerOrgAssociationId
            },
          };
      
          IssuerFilerService.createFilerIssuerAssoc(obj)
          .then((res) => res)
          .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
              toast.error(result.data.errors[0].message);
            } else {
                toast.success("Filer Association Deleted Successfully");
                getFilerOrgs();
                // let issuerVal = document.getElementById('issuer').value;
                // getIssuersOrgs(issuerVal);
            }
          })
        }
        });
    }

    const viewFormatter = (row) => { 
      if(row['issuerStatus'] !== "Approved" && userOrgType === "I"){
      return ( 
        <div style={{ textAlign: "center", lineHeight: "normal" }}>
          <div className="dropdown">
              <Dropdown>
              <Dropdown.Toggle 
              variant="secondary btn-sm" 
              id="dropdown-basic">
                  Action
              </Dropdown.Toggle>

              <Dropdown.Menu style={{backgroundColor:'#73a47'}} >
                  {actions[row['issuerStatus']].map((action, i)=> {
                      return <Dropdown.Item key={i} href="#" onClick={(e)=>handleActionClick(e, action.value, row)}>{action.label}</Dropdown.Item>
                  })}
              </Dropdown.Menu>
              </Dropdown>
          </div> 
        </div> 
      ); 
      } else {
        return ( 
          <div style={{ textAlign: "center", lineHeight: "normal" , cursor: "pointer"}}>
            <VscTrash onClick={(e) => deleteFilerAssociation(row)} />
          </div>  
        );
      }
    }

    const handleIssuerEdit = (e, row) => {
      setSelectedIssuerAssoc(row);
      setEditIssuer(row);
    }
    const handleIssuerAssocModalClose = () => {
      setEditIssuer(false);
    }
    const issuerAssocFormatter = (row) => { 
      return (
        <div style={{ lineHeight: "normal"}}> 
          <FontAwesomeIcon icon={faPencilAlt} className="custom-icon" onClick={(e)=>handleIssuerEdit(e, row)}/>{"  "} 
          <FontAwesomeIcon icon={faTrash} className="custom-icon" onClick={(e)=>deleteIssuerAssociation(row)}/>
        </div> 
      )
    }

    const viewHeaderFormatter = (row) => {
      return (<>{row.text}
      {userOrgType === "I" && <MDBTooltip domElement tag="span" placement="right" >
          <span>{"  "}<AiFillQuestionCircle className="help-icon heading-help-icon"/></span>
          <span>Organizations that have requested or been approved to submit, edit, or view reports on behalf of the selected issuer.</span>
      </MDBTooltip>
      }
      </>);
    }

    const columns = [
      {
      dataField: 'issuerName',
      text: userOrgType === "I" ? 'Filer Organization' : 'Issuer Name',
      sort: true,
      headerFormatter: viewHeaderFormatter
    },{
      dataField: 'issuerStatus',
      text: 'Status',
      sort: true
    },{ 
        dataField: "",
        text: "Action", 
        formatter: viewFormatter,
    }
    ];

    const authorizedFilersColumns = [
      {
      dataField: 'issuerName',
      text: 'Filer Organization',
      sort: true,
      headerFormatter: viewHeaderFormatter
    },{
      dataField: 'issuerStatus',
      text: 'Status',
      sort: true
    }
    ];

    const issuerAssocColumns = [
      {
      dataField: 'issuerName',
      text: 'Issuer Name',
      sort: true
    },{
      dataField: 'createUser',
      text: 'Create User',
      sort: true
    },{
      dataField: 'verifiedFlag',
      text: 'Status',
      sort: true,
    },
    { 
        dataField: "",
        text: "Actions", 
        formatter: issuerAssocFormatter,
    }
    ];

    const handleIssuerAssocActionClick = (e, action, row) => {
      const verifiedInd = (action === "approve") ? 'Y' : 'D';
      var obj = {
        msgId: "null",
        sysId: null,
        opr: "updateLinkedIssuerAssoc",
        hdrStruct: null,
        queryParam: null,
        reqData: { 
          "issuerAssocId" : row.issuerAssocId,
          "verifiedFlag" : verifiedInd,
         },
        };
        
        IssuerFilerService.updateFilerIssuerAssoc(obj)
        .then((res) => res)
        .then((result) => {
            toast.success("Issuer-Issuer Association Updated Successfully");
            let issuerVal = document.getElementById('issuer').value;
            getIssuersOrgs(issuerVal);
            getRequestedIssuerAssocsByUserId();
        })

    }
    const requestedIssuerActionsFormatter = (row) => { 
      if(row['verifiedFlag'] !== "Approved"){
      return ( 
        <div style={{ textAlign: "center", lineHeight: "normal" }}>
          <div className="dropdown">
              <Dropdown>
              <Dropdown.Toggle 
              variant="secondary btn-sm" 
              id="dropdown-basic">
                  Action
              </Dropdown.Toggle>

              <Dropdown.Menu style={{backgroundColor:'#73a47'}} >
                  {actions[row['verifiedFlag']].map((action, i)=> {
                      return <Dropdown.Item key={i} href="#" onClick={(e)=>handleIssuerAssocActionClick(e, action.value, row)}>{action.label}</Dropdown.Item>
                  })}
              </Dropdown.Menu>
              </Dropdown>
          </div> 
        </div> 
      ); 
      } else {
        return ( 
          <div style={{ textAlign: "center", lineHeight: "normal" , cursor: "pointer"}}>
            <VscTrash onClick={(e) => deleteIssuerAssociation(row)} />
          </div>  
        );
      }
    }
    const requestedIssuerAssocColumns = [
      {
      dataField: 'issuerName',
      text: 'Issuer Name',
      sort: true
    },{
      dataField: 'verifiedFlag',
      text: 'Status',
      sort: true,
    },
    { 
        dataField: "",
        text: "Action", 
        formatter: requestedIssuerActionsFormatter,
    }
    ];

  const handleFilerIssuerAssocChange = (e) => {
     if(e.length > 0) {
       setSelectedNewIsuerAssoc(e[0]);
     }
  }
  const saveIssuer = async () => {
    var obj = {
        msgId: "null",
        sysId: null,
        opr: "addIssuerOrgAssoc",
        hdrStruct: null,
        queryParam: null,
        reqData: { 
            "issuerOrgAssoc" : {
                "issuerId": selectedNewIssuerAssoc.issuerId,
                "issuerName": selectedNewIssuerAssoc.issuerName,
                "orgId" : loggedInUserInfo.orgId,
                "orgName" : loggedInUserInfo.orgName,
                "verifiedInd" : "N",
                "createUser" : user.username,
                "lastUpdateUser" : user.username
            }
         },
      };
    await IssuerFilerService.createFilerIssuerAssoc(obj)
    .then((res) => res)
    .then((result) => {
        toast.success("Filer Issuer Association Added Successfully and Email Notification has been sent to the Issuer.");
        setAddNewIssuer(false);
        getFilerOrgs();
        getIssuers();
    })
  }

  const createNewReport = (e) => {
    var obj ={
        "msgId": null,
        "sysId": null,
        "opr": "postProposeDebt",
        "hdrStruct": null,
        "queryParam": null,
        "reqData": {"proposeDebt": {
                  "issuerId": issuer,
                  "cdiacNbr": null,
                  "cdiacNbrCreateDate": null,
                  "issueName": null,
                  "issueStatusFlag": "D",
                  "projectName": null,
                  "proposedSaleDate": null,
                  "proposedPrincipalSaleAmt": null,
                  "validationActionFlag": null,
                  "debtRepayNonUsCurrFlag": null,
                  "refndRedeemPaydnRefinInd": null,
                  "refndRedeemPaydnRefinAmt": null,
                  "stateTaxInd": null,
                  "federalTaxFlag": null,
                  "federalTaxPrincipalAmt": null,
                  "saleTypeCode": null,
                  "pvtPlacementFinanceInd": null,
                  "debtPolicyFlag": null,
                  "debtInstrumentCode": null,
                  "debtInstrumentOtherText": null,
                  "principalSaleAmt": null,
                  "debtlineMonyy": "",
                  "filingDate": new Date(),
                  "createUser": CommonService.getUserName(user.username),
                  "lastUpdateUser": CommonService.getUserName(user.username),
                  "cdiacComment": null,
              }}
      } ;
  
      ReportOfProposedDebtService.createNewReport(obj)
      .then((res) => res)
      .then(async (result) => {
          const proposedDebtId = result.data.responses[0];
          await saveFilingContact(proposedDebtId, function(){
            handleNewReportLinkClick('/dashboard/report-proposed-sale', proposedDebtId )
          });
      });
    }
  
  const createNewDraw = (e) => {
    setShowReports(false);
    setReportType('FS');
    setReportStatus('view-previous')
    setShowSearchModal(true);
  }
  const saveFilingContact = (proposeDebtId, callback) => {
      var obj ={
          "msgId": null,
          "sysId": null,
          "opr": "postIssueFilingContactAssoc",
          "hdrStruct": null,
          "queryParam": null,
          "reqData": {
              "issueContactAssoc": {
                  "finalSaleId": null,
                  "proposeDebtId": proposeDebtId,
                  "userId" : user.username,
                  "roleCode" : 'FC',
                  "feeAmt": null,
                  "createUser": CommonService.getUserName(user.username),
                  "lastUpdateUser": CommonService.getUserName(user.username),
              }
          }
        } ;
    
      ReportOfProposedDebtService.saveFilingContact(obj)
      .then((res) => res)
      .then(async (result) => {
          if (result.data.errors && result.data.errors.length > 0){
              toast.error(result.data.errors[0].message)
          }
          else{
              callback();
          }
      });
  }

    return (
          <Card>
            <form className="form">
              <div>
              <button className="custom-button" style={{float: 'right', marginBottom:'0.5rem'}}><a href="/assets/referenceGuides/Quick Ref Guide-Dashboard.pdf" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: 'white'}}>Quick Reference Guide</a></button>{" "}
              </div>
              <Form.Label><span className="required">*</span>Issuer:
              <MDBTooltip domElement tag="span" placement="right">
                  <span><AiFillQuestionCircle className="help-icon"/></span>
                  <span>Select the Issuer for which you are authorized to submit, edit, and view data and reports, then select the Submit button. If the Issuer is not listed, select Add Issuer from Issuer Associations, below.</span>
              </MDBTooltip>
              </Form.Label>
              {isLoading && <Spinner animation="border" variant="primary" />}
              {issuers && <Form.Control as="select" onChange={(e)=>handleChange(e)} id="issuer" name="issuer" defaultValue={issuer}>
                <option value="">Select</option>
                {issuers.map((issuer, i)=>{
                  return  <option key={i} value={issuer.issuerId}>{issuer.issuerName}</option>
                })}
              </Form.Control>
              }
              {errors.issuer && <p className="error">{errors.issuer}</p>}

              <div className="btn-div">
              <button type="button" onClick={()=>handleSubmit()} className="custom-button">Submit</button>
              </div>
            </form>

             {/* Dashboard */}
             {/* className="panel-heading" */}
             { showDashBoardDetails &&
             <>
             {/* Quick Actions */}
             <div className="panel panel-default">
                <div className="panel-heading" role="tab">
                <h5 className="panel-title icon-style" onClick={()=>setOpenQuickActions(!openQuickActions)}>
                <button
                  type="button"
                  className="link-button" 
                  style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
                aria-expanded={openQuickActions} aria-controls="quick_actions" > {!openQuickActions ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Quick Actions</button> 
                </h5>
                </div>
                  
                <Collapse in={openQuickActions}>
                <div id="quick_actions">
                  <Card>
                    <div className="row form">
                      <div className="col-6">
                        <button className="custom-button text-light rounded" onClick={()=>createNewReport()}  >
                            New Report of Proposed Debt Issuance
                        </button>
                        <MDBTooltip domElement tag="span" placement="right">
                            <span><AiFillQuestionCircle className="help-icon"/></span>
                            <span>Click to start a new Report of Debt Issuance for the Issuer selected above.</span>
                        </MDBTooltip>
                      </div>
                      <div className="col-6">
                        <button className="custom-button text-light rounded" onClick={()=>createNewDraw()}  >
                        Draw on Reserve/Default/Replenishment
                        </button>
                        <MDBTooltip domElement tag="span" placement="right">
                            <span><AiFillQuestionCircle className="help-icon"/></span>
                            <span>Click to start a new Draw on Reserve/Default/Replenishment for the Issuer selected above.</span>
                        </MDBTooltip>
                      </div>

                      <div className="col-6">
                        <Link to="/status-history-upload-documents"><button className="custom-button">Quick Retrievals</button></Link>
                        <MDBTooltip domElement tag="span" placement="right">
                            <span><AiFillQuestionCircle className="help-icon"/></span>
                            <span>Retrieves a record of report filings for the debt issue by CDIAC # including PDFs of each submitted report.</span>
                        </MDBTooltip>
                      </div>
                    </div>
                  </Card>
                </div>
                </Collapse>
              </div> 
              {/* Dashboard */}
              <div className="panel panel-default">
                <div className="panel-heading" role="tab">
                <h5 className="panel-title icon-style" onClick={()=>setOpenDashboard(!openDashboard)}>
                <button
                  type="button"
                  className="link-button" 
                  style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
                aria-expanded={openDashboard} aria-controls="dashboard" > {!openDashboard ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Dashboard</button> 
                </h5>
                </div>
                  
                <Collapse in={openDashboard}>
                <div id="dashboard">
                  <Card>
                    <div className="form">
                      <form>
                      <div style={{display: 'inline-flex'}}>
                        <h3>Filters</h3>
                        <MDBTooltip domElement tag="span" placement="right">
                            <span>&nbsp;<AiFillQuestionCircle className="help-icon"/></span>
                            <span>Choose from the filters below to build the dashboard for the Issuer selected. Select the GO button after each of the three filter selections have been made.</span>
                        </MDBTooltip>
                      </div>
                      <Row>
                        <Col md={6}>
                        <Form.Check inline type="radio" name="issuanceType" id="issuance" value="issuance" label="Issuance" onChange={(e)=>handleIssuanceTypeChange(e)} checked={issuanceType === 'issuance'}/><MDBTooltip domElement tag="span" placement="right">
                            <span><AiFillQuestionCircle className="help-icon"/></span>
                            <span>Select Issuance to filter the dashboard to display Reports of Proposed Debt and Reports of Final Sale for the issuer selected above.</span>
                        </MDBTooltip>
                        </Col>
                        <Col md={6}>
                        <Form.Check inline type="radio" name="issuanceType" id="yearlyStatus" value="yearlyStatus" label="Ongoing/Annual Reports" onChange={(e)=>handleIssuanceTypeChange(e)} checked={issuanceType === 'yearlyStatus'}/><MDBTooltip domElement tag="span" placement="right">
                            <span><AiFillQuestionCircle className="help-icon"/></span>
                            <span>Select Ongoing/Annual Reports to filter the dashboard to display Annual Debt Transparency, Mello- and Marks-Roos Yearly Fiscal Status, and Draw on Reserve/Default/Replenishment for the issuer selected above.</span>
                        </MDBTooltip>
                        </Col>
                      </Row>
                      <Row>
                        <label><span className="required" >*</span>Select Type of Report: <MDBTooltip domElement tag="span" placement="right">
                            <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                            <span>Select Type of Report</span>
                        </MDBTooltip>
                        </label>
                        <Select 
                          options={ReportTypes.filter(
                            option => option.type === issuanceType
                          )} 
                          onChange={(e)=>handleReportTypeChange(e)}
                          value={ReportTypes.filter(
                            option => option.value === reportType
                          )}/>
                      </Row>
                      <Row>
                        <Col md={6}>
                        <Form.Check inline type="radio" name="reportStatus" id="new" value="new-pending" label="New and Pending Reports" onChange={(e)=>handleReportStatusChange(e)} checked={reportStatus === 'new-pending'}/>
                        <MDBTooltip domElement tag="span" placement="right">
                            <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                            <span>Select New and Pending Reports to cause the dashboard to display issues with in-progress or pending reporting requirements for the issuer selected above.</span>
                        </MDBTooltip>
                        </Col>
                        <Col md={6}>
                        <Form.Check inline type="radio" name="reportStatus" id="view-previous" value="view-previous" label="View Previous Reports" onChange={(e)=>handleReportStatusChange(e)} checked={reportStatus === 'view-previous'} disabled={reportType === 'PD'}/>
                        <MDBTooltip domElement tag="span" placement="right">
                            <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                            <span>Select View Previous Reports to cause the dashboard to display reports for the selected issuer that have been previously submitted and reviewed by CDIAC.</span>
                        </MDBTooltip>
                        </Col>
                      </Row>
                      <div style={{overflow: 'auto', margin: '10px'}}>
                      <button className="fright custom-button" type="button" onClick={()=>handleShowReports()}>GO</button>
                      </div>
                      </form>
                      {showReports && <Reports reportType={reportType} reportStatus={reportStatus} searchCriteria={searchCriteria} newReportLink={handleNewReportLinkClick} issuerId={issuer}/>}
                      </div>
                  </Card>
                </div>
                </Collapse>
              </div> 
              </>           
             }
            {/* Issuer / filer associations  */}
            <div className="panel panel-default">
              <div className="panel-heading" role="tab"> 
              <h5 className="panel-title icon-style" onClick={()=>setOpenContactDetails(!openContactDetails)}>
              <button
                type="button"
                className="link-button" 
                style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
                aria-expanded={openContactDetails} aria-controls="contact_details" > {!openContactDetails ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; {userOrgType === "F" ? "Associated Issuers (On this account)" : "Authorized Filers (For selected Issuer)" } </button> 
              </h5>
              </div>
                   
              <Collapse in={openContactDetails}>
              <div id="contact_details">
                <Card>
                  <div className="form">
                    {issuerOrgsLoading && <Spinner animation="border" variant="primary" />}
                    <DataGrid
                      dataSource={issuerOrgs}
                      sortField='issuerName'
                      emptyDataMessage='No data available to display'
                      showPaginator={false}
                      showQuickSearch={false}
                      >
                      {columns.map((column, i) => {
                          return (
                          <Column 
                          key={i}
                          field={column.dataField}
                          header={column.text}
                          sortable={column.sort} 
                          body={column.formatter}
                          hidden={column.hidden}
                          />)
                          }) 
                      } 
                    </DataGrid>
                  
                  {userOrgType === "F" &&
                  <>
                    <br />
                    <div>
                    <button type="button" className="custom-button" onClick={()=>setAddNewIssuer(true)}>Add Issuer</button>
                    </div>
                    {addNewIssuer && 
                    <div>
                      <Row>
                        <Col md={6}>
                            <Typeahead id="addIssuer" 
                            placeholder="Choose Issuer.....Type name to search"
                            labelKey="issuerName"
                            options={issuersList} isLoading={issuersListLoading}
                            onChange={(e)=>handleFilerIssuerAssocChange(e)}/>
                        </Col>
                        <Col>
                            <button className="custom-button" type="button" onClick={()=>saveIssuer()}>Save</button>{" "}
                        </Col>
                      </Row>
                    </div>
                    }
                  </>
                  }
                </div>
                </Card>

              </div>
              </Collapse>
            </div>

            {/* Authorized filer associations  */}
            {userOrgType === "F" && 
            <div className="panel panel-default">
              <div className="panel-heading" role="tab"> 
              <h5 className="panel-title icon-style" onClick={()=>setOpenContactDetails(!openContactDetails)}>
              <button
                type="button"
                className="link-button" 
                style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
                aria-expanded={openContactDetails} aria-controls="contact_details" > {!openContactDetails ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Authorized Filers (For selected Issuer) </button> 
              </h5>
              </div>
                   
              <Collapse in={openContactDetails}>
              <div id="contact_details">
                <Card>
                  <div className="form">
                    {issuerOrgsLoading && <Spinner animation="border" variant="primary" />}
                    <DataGrid
                      dataSource={authorizedFilers}
                      sortField='issuerName'
                      emptyDataMessage='No data available to display'
                      showPaginator={false}
                      showQuickSearch={false}
                      >
                      {authorizedFilersColumns.map((column, i) => {
                          return (
                          <Column 
                          key={i}
                          field={column.dataField}
                          header={column.text}
                          sortable={column.sort} 
                          body={column.formatter}
                          hidden={column.hidden}
                          />)
                          }) 
                      } 
                    </DataGrid>
                </div>
                </Card>
              </div>
              </Collapse>
            </div>
            }

            {/* Issuer Associations  */}
            {userOrgType === "I" &&
            <div className="panel panel-default">
              <div className="panel-heading" role="tab"> 
              <h5 className="panel-title icon-style" onClick={()=>setOpenIssuerAssociations(!openIssuerAssoctions)}>
              <button
                type="button"
                className="link-button" 
                style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
                aria-expanded={openIssuerAssoctions} aria-controls="contact_details" > {!openIssuerAssoctions ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Associated Issuers (On this account) </button> 
              </h5>
              </div>
                   
              <Collapse in={openIssuerAssoctions}>
              <div id="contact_details">
                <Card>
                  <div className="form">
                    {issuerAssocsLoading && <Spinner animation="border" variant="primary" />}
                    <DataGrid
                      dataSource={issuerAssocs}
                      sortField='linkedIssuerId'
                      emptyDataMessage='No data available to display'
                      showPaginator={false}
                      showQuickSearch={false}
                      >
                      {issuerAssocColumns.map((column, i) => {
                          return (
                          <Column 
                          key={i}
                          field={column.dataField}
                          header={column.text}
                          sortable={column.sort} 
                          body={column.formatter}
                          hidden={column.hidden}
                          />)
                          }) 
                      } 
                      </DataGrid>
                    <br />
                    <div>
                    <button type="button" className="custom-button" onClick={()=>setAddNewIssuer(true)}>Add Issuer</button>
                    <MDBTooltip domElement tag="span" placement="right">
                          <span>{" "}<AiFillQuestionCircle className="help-icon"/></span>
                          <span>Select Add Issuer to add yourself as a representative of an Issuer (not shown above) and obtain full Issuer permissions to submit, edit, or view reports of the selected issuer.</span>
                      </MDBTooltip>
                    </div>
                    {addNewIssuer && 
                    <div>
                      <Row>
                        <Col md={6}>
                            <Typeahead id="addIssuer" 
                            placeholder="Choose Issuer.....Type name to search"
                            labelKey="issuerName"
                            options={issuersList} isLoading={issuersListLoading}
                            onChange={(e)=>handleFilerIssuerAssocChange(e)}/>
                        </Col>
                        <Col>
                            <button className="custom-button" type="button" onClick={()=>createIssuerAssociation()}>Save</button>{" "}
                        </Col>
                      </Row>
                    </div>
                    }
                    <br />
                    {/* Following Issuers have requested to be associated with you: */}
                    <div>
                      <h4>Following Issuers have requested to be associated with you:</h4>
                      <DataGrid
                      dataSource={requestedIssuerAssocs}
                      sortField='issuerId'
                      emptyDataMessage='No data available to display'
                      showPaginator={false}
                      showQuickSearch={false}
                      >
                      {requestedIssuerAssocColumns.map((column, i) => {
                          return (
                          <Column 
                          key={i}
                          field={column.dataField}
                          header={column.text}
                          sortable={column.sort} 
                          body={column.formatter}
                          hidden={column.hidden}
                          />)
                          }) 
                      } 
                      </DataGrid>
                    </div>
                </div>
                </Card>

              </div>
              </Collapse>
            </div> 
            }          

          {showSearchModal && <DashboardSearchModal onModalClose={()=>setShowSearchModal(false)} handleSearch={handleSearchCriteria}/>}
          {showAddContactModal && <AddContactModal onModalClose={()=>setShowAddContactModal(false)}/>}
          {showEditContactModal && <EditContactModal onModalClose={()=>setShowEditContactModal(false)} contactInfo={editContactInfo}/>}          
          {showAknowledgeModal &&  (selectedPath === "/dashboard/report-proposed-sale" ) && <ProposedAcknowledgementModal issueId={issueId} onModalClose={()=>setShowAknowledgeModal(false)}/>}
          {showAknowledgeModal &&  (selectedPath === "/dashboard/report-final-sale" ) && <FinalAcknowledgementModal onModalClose={()=>setShowAknowledgeModal(false)}/>}
          {editIssuer && <EditIssuer onModalClose={() => handleIssuerAssocModalClose()} issuerId={selectedIssuerAssoc.linkedIssuerId} />}

          </Card>
       
        
    )
}

export default Dashboard

import { useEffect, useState, useContext, useRef } from 'react';
import {Button, Dropdown, SplitButton} from 'react-bootstrap';
import CommonService from '../../services/CommonService';
import { UserContext } from '../../UseContext';
import { CSVLink } from 'react-csv';
import { toast } from 'react-toastify';
import BaseService from '../../services/BaseService';
import Loader from '../../common/Loader';
import DataGrid from '../GenericComponents/DataGrid';
import { Column } from 'primereact/column';

const AdtrList = (props) => {
    const loginUserId = useContext(UserContext)
    const [adtrs, setAdtrs] = useState([]);
    //const [filteredAdtrs, setFilteredAdtrs] = useState([]);
    const [selected, setSelected] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [csvData, setCSVData] = useState([]);
    const [download, setDownload] = useState(false);
    const [adtrBatchDownloadType, setAdtrBatchDownloadType] = useState(null);
    const authCsvDownload = useRef(null);
    const proceedCsvDownload = useRef(null);
    //const table = useRef(null);
    useEffect(() => {
        getAdtrWorkLoad();
    // eslint-disable-next-line
    }, [props.issuerId]);

    useEffect(() => {
      if(download){
        setDownload(false);
        if (adtrBatchDownloadType === "auth") {
          authCsvDownload.current.link.click();
        } else if(adtrBatchDownloadType === "proceed") {
          proceedCsvDownload.current.link.click();
        } 
      }
      // eslint-disable-next-line
    },[csvData]);

    const getAdtrWorkLoad = () => {
        setIsLoading(true);
        setAdtrs([]);
        let obj = {
                "msgId": null,
                "sysId": null,
                "opr": "getAdtrs",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "issuerId" : props.issuerId,
                }
            }
        BaseService.processRequest(obj)
        .then((res) => res)
        .then((result) => {
            setIsLoading(false);
            setAdtrs(result.data.responses[0]);
           // setFilteredAdtrs(result.data.responses[0]);
        });
    }
   
    const columns = [{
        dataField: 'cdiacNbr',
        text: 'CDIAC #',
        sort: true
      }, 
      {
        dataField: 'fiscalYrEndDate',
        text: 'Fiscal Yr End Date',
        sort: true,
        formatter: (row) => CommonService.dateFormatter(row['fiscalYrEndDate'])
      },
      {
        dataField: 'issueStatus',
        text: 'Status',
        sort: true
      },{
        dataField: 'issuer',
        text: 'Issuer Name',
        sort: true
      },{
        dataField: 'issueName',
        text: 'Issue Name',
        sort: true
      }, {
        dataField: 'projectName',
        text: 'Project Name',
        sort: true
      }, {
        dataField: 'actualSaleDate',
        text: 'Actual Sale Date',
        sort: true,
        formatter: (row) => CommonService.dateFormatter(row['actualSaleDate'])
      },{
        dataField: 'principalSaleAmt',
        text: 'Principal Amount',
        sort: true,
        formatter: (row) => CommonService.currencyFormatter(row['principalSaleAmt'])
      }
    ];

    const handleDownload = (e) => {
      setDownload(true);
      const downloadType = e.target.dataset.type;
      setAdtrBatchDownloadType(downloadType);
      let functionName = "";
      if (downloadType === "auth") {
        functionName = "getAdtrAuthBatch";
      } else if(downloadType === "proceed") {
        functionName = "getAdtrProceedBatch";
      } 
      setIsLoading(true);
      let obj = {
        "msgId": null,
        "sysId": null,
        "opr": functionName,
        "hdrStruct": null,
        "queryParam": null,
        "reqData": {
            "userId" : loginUserId,
            "adtrBatch" : selected
        }
      }
      
      BaseService.processRequest(obj)
      .then((res) => res)
      .then((result) => {
          if (result.data.errors && result.data.errors.length > 0){
            toast.error(result.data.errors[0].message);
          }
          else{
            setCSVData(result.data.responses[0])
          } 
          setIsLoading(false);         
      });
    }

   const authHeaders = [
      { label: "CDIAC Number", key: "cdiacNbr" },
      { label: "Fiscal Year End Date", key: "fiscalYrEndDate" },
      { label: "Issuer Name", key: "issuer" },
      { label: "Issue Name", key: "issueName" },
      { label: "Project Name", key: "projectName" },
      { label: "Original Principal Amount", key: "principalSaleAmt" },
      { label: "Net Original Issue Premium/Discount", key: "issuePremiumDiscount" },
      { label: "Proceeds Used to Acquire Local Obligations", key: "marksRoosProceedAmt" },
      { label: "Principal Balance Upon Sale or at Beginning of the Reporting Period", key: "rptPeriodBeginBalAmt" },
      { label: "ACCRETED INTEREST – DURING REPORTING PERIOD", key: "accretedInterestAmt" },
      { label: "PRINCIPAL PAID WITH PROCEEDS FROM OTHER DEBT ISSUES - DURING THE REPORTING PERIOD", key: "principalOtherPaidAmt" },
      { label: "PRINCIPAL PAYMENTS - DURING THE REPORTING PERIOD (NOT REPORTED AS PAYMENTS ABOVE)", key: "principalRedeemAmt" },
      { label: "Authorization Name 1", key: "authName1" },
      { label: "Authorization Date 1", key: "authDate1" },
      { label: "Amount Authorized - Beginning of the Reporting Period 1", key: "authBeginAmt1" },
      { label: "AMOUNT AUTHORIZED - DURING REPORTING PERIOD 1", key: "authDuringAmt1" },
      { label: "Debt Issued During the Reporting Period 1", key: "authorityReducedAmt1" },
      { label: "Replenishment Reported During the Reporting Period 1", key: "authorityReplenishAmt1" },
      { label: "AUTHORIZATION LAPSED 1", key: "lapsedAmt1" },
      { label: "Authorization Name 2", key: "authName2" },
      { label: "Authorization Date 2", key: "authDate2" },
      { label: "Amount Authorized - Beginning of the Reporting Period 2", key: "authBeginAmt2" },
      { label: "AMOUNT AUTHORIZED - DURING REPORTING PERIOD 2", key: "authDuringAmt2" },
      { label: "Debt Issued During the Reporting Period 2", key: "authorityReducedAmt2" },
      { label: "Replenishment Reported During the Reporting Period 2", key: "authorityReplenishAmt2" },
      { label: "AUTHORIZATION LAPSED 2", key: "lapsedAmt2" },
      { label: "Authorization Name 3", key: "authName3" },
      { label: "Authorization Date 3", key: "authDate3" },
      { label: "Amount Authorized - Beginning of the Reporting Period 3", key: "authBeginAmt3" },
      { label: "AMOUNT AUTHORIZED - DURING REPORTING PERIOD 3", key: "authDuringAmt3" },
      { label: "Debt Issued During the Reporting Period 3", key: "authorityReducedAmt3" },
      { label: "Replenishment Reported During the Reporting Period 3", key: "authorityReplenishAmt3" },
      { label: "AUTHORIZATION LAPSED 3", key: "lapsedAmt3" },
      { label: "ISSUER COMMENTS", key: "issuerComments" }
    ];
   const proceedHeaders = [
      { label: "CDIAC Number", key: "cdiacNbr" },
      { label: "Fiscal Year End Date", key: "fiscalYrEndDate" },
      { label: "Issuer Name", key: "issuerName" },
      { label: "Issue Name", key: "issueName" },
      { label: "Project Name", key: "projectName" },
      { label: "Fund Category", key: "fundCategoryText" },
      { label: "Total Reportable Proceeds Available", key: "proceedAvlAmt" },
      { label: "PURPOSE 1", key: "purposeTypeDesc1" },
      { label: "PROCEEDS SPENT CURRENT REPORTING PERIOD 1", key: "spentAmt1" },
      { label: "PURPOSE 2", key: "purposeTypeDesc2" },
      { label: "PROCEEDS SPENT CURRENT REPORTING PERIOD 2", key: "spentAmt2" },
      { label: "PURPOSE 3", key: "purposeTypeDesc3" },
      { label: "PROCEEDS SPENT CURRENT REPORTING PERIOD 3", key: "spentAmt3" },
      { label: "PURPOSE 4", key: "purposeTypeDesc4" },
      { label: "PROCEEDS SPENT CURRENT REPORTING PERIOD 4", key: "spentAmt4" },
      { label: "PURPOSE 5", key: "purposeTypeDesc5" },
      { label: "PROCEEDS SPENT CURRENT REPORTING PERIOD 5", key: "spentAmt5" },
      { label: "PURPOSE 6", key: "purposeTypeDesc6" },
      { label: "PROCEEDS SPENT CURRENT REPORTING PERIOD 6", key: "spentAmt6" },
      { label: "PURPOSE 7", key: "purposeTypeDesc7" },
      { label: "PROCEEDS SPENT CURRENT REPORTING PERIOD 7", key: "spentAmt7" },
      { label: "PURPOSE 8", key: "purposeTypeDesc8" },
      { label: "PROCEEDS SPENT CURRENT REPORTING PERIOD 8", key: "spentAmt8" },
      { label: "PURPOSE 9", key: "purposeTypeDesc9" },
      { label: "PROCEEDS SPENT CURRENT REPORTING PERIOD 9", key: "spentAmt9" },
      { label: "PURPOSE 10", key: "purposeTypeDesc10" },
      { label: "PROCEEDS SPENT CURRENT REPORTING PERIOD 10", key: "spentAmt10" },
     
    ];
    return (
        <div className="form">
            {isLoading && <Loader /> }
            <DataGrid
            title="Annual Debt Transparency Reports"
            dataSource={adtrs}
            sortField='issuerId'
            emptyDataMessage='There is no data to display'
            searchToolTip="Use the search feature to find a specific issue within the dashboard. Click on the dashboard column headings to sort."
            selectionMode='checkbox'
            selection={selected}
            onSelectionChange={(e)=>{ setSelected(e.value) }}
            addActionBtn="true"
            actionBtnContent={
              <div className="fright p-0">
                <SplitButton id="download" title="Download" disabled={selected.length > 0 ? false : true}>
                  <Dropdown.Item eventKey="1" data-type="auth" onClick={(e)=>handleDownload(e)}>
                    CDIAC # for Outstanding Principal and Authorizations
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="2" data-type="proceed" onClick={(e)=>handleDownload(e)}>
                    CDIAC # for Proceeds and Expenditures
                  </Dropdown.Item>
                </SplitButton>
              </div>
            }
            >
            <Column selectionMode='multiple' headerStyle={{"width": "3rem"}} />
            {columns.map((column, i) => {
                return (
                <Column 
                key={i}
                field={column.dataField}
                header={column.text}
                sortable={column.sort} 
                body={column.formatter}
                hidden={column.hidden}
                />)
                }) 
            } 
            </DataGrid>

            {/* 
                search ={
                  {
                    afterSearch: (newResult) => {
                      setFilteredAdtrs(newResult)
                    }
                  }
                }
                >
                        <div className="fright">
                          <SplitButton id="download" title="Download" disabled={selected.length > 0 ? false : true}>
                            <Dropdown.Item eventKey="1" data-type="auth" onClick={(e)=>handleDownload(e)}>
                              CDIAC # for Outstanding Principal and Authorizations
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="2" data-type="proceed" onClick={(e)=>handleDownload(e)}>
                              CDIAC # for Proceeds and Expenditures
                            </Dropdown.Item>
                          </SplitButton>

                        selectRow={selectRow}
                        />
                        </div>
                    </div>
                    )
                }
            </ToolkitProvider> */}
            <Button type="button" style={{visibility : 'hidden'}}><CSVLink ref={authCsvDownload} data={csvData} headers={authHeaders} filename="ADTR_Batch_Auth.csv">Download CSV</CSVLink></Button> 
            <Button type="button" style={{visibility : 'hidden'}}><CSVLink ref={proceedCsvDownload} data={csvData} headers={proceedHeaders} filename="ADTR_Batch_Proceed.csv">Download CSV</CSVLink></Button> 
        </div>
    )
}

export default AdtrList

import { useEffect } from 'react'
import Card from '../ui/Card'
import {Form, Row, Col, Modal, Spinner} from 'react-bootstrap'
import { useState } from 'react'
import classes from './IssuanceAuthorization.module.css'
import {AiFillQuestionCircle} from 'react-icons/ai'
import { MDBTooltip } from 'mdbreact'
import {FaPencilAlt} from 'react-icons/fa'
import {VscTrash} from 'react-icons/vsc'
import AddIssuanceAuthorization from './addIssuanceAuthorization'
import EditIssuanceAuthorization from './editIssuanceAuthorization'
import IssueAuthorizationService from '../../services/IssueAuthorizationService'
import CommonService from '../../services/CommonService'
import { useMsal } from '@azure/msal-react'

const IssuanceAuthorization = (props) => {
    const {instance} = useMsal();
    const {issueId} = props;
    const [openAddModal, setOpenAddModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [editAuthorizationInfo, setEditAuthorizationInfo] = useState(null);
    const [showInstructionsModal, setShowInstructionsModal] = useState(false);
    const [showHelpTextModal, setShowHelpTextModal] = useState(false);
    const [authorizations, setAuthorizations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(()=>{
        getIssueAuthorizations(issueId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[issueId]);

    const getIssueAuthorizations = async (issueId) => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getIssueAuthorizationsById",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": { "id" : issueId, "reportType" : "RPD" }
        };
       await IssueAuthorizationService.getAuthorizationsByIssueId(obj)
          .then((res) => res)
          .then((result) => {
            setAuthorizations(result.data.responses[0]);
            props.updateProposeDebtDependencies(result.data.responses[0], 'issueAuthorizations');
            setIsLoading(false);
          });
    }
    const deleteIssueAuthorization = async (authorizationId) => {
        var obj = {
            "msgId": null,
            "sysId": null,
            "opr": "deleteIssueAuthorization",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": { "id" : authorizationId }
        };
       await IssueAuthorizationService.deleteAuthorization(obj)
          .then((res) => res)
          .then((result) => {
            setIsLoading(true);
            if(result.data.statusCode === 401 || result.data.statusCode === 500) {
                CommonService.handleServerResponse(result.data, instance);
            } else {
                getIssueAuthorizations(issueId);
            }
          });
    }

    const addAuthorization = () => {
        setOpenAddModal(true);
        setEditAuthorizationInfo(null);
    }
    const handleModalClose = () => {
        setOpenAddModal(false);
        setTimeout(() => {
        getIssueAuthorizations(issueId);
    }, 1000);
    }
    const handleEditModalClose = () => {
        setIsLoading(true);
        setOpenEditModal(false);
        setTimeout(() => {
        getIssueAuthorizations(issueId);
        }, 1000);
    }
 
    const editAutorization=(i)=> {
        let authorizationsArr = [...authorizations];
        setEditAuthorizationInfo(authorizationsArr[i]);
        setOpenEditModal(true);
    }

    const deleteAuthorization = (i) => {
        deleteIssueAuthorization(i);
    }

    const validateForm = (e) => {
        if(authorizations.length !== 0) {
            props.sectionValidated('issuanceAuthorization', true);
            props.openWidget('statutories');
        } else {
            props.sectionValidated('issuanceAuthorization', false);
            alert("Please add at least one issuance authorization.");
        }           
    }    

    return (
        <Card>
            <Form className="form" id="issuanceAuthorizationForm">
            <h3>Issuance Authorization
                {' '}<AiFillQuestionCircle onClick={()=>setShowInstructionsModal(true)} className="help-icon heading-help-icon"/>

                {showInstructionsModal && 
                <Modal show={true} backdrop="static" onHide={()=>setShowInstructionsModal(false)}>
                    <Modal.Body>
                        <div>
                            <ul style={{listStyleType: 'lower-alpha'}}>
                                <li>The current Annual Debt Transparency Report ADTR requires submitters to identify the authorization, either provided by an act of the governing body or a vote of the electorate, under which the debt was issued.</li>
                                <li>The identification is made through the authorization “name”, the date of authorization, and the original amount of authorized issuance. The submitter must enter the amount of unissued authorization at the beginning of the reporting period and how much of that debt authorization was issued during the reporting period. The goal of this section of the ADTR is to disclose a balance of unissued, but authorized debt at the end of each reporting period for the authorization used to issue the specific debt being reported.</li>
                                <li>The authorization for a debt issuance will be reported first on the Report of Proposed Debt Issuance (RPDI).
                                The issue specific authorization data will be used and carried forward to the report of Report of Final Sale (RFS). Submitters will be able to either keep the authorization information they submitted on the RPDI, modify amounts reduced or replenished, select a different previously stored authorization, or create an entirely new authorization.</li>
                            </ul>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="custom-button custom-button-secondary" onClick={()=>setShowInstructionsModal(false)}>Close</button>
                    </Modal.Footer>
                </Modal>
                }
            </h3>

           {isLoading ? <Spinner /> :
            authorizations.length !== 0 && authorizations.map((auth, i) => {
                return (
                    <Row key={i}>
                        <div>
                            <span style={{float: "right"}}>
                                <FaPencilAlt className="custom-icon" onClick={()=>editAutorization(i)}/>{"   "}
                                <VscTrash className="custom-icon" onClick={()=>deleteAuthorization(auth.issuerAuthorizationId)}/>
                            </span>
                            <h3 className={classes.authheading}>Issuance Authorization {i+1}</h3>
                        </div>
                        <Row>
                            <Col md={5}>Authorization Name : </Col>
                            <Col md={5}>{auth.authorizationName} </Col>
                        </Row>
                        <Row>
                            <Col md={5}>Original Authorized Amount : </Col>
                            <Col md={5}>{CommonService.currencyFormatter(auth.originalAuthorizedAmt)} </Col>
                        </Row>
                        <Row>
                            <Col md={5}>Authorization Date : </Col>
                            <Col md={5}>{CommonService.dateFormatter(auth.authorizationDate) }</Col>
                        </Row>
                        <Row>
                            <Col md={5}>Amount Authority Reduced (By this Issue) : </Col>
                            <Col md={5}>{CommonService.currencyFormatter(auth.authorityReducedAmt)} </Col>
                        </Row>
                        <Row>
                            <Col md={5}>Amount Authority Replenished (By this Issue) : </Col>
                            <Col md={5}>{CommonService.currencyFormatter(auth.authorityReplenishAmt)}</Col>
                        </Row>
                        <Row>
                            <Col md={5}>Net Change (By this Issue) : </Col>
                            <Col md={5}>{CommonService.currencyFormatter(auth.authorityReplenishAmt - auth.authorityReducedAmt)} </Col>
                        </Row>
                        <hr/>
                    </Row>

                )
            })}
            
            {openAddModal && <AddIssuanceAuthorization onModalClose={handleModalClose} issueId={issueId}/> }
            {openEditModal && <EditIssuanceAuthorization onModalClose={handleEditModalClose} authInfo={editAuthorizationInfo} issueId={issueId}/> }
            <span className="required"style={{fontSize: '24px'}}>* </span><button type="button" className="custom-button" onClick={() => addAuthorization()}>Add Authorization</button>
            <MDBTooltip
                  domElement
                  tag="span"
                  placement="right"
                  >
                  <span>{"  "}<AiFillQuestionCircle onClick={()=>setShowHelpTextModal(true)} className="help-icon heading-help-icon"/></span>
                  <span>Click me for more info.</span>
            </MDBTooltip>
            {showHelpTextModal && 
            <Modal show={true} backdrop="static" onHide={()=>setShowHelpTextModal(false)}>
                <Modal.Body>
                    <div>
                        <p>Identify the authorization(s) under which this proposed debt will be issued, either by vote of the electorate (ballot measure) or by act of the governing body (resolution).</p>
                        <p>Multiple authorizations may be entered for the same issuance. This may arise if the authorization for this single debt issue is derived from two or more voter measures or if this single debt issue reduces one authorization while it replenishes another. Replenishment of an authorization may occur if interim financing is repaid by permanent financing, for example, commercial paper paydown.</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="custom-button-secondary btn-secondary" onClick={()=>setShowHelpTextModal(false)}>Close</button>
                </Modal.Footer>
            </Modal>
            }
            
            <div className="btn-div">
            {/* <button className="custom-button" type="button">Back</button> */}
            <button className="custom-button fright" type="button" onMouseDown={(e)=>validateForm(e)}>Save & Next</button>
            </div>
            </Form>
        </Card>
    )
}

export default IssuanceAuthorization

import CommonService from "./CommonService";

const saveAdtrProceed = (obj) => {
    return CommonService.apicall(obj);
};
const getUseOfProceedsSummary = (obj) => {
    return CommonService.apicall(obj);
};
const validateTotalReportableProceedsAvl = (obj) => {
    return CommonService.apicall(obj)
};
const getNetProceedsBalance = (obj) => {
    return CommonService.apicall(obj)
};
const updateAdtrReportableFlag = (obj) => {
    return CommonService.apicall(obj)
};
const getInfo = (obj) => {
    return CommonService.apicall(obj)
};
const validateAdtr = (obj) => {
    return CommonService.apicall(obj)
};
const updateFinalAdtr = (obj) => {
    return CommonService.apicall(obj)
};


const AdtrService = {
    saveAdtrProceed,
    getUseOfProceedsSummary,
    validateTotalReportableProceedsAvl,
    getNetProceedsBalance,
    updateAdtrReportableFlag,
    getInfo,
    validateAdtr,
    updateFinalAdtr
};


export default AdtrService;

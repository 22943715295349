import { useContext, useState } from 'react'
import Card from '../ui/Card'
import ReviewComponent from './reviewComponent'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import CommonService from '../../services/CommonService'
import { IssuanceDetailContext, UserContext } from '../../UseContext'
import { toast } from 'react-toastify'
import { renderToString } from 'react-dom/server'
import EditReasonModal from '../reports/editReasonModal'
import swal from 'sweetalert'
import ReportsService from '../../services/ReportsService'
import { useMsal } from '@azure/msal-react'

const MKRLocalObligorsYFSReview = (props) => {
    const {instance} = useMsal();
    const {markRoosLocalOblig, markRoosLocalObligDetails, contact} = props;
    const user = useContext(UserContext);
    const issuanceDetail = useContext(IssuanceDetailContext);
    const [showEditReasonModal, setShowEditReasonModal] = useState(false);
    const updateFinalMarkRoosLocalOblig= async() => {
        const html = renderToString(<ReviewComponent issuanceDetail={issuanceDetail} markRoosLocalOblig={markRoosLocalOblig} markRoosLocalObligDetails={markRoosLocalObligDetails} contact={contact} />)

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "updateFinalMarkRoosLocalOblig",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                //Change Later
                "id": markRoosLocalObligDetails.marksRoosLocalObligorId,
                "env": "E",
                "marksRoosLocalObligor":{
                    "finalSaleid" : markRoosLocalObligDetails.finalSaleId,
                    "lastUpdateUser": CommonService.getUserName(user),
                    "emailId": user,
                    "statusFlag" : "S"
                },
                "reportType" : "MKR-L",
                "html" : html,
                "cdiacNbr" :  markRoosLocalOblig.cdiacNbr
            }
        };
        FinalSaleReportService.updateFinalMarkRoosLocalOblig(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                CommonService.handleServerResponse(result.data, instance);
            }
            else{
                if(result.data && result.data.responses[0]) {
                    var base64EncodedPDF = result.data.responses[0].pdf;
                    var arrrayBuffer = CommonService.base64ToArrayBuffer(base64EncodedPDF); 
                    var blob = new Blob([arrrayBuffer], {type: "application/pdf"});
                    var url = window.URL.createObjectURL(blob);
                    window.open(url);
                } 
                toast.success("Mark Roos Local Obligor updated successfully!");
            }
        });
    }

    const validateRequiredFields = async() => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "validateMarkRoosLocalOblig",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                //Change Later
                "id": markRoosLocalObligDetails.marksRoosLocalObligorId
            }
        };
        FinalSaleReportService.validateMarkRoosLocalOblig(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                alert(result.data.errors[0].message)
            }
            else{
                if(result.data.responses[0].length > 0) {
                  let alertMessage = "";
                  result.data.responses[0].forEach(item => {
                    alertMessage +=  "- " + item.message + "\n";
                  });
                  alert(alertMessage);
                } else {
                    if(markRoosLocalObligDetails.statusFlag === "R"){
                        swal({
                            text: "Do you want to re-edit this record?",
                            icon: "warning",
                            buttons: [true, 'Yes'],
                            closeOnClickOutside: false
                        }).then((confirm) => {
                            if (confirm) {
                                setShowEditReasonModal(true)
                            } 
                        });
                    } else {
                        updateFinalMarkRoosLocalOblig()
                    }      
                }
            }

        });
    }

    const saveEditReason = (editReason) => {
        let obj ={
             "msgId": null,
             "sysId": null,
             "opr": "postEditReason",
             "hdrStruct": null,
             "queryParam": null,
             "reqData": {
                 "editReason" : {
                     "editReasonCodeId" : editReason.editReasonCodeId,
                     "comment" : editReason.comment,
                     "mkrLobId" : markRoosLocalObligDetails.marksRoosLocalObligorId,
                     "createUser" : CommonService.getUserName(user)
                 }
             }
         } 

         ReportsService.saveEditReason(obj)
         .then((res) => res)
         .then((result) => {
             if(result.data.errors.length > 0) {
 
             } else {
                 toast.success('Edit Reason saved successfully!')
                 setShowEditReasonModal(false)
                 updateFinalMarkRoosLocalOblig()
             }
         });
    }

    const validateMelloRoosReport = () => {
        props.toggleWidget('review');
        validateRequiredFields();
         
    }

    return (
        <Card>
            <ReviewComponent issuanceDetail={issuanceDetail} markRoosLocalOblig={markRoosLocalOblig} markRoosLocalObligDetails={markRoosLocalObligDetails} contact={contact}/>
            <div className="form">
                <div className="btn-div">
                <button className="fright custom-button" type="button" onClick={()=>validateMelloRoosReport()}>Validate & Submit</button>
                </div>
            </div>   
            {showEditReasonModal && <EditReasonModal  onModalClose={()=>setShowEditReasonModal(false)} handleSave={saveEditReason} cdiacReasonCode="MKLER"/>}
        </Card>
    )
}

export default MKRLocalObligorsYFSReview

import React, {useState, useEffect} from 'react'
import {Modal, Table, Spinner} from 'react-bootstrap'
import CommonService from '../../services/CommonService';
import ReportOfProposedDebtService from '../../services/ReportOfProposedDebtService';
import { useMsal } from '@azure/msal-react';

const History = ({onModalClose, issueId, reportTypeId}) => {
    const {instance} = useMsal();
    const [history, setHistory] = useState(null);
    const [proposedHistory, setProposedHistory] = useState(null);
    const [showSpinner, setShowSpinner] = useState(false);
    useEffect(()=> {
        async function getHistory () {
            CommonService.showHideSpinner(true);
            let obj = {
                "msgId": null,
                "sysId": null,
                "opr": "getStatusHistoryById",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": { "id" : issueId,
                "idType" : reportTypeId }
            };
           await ReportOfProposedDebtService.getHistory(obj)
              .then((res) => res)
              .then((result) => {
                if(result.data.errors && result.data.errors.length > 0 ) {
                    CommonService.handleServerResponse(result.data, instance);
                }else {
                    if(reportTypeId === "final_sale_id") {
                        setHistory(result.data.responses[0].filter(x=>x.finalSaleId !== null));
                        setProposedHistory(result.data.responses[0].filter(x=>x.proposeDebtId !== null))
                    } else {
                       setHistory(result.data.responses[0]);
                    }
                }
                CommonService.showHideSpinner(false);
                });
        }
        getHistory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const handleViewDocument = (e, issueStatusId) => {
        setShowSpinner(true);
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getPdfDocumentFromBlob",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": { 
                "statusHistId" : issueStatusId,
            }
        };
        ReportOfProposedDebtService.getHistory(obj)
            .then((res) => {
            var base64EncodedPDF = res.data.responses[0];
            var arrrayBuffer = base64ToArrayBuffer(base64EncodedPDF); 
            function base64ToArrayBuffer(base64) {
                let binaryString = window.atob(base64);
                let binaryLen = binaryString.length;
                var bytes = new Uint8Array(binaryLen);
                for (var i = 0; i < binaryLen; i++) {
                    var ascii = binaryString.charCodeAt(i);
                    bytes[i] = ascii;
                }
                return bytes;
            }
            setShowSpinner(false);
            var blob = new Blob([arrrayBuffer], {type: "application/pdf"});
            var url = window.URL.createObjectURL(blob);
            window.open(url);
        });
        
    }
    return (
        <>
        <Modal show={true} backdrop="static" onHide={onModalClose}>
            <Modal.Header closeButton><h3>History</h3></Modal.Header>
            <Modal.Body>
                <Table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Create User</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {history && history.filter(x=>(x.invoiceId === null)).map((item, i)=>{
                            return (<tr key={i}>
                                <td>{CommonService.dateFormatter(item.createDatetime)}</td>
                                <td>{item.issueStatusCode}</td>
                                <td>{item.createUser}</td>
                                <td>{<button
                                    type="button"
                                    className="link-button dk-blue-color"
                                    onClick={(e)=>handleViewDocument(e, item.issueStatusId)}>PDF
                                  </button>
                                  }</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                <h3>Invoice History</h3>
                <Table bordered>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Create User</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {history && history.filter(x=>x.invoiceId !== null).map((item, i)=>{
                            return (<tr key={i}>
                                <td>{CommonService.dateFormatter(item.createDatetime)}</td>
                                <td>{item.createUser}</td>
                                <td>{<button
                                    type="button"
                                    className="link-button dk-blue-color"
                                    onClick={(e)=>handleViewDocument(e, item.issueStatusId)}>PDF
                                  </button>
                                  }</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>

                { reportTypeId==="final_sale_id" &&
                <div>
                <h3>Proposed Debt History</h3>
                <Table bordered>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Create User</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {proposedHistory && proposedHistory.map((item, i)=>{
                            return (<tr key={i}>
                                <td>{CommonService.dateFormatter(item.createDatetime)}</td>
                                <td>{item.issueStatusCode}</td>
                                <td>{item.createUser}</td>
                                <td>{<button
                                    type="button"
                                    className="link-button dk-blue-color"
                                    onClick={(e)=>handleViewDocument(e, item.issueStatusId)}>PDF
                                  </button>
                                  }</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                </div>
                }
            </Modal.Body>
            <Modal.Footer>
            <button className="custom-button-secondary btn-secondary"onClick={()=>onModalClose()}>Close</button>
            </Modal.Footer>
        </Modal>
        {showSpinner && 
            <Modal show={true} className="modal bd-example-modal-lg" data-backdrop="static">
                <div className="modal-dialog modal-sm">
                        <Spinner animation="border"/>
                </div>
            </Modal>
        } 
        </>
    )
}

export default History
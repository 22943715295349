import {Form, Modal} from 'react-bootstrap'
import {useState, useContext} from 'react'
import {validateFormFields} from '../../../utils'
import CurrencyInput from '../../formElements/CurrencyInput'
import IssueSocialImpactService from '../../../services/IssueSocialImpactService'
import { UserContext } from '../../../UseContext'
import CommonService from '../../../services/CommonService'
import {AiFillQuestionCircle} from 'react-icons/ai'
import { MDBTooltip } from 'mdbreact'

const AddSocialImpactBond = ({onModalClose, issueId}) => {
    const user = useContext(UserContext);
    const [disableAmountField, setDisableAmountField] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    const socialImpactBonds = {
        "G" : "Green",
        "S" : "Social Impact",
        "U" : "Sustainability",
        "N" : "None",
    }
    const onSocialImpactBondChange = (e) => {
        if(e.target.value === 'N') {
            setDisableAmountField(true);
        } else {
            setDisableAmountField(false);
        }
    }

    const saveSocialImpact = (socialImpactInfo) => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "postIssueSocEnvImpact",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "issueSocialEnvironmentalImpact": {
                    "finalSaleId": null,
                    "proposeDebtId": issueId,
                    "envSocialImpactAmt": socialImpactInfo.amount,
                    "envSocialImpactCode": socialImpactInfo.impactType,
                    "createUser": CommonService.getUserName(user),
                    "lastUpdateUser": CommonService.getUserName(user)
                }
            }
        };
        
        IssueSocialImpactService.addSocialEnvImpact(obj)
        .then((res) => res)
        .then((result) => {
            onModalClose();
        });
    }

    const handleSubmit = (e) => {
        const addForm = e.target.closest('form#socialImpactForm');
        let formErrors = validateFormFields(addForm.elements);

        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});
            
            let socialImpactInfo = {};
            Array.prototype.forEach.call(addForm.elements, (element) => {
                socialImpactInfo[element.name] = element.value;
            })

            saveSocialImpact(socialImpactInfo);
        }
    }
    return (
        <Modal show={true}>
            <Modal.Header closeButton><h3>Add Environmental / Social Impact Designation:
            <MDBTooltip domElement tag="span" placement="right">
                            <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                            <span>Select the environmental or social impact designation and enter the total principal amount to be
sold with the designation.</span>
                        </MDBTooltip>
                        </h3></Modal.Header>
            <Modal.Body>
                <form id="socialImpactForm">
                <div className="control">
                    <label>Designation Type: </label>
                    <Form.Select name="impactType" onChange={(e)=>onSocialImpactBondChange(e)} data-validations="required" style={formErrors['impactType'] && {border: '1px solid red'}}>
                            <option value="">Select all that apply</option>
                            
                            {(Object.keys(socialImpactBonds).length !== 0) && Object.entries(socialImpactBonds).map(([k, val]) =>{
                                return (<option key={k} value={k}>{val}</option>);
                            }
                            ) }
                    </Form.Select>
                    {formErrors['impactType'] && <p className="error">{ formErrors['impactType'] }</p>}

                </div>
                
                <div className="control">
                    <CurrencyInput placeholder="0.00" 
                        label="Amount" 
                        name="amount" 
                        type="text" 
                        //value={financingParticipant.issuanceFee}
                        //onChange={(e)=>handleChange(e)} 
                        error={formErrors.amount} 
                        validations={["max:999999999999.99"]}
                        disabled={disableAmountField}
                        />
                </div>

                <div className="btn-div">
                    <div className="fright">
                    <button type="button" className="custom-button-secondary btn-secondary" onClick={()=>onModalClose()}>Cancel</button>{" "}
                    <button type="button" className="custom-button" onClick={(e)=>handleSubmit(e)}>Save</button>
                    </div>
                </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default AddSocialImpactBond

import CommonService from "./CommonService";

const getAllStatutes = (obj) => {
    return CommonService.apicall(obj);
};

const StatuteMaintenanceService = {
    getAllStatutes
};

export default StatuteMaintenanceService;
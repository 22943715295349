import { Form, Col } from 'react-bootstrap';
import {useEffect, useState, useContext} from 'react';
import Card  from '../ui/Card';
import AdtrList from './adtrList';
import { LoggedInUserContactContext } from '../../UseContext';
import { toast } from 'react-toastify';
import BaseService from '../../services/BaseService';
import { MultiSelect } from 'primereact/multiselect';
import 'primeicons/primeicons.css';
// import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import CommonService from '../../services/CommonService';
import { useMsal } from '@azure/msal-react';

const AdtrBatch = () => {
  const {instance} = useMsal();
    const loggedInUserInfo = useContext(LoggedInUserContactContext);
    const [issuers, setIssuers] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const [selectedIssuers, setSelectedIssuers] = useState(null);
    const [selectedIssuerId, setSelectedIssuerId] = useState("");
    const [showAdtrList, setShowAdtrList] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    async function getIssuersByUserId() {
      let obj = {
        "msgId": null,
        "sysId": null,
        "opr": "getIssuersByUserId",
        "hdrStruct": null,
        "queryParam": null,
        "reqData": {
            "userId": loggedInUserInfo.contactEmailId 
        }
      };
    
      await BaseService.processRequest(obj)
        .then((res) => res)
        .then((result) => {
          if(result.data.statusCode === 401 || result.data.statusCode === 500) {
            CommonService.handleServerResponse(result.data, instance);
          } else {
            if(result.data.responses.length > 0 && result.data.responses !== null) {
              let tempIssuersList = [];
              result.data.responses[0].forEach((x)=>{
                 tempIssuersList.push({code: x.issuerId, name: x.issuerName});
              })
              setIssuers(tempIssuersList);
            }
            else{
              toast.error("No Issuers associated with the Login Id");
            }
          }
            setIsLoading(false);
      });
    }
         
    useEffect(()=>{
      getIssuersByUserId();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[loggedInUserInfo.contactEmailId]);
    
    const handleChange = (e) => {
      setSelectedIssuers(e);
      setSelectedIssuerId(e.map(item => item.code).join(","));
      setShowAdtrList(false);
    }

    const handleSubmit = () => {
      if(selectedIssuerId === "") {
        let formErrorsArr = {};
        if( selectedIssuerId === "" ) formErrorsArr['issuerId'] = "This field is required." ;
        setFormErrors(formErrorsArr);
        return
      } else {
        setFormErrors({});
        setShowAdtrList(true);
      }
    }
    const onShow = () => {
      let selectAllCheckbox = document.querySelector(
        ".p-multiselect-header .p-checkbox"
      );
      selectAllCheckbox.after(" Select All");
    };
    return (
        <Card>
            <div className="panel panel-default">
              <div className="panel-heading">
                  <div className="container">
                      <div id="packet_id" className="panel-title">
                        <div  style={{float: 'right', padding:'0.25rem'}}>
                          <button className="custom-button"><a href="/assets/referenceGuides/Quick Ref Guide-ADTR Batch.pdf" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: 'white'}}>Quick Reference Guide</a></button>{" "}
                        </div>
                        <h1 className="menu-title">ADTR Batch Download</h1>
                      </div>
                  </div>
              </div>
              <div className="panel-body">
                <div className="container form">
                  <form className="form">
                    <div className="row">
                    <Form.Group as={Col} >
                    <Form.Label><span className="required">*</span>Issuer:</Form.Label>
                    <div>
                    {!isLoading && <MultiSelect value={selectedIssuers} options={issuers} 
                    onChange={(e) => handleChange(e.value)} optionLabel="name" placeholder="Select an Issuer"
                    onShow={onShow}
                    />}
                    </div>
                    </Form.Group>
                    {formErrors['issuerId'] && <p className="error">{formErrors['issuerId']}</p>}
                    </div>
                    <div className="btn-div">
                    <button type="button" onClick={()=>handleSubmit()} className="custom-button">Submit</button>
                    </div>

                    <div style={{color: 'blue', fontSize: '12px'}}>
                      <i>
                      <span>The User acknowledges that this Batch Upload feature may be used for submitting Annual Debt Transparency Reports (ADTRs) subject to the following restrictions:</span>
                      <ul>
                        <li>The first initial ADTR for each issue must have been filed already to establish the Fund Categories.</li>
                        <li>The data must be free from commas and any formatting changes due to the file format (CSV).</li>
                        <li>Each issue must not have more than three (3) Issuance Authorizations identified on the Report of Final Sale.</li>
                        <li>A maximum of ten (10) Expenditure purpose types within a Fund Category may be entered.</li>
                        <li>Negative numbers are not allowed to be uploaded.</li>
                        <li>It is recommended that users batch no more than 100 ADTRs for upload at a time.</li>
                      </ul>
                      <span>Please use the Data Portal Dashboard to file ADTRs manually if the above restrictions are not met. For questions, please contact us at CDIAC_Issuance@treasurer.ca.gov or call (916) 653-3269.</span>
                      </i>
                    </div>
                  </form>
                </div>    
              </div>            
            </div>
            {showAdtrList && <AdtrList issuerId={selectedIssuerId}/>}
        </Card>
    )
}

export default AdtrBatch;

import {useState, useEffect, useContext} from 'react'
import CollapsibleWidgets from'./collapsibleWidgets'
import MKRLocalObligorsProgressBar from './mkrLocalObligorsProgressBar'
import {VscExpandAll, VscCollapseAll} from 'react-icons/vsc'
import { IssuanceDetailContext } from '../../UseContext'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import History from '../../common/History'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import CommonService from '../../services/CommonService'
import { UserContext } from '../../UseContext'
import ReviewComponent from './reviewComponent'
import HtmlPdfService from '../../services/HtmlPdfService'
import { renderToString } from 'react-dom/server'
import { useMsal } from '@azure/msal-react'
import { configManager } from '../../configManager'

const MKRLocalObligorsYFS = (props) => {
    const {instance} = useMsal();
    const userEmail = useContext(UserContext);
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const [issuanceDetail, setIssuanceDetails] = useState([]);
    const [contact, setContactDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const cdiacNo = params.cdiac; 
    let markRoosLocalOblig = location.state.reportData;

    if(!location.state.reportData){
        markRoosLocalOblig = location.state.markRoosLocalOblig
    }
  
    const [markRoosLocalObligDetails, setMarkRoosLocalObligDetails] =  useState([]);
    const [expandAll, setExpandAll] = useState(false);
    const [openSection, setOpenSection] = useState({
        issuance: true,
        fundBalance: false,
        delinquentReporting: false,
        retiredIssues: false,
        filingContact: false,
        comments: false,
        review: false
    });
    const [showHistory, setShowHistory] = useState(false);
    const toggleAllWidgets = () => {
        setExpandAll( !(expandAll) );
        let openSecObj = Object.assign(...Object.keys(openSection).map(k => ({ [k]: !(expandAll) })));
        setOpenSection(openSecObj);
    }

    const toggleWidget = (sectionName) => {
        setOpenSection({...openSection, [sectionName]: !(openSection[sectionName])})
    }

    const openWidget = (sectionName) => {
        let openSecObj = Object.assign(...Object.keys(openSection).map(k => ({ [k]: false })));
        openSecObj[sectionName] = !(openSecObj[sectionName]);
        setOpenSection(openSecObj);
    }
    
    const handlePrint = () => {
        const html = renderToString(<ReviewComponent issuanceDetail={issuanceDetail} markRoosLocalOblig={markRoosLocalOblig} markRoosLocalObligDetails={markRoosLocalObligDetails} contact={contact} />)

        var obj = {
            msgId: null,
            sysId: null,
            opr: "getPdf",
            hdrStruct: null,
            queryParam: null,
            reqData: {
                "html": html,
                "id" : markRoosLocalOblig.mkrLobId,
                "cdiacNbr": cdiacNo,
                "createUser":  CommonService.getUserName(userEmail),   
                "status": 'D',
                "reportType" : "MKR-L" 
            },
          };

        HtmlPdfService.generatePdf(obj)
        .then((res) => { 
             var base64EncodedPDF = res.data;
             var arrrayBuffer = CommonService.base64ToArrayBuffer(base64EncodedPDF); 
             var blob = new Blob([arrrayBuffer], {type: "application/pdf"});
             var url = window.URL.createObjectURL(blob);
             window.open(url);

        }) // Handle the response from backend here
          .catch((err) => { }); // Catch errors if any
    }

    const backToDashboard = () => {
        const dashboardSelectionObj = JSON.parse(sessionStorage.getItem('dashboardSelection'));
        navigate("/dashboard", {state: {dashboardSelection: dashboardSelectionObj}});
    }

    const getRetireFlagDesc = (finalSaleDetail)  => {
        switch(finalSaleDetail.retireFlag){
            case "R" :
              return "Redeemed Entirely";
            case "M" :
              return "Matured";
            case "N" :
              return "Not Retired";
            case "O" :
              return "Other";
            default:
                return "";
            } 
    }
    
    useEffect(() => {
        async function getIssuanceDetailsByFinalSaleId() {
            
            if(markRoosLocalOblig.finalSaleId !== null){
                var obj = {
                    msgId: null,
                    sysId: null,
                    opr: "getIssuanceDetailsByFinalSaleId",
                    hdrStruct: null,
                    queryParam: null,
                    reqData:{
                        "finalSaleId" : markRoosLocalOblig?.finalSaleId
                    }
                  };
              
                await FinalSaleReportService.getIssuanceDetailsByFinalSaleId(obj)
                    .then((result) => {
                        if (result.data.errors && result.data.errors.length > 0){
                            CommonService.handleServerResponse(result.data, instance);
                            return;
                        }
                        else{
                            let retireFlagDesc = getRetireFlagDesc(result.data.responses[0]);
                            result.data.responses[0].retireFlagDesc = retireFlagDesc;
                            setIssuanceDetails(result.data.responses[0]);
                        }
            
                     });
            }
        }

        async function getMKRRoosLocalOblig() {
            
            if(markRoosLocalOblig !== undefined){

                    var obj = {
                        msgId: null,
                        sysId: null,
                        opr: "getMKRRoosLocalOblig",
                        hdrStruct: null,
                        queryParam: null,
                        reqData:{"id" : markRoosLocalOblig.mkrLobId}
                      };
                    await FinalSaleReportService.getMKRRoosLocalOblig(obj)
                        .then((result) => {
                            if (result.data.errors && result.data.errors.length > 0){
                                CommonService.handleServerResponse(result.data, instance);
                                return;
                            }
                            else{
                                setMarkRoosLocalObligDetails(result.data.responses[0]);
                            }

                        });
            }
            else{
                var anotherobj = {
                    msgId: null,
                    sysId: null,
                    opr: "getMKRRoosLocalOblig",
                    hdrStruct: null,
                    queryParam: null,
                    reqData:{"id" : markRoosLocalObligDetails.markRoosLocalObligId}
                    };
                await FinalSaleReportService.getMKRRoosLocalOblig(anotherobj)
                    .then((result) => {
                        if (result.data.errors && result.data.errors.length > 0){
                            CommonService.handleServerResponse(result.data, instance);
                            return;
                        }
                        else{
                            setMarkRoosLocalObligDetails(result.data.responses[0]);
                        }
                        
                    });
            }
        }

        async function getFilingContact() {
            var obj = {
                msgId: null,
                sysId: null,
                opr: "getContactByEmailId",
                hdrStruct: null,
                queryParam: null,
                reqData:{"emailId" : userEmail}
                };
            await FinalSaleReportService.getContactDetails(obj)
                .then((result) => {
                    if (result.data.errors && result.data.errors.length > 0){
                        CommonService.handleServerResponse(result.data, instance);
                        return;
                    }
                    else{
                        setContactDetails(result.data.responses[0]);
                    }
                    
                });
        }

        getIssuanceDetailsByFinalSaleId();
        getMKRRoosLocalOblig();
        getFilingContact();
        
        setTimeout(() => {
            setIsLoading(false);
          }, 5000);
        // eslint-disable-next-line
      }, []);
    
    const updateIssuanceDetails = (finalSaleInfo) => {
        let retireFlagDesc = getRetireFlagDesc(finalSaleInfo);
        finalSaleInfo.retireFlagDesc = retireFlagDesc;
        setIssuanceDetails(finalSaleInfo);
    }
    const updateMkrLocalObligorDetails = (mkrLocalObligorInfo) => {
        setMarkRoosLocalObligDetails(mkrLocalObligorInfo);
    }  

    return (
        <div>
            <div>
                <div className="panel panel-default" id="stepspanel" style={{marginBottom: '0px'}}>
    
                    <div className="panel-heading">
                        <div className="container">
                        <div id="packet_id" className="panel-title">                           

                            <div className="fright" style={{margin: '5px'}}>
                            <button className="custom-button"><a href="/assets/referenceGuides/Quick Ref Guide-MARKS.pdf" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: 'white'}}>Quick Reference Guide</a></button>{" "}
                            {markRoosLocalOblig.issueStatusFlag === "R" && <button className="custom-button me-2" onClick={() => window.open(`${configManager.debtWatchUrl}/issue-level-detail?cdiacNumber=${cdiacNo}`)}>View in DebtWatch</button>}
                            {markRoosLocalOblig?.issueStatusFlag === 'D' && <button className="custom-button" onClick={handlePrint}>Print PDF (Draft)</button>}</div>
                                <h1 className="menu-title">Marks Roos Yearly Fiscal Status Report For Local Obligors</h1>                                
                            </div>
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="container">
                            <MKRLocalObligorsProgressBar openSection={openWidget} />
                            <div className="center-align">
                            <div style={{float: 'left'}}>
                                <button
                                type="button"
                                className="dk-blue-color link-button" 
                                onClick={()=>backToDashboard()}>Back to Dashboard</button>
                            </div>
                            <button
                            type="button"
                            style={{textDecoration: 'none', fontWeight: '500'}}
                            className="dk-blue-color link-button"
                            onClick={()=>toggleAllWidgets()}>{expandAll ? <VscCollapseAll /> : <VscExpandAll /> } {expandAll ? "Collapse All" : "Expand All" }</button>
                            </div>
                            <div style={{float: 'right'}}>
                                <button
                                type="button"
                                className="dk-blue-color link-button" 
                                onClick={()=>setShowHistory(true)}>Show History</button>
                            </div>
                            <br/>
                            <div style={{color: 'green'}}>
                            <em>CDIAC # {cdiacNo}</em>
                            <br/>
                            <em>Balances Reported as of {CommonService.dateFormatterMonthDayYear(markRoosLocalOblig?.fiscalYrEndDate)}</em>
                            </div>
                        </div>
                    </div>
                </div>
                    
            </div>
            <br/>
            {showHistory && <History onModalClose={()=>setShowHistory(false)} issueId={markRoosLocalOblig.mkrLobId} reportTypeId="marks_roos_local_obligor_id"/>}
            {isLoading ? <Spinner animation="border" variant="primary"/> :
            <><IssuanceDetailContext.Provider value={issuanceDetail}>
                    <CollapsibleWidgets 
                    openSectionObj={openSection} 
                    toggleWidget={toggleWidget} 
                    openSection={openWidget} 
                    markRoosLocalOblig={markRoosLocalOblig} 
                    markRoosLocalObligDetails={markRoosLocalObligDetails} 
                    contact={contact} 
                    updateIssuanceDetails={updateIssuanceDetails}
                    updateMkrLocalObligorDetails={updateMkrLocalObligorDetails}/>
                </IssuanceDetailContext.Provider>
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <div className="container">
                                <div className="panel-body" style={{ padding: '10px 0 0' }}>
                                    <button className="custom-button" onClick={() => backToDashboard()}>Back to Dashboard</button>
                                </div>
                            </div>
                        </div>
                    </div></>
            }
        </div>
    )
}

export default MKRLocalObligorsYFS

import {useState} from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import ParentAssociations from './parentAssociations'
import ChildAssociations from './childAssociations'
import { useForm } from "react-hook-form";
import swal from 'sweetalert'

const Associations = () => {
    const [associationType, setAssociationType] = useState('marksroos-authority');
    const [parentAssociationTitle, setParentAssociationTitle] = useState('Senior');
    const [childAssociationTitle, setChildAssociationTitle] = useState('Subordinate');
    const [showParentAssociations, setShowParentAssociations] = useState(false);
    const [showChildAssociations, setShowChildAssociations] = useState(false);
    const [selectedParentCdiacNo, setSelectedParentCdiacNo] = useState(null);

    const [searchReq, setSearchReq] = useState([]);
    const {register, handleSubmit} = useForm();

    const handleAssociationType = (e) => {
        const {value} = e.target;
        setAssociationType(value);
        setShowParentAssociations(false);
        setShowChildAssociations(false);

        switch (value) {
            case ('senior-subordinate'):
                setParentAssociationTitle('Senior Associations');
                setChildAssociationTitle('Subordinate Associations');
                break;
            case ('marksroos-authority'):
                setParentAssociationTitle('Marks-Roos Authority Associations');
                setChildAssociationTitle('Local Obligor Associations');
                break;
            case ('series-association'):
                setParentAssociationTitle('Series Associations')
                setChildAssociationTitle('Associated Series');
                break;
            default:
                setParentAssociationTitle('Senior Associations');
                setChildAssociationTitle('Subordinate Associations');
                break;
        }        
    }

    const onSubmit = (data) => {
        if(data.issuerName === "" && data.cdiacNumber === ""){
            swal("Please select Issuer Name or CDIAC# as a search criteria");
            return;
        }
        var obj = {
            "issuerName": data.issuerName,
            "cdiacNumber": data.cdiacNumber,
            "associationType": associationType
        }
        setSearchReq(obj);
        setShowParentAssociations(true);
    }

    const getChildAssociations = (row) => {
        setSelectedParentCdiacNo(row);
        setShowChildAssociations(true);
    }

    return (
        <div>
            <div className="panel panel-default" id="stepspanel" style={{marginBottom: '0px'}}>

                <div className="panel-heading">
                    <div className="container">
                        <div id="packet_id" className="panel-title">
                            <h1 className="menu-title">Associations</h1>
                        </div>
                    </div>
                </div>

                <div className="panel-body">
                    <div className="container">

                        <div>
                            <label className="field-label">Type of Association</label>
                            <div style={{display: 'flex'}}> 
                                <Form.Check inline type="radio" name="associationType" id="marksroos-authority" value="marksroos-authority" label="Marks-Roos Authority - Local Obligor Association" onChange={(e)=>handleAssociationType(e)} checked={associationType === 'marksroos-authority'}/>
                            </div>
                        </div>

                        <div>
                            <label className="field-label">Select {parentAssociationTitle}</label>

                            <Row>
                                <Col md={3}><label>Issuer Name Contains:</label></Col>
                                <Col md={9}><Form.Control type="text" placeholder="Issuer Name" {...register('issuerName')} name="issuerName" /></Col>
                            </Row>
                            <Row>
                                <Col md={3}><label>CDIAC Number:</label></Col>
                                <Col md={4}><Form.Control type="text" placeholder="CDIAC Number" name="cdiacNumber" {...register('cdiacNumber')} /></Col>
                                <Col md={2}><button type="button" className="custom-button" onClick={handleSubmit(onSubmit)}>Go</button></Col>
                            </Row>
                        </div>

                        {showParentAssociations && <ParentAssociations searchReq={searchReq} getChildrenAssociations={getChildAssociations} parentTitle={parentAssociationTitle}/>}
                        
                        {showChildAssociations && <ChildAssociations selectedRow={selectedParentCdiacNo} childTitle={childAssociationTitle} associationType={associationType}/>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Associations

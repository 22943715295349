import { useParams, useNavigate, Link } from 'react-router-dom';
import CommonService from '../../services/CommonService';
import BaseService from '../../services/BaseService';
import DataGrid from '../GenericComponents/DataGrid';
import { Column } from 'primereact/column';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import React, { useContext, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { LoggedInUserContactContext, UserContext } from '../../UseContext';
import { checkIfReviewedReportCanEdit } from '../reports/adtrReports';
import * as melloRoosReports from '../reports/melloRoosReports';
import * as marksRoosAuthorityReports from '../reports/marksRoosAuthorityReports';
import * as marksRoosLocalObligorReports from '../reports/marksRoosLocalObligorReports';
import * as finalSaleReports from '../reports/finalSaleReports';

const IssuanceDashboard = () => {
    const params = useParams();
    const navigate = useNavigate();
    const user = useContext(UserContext);
    const {instance} = useMsal();
    const loggedInUserInfo = useContext(LoggedInUserContactContext);
    const cdiacNumber = params.cdiacNumber;
    const [issuanceRecords, setIssuanceRecords] = useState([]);
    const [emptyDataMessage, setEmptyDataMessage] = useState("No data available to display");
    useEffect(() => {
        CommonService.showHideSpinner(true);
        const getIssuanceRecords = async() => {
            var obj = {
                msgId: null,
                sysId: null,
                opr: "getIssuanceRecords",
                hdrStruct: null,
                queryParam: null,
                reqData: { 
                    "cdiacNumber": cdiacNumber,
                    "userId": loggedInUserInfo.contactEmailId
                },
              };
          
            BaseService.processRequest(obj)
            .then((res) => res)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0 ) {
                    CommonService.handleServerResponse(result.data, instance);
                    setEmptyDataMessage(<>
                        You do not have the required user permissions to access this record. Please visit your <Link to="/dashboard">User Dashboard</Link> to request access to this view for [{cdiacNumber}].
                    </>)
                } else {
                    setIssuanceRecords(result.data.responses[0]);
                }
                CommonService.showHideSpinner(false);
            });
        }
        getIssuanceRecords();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cdiacNumber]);

    const onRowClick = (row) => {
        switch(row.sourceType){
            case "RPD":
                navigate(`/dashboard/report-proposed-sale/${row.id}`);
                break;
            case "RFS":
                if(row.issueStatusFlag === 'R') {
                    finalSaleReports.checkIfReviewedFinalSaleCanEdit(row.id, navigate);
                } else {
                    finalSaleReports.saveFilingContact(row.id, user, function() {
                        navigate(`/dashboard/report-final-sale/${row.id}`);
                    });
                }
                break;
            case "DRAW":
                navigate(`/dashboard/draw-on-reserve/${row.id}`);
                break;
            case "ADTR":
                let rowInfo = {
                    adtrId: row.id,
                    cdiacNbr: cdiacNumber,
                    finalSaleId: row.finalSaleId,
                    fiscalYrEndDate: row.fiscalYrEndDate
                }
                if(row.issueStatusFlag === 'R') {
                    checkIfReviewedReportCanEdit(rowInfo)
                } else {
                    navigate(`/adtr-yfs/${cdiacNumber}`, {state: {appState:rowInfo}});
                }
                break;
            case "MLR":
                let mlrInfo = {
                    melloRoosId: row.id,
                    cdiacNbr: cdiacNumber,
                    finalSaleId: row.finalSaleId,
                    fiscalYrEndDate: row.fiscalYrEndDate
                }
                if(row.issueStatusFlag === 'R') {
                    melloRoosReports.checkIfReviewedReportCanEdit(mlrInfo, navigate);
                } else {
                    navigate(`/mello-roos-yfs/${cdiacNumber}`, {state:{reportData: mlrInfo}});
                }
                break;
            case "MKR-AUTH":
                let mkrAuthInfo = {
                    mkrAuthId: row.id,
                    cdiacNbr: cdiacNumber,
                    finalSaleId: row.finalSaleId,
                    fiscalYrEndDate: row.fiscalYrEndDate
                }
                if(row.issueStatusFlag === 'R') {
                    marksRoosAuthorityReports.checkIfReviewedReportCanEdit(mkrAuthInfo);
                } else {
                    navigate(`/mkr-authority-issue-yfs/${cdiacNumber}`, {state:{ reportData: mkrAuthInfo}});
                }
                break;
            case "MKR-LOB":
                let mkrLobInfo = {
                    mkrLobId: row.id,
                    cdiacNbr: cdiacNumber,
                    finalSaleId: row.finalSaleId,
                    fiscalYrEndDate: row.fiscalYrEndDate
                }
                if(row.issueStatusFlag === 'R') {
                    marksRoosLocalObligorReports.checkIfReviewedReportCanEdit(mkrLobInfo);
                } else {
                    navigate(`/mkr-local-obligors-yfs/${cdiacNumber}`, {state:{reportData: mkrLobInfo}});
                }
                break;
            default:
                return;
        }
    }
    const dateTemplate = (row) => { 
        return ( 
            CommonService.dateFormatter(row.fiscalYrEndDate)
        ); 
    }
    const actionTemplate = (row) => { 
       
        return ( 
            <div style={{ textAlign: "center", lineHeight: "normal" }}>
                { row['retiredFlag'] === "No" ?
                <span>Not Applicable</span> :
                <div style={{
                    textAlign: "center",
                    lineHeight: "normal"
                  }}>
                    <FontAwesomeIcon icon={faPencilAlt} className="custom-icon" onClick={(e)=>onRowClick(row)}/> 
                  </div>
                }
            </div> 
        ); 
    }

    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                <div className="container">
                    <h1 className="menu-title">Report Access view for [{cdiacNumber}]</h1>
                </div>
            </div>
            <div className="panel-body">
            <div className="container form">
                <div>
                    <DataGrid
                    dataSource={issuanceRecords}
                    sortField='createDatetime'
                    emptyDataMessage={emptyDataMessage}
                    showPaginator={false}
                    showQuickSearch={false}
                    className="p-0"
                    >
                        <Column field='source' header='Source' sortable={true} />
                        <Column field='issueStatus' header='Status' sortable={true} />
                        <Column field='fiscalYrEndDate' header='Fiscal Yr End Date' body={dateTemplate} sortable={true} />
                        <Column field='' header='Action' body={actionTemplate} sortable={true} />
                    </DataGrid>
                </div>
            </div>
            </div>
        </div>
    );
}
export default IssuanceDashboard;
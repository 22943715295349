import CommonService from "./CommonService";

const getAllDebtGroups = (obj) => {
    return CommonService.apicall(obj);
};

const getDebtGroupDetails = (obj) => {
    return CommonService.apicall(obj);
};

const DebtInstrumentMaintenanceService = {
    getAllDebtGroups,
    getDebtGroupDetails
};

export default DebtInstrumentMaintenanceService;
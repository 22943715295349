import { useState } from 'react'
import Card from '../ui/Card'
import { Modal } from 'react-bootstrap'
import { AiFillQuestionCircle } from 'react-icons/ai'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import CommonService from '../../services/CommonService'
import { UserContext } from '../../UseContext'
import { useContext } from 'react'
import { toast } from 'react-toastify'
import { MDBTooltip } from 'mdbreact'

const Comments = (props) => {
    const {markRoosAuthority, markRoosAuthorityDetails} = props;
    const [count, setCount] = useState(markRoosAuthorityDetails.issuerComment ? markRoosAuthorityDetails.issuerComment.length : 0);
    const [bgcolor, setBgcolor] = useState('yellowgreen');
    const [stateValues, setStateValues] = useState({
        issuerComment : markRoosAuthorityDetails.issuerComment ? markRoosAuthorityDetails.issuerComment : ''
    });

    const [showInstructionsModal, setShowInstructionsModal] = useState(false);
    const user = useContext(UserContext);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setCount(e.target.value.length);
        const color = (e.target.value.length === 4000) ? 'orangered' : 'yellowgreen';
        setBgcolor(color);
        setStateValues(prevState => ({
            ...stateValues, [name]: value
        })
        )
    }

    const validateForm = (e) => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "updateMarkRoosAuthorityComment",
            hdrStruct: null,
            queryParam: null,
            reqData: {  "id":markRoosAuthority.mkrAuthId,
                "env" : "E",
                "markRoosAuthority":{
                    "issuerComment" : stateValues.issuerComment,
                    "lastUpdateUser": CommonService.getUserName(user),
                    "emailId": user,
                    "contactId": markRoosAuthority.contactId
                }
            },
          };
      
          FinalSaleReportService.updatemarkRoosAuthorityComment(obj)
            .then((result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    toast.error(result.data.errors[0].message);
                }
                else{
                    props.updateMarkRoosAuthorityDependencies(result.data.responses[0], 'mkrAuthorityData');
                    toast.success("Comment updated successfully!");
                }
                
            });
        props.openWidget('review');
    }
    return (
        <Card>
            <div className="form">
                <h3>Comments 
                <MDBTooltip domElement tag="span" placement="right">
                  <span>{"  "}<AiFillQuestionCircle onClick={() => setShowInstructionsModal(true)} className="help-icon heading-help-icon" /></span>
                  <span>Click me for more info.</span>
                </MDBTooltip>

                    {showInstructionsModal &&
                        <Modal show={true} backdrop="static" onHide={() => setShowInstructionsModal(false)}>
                            {/* <Modal.Header closeButton><h3>Issuance Authorization</h3></Modal.Header> */}
                            <Modal.Body>
                                <div>
                                Please include any additional comments that relate to this filing.
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn-secondary custom-button-secondary" onClick={() => setShowInstructionsModal(false)}>Close</button>
                            </Modal.Footer>
                        </Modal>
                    }</h3>
                <div className="comments-box">

                    <textarea name="issuerComment" value={stateValues.issuerComment}
                        id="issuerComment" onChange={(e)=>handleChange(e)}
                        className="form-control" rows="5" maxLength="4000" placeholder="Add a comment..." />
                    <span style={{ position: 'relative', left: '45%', backgroundColor: bgcolor }}>{count} out of 4000 max</span>


                </div>

                <div className="btn-div">
                    {/* <button className="custom-button" type="button" onClick={() => props.openWidget('filingContact')}>Back</button> */}
                    <button className="fright custom-button" type="button" onClick={() => validateForm()}>Save & Next</button>
                </div>
            </div>
        </Card>
    )
}

export default Comments

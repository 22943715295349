
import {useState, useContext} from 'react'
import Issuance from './issuance'
import FundBalance from './fundBalance'
import AssessedValue from './assessedValue'
import {FaPlus, FaMinus} from 'react-icons/fa'
import 'bootstrap/dist/css/bootstrap.min.css'
import DelinquentReporting from './delinquentReporting'
import TaxCollection from './taxCollection'
import DocUpload from './docUpload'
import Foreclosure from './foreclosure'
import RetiredIssues from './retiredIssues'
import FilingContact from './filingContact'
import Comments from './comments'
import MelloRoosYFSReview from './melloRoosYFSReview'
import { Collapse } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faCheck} from "@fortawesome/free-solid-svg-icons"
import { IssuanceDetailContext } from '../../UseContext'

const CollapsibleWidgets = ({
  openSectionObj, 
  toggleWidget, 
  openSection, 
  melloRoos , 
  melloRoosDetails, 
  contact, 
  foreclosureDetails,
  issueDocuments,
  updateIssuanceDetails,
  updateMelloRoosDetails,
  updateForeClosures,
  updateIssueDocuments
  }) => {
    const retiredIssuesIns = "If this issue is no longer subject to the requirements of the law, indicate the reason (i.e. Issue redeemed entirely, issue fully matured, etc.) within the current reporting fiscal year. Please include the CDIAC number and issue date of the new refunding bond(s).";
    const issuanceDetail = useContext(IssuanceDetailContext);
    const [sectionValidated, setSectionValidated] = useState({
      issuance: melloRoosDetails.reserveMinBalInd ? true : false,
      assessedValue: melloRoosDetails.assessmentDate ? true : false,
      taxCollection: melloRoosDetails.teeterIndicator ? true : false,
      delinquentReporting: melloRoosDetails.dlqParcelDate ? true : false,
      retiredIssues: issuanceDetail.retireFlag ? true : false,
    });
    const updateSectionValidated = (sectionName, isValid) => {
      setSectionValidated(prevState=>({
        ...prevState, [sectionName]: isValid
      }))
    }

    return (
      <>
      
      <div className="panel panel-default">
        <div className="panel-heading" role="tab"> 
          <h5 className="panel-title icon-style" onClick={()=>toggleWidget('issuance')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}} 
             id="issuance" aria-expanded={openSectionObj.issuance} aria-controls="collapse_issuance"> {!openSectionObj.issuance ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Issuance </button> 
            {sectionValidated.issuance && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
          </h5>
          <Collapse in={openSectionObj.issuance}>
            <div id="collapse_issuance">
              <Issuance openWidget={openSection} sectionValidated={updateSectionValidated} melloRoosDetails={melloRoosDetails} updateMelloRoosDetails={updateMelloRoosDetails}/>
            </div>
          </Collapse>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-heading" role="tab"> 
          <h5 className="panel-title icon-style" onClick={()=>toggleWidget('fundBalance')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}} 
             id="draw" aria-expanded={openSectionObj.fundBalance} aria-controls="collapse_fundBalance"> {!openSectionObj.fundBalance ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Fund Balance</button> 
          </h5>
          <Collapse in={openSectionObj.fundBalance}>
            <div id="collapse_fundBalance">
              <FundBalance openWidget={openSection} melloRoosDetails={melloRoosDetails} updateMelloRoosDetails={updateMelloRoosDetails}/>
              </div>
          </Collapse>
        </div>
      </div>
     
      <div className="panel panel-default">
        <div className="panel-heading" role="tab"> 
          <h5 className="panel-title icon-style" onClick={()=>toggleWidget('assessedValue')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}} 
             id="assessedValue" aria-expanded={openSectionObj.assessedValue} aria-controls="collapse_assessedValue"> {!openSectionObj.assessedValue ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Assessed Value </button> 
          {sectionValidated.assessedValue && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
          </h5>
          <Collapse in={openSectionObj.assessedValue}>
            <div id="collapse_assessedValue">
              <AssessedValue openWidget={openSection} sectionValidated={updateSectionValidated} melloRoosDetails={melloRoosDetails} updateMelloRoosDetails={updateMelloRoosDetails}/>
              </div>
          </Collapse>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-heading" role="tab"> 
          <h5 className="panel-title icon-style" onClick={()=>toggleWidget('taxCollection')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}} 
             id="taxCollection" aria-expanded={openSectionObj.taxCollection} aria-controls="collapse_taxCollection"> {!openSectionObj.taxCollection ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Tax Collection </button> 
          {sectionValidated.taxCollection && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
          </h5>
          <Collapse in={openSectionObj.taxCollection}>
            <div id="collapse_taxCollection">
            {openSectionObj.taxCollection && <TaxCollection openWidget={openSection} sectionValidated={updateSectionValidated} melloRoosDetails={melloRoosDetails} updateMelloRoosDetails={updateMelloRoosDetails}/>}
            </div>
          </Collapse>
        </div> 
      </div>
      
      <div className="panel panel-default">
        <div className="panel-heading" role="tab"> 
          <h5 className="panel-title icon-style" onClick={()=>toggleWidget('delinquentReporting')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}} 
             id="delinquentReporting" aria-expanded={openSectionObj.delinquentReporting} aria-controls="collapse_delinquentReporting"> {!openSectionObj.delinquentReporting ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Delinquent Reporting</button> 
          {sectionValidated.delinquentReporting && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
          </h5>
          <Collapse in={openSectionObj.delinquentReporting}>
            <div id="collapse_delinquentReporting">
            {openSectionObj.delinquentReporting && <DelinquentReporting openWidget={openSection} sectionValidated={updateSectionValidated} melloRoosDetails={melloRoosDetails} updateMelloRoosDetails={updateMelloRoosDetails}/>}
              </div>
          </Collapse>
        </div>
      </div>
      
      <div className="panel panel-default">
        <div className="panel-heading" role="tab"> 
          <h5 className="panel-title icon-style" onClick={()=>toggleWidget('docUpload')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}} 
             id="docUpload" aria-expanded={openSectionObj.docUpload} aria-controls="collapse_docUpload"> {!openSectionObj.docUpload ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Delinquency Parcel Reporting</button> 
          </h5>
          <Collapse in={openSectionObj.docUpload}>
            <div id="collapse_docUpload">
             <DocUpload openWidget={openSection} melloRoosDetails={melloRoosDetails} updateIssueDocuments={updateIssueDocuments} />
            </div>
          </Collapse>
        </div>
      </div>
      
      <div className="panel panel-default">
        <div className="panel-heading" role="tab"> 
          <h5 className="panel-title icon-style" onClick={()=>toggleWidget('foreclosure')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}} 
             id="foreclosure" aria-expanded={openSectionObj.foreclosure} aria-controls="collapse_foreclosure"> {!openSectionObj.foreclosure ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Foreclosure</button> 
          </h5>
          <Collapse in={openSectionObj.foreclosure}>
            <div id="collapse_foreclosure">
            {openSectionObj.foreclosure && <Foreclosure openWidget={openSection} melloRoosDetails={melloRoosDetails} foreclosureDetails={foreclosureDetails} updateForeClosures={updateForeClosures}/>}
            </div>
          </Collapse>
        </div>
      </div>
      
      <div className="panel panel-default">
        <div className="panel-heading" role="tab"> 
          <h5 className="panel-title icon-style" onClick={()=>toggleWidget('retiredIssues')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}} 
             id="retiredIssues" aria-expanded={openSectionObj.retiredIssues} aria-controls="collapse_retiredIssues"> {!openSectionObj.retiredIssues ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Retired Issues</button> 
          {sectionValidated.retiredIssues && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
          </h5>
          <Collapse in={openSectionObj.retiredIssues}>
            <div id="collapse_retiredIssues">
            {openSectionObj.retiredIssues && <RetiredIssues openWidget={openSection} instructions={retiredIssuesIns} sectionValidated={updateSectionValidated} melloRoos={melloRoos} melloRoosDetails={melloRoosDetails} updateIssuanceDetails={updateIssuanceDetails} updateMelloRoosDetails={updateMelloRoosDetails}/> }
              </div>
          </Collapse>
        </div>
      </div>
      
      <div className="panel panel-default">
        <div className="panel-heading" role="tab"> 
          <h5 className="panel-title icon-style" onClick={()=>toggleWidget('filingContact')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}} 
             id="filingContact" aria-expanded={openSectionObj.filingContact} aria-controls="collapse_filingContact"> {!openSectionObj.filingContact ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Filing Contact</button> 
          </h5>
          <Collapse in={openSectionObj.filingContact}>
            <div id="collapse_filingContact">
            {openSectionObj.filingContact && <FilingContact openWidget={openSection} melloRoosDetails={melloRoosDetails} />}
            </div>
          </Collapse>
        </div>
      </div>
      
      <div className="panel panel-default">
        <div className="panel-heading" role="tab"> 
          <h5 className="panel-title icon-style" onClick={()=>toggleWidget('comments')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}} 
             id="comments" aria-expanded={openSectionObj.comments} aria-controls="collapse_comments"> {!openSectionObj.comments ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Comments</button> 
          </h5>
          <Collapse in={openSectionObj.comments}>
            <div id="collapse_comments">
            {openSectionObj.comments && <Comments openWidget={openSection} melloRoos={melloRoos} melloRoosDetails={melloRoosDetails} updateMelloRoosDetails={updateMelloRoosDetails}/>}
              </div>
          </Collapse>
        </div>
      </div>
      
      <div className="panel panel-default">
        <div className="panel-heading" role="tab"> 
          <h5 className="panel-title icon-style" onClick={()=>toggleWidget('review')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}} 
             id="review" aria-expanded={openSectionObj.review} aria-controls="collapse_review"> {!openSectionObj.review ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Review</button> 
          </h5>
          <Collapse in={openSectionObj.review}>
            <div id="collapse_review">
            <MelloRoosYFSReview openWidget={openSection} toggleWidget={toggleWidget} melloRoos={melloRoos} melloRoosDetails={melloRoosDetails} contact={contact} foreclosures={foreclosureDetails} issueDocuments={issueDocuments} />
            </div>
          </Collapse>
        </div>
      </div>
      <br/>
      </>
    );
}

export default CollapsibleWidgets

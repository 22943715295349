import {useState, useEffect, useContext} from 'react'
import CollapsibleWidget from './collapsibleWidgets'
import ProgressBar from '../layout/ProgressBar'
import {VscExpandAll, VscCollapseAll} from 'react-icons/vsc'
import History from '../../common/History'
import PrintPdfComponent from './printPdfComponent'
import { renderToString } from 'react-dom/server'
import { useLocation, useNavigate } from "react-router-dom";
import ReportOfProposedDebtService from '../../services/ReportOfProposedDebtService'
import { Spinner } from 'react-bootstrap'
import { IssuerContext, UserContext } from '../../UseContext'
import HtmlPdfService from '../../services/HtmlPdfService'
import { toast } from 'react-toastify'
import CommonService from '../../services/CommonService'

const ProposedSaleReport = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const issueId = pathname.substring(pathname.lastIndexOf('/') + 1);
    const loginUser = useContext(UserContext);

    const [showHistory, setShowHistory] = useState(false);
    const [expandAll, setExpandAll] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [proposedDebtDetails, setProposedDebtDetails] = useState({});
    const [proposeDebtDependencies, setProposeDebtDependencies] = useState({
        "issueAuthorizations" : [],
        "issueStatutes" : [],
        "issueContacts": [],
        "issueRefundAssocs" : [],
        "issueFinancingPurposes" : [],
        "issueDocuments" : [],
        "issueMkrAuthAssocs" : [],
        "issuePrivateSaleReasons" : [],
        "issueRepaymentSources" : [],
        "issueSocEnvImpacts" : []
    });

    const getFederalLawSelection = (proposeDebt) => {
        let federalTaxSelectionArr = proposeDebt.federalTaxFlag ? proposeDebt.federalTaxFlag.split("") : [];

        let federalLaw = [];
        federalTaxSelectionArr.forEach(val => {
          if( val === 'T') {
            federalLaw.push("Taxable");
          }
          if( val === 'E') {
            federalLaw.push("Tax Exempt, No AMT");
          }
          if( val === 'A') {
            federalLaw.push("Tax Exempt, with AMT");
          }
        });
        federalLaw = federalLaw.join(', ');
        return federalLaw;
   } 

    useEffect(()=>{
        async function fetchProposeDebt() {
            let obj = {
                "msgId": null,
                "sysId": null,
                "opr": "getProposeDebt",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "id" : issueId  
                }
            };
            await ReportOfProposedDebtService.getProposedDebt(obj)
            .then((res) => res)
            .then((result) => {
                let federalLawDisplayText = getFederalLawSelection(result.data.responses[0]);
                result.data.responses[0].federalLawDisplayText = federalLawDisplayText;
                setProposedDebtDetails(result.data.responses[0]);
                //setIsLoading(false);
            });
        }
        async function fetchFinalSaleOtherDetails() {
            let obj = {
                "msgId": null,
                "sysId": null,
                "opr": "getProposeDebtOtherDetails",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "id" : issueId  
                }
            };
            await ReportOfProposedDebtService.getProposedDebt(obj)
            .then((res) => res)
            .then((result) => {
                if(result.data.errors[0]) {
                    toast(result.data.errors[0].message);
                } else {
                    setProposeDebtDependencies(result.data.responses[0]);
                }
            });
        }

        fetchProposeDebt();
        fetchFinalSaleOtherDetails();
        setTimeout(() => {
            setIsLoading(false);
          }, 5000);
    },[issueId])

    const [openSection, setOpenSection] = useState({
        issuance: true,
        issuanceAuthorization: false,
        statutories: false,
        issuerContact: false,
        filingContact: false,
        financingParticipants: false,
        typeOfDebt: false,
        interestDebtExempt: false,
        typeOfSale: false,
        sourceOfRepayment: false,
        purposeOfFinancing: false,
        uploadDoc: false,
        review: false
    });

    const updateProposedDebtDetails = (proposedDebtDetails) => {
        let federalLawDisplayText = getFederalLawSelection(proposedDebtDetails);
        proposedDebtDetails.federalLawDisplayText = federalLawDisplayText;
        setProposedDebtDetails(proposedDebtDetails);
    }
    const updateProposeDebtDependencies = (data, type) => {
        setProposeDebtDependencies(prevState => ({
            ...proposeDebtDependencies, [type] : data
        }));
    }
    const toggleWidget = (sectionName) => {
      setOpenSection({...openSection, [sectionName]: !(openSection[sectionName])})
    }

    const toggleAllWidgets = () => {
        setExpandAll( !(expandAll) );
        let openSecObj = Object.assign(...Object.keys(openSection).map(k => ({ [k]: !(expandAll) })));
        setOpenSection(openSecObj);
    }
    const openWidget = (sectionName) => {
        let openSecObj = Object.assign(...Object.keys(openSection).map(k => ({ [k]: false })));
        openSecObj[sectionName] = !(openSecObj[sectionName]);
        setOpenSection(openSecObj);
    }
    
    const handlePrint = () => {
        const html = renderToString(<PrintPdfComponent proposedDebtDetails={proposedDebtDetails} proposeDebtDependencies={proposeDebtDependencies} />)

        var obj = {
            msgId: null,
            sysId: null,
            opr: "getPdf",
            hdrStruct: null,
            queryParam: null,
            reqData: {
                "html": html,
                "id" : issueId,
                "cdiacNbr": proposedDebtDetails.cdiacNbr,
                "createUser":  CommonService.getUserName(loginUser),   
                "status": 'D',
                "reportType" : "RPD" 
            },
          };

        HtmlPdfService.generatePdf(obj)
        .then((res) => { 
             var base64EncodedPDF = res.data;
             var arrrayBuffer = base64ToArrayBuffer(base64EncodedPDF); 
             function base64ToArrayBuffer(base64) {
                 let binaryString = window.atob(base64);
                 let binaryLen = binaryString.length;
                 var bytes = new Uint8Array(binaryLen);
                 for (var i = 0; i < binaryLen; i++) {
                     var ascii = binaryString.charCodeAt(i);
                     bytes[i] = ascii;
                 }
                 return bytes;
             }
             var blob = new Blob([arrrayBuffer], {type: "application/pdf"});
             var url = window.URL.createObjectURL(blob);
             window.open(url);

        }) // Handle the response from backend here
          .catch((err) => { }); // Catch errors if any
         
    }
    const backToDashboard = () => {
        const dashboardSelectionObj = JSON.parse(sessionStorage.getItem('dashboardSelection'));
        navigate("/dashboard", {state: {dashboardSelection: dashboardSelectionObj}});
    }
    return (
        
        <div>
            <div className="panel panel-default">
                <div className="panel-heading" role="tab"> 
                    <div className="container">
                        <div className="panel-title">
                        <div className="fright" style={{margin: '5px'}}>
                            <button className="custom-button"><a href="/assets/referenceGuides/Quick Ref Guide-RPDI.pdf" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: 'white'}}>Quick Reference Guide</a></button>{" "}
                           {proposedDebtDetails && proposedDebtDetails.issueStatusFlag === 'D' && <button className="custom-button" onClick={()=>handlePrint()}>Print PDF (Draft)</button> }
                        </div>

                            <h1 className="main-title">Report of Proposed Debt Issuance</h1>
                            <small>(*Required)</small>
                        </div>
                    </div>
                </div>
                <div className="panel-body" style={{padding: '10px 0'}}>
                    <div className="container">
                        <ProgressBar openSection={openWidget}/> 
                        <div className="center-align">
                        <div style={{float: 'left'}}>
                                <button
                                type="button"
                                className="dk-blue-color link-button" 
                                onClick={()=>backToDashboard()}>Back to Dashboard</button></div>
                        <button
                                type="button"
                                className="dk-blue-color link-button" 
                                onClick={()=>toggleAllWidgets()}>{expandAll ? <VscCollapseAll /> : <VscExpandAll /> } {expandAll ? "Collapse All" : "Expand All" }</button>
                            <div style={{float: 'right'}}>
                                <button
                                type="button"
                                className="dk-blue-color link-button" 
                                onClick={()=>setShowHistory(true)}>Show History</button></div>
                        </div><br/>
                        <div>
                            <p>CDIAC Number: ( {proposedDebtDetails && proposedDebtDetails.cdiacNbr} )</p>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading ? <Spinner animation="border" variant="primary"/> :
                <IssuerContext.Provider value={proposedDebtDetails.issuerId && proposedDebtDetails.issuerId}>
                    <><CollapsibleWidget openSectionObj={openSection} toggleWidget={toggleWidget} openSection={openWidget} issueId={issueId} 
                    proposedDebtDetails={proposedDebtDetails} 
                    proposeDebtDependencies = {proposeDebtDependencies}
                    updateProposedDebtDetails={updateProposedDebtDetails}
                    updateProposeDebtDependencies={updateProposeDebtDependencies}/>
                    <div className="panel panel-default">
                        <div className="panel-heading"> 
                            <div className="container">
                                <div className="panel-body" style={{padding: '10px 0 0'}} >
                                <button className="custom-button" onClick={()=>backToDashboard()}>Back to Dashboard</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <PrintPdfComponent proposedDebtDetails={proposedDebtDetails} proposeDebtDependencies={proposeDebtDependencies} /> */}
                    </>
                </IssuerContext.Provider>
            }
            {showHistory && <History onModalClose={()=>setShowHistory(false)} issueId={proposedDebtDetails.proposeDebtId} reportTypeId="propose_debt_id"/>}

        </div>
    )
}

export default ProposedSaleReport

import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import IssuerService from '../../services/IssuerService';
import { LoggedInUserContactContext } from '../../UseContext';
import CommonService from '../../services/CommonService';
import { useMsal } from '@azure/msal-react';

const Layout = (props) => {
    const {instance} = useMsal();
    const {user} = props;
    const [loggedInUserInfo, setLoggedInUserInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    //const idToken = localStorage.getItem("token");
    useEffect(()=>{
        CommonService.showHideSpinner(true);

        const getUserContact =  async() => {
            //if(idToken !== null) {
            let obj = {
                "msgId": null,
                "sysId": null,
                "opr": "getUserContactByEmailId",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "userEmail": user.username 
                }
            };
    
            await IssuerService.getIssuersByUserId(obj)
            .then((res) => res)
            .then((result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    CommonService.handleServerResponse(result.data, instance);
                } else {
                    setLoggedInUserInfo(result.data.responses[0])
                }
                setIsLoading(false);
                CommonService.showHideSpinner(false);
            })
            //}
        }
        getUserContact();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.username]);    
    
    return (
        <div>
            <LoggedInUserContactContext.Provider value={loggedInUserInfo}>
            <div id="HeaderAlertMessage"/>
            {!isLoading && <>
            <Header user={user} />
            <Sidebar />
            <div className="container" style={{minHeight: '70vh'}}>
            <main>{props.children}</main>
            </div>
            <Footer />
            </>
            }
            </LoggedInUserContactContext.Provider> 
        </div>
    )
}

export default Layout

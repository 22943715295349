import React from 'react'
import { Form } from 'react-bootstrap'
import CurrencyInput from './CurrencyInput'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {FaCalendarAlt} from 'react-icons/fa'

const field = (
    i,
    data,
    col,
    handleChange
  ) => {
      switch(col.fieldType) {
        case("currency") :
          return(
            <td key={i}><div className="control"><CurrencyInput name={col.prop}
            onChange={e => handleChange(e, col.prop)}
            value={data[col.prop]}
            disabled={col.editable === false}/></div></td>
          );
        case("date") :
          return(
            <td key={i}><div className="control">
              <span>
                <DatePicker 
                  selected={data[col.prop] ? new Date(data[col.prop]) : null}
                  dateFormat="M/d/yyyy"
                  maxDate= {col.maxDate}
                  minDate= {col.minDate}
                  name="{col.prop}"
                  type="date"
                  onChange={(e)=>handleChange(e,col.prop)}
                  disabled={col.editable === false} 
                  />
                <FaCalendarAlt className="date-picker-icon"/>
              </span>
            </div></td>
          )
        default :
          return(
            <td key={i}><Form.Control
              name={col.prop}
              onChange={e => handleChange(e, col.prop)}
              value={data[col.prop]}
              disabled={col.editable === false}
            /></td>
          )
      }
    
  }

const EditableRow = ({
   data,
   handleChange,
   handleCancelClick,
   handleUpdate,
   columns
}) => {
  return (
    <tr>
        {columns.map((col, i)=>(
            field(
              i,
              data,
              col,
              handleChange,
            )
        ))}
        <td>
            <button type="button" className="link-button dk-blue-color"
            style={{textDecoration: 'none', paddingRight: '1em'}} onClick={handleUpdate}>Save</button>
            <button type="button" className="link-button dk-blue-color"
            style={{textDecoration: 'none', paddingRight: '1em'}} onClick={handleCancelClick}>Cancel</button>
        </td>
    </tr>
  );
};

export default EditableRow;
import { Form, Spinner, Button } from 'react-bootstrap';
import {useEffect, useState, useContext} from 'react';
import Card  from '../ui/Card';
import BatchUploadList from './batchUploadList';
import { LoggedInUserContactContext } from '../../UseContext';
import { toast } from 'react-toastify';
import BlobService from '../../services/BlobService';
import BaseService from '../../services/BaseService';

const AdtrBatchUpload = () => {
    const loggedInUserInfo = useContext(LoggedInUserContactContext);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [file, setFile] = useState(null);
    const [showAdtrBatchUploads, setShowAdtrBatchUploads] = useState(false);
    const [fileType, setFileType] = useState("");

    async function getADTRBatchUploads() {
      setIsLoading(true);
      let obj = {
        "msgId": null,
        "sysId": null,
        "opr": "getADTRBatchUploadsByUserId",
        "hdrStruct": null,
        "queryParam": null,
        "reqData": {
            "userId": loggedInUserInfo.contactEmailId 
        }
      };
    
      await BaseService.processRequest(obj)
        .then((res) => res)
        .then((result) => {
            if(result.data.responses.length > 0 && result.data.responses !== null) {
              setUploadedFiles(result.data.responses[0]);
            }
            setShowAdtrBatchUploads(true);
            setIsLoading(false);
      });
    }
         
    const handleFileTypeChange = (e) => {
      setFileType(e.target.value);
    }

    useEffect(()=>{
      getADTRBatchUploads();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    
    const handleChange = (e) => {
      setFile(e.target.files[0])
    }

    const handleUpload = (e) => {
      if (file && fileType !== "" ){
        let formData = new FormData();
        
        formData.append('file', file);
        formData.append('createUser', loggedInUserInfo.contactEmailId);
        formData.append('mimeType', 'csv');
        formData.append('fileName', file.name);
        formData.append('fileUploadType', 'adtrBatch');
        formData.append('fileType', fileType);

        BlobService.uploadFile(formData)
        .then((res) => { 
            if (res.data.errors && res.data.errors.length > 0){
                toast.error(res.data.errors[0].message);
            }
            else{
                e.target.value = null;
                toast.success("File uploaded successfully!");
            }
            getADTRBatchUploads();
        }) // Handle the response from backend here
          .catch((err) => { }); // Catch errors if any
      } else {
          let formErrorsArr = {};
          if( fileType === "" ) formErrorsArr['fileType'] = "Please choose file type." ;
          if(!file) formErrorsArr['inputFile'] = "This field is required." ;
          setFormErrors(formErrorsArr);
      }
    }

    return (
        <Card>
            <div className="panel panel-default">
              <div className="panel-heading">
                  <div className="container">
                      <div id="packet_id" className="panel-title">
                        <div  style={{float: 'right', padding:'0.25rem'}}>
                          <button className="custom-button"><a href="/assets/referenceGuides/Quick Ref Guide-ADTR Batch.pdf" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: 'white'}}>Quick Reference Guide</a></button>{" "}
                        </div>
                        <h1 className="menu-title">ADTR Batch Upload</h1>
                      </div>
                  </div>
              </div>
              <div className="panel-body">
                <div className="container form">
                  <form className="form">
                    <div className="mb-20">
                      <div col-md="3">
                      <label>Please choose the file type:</label>
                      </div>
                      <div>
                        <Form.Check inline 
                        type="radio" 
                        name="fileType" 
                        id="auth" 
                        value="auth"
                        label="Outstanding Balance and Authorizations"
                        checked={fileType === "auth"}
                        onChange={(e)=>handleFileTypeChange(e)} />
                        <Form.Check inline 
                        type="radio" 
                        name="fileType" 
                        id="proceed" 
                        value="proceed"
                        label="Proceeds and Expenditures" 
                        checked={fileType === "proceed"}
                        onChange={(e)=>handleFileTypeChange(e)} />
                      </div>
                      {formErrors['fileType'] && <p className="error">{formErrors['fileType']}</p>}
                    </div>
                    <div className="mb-20">
                      <label className="mb-10">Choose a document to upload</label>
                      <div className="mb-10">
                          <input type="file" className="form-control" name="file" accept=".csv" onChange={(e)=>handleChange(e)}/>
                      </div>
                      {formErrors['inputFile'] && <p className="error">{formErrors['inputFile']}</p>}
                      <div className="mb-10">
                          <Button className="custom-button" type="button" onClick={(e) => handleUpload(e)}>Upload File</Button>
                      </div>
                    </div>  
                  

                  </form>
                </div>    
              </div>            
            </div>
            {isLoading ? 
            <Spinner animation="border" variant="primary" /> : 
            (showAdtrBatchUploads && <BatchUploadList files={uploadedFiles}/>) 
            }
        </Card>
    )
}

export default AdtrBatchUpload;

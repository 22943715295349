import {useState, useEffect, useContext, Fragment} from 'react'
import Card from '../ui/Card'
import {Row, Col, Form, Modal, Spinner} from 'react-bootstrap'
import CommonService from "../../services/CommonService";
import FinalSaleReportService from '../../services/FinalSaleReportService'
import { UserContext } from "../../UseContext"
import CurrencyInput from '../formElements/CurrencyInput'
import { FormInput } from '../formElements/FormInput'
import AdtrService from '../../services/AdtrService'
import ReadOnlyRow from '../formElements/ReadOnlyRow'
import EditableRow from '../formElements/EditableRow'
import { toast } from 'react-toastify'
import { validateFormFields } from '../../utils'
import {AiFillQuestionCircle} from 'react-icons/ai'
import { MDBTooltip } from 'mdbreact'
import IssueCdiacNumberService from '../../services/IssueCdiacNumberService'
import DataGrid from '../GenericComponents/DataGrid';
import { Column } from 'primereact/column';

const ProceedsTable = (props) => {
    const {onModalClose, linkClicked, finalSaleId} = props;
    const [proceeds, setProceeds] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const functionName = (linkClicked === "MLRProceeds" ? "getMLRProceeds" : "getMKRProceeds");
    useEffect(() => {
        async function getProceeds () {
            var obj = {
            msgId: null,
            sysId: null,
            opr: functionName,
            hdrStruct: null,
            queryParam: null,
            reqData:{"id" : finalSaleId}
            };
        
            await AdtrService.getInfo(obj)
            .then((result) => {
                setProceeds(result.data.responses[0]);
                setIsLoading(false);
            });
        }

       getProceeds();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [functionName, finalSaleId]);

    const proceedsColumns = [
        {
            dataField: 'mkrSource',
            text: 'Source',
            hidden: linkClicked === "MKRProceeds" ? false : true
        },
        {
            dataField: 'fiscalYrEndDate',
            text: 'Fiscal Year End Date',
            formatter: CommonService.dateFormatter
        },{
            dataField: 'capitalizedIntFundAmt',
            text: 'Capitalized Interest Fund Amount',
            formatter: CommonService.currencyFormatter
        },{
            dataField: 'constructionFundAmt',
            text: 'Construction Fund balance',
            formatter: CommonService.currencyFormatter,
            hidden: linkClicked === "MLRProceeds" ? false : true
        },{
            dataField: 'principalOutstdgAmt',
            text: 'Principal Outstanding Amount',
            formatter: CommonService.currencyFormatter
        }
    ]
    return (
        <Modal show={true} onHide={onModalClose} backdrop="static" keyboard={false}  size="lg">
            <Modal.Header closeButton>
                <h3>{linkClicked==="MLRProceeds" ? 
                "Proceeds from Mello-Roos Last Submission" :
                "Proceeds from Marks-Roos Last Submission"}</h3></Modal.Header>
            <Modal.Body>
            {isLoading ? 
            <Spinner animation="border" variant="primary" /> :
            <DataGrid
            dataSource={proceeds}
            sortField='fiscalYrEndDate'
            showPaginator={false}
            showQuickSearch={false}
            emptyDataMessage='There is no data to display.'
            >
             {proceedsColumns.map((column, i) => {
                 return (
                 <Column 
                 key={i}
                 field={column.dataField}
                 header={column.text}
                 sortable={column.sort} 
                 body={column.formatter}
                 hidden={column.hidden}
                 />)
                 }) 
             } 
            </DataGrid>
            }
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="custom-button custom-button-secondary" onClick={()=>onModalClose()}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}
const UseOfProceeds = (props) => {
    const { adtrDependenciesData, adtrId, updateAdtrDependencies} = props;
    const isAdtrFirstYear = adtrDependenciesData.isAdtrFirstYear;
    const issuanceDetail = adtrDependenciesData.issuanceDetails;
    const adtrInfo = adtrDependenciesData.adtrInfo;
    const [fiscalYrProceedsSummary, setFiscalYrProceedsSummary] = useState(adtrDependenciesData.fiscalYrProceedsSummary);
    const [proceedsSummary, setProceedsSummary] = useState(adtrDependenciesData.proceedsSummary);
    const [expenditureSummary, setExpenditureSummary] = useState(adtrDependenciesData.expenditureSummary);
    const [currentPeriodexpenditures, setCurrentPeriodExpenditures] = useState(adtrDependenciesData.currentPeriodexpenditures);
    const [issueRefundedAssocs, setIssueRefundedAssocs] = useState(adtrDependenciesData.issueRefundingAssocs)
    const [proceedsFundCategories, setProceedsFundCategories] = useState(null);
    const [showOtherField, setShowOtherField] = useState(false);
    const [expenditureFundCategories, setExpenditureFundCategories] = useState(null);
    const [editProceedFundInd, setEditProceedFundInd] = useState(null);
    const [editProceedData, setEditProceedData] = useState({});
    const [editExpenditureInd, setEditExpenditureInd] = useState(null);
    const [editExpenditureData, setEditExpenditureData] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [showMLRProceeds, setShowMLRProceeds] = useState(false);
    const [showMKRProceeds, setShowMKRProceeds] = useState(false);
    const [showReportableProceedsHelpText, setShowReportableProceedsHelpText] = useState(false);
    const [showProceedsSpentUnspentHelpText, setShowProceedsSpentUnspentHelpText] = useState(false);
    const [showProceedsExpenditureHelpText, setShowProceedsExpenditureHelpText] = useState(false);
    const [showPurposeHelpText, setShowPurposeHelpText] = useState(false);
    const [refundedAmtTotal, setRefundedAmtTotal] = useState(0)
    const [showRefundIssueHelpText, setShowRefundIssueHelpText ] = useState(false) 
    const [editInd, setEditInd] = useState(null)
    const [editData, setEditData] = useState({})
    const [fiscalYrStartDate, setFiscalYrStartDate] = useState(null)
    const user = useContext(UserContext);
    
    const[stateValues, setStateValues] = useState({
        'fundCategoryText' : '',
        'proceedAvlAmt' : '',
        'otherFundCategory' : '',
        'purposeDesc' : '',
        'currSpentAmt': '',
        'adtrProceedId': ''
    })

    async function getExpenditureFundCategories() {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "getExpendituresFundCategories",
            hdrStruct: null,
            queryParam: null,
            reqData:{"adtrId" : adtrId}
            };
        await FinalSaleReportService.getAdtrInfo(obj)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0) {
                    console.log(result.data.errors[0].message);
                } else {
                    setExpenditureFundCategories(result.data.responses[0]);
                }
                
            });
    }

    useEffect(()=>{
        const fiscalYear = CommonService.getFullYear(adtrInfo.fiscalYrEndDate)
        let today = new Date()
        today.setMonth(6)
        today.setDate(1)
        today.setFullYear(fiscalYear - 1)
        const fiscalYearStartDate = today
        issueRefundedAssocs && issueRefundedAssocs.map(item => {
            if(!item.redeemDate || (new Date(item.redeemDate) > fiscalYearStartDate && new Date(item.redeemDate) < new Date(adtrInfo.fiscalYrEndDate) && adtrInfo.statusFlag !== 'R')){
                item.isRowEditable = true
            } else {
                item.isRowEditable = false
            }
            return item;
        });
        const refundedAmtTotal = issueRefundedAssocs.reduce((total, item) => total + (item.redeemAmt && item.redeemAmt !== "" ? item.redeemAmt : 0), 0)
        setRefundedAmtTotal(refundedAmtTotal)
        setIssueRefundedAssocs(issueRefundedAssocs)
        setFiscalYrStartDate(fiscalYearStartDate)
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        async function getProceedsFundCategories() {
            var obj = {
                msgId: null,
                sysId: null,
                opr: "getProceedsFundCategories",
                hdrStruct: null,
                queryParam: null,
                reqData:{"adtrId" : adtrId}
                };
            await FinalSaleReportService.getAdtrInfo(obj)
                .then((result) => {
                    if(result.data.errors && result.data.errors.length > 0) {
                        console.log(result.data.errors[0].message);
                    } else {
                        setProceedsFundCategories(result.data.responses[0])
                    }
                    
                });
        }  
        
        getProceedsFundCategories();
        getExpenditureFundCategories();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUseOfProceedsSummary = async() => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "getUseOfProceedsSummary",
            hdrStruct: null,
            queryParam: null,
            reqData:{
                "adtr" : {
                    "finalSaleId" : adtrInfo.finalSaleId,
                    "fiscalYrEndDate" : adtrInfo.fiscalYrEndDate
                }
            }
        };
        await AdtrService.getUseOfProceedsSummary(obj)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0) {
                    console.log(result.data.errors[0].message);
                } else {
                    setFiscalYrProceedsSummary(result.data.responses[0].fiscalYrProceedsSummary);
                    setProceedsSummary(result.data.responses[0].proceedsSummary);
                    setExpenditureSummary(result.data.responses[0].expenditureSummary);
                    setCurrentPeriodExpenditures(result.data.responses[0].currentPeriodexpenditures);
                    updateAdtrDependencies(result.data.responses[0], 'useOfProceeds')
                }
                
            });
    }
    const validateForm = async() => {
        if(isAdtrFirstYear){
            await validateTotalReportableProceedsAvl();
        } else {
            props.openWidget('filingContact');
        }
    }
    const validateTotalReportableProceedsAvl = () => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "validateTotalReportableProceedsAvl",
            hdrStruct: null,
            queryParam: null,
            reqData:{"adtrId" : adtrId}
            };

        AdtrService.validateTotalReportableProceedsAvl(obj)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0) {
                    toast.error(result.data.errors[0].message);
                } else {
                    props.openWidget('filingContact');
                }
            });
    }
    // const getRemainAmount  = (cell, row, rowIndex, formatExtraData) => { 
    //     return ( 
    //         CommonService.currencyFormatter(row.proceedAvailableAmt - row.proceedSpentAmt)
    //     ); 
    // }
    // const fiscalYrPrsSummaryColumns = [{
    //     dataField: 'fiscalYrEndDate',
    //     text: 'Reporting Period End Date',
    //     formatter: CommonService.dateFormatter,
    //     sort: true
    //   },{
    //     dataField: 'proceedAvailableAmt',
    //     text: 'Beginning Amount',
    //     formatter: CommonService.currencyFormatter
    //   },{
    //     dataField: 'proceedSpentAmt',
    //     text: 'Spent Amount',
    //     formatter: CommonService.currencyFormatter
    // },{
    //     dataField: '',
    //     text: 'Remaining Amount',
    //     formatter: getRemainAmount
    // }]
    
    // const proceedsSummaryColumns = [
    //     {
    //         dataField: 'fundCategory',
    //         text: 'Fund Category',
    //     },{
    //         dataField: 'proceedAvlAmt',
    //         text: 'Total Reportable Proceeds Available',
    //         formatter: CommonService.currencyFormatter
    //     },{
    //         dataField: 'currSpentAmt',
    //         text: 'Proceeds Spent Current Reporting Period',
    //         formatter: CommonService.currencyFormatter
    //     },{
    //         dataField: 'prevSpentAmt',
    //         text: 'Proceeds Spent Prior Reporting Period',
    //         formatter: CommonService.currencyFormatter
    //     },{
    //         dataField: 'balance',
    //         text: 'Proceeds Unspent / Remaining',
    //         formatter: CommonService.currencyFormatter
    //     }
    // ]
    
    const handleChange = (e) => {
        const{name,value} = e.target;

        setStateValues(prevState => ({
            ...stateValues, [name] : value
        }));
        if(name === "fundCategoryText"){
            if( value === "Other") {
                setShowOtherField(true);
            } else {
                setShowOtherField(false);
            }
        }
    }
    const handleAddFund = async() => {        
        var obj = {
            msgId: null,
            sysId: null,
            opr: "addAdtrProceed",
            hdrStruct: null,
            queryParam: null,
            reqData:{
                "adtrProceed" : {
                    "adtrId" : adtrId,
                    "proceedAvlAmt" : stateValues.proceedAvlAmt,
                    "fundCategoryText" : stateValues.fundCategoryText !== "Other" ? stateValues.fundCategoryText : stateValues.otherFundCategory,
                    "createUser" : CommonService.getUserName(user),
                    "lastUpdateUser" : CommonService.getUserName(user)
                }
            }
        };
        await AdtrService.saveAdtrProceed(obj)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0) {
                    console.error(result.data.errors[0].message);
                } else {
                    const newStateValues = {
                        'fundCategoryText' : '',
                        'proceedAvlAmt' : '',
                        'otherFundCategory' : ''
                    }
                    setStateValues(newStateValues);
                    getUseOfProceedsSummary();
                    getExpenditureFundCategories();
                }
                
            });
    }
    const handleAddExpenditure = async(e) => {  
        const form = e.target.closest('form#add-expenditure');
        let formErrors = validateFormFields(form.elements);
        
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {  
            setFormErrors({}); 
            var obj = {
                msgId: null,
                sysId: null,
                opr: "addAdtrProceedExpenditure",
                hdrStruct: null,
                queryParam: null,
                reqData:{
                    "adtrProceedExpenditure" : {
                        "adtrProceedId" : stateValues.adtrProceedId,
                        "spentAmt" : stateValues.currSpentAmt,
                        "purposeDesc" : stateValues.purposeDesc,
                        "createUser" : CommonService.getUserName(user),
                        "lastUpdateUser" : CommonService.getUserName(user)
                    }
                }
            };
            await AdtrService.saveAdtrProceed(obj)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0) {
                    console.error(result.data.errors[0].message);
                } else {
                    const newStateValues = {
                        'adtrProceedId' : '',
                        'currSpentAmt' : '',
                        'purposeDesc' : ''
                    }
                    setStateValues(newStateValues);
                    getUseOfProceedsSummary();
                }
                
            });
        }
    }
    const handleProccedEditClick = (event, i, item) => {
        event.preventDefault();
        setEditProceedFundInd(i);
        setEditProceedData(item);
    }
    const handleProceedFundChange = (e, i) => {
        e.preventDefault();
        const {name, value} = e.target;
        let newEditProceedData = {...editProceedData};
        newEditProceedData[name] = value;
        setEditProceedData(newEditProceedData);
    }

    const handleProceedFundUpdate = async (event) => {
        event.preventDefault();

        var obj = {
            msgId: null,
            sysId: null,
            opr: "updateAdtrProceed",
            hdrStruct: null,
            queryParam: null,
            reqData:{
                "adtrProceed" : {
                    "fundCategoryText" : editProceedData.fundCategory,
                    "adtrId" : adtrId,
                    "proceedAvlAmt" : editProceedData.proceedAvlAmt,
                    "LastUpdateUser" : CommonService.getUserName(user)
                }
            }
        };
        await AdtrService.saveAdtrProceed(obj)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0) {
                    console.log(result.data.errors[0].message);
                } else {
                    getUseOfProceedsSummary();
                    setEditProceedFundInd(null);
                }
                
            });
    }
    const handleProceedCancelClick = () => {
        setEditProceedFundInd(null);
    }

    const handleExpenditureEditClick = (event, i, item) => {
        event.preventDefault();
        setEditExpenditureInd(i);
        setEditExpenditureData(item);
    }
    const handleExpenditureChange = (e, i) => {
        e.preventDefault();

        const {name, value} = e.target;
        let newEditExpenditureData = {...editExpenditureData};
        newEditExpenditureData[name] = value;
        setEditExpenditureData(newEditExpenditureData);
    }

    const handleExpenditureUpdate = async (event) => {
        event.preventDefault();

        var obj = {
            msgId: null,
            sysId: null,
            opr: "updateAdtrProceedExpenditure",
            hdrStruct: null,
            queryParam: null,
            reqData:{
                "adtrProceedExpenditure" : {
                    "adtrProceedExpenditureId" : editExpenditureData.adtrProceedExpenseId,
                    "purposeDesc" : editExpenditureData.purposeDesc,
                    "spentAmt" : editExpenditureData.currSpentAmt,
                    "LastUpdateUser" : CommonService.getUserName(user)
                }
            }
        };
        await AdtrService.saveAdtrProceed(obj)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0) {
                    console.error(result.data.errors[0].message);
                } else {
                    getUseOfProceedsSummary();
                    setEditExpenditureInd(null);
                }
                
            });
    }
    const handleExpenditureCancelClick = () => {
        setEditExpenditureInd(null);
    }
    const handleProceedDelete = async (event, proceed) => {
        event.preventDefault();
        var obj = {
            msgId: null,
            sysId: null,
            opr: "deleteAdtrProceed",
            hdrStruct: null,
            queryParam: null,
            reqData:{
                "fundCategoryText" : proceed.fundCategory,
                "adtrId" : adtrId,
                }
            
        };
        await AdtrService.saveAdtrProceed(obj)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0) {
                    console.error(result.data.errors[0].message);
                } else {
                    getUseOfProceedsSummary();
                }
                
            });
    }
    const handleExpenditureDelete = async (event, expenditure) => {
        event.preventDefault();

        var obj = {
            msgId: null,
            sysId: null,
            opr: "deleteAdtrProceedExpenditure",
            hdrStruct: null,
            queryParam: null,
            reqData:{
                    "adtrProceedExpenseId" : expenditure.adtrProceedExpenseId,
            }
        };
        await AdtrService.saveAdtrProceed(obj)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0) {
                    console.error(result.data.errors[0].message);
                } else {
                    getUseOfProceedsSummary();
                }
                
            });
    }
    const handleMLRProceedsClick = () => {
        setShowMLRProceeds(true);
    }
    const handleMKRProceedsClick = () => {
        setShowMKRProceeds(true);
    }

    const handleSave = (e) => {
        e.preventDefault()
        updateIssueRefundAssoc(editData);
    }

    const handleRefundDateChange = (e, name) => {
        let newEditData = {...editData};
        newEditData[name] = e;
        setEditData(newEditData);
    }

    const updateIssueRefundAssoc = async () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putIssueRefundAssoc",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id" : editData.issueRefundId,
                "issueRefundAssoc" : {...editData, 'lastUpdateUser' : user}
            }
        };
    
      await IssueCdiacNumberService.saveIssueRefundAssoc(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                console.log(result.data.errors[0].message)
            }
            getIssueRefundedAssocs()
            setEditInd(null)
        });
    }

    const getIssueRefundedAssocs = async () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getIssueRefundAssocByCdiacNbr",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "cdiacNbr" :  adtrInfo.cdiacNbr
            }
        };
    
      await IssueCdiacNumberService.saveIssueRefundAssoc(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                console.log(result.data.errors[0].message)
            } else {
                setIssueRefundedAssocs(result.data.responses[0])
                updateAdtrDependencies(result.data.responses[0], 'issueRefundingAssocs')
            }
        });
    }

    const handleCancelClick = () => {
        setEditInd(null);
    }
    const handleEditClick = (event, i, item) => {
        event.preventDefault();
        setEditInd(i);
        setEditData(item);
    }
    return (
        <Card>
            <div className="form">
                <div>
                    <p><b>Reportable Proceeds </b>
                    <AiFillQuestionCircle onClick={()=>setShowReportableProceedsHelpText(true)} className="help-icon heading-help-icon"/>
                    {showReportableProceedsHelpText && 
                    <Modal show={true} backdrop="static" onHide={()=>setShowReportableProceedsHelpText(false)}>
                        <Modal.Body>
                            <div>
                            Read-only, reference values in table below. Beginning Amount on 1 st year ADTR is
prepopulated from the Total Reportable Proceeds in the Issuance section above. In
subsequent years, prepopulated with Remaining Amount from the prior year ADTR.
Spent Amount is populated dynamically from Expenditures of Current Reporting
Period below. Remaining Amount is calculated.
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button variant="secondary" onClick={()=>setShowReportableProceedsHelpText(false)}>Close</button>
                        </Modal.Footer>
                    </Modal>
                    }</p>
                    <div className="container">
                        <form>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                <th>Reporting Period End Date</th>
                                <th>Beginning Amount</th>
                                <th>Spent Amount</th>
                                <th>Remaining Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                            {(fiscalYrProceedsSummary && fiscalYrProceedsSummary.length > 0) ? fiscalYrProceedsSummary.map((item, i)=>(
                                <Fragment>
                                    <ReadOnlyRow 
                                    key={i}
                                    data={item} 
                                    isActionsRequired={false}
                                    columns={[
                                        {
                                        prop: "fiscalYrEndDate",
                                        fieldType: "date"
                                        },
                                        {
                                        prop: "proceedAvailableAmt",
                                        fieldType: "currency",
                                        },
                                        {
                                        prop: "proceedSpentAmt",
                                        fieldType: "currency"
                                        },
                                        {
                                        prop: "",
                                        fieldType: "formatted",
                                        value: CommonService.currencyFormatter(item.proceedAvailableAmt - item.proceedSpentAmt)
                                        }
                                    ]}
                                    />
                                </Fragment>
                            )) :
                            <tr><td colSpan="4" style={{textAlign:'center'}}>No data available to display.</td></tr>
                            }
                            </tbody>
                        </table>
                        </form>
                    </div>
                </div>
                <div>
                    <p><b>Proceeds Spent/Unspent (Fund Level) </b>
                    <AiFillQuestionCircle onClick={()=>setShowProceedsSpentUnspentHelpText(true)} className="help-icon heading-help-icon"/>
                    {showProceedsSpentUnspentHelpText && 
                    <Modal show={true} backdrop="static" onHide={()=>setShowProceedsSpentUnspentHelpText(false)}>
                        <Modal.Body>
                            <div>
                            For the 1 st year ADTR, select a fund category, enter the amount of proceeds in each, and select Add Fund. The issue’s
Total Reportable Proceeds must be assigned to a fund category. Category edits and deletions may be made in the table
below – 1 st year only. In subsequent years, fund categories and amounts may not be added or edited and the table
below will be prepopulated with previously submitted fund and expenditure data.
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button variant="secondary" onClick={()=>setShowProceedsSpentUnspentHelpText(false)}>Close</button>
                        </Modal.Footer>
                    </Modal>
                    }</p>
                    { isAdtrFirstYear && <>
                    <Row style={{margin: '0.5px'}}>
                        <Col md={6}>
                        <div>
                            <label>Fund Category: 
                            <MDBTooltip domElement tag="span" placement="right">
                                <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                                <span>Select one of the common fund categories or select “Other” to add a custom fund category. Add as many fund
categories as necessary to account for the issue’s Total Reportable Proceeds.</span>
                            </MDBTooltip>
                            </label>
                            <Form.Select as="select" name="fundCategoryText" onChange={(e)=>handleChange(e)} value={stateValues.fundCategoryText}>
                                    <option>select</option>
                                    {proceedsFundCategories && proceedsFundCategories.map((item, i) =>{
                                        return (<option key={i} value={item.FundCategoryDesc} >{item.FundCategoryDesc}</option>);
                                    }
                                    ) }
                            </Form.Select>
                        </div>
                        </Col>
                        < Col md={6}>
                            <div className="control">
                            <CurrencyInput 
                            placeholder="0.00" 
                            label="Reportable Proceeds Available" 
                            name="proceedAvlAmt" 
                            type="text" 
                            value={stateValues.proceedAvlAmt}
                            onChange={(e)=>handleChange(e)}
                            validations={["required", "max:999999999999.99"]}
                            helpText="Enter the amount of the issue’s Total Reportable Proceeds that are allocated to the selected fund category."
                            />
                            </div>
                        </Col>
                        {showOtherField && <Col md={6}>
                            <FormInput label="Other Fund Category Name" 
                            name="otherFundCategory" 
                            type="text"
                            value={stateValues.otherFundCategory} 
                            onChange={(e)=>handleChange(e)} 
                            helpText="Enter the name for the customized “other” fund category."
                            />
                        </Col>}
                    </Row>
                    <div style={{margin: '0.5em'}}><button type="button" className="custom-button" onClick={()=>handleAddFund()}>Add Fund</button></div>
                    </>
                    }
                    { isAdtrFirstYear ? (
                    <div className="app-container">
                        <form>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                <th>Fund Category</th>
                                <th>Reportable Proceeds Available</th>
                                <th>Proceeds Spent Current Reporting Period</th>
                                <th>Proceeds Spent Prior Reporting Periods</th>
                                <th>Proceeds Remaining</th>
                                <th></th>
                                </tr>
                            </thead>
                            <tbody>
                            {(proceedsSummary && proceedsSummary.length > 0) ? proceedsSummary.map((item, i)=>(
                                <Fragment>
                                    {editProceedFundInd === i ? (
                                        <EditableRow 
                                        key={i}
                                        data={editProceedData}
                                        handleChange={(e)=>handleProceedFundChange(e, i)}
                                        handleCancelClick={()=>handleProceedCancelClick()}
                                        handleUpdate={(e)=>handleProceedFundUpdate(e, editProceedData )}
                                        columns={[
                                            {
                                            prop: "fundCategory",
                                            fieldType: "text",
                                            editable: false
                                            },
                                            {
                                            prop: "proceedAvlAmt",
                                            fieldType: "currency",
                                            editable: true
                                            },
                                            {
                                            prop: "currSpentAmt",
                                            fieldType: "currency",
                                            editable: false
                                            },
                                            {
                                            prop: "prevSpentAmt",
                                            fieldType: "currency",
                                            editable: false
                                            },
                                            {
                                            prop: "balance",
                                            fieldType: "currency",
                                            editable: false
                                            }
                                        ]}
                                        />
                                    ) : (
                                        <ReadOnlyRow 
                                        key={i}
                                        data={item} 
                                        handleEditClick={(e)=>handleProccedEditClick(e, i, item) } 
                                        handleDeleteClick={(e)=>handleProceedDelete(e, item)}
                                        // isEditable = {true}
                                        isActionsRequired={true}
                                        columns={[
                                            {
                                            prop: "fundCategory",
                                            fieldType: "text"
                                            },
                                            {
                                            prop: "proceedAvlAmt",
                                            fieldType: "currency"
                                            },
                                            {
                                            prop: "currSpentAmt",
                                            fieldType: "currency"
                                            },
                                            {
                                            prop: "prevSpentAmt",
                                            fieldType: "currency"
                                            },
                                            {
                                            prop: "balance",
                                            fieldType: "currency"
                                            }
                                        ]}
                                        />
                                    )}
                                </Fragment>
                            )):
                            <tr><td colSpan="5" style={{textAlign:'center'}}>No data available to display.</td></tr>
                            }
                            <tr>
                                <td><b>Total:</b></td>
                                <td>{CommonService.currencyFormatter(proceedsSummary && proceedsSummary.reduce((a, v) => a + (v.proceedAvlAmt), 0))}</td>
                                <td>{CommonService.currencyFormatter(proceedsSummary && proceedsSummary.reduce((a, v) => a + (v.currSpentAmt), 0))}</td>
                                <td>{CommonService.currencyFormatter(proceedsSummary && proceedsSummary.reduce((a, v) => a + (v.prevSpentAmt), 0))}</td>
                                <td>{CommonService.currencyFormatter(proceedsSummary && proceedsSummary.reduce((a, v) => a + (v.balance), 0))}</td><td></td>
                            </tr>
                            </tbody>
                        </table>
                        </form>
                    </div>
                    ) : (
                        <form>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                <th>Fund Category</th>
                                <th>Total Reportable Proceeds Available</th>
                                <th>Proceeds Spent Current Reporting Period</th>
                                <th>Proceeds Spent Prior Reporting Period</th>
                                <th>Proceeds Unspent / Remaining</th>
                                </tr>
                            </thead>
                            <tbody>
                            {(proceedsSummary && proceedsSummary.length > 0) ? proceedsSummary.map((item, i)=>(
                                <Fragment>
                                    {
                                        <ReadOnlyRow 
                                        key={i}
                                        data={item} 
                                        isActionsRequired={false}
                                        columns={[
                                            {
                                            prop: "fundCategory",
                                            fieldType: "text"
                                            },
                                            {
                                            prop: "proceedAvlAmt",
                                            fieldType: "currency",
                                            },
                                            {
                                            prop: "currSpentAmt",
                                            fieldType: "currency"
                                            },
                                            {
                                            prop: "prevSpentAmt",
                                            fieldType: "currency"
                                            },
                                            {
                                            prop: "balance",
                                            fieldType: "currency"
                                            }
                                        ]}
                                        />
                                    }
                                </Fragment>
                            )) :
                            <tr><td colSpan="4" style={{textAlign:'center'}}>No data available to display.</td></tr>
                            }
                            <tr>
                                <td><b>Total:</b></td>
                                <td>{CommonService.currencyFormatter(proceedsSummary && proceedsSummary.reduce((a, v) => a + (v.proceedAvlAmt), 0))}</td>
                                <td>{CommonService.currencyFormatter(proceedsSummary && proceedsSummary.reduce((a, v) => a + (v.currSpentAmt), 0))}</td>
                                <td>{CommonService.currencyFormatter(proceedsSummary && proceedsSummary.reduce((a, v) => a + (v.prevSpentAmt), 0))}</td>
                                <td>{CommonService.currencyFormatter(proceedsSummary && proceedsSummary.reduce((a, v) => a + (v.balance), 0))}</td>
                            </tr>
                            </tbody>
                        </table>
                        </form>
                        )
                    }
                </div>
                <hr />
                <div>
                    <p><b>Expenditure of Proceeds </b>
                    <AiFillQuestionCircle onClick={()=>setShowProceedsExpenditureHelpText(true)} className="help-icon heading-help-icon"/>
                    {showProceedsExpenditureHelpText && 
                    <Modal show={true} backdrop="static" onHide={()=>setShowProceedsExpenditureHelpText(false)}>
                        <Modal.Body>
                            <div>
                            Select a fund category, enter a purpose for the expenditure, and the amount of expenditure for the purpose in the
current reporting period, then select Add Expenditure. Fund categories may only be edited on 1 st year ADTRs. If identical
purpose descriptions are used year-to-year, expenditure by purpose will sum across reporting years. Expenditure edits
and deletions may be made in the table below.
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button variant="secondary" onClick={()=>setShowProceedsExpenditureHelpText(false)}>Close</button>
                        </Modal.Footer>
                    </Modal>
                    }</p>
                    <form id="add-expenditure">
                    <Row style={{margin: '0.5px'}}>
                        <Col md={6}>
                        <div>
                            <label>Fund Category: 
                            <MDBTooltip domElement tag="span" placement="right">
                                <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                                <span>Select the fund category used for the expenditure in the current reporting period. Fund categories are established on
the 1 st year ADTR and are read-only in subsequent years.</span>
                            </MDBTooltip>
                            </label>
                            <Form.Select as="select" name="adtrProceedId" onChange={(e)=>handleChange(e)} value={stateValues.adtrProceedId} data-validations="required">
                                    <option value="">select</option>
                                    {expenditureFundCategories && expenditureFundCategories.map((item, i) =>{
                                        return (<option key={i} value={item.AdtrProceedId} >{item.FundCategoryText}</option>);
                                    }
                                    ) }
                            </Form.Select>
                        </div>
                        {formErrors['adtrProceedId'] && <p className="error">Please Select Fund Category.</p>}

                        </Col>
                        <Col md={6}>
                            <label>Purpose:  
                            <MDBTooltip domElement tag="span" placement="right" >
                            <span>{' '}<AiFillQuestionCircle onClick={()=>setShowPurposeHelpText(true)} className="help-icon heading-help-icon"/></span>
                            <span>Click me for more info.</span>
                            </MDBTooltip>
                            {showPurposeHelpText && 
                            <Modal show={true} backdrop="static" onHide={()=>setShowPurposeHelpText(false)}>
                                <Modal.Body>
                                    <div>
                                    Enter the purpose of the expenditure in the current reporting period. Identical purpose descriptions will allow
expenditures for the purpose to sum across reporting years. If the purpose of the expenditure is to refund, refinance, or
pay-down other debt of the issuer, enter a separate expenditure for each refunding event and include the CDIAC# of the
refunded debt. If reported on the Report of Final Sale, the CDIAC#s, refunding amount, and redemption date will appear
in a chart below the current period expenditure table that can be used as a reference.
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button variant="secondary" onClick={()=>setShowPurposeHelpText(false)}>Close</button>
                                </Modal.Footer>
                            </Modal>
                            }
                            </label>
                            <FormInput 
                            name="purposeDesc" 
                            type="text"
                            value={stateValues.purposeDesc} 
                            onChange={(e)=>handleChange(e)} 
                            validations={["required"]}
                            error={formErrors.purposeDesc}
                            />
                        </Col>
                        < Col md={6}>
                            <div className="control">
                            <CurrencyInput 
                            placeholder="0.00" 
                            label="Expenditure in the Current Reporting Period" 
                            name="currSpentAmt" 
                            type="text" 
                            value={stateValues.currSpentAmt}
                            onChange={(e)=>handleChange(e)}
                            validations={["required", "max:999999999999.99"]}
                            data-validations="required"
                            error={formErrors.currSpentAmt}
                            helpText="Enter the amount of proceeds spent during the reporting period for the purpose indicated."
                            />
                            </div>
                        </Col>
                        
                    </Row>
                    <div style={{margin: '0.5em'}}><button type="button" className="custom-button" onClick={(e)=>handleAddExpenditure(e)}>Add Expenditure</button></div>
                    </form>

                    <div>
                        <p><b>Expenditures of Current Reporting Period: </b>
                        <MDBTooltip domElement tag="span" placement="right">
                                <span><AiFillQuestionCircle className="help-icon"/></span>
                                <span>Table reflects expenditures added for the current reporting period. Edit or delete expenditures as needed. If selected
fund category is incorrect, delete and add a new expenditure.</span>
                        </MDBTooltip></p>
                        <div className="container">
                        <form>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                <th>Fund Category</th>
                                <th>Purpose</th>
                                <th>Expenditure In Current Reporting Period</th>
                                <th></th>
                                </tr>
                            </thead>
                            <tbody>
                            {(currentPeriodexpenditures && currentPeriodexpenditures.length > 0) ? currentPeriodexpenditures.map((item, i)=>(
                                <Fragment>
                                    {editExpenditureInd === i ? (
                                        <EditableRow 
                                        key={i}
                                        data={editExpenditureData}
                                        handleChange={(e)=>handleExpenditureChange(e, i)}
                                        handleCancelClick={()=>handleExpenditureCancelClick()}
                                        handleUpdate={(e)=>handleExpenditureUpdate(e, editProceedData )}
                                        columns={[
                                            {
                                            prop: "fundCategory",
                                            fieldType: "text",
                                            editable: false
                                            },
                                            {
                                            prop: "purposeDesc",
                                            fieldType: "text",
                                            editable: true
                                            },
                                            {
                                            prop: "currSpentAmt",
                                            fieldType: "currency",
                                            editable: true
                                            }
                                        ]}
                                        />
                                    ) : (
                                        <ReadOnlyRow 
                                        key={i}
                                        data={item} 
                                        handleEditClick={(e)=>handleExpenditureEditClick(e, i, item) } 
                                        handleDeleteClick={handleExpenditureDelete}
                                        isActionsRequired={true}
                                        columns={[
                                            {
                                            prop: "fundCategory",
                                            fieldType: "text"
                                            },
                                            {
                                            prop: "purposeDesc",
                                            fieldType: "text",
                                            },
                                            {
                                            prop: "currSpentAmt",
                                            fieldType: "currency"
                                            }
                                        ]}
                                        />
                                    )}
                                </Fragment>
                            )) :
                            <tr><td colSpan="4" style={{textAlign:'center'}}>No data available to display.</td></tr>
                            }
                            <tr>
                                <td><b>Total:</b></td>
                                <td></td>
                                <td>{CommonService.currencyFormatter(currentPeriodexpenditures && currentPeriodexpenditures.reduce((a, v) => a + (v.currSpentAmt), 0))}</td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                        </form>
                        </div>
                    </div>
                    <div>
                    <p><b>Expenditure Summary: </b>
                    <MDBTooltip domElement tag="span" placement="right">
                                <span><AiFillQuestionCircle className="help-icon"/></span>
                                <span>Read-only reference data. Values are populated dynamically as expenditures are entered. Expenditures with identical
purpose descriptions will total within the reporting period and across reporting years.</span>
                    </MDBTooltip></p>
                    
                    <div className="container">
                        <form>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                <th>Fund Category</th>
                                <th>Purpose</th>
                                <th>Expenditure In Current Reporting Period</th>
                                <th>Expenditure in Previous Reporting Periods</th>
                                <th>Total Expenditure All Periods</th>
                                </tr>
                            </thead>
                            <tbody>
                            {(expenditureSummary && expenditureSummary.length > 0) ? expenditureSummary.map((item, i)=>(
                                <Fragment>
                                    <ReadOnlyRow 
                                    key={i}
                                    data={item} 
                                    isActionsRequired={false}
                                    columns={[
                                        {
                                        prop: "fundCategory",
                                        fieldType: "text"
                                        },
                                        {
                                        prop: "purposeDesc",
                                        fieldType: "text",
                                        },
                                        {
                                        prop: "currSpentAmt",
                                        fieldType: "currency"
                                        },
                                        {
                                        prop: "prevSpentAmt",
                                        fieldType: "currency"
                                        },
                                        {
                                        prop: "",
                                        fieldType: "formatted",
                                        value: CommonService.currencyFormatter(item.currSpentAmt + item.prevSpentAmt)
                                        }
                                    ]}
                                    />
                                </Fragment>
                            )) :
                            <tr><td colSpan="5" style={{textAlign:'center'}}>No data available to display.</td></tr>
                            }
                            <tr>
                                <td><b>Total:</b></td>
                                <td></td>
                                <td>{CommonService.currencyFormatter(expenditureSummary && expenditureSummary.reduce((a, v) => a + (v.currSpentAmt), 0))}</td>
                                <td>{CommonService.currencyFormatter(expenditureSummary && expenditureSummary.reduce((a, v) => a + (v.prevSpentAmt), 0))}</td>
                                <td>{CommonService.currencyFormatter(expenditureSummary && expenditureSummary.reduce((a, v) => a + (v.currSpentAmt+v.prevSpentAmt), 0))}</td>
                            </tr>
                            </tbody>
                        </table>
                        </form>
                        </div>
                    </div>
                </div>

                <div className="control">
                    <button type="button" className="link-button dk-blue-color" style={{textDecoration: 'none', fontWeight: '500'}}  onClick={()=>handleMLRProceedsClick()}>View Proceeds from Mello-Roos Last Submission</button><br />
                    <button type="button" className="link-button dk-blue-color" style={{textDecoration: 'none', fontWeight: '500'}} onClick={()=>handleMKRProceedsClick()}>View Proceeds from Marks-Roos Last Submission</button>
                </div>

                <div>
                    <Row>
                        < Col md={6}>
                            <div className="control">

                            <CurrencyInput 
                            placeholder="0.00" 
                            label="Total cost of issuance (From Report of Final Sale)" 
                            name="reserveMinBalAmt" 
                            type="text" 
                            value={issuanceDetail.totalIssuanceAmt}
                            validations={["required", "max:999999999999.99"]}
                            disabled readOnly
                            helpText="Read-only. Prepopulated from data submitted on the RFS. If data is incorrect, return to the dashboard to edit the RFS
                            for this issue – 1 st year ADTR only. After the ADTR has been reviewed, the RFS may not be edited."
                            />
                            </div>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Row>
                    <p><b>Refunded/Refinanced Issues </b>
                        <AiFillQuestionCircle onClick={()=>setShowRefundIssueHelpText(true)} className="help-icon heading-help-icon"/>
                        {showRefundIssueHelpText && 
                        <Modal show={true} backdrop="static" onHide={()=>setShowRefundIssueHelpText(false)}>
                            <Modal.Body>
                                <div>
                                The issue(s) listed in the table below were reported previously to CDIAC as refunded, refinanced, or paid-down all or in part by the 
                                proceeds of the issue subject to this report. This data is populated from data on the RFS(s) for the issue subject to this report. It 
                                is provided as a reference for completion of the Expenditures in the Current Reporting Period. A refunding expenditure 
                                should be reported if the redemption/repayment date occurs within the reporting period of this report.
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button variant="secondary" onClick={()=>setShowRefundIssueHelpText(false)}>Close</button>
                            </Modal.Footer>
                        </Modal>
                        }
                    </p>

                    <div className="container">
                        <form>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                <th>CDIAC #
                                    <MDBTooltip domElement tag="span" placement="right">
                                        <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                                        <span>Read-only. CDIAC number of the debt issue that was refunded with the proceeds of the issue subject to this report.</span>
                                    </MDBTooltip>
                                </th>
                                <th>Refunded/Refinanced Amount
                                    <MDBTooltip domElement tag="span" placement="right">
                                        <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                                        <span>Read-only. The amount of the debt issue that was refunded, refinanced, or paid down with proceeds of the issue subject to this report.</span>
                                    </MDBTooltip>
                                </th>
                                <th>Redemption/Repayment Date
                                    <MDBTooltip domElement tag="span" placement="right">
                                        <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                                        <span>Date that debt holders or lenders for each issue of debt listed were repaid. Prepopulated with the date previously entered on the RFS (source of funds) for the issue subject to this report. Edit as necessary.</span>
                                    </MDBTooltip>
                                </th>
                                <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                            {(issueRefundedAssocs && issueRefundedAssocs.length > 0) ? issueRefundedAssocs.map((item, i)=>(
                                <Fragment>
                                    {editInd === i ? (
                                        <EditableRow
                                        key={i} 
                                        data={editData}
                                        handleChange={handleRefundDateChange}
                                        handleCancelClick={()=>handleCancelClick()}
                                        handleUpdate={(e)=>handleSave(e, editData )}
                                        columns={[
                                            {
                                            prop: "linkedCdiacNbr",
                                            fieldType: "text",
                                            editable: false
                                            },
                                            {
                                            prop: "redeemAmt",
                                            fieldType: "currency",
                                            editable: false
                                            },
                                            {
                                            prop: "redeemDate",
                                            fieldType: "date",
                                            editable: true,
                                            maxDate: new Date(adtrInfo.fiscalYrEndDate),
                                            minDate: new Date(fiscalYrStartDate)
                                            }
                                        ]}
                                        />
                                    ) : (
                                        <ReadOnlyRow 
                                        key={i}
                                        data={item} 
                                        tableName={"issueRefund"}
                                        handleEditClick={item.isRowEditable ? (e)=>handleEditClick(e, i, item) : null}
                                        isActionsRequired={true} 
                                        columns={[
                                            {
                                            prop: "linkedCdiacNbr",
                                            fieldType: "text"
                                            },
                                            {
                                            prop: "redeemAmt",
                                            fieldType: "currency"
                                            },
                                            {
                                            prop: "redeemDate",
                                            fieldType: "date"
                                            }
                                        ]}
                                        />
                                    )}
                                </Fragment>
                                )):
                                <tr><td colSpan="4" style={{textAlign:'center'}}>No data available to display.</td></tr>
                            }
                            <tr><td><b>Total:</b></td><td>{CommonService.currencyFormatter(refundedAmtTotal)}</td><td></td></tr>
                            </tbody>
                        </table>
                        </form>
                    </div>
                    </Row>
                </div>

                <div className="btn-div">
                    {/* <button className="custom-button" type="button" onClick={() => props.openWidget('principalOutstanding')}>Back</button> */}
                    <button className="fright custom-button" type="button" onClick={()=>validateForm()}>Save & Next</button>
                </div>
            </div>
            {showMLRProceeds && <ProceedsTable linkClicked="MLRProceeds" onModalClose={()=>setShowMLRProceeds(false)} finalSaleId={adtrInfo.finalSaleId}/>}
            {showMKRProceeds && <ProceedsTable linkClicked="MKRProceeds" onModalClose={()=>setShowMKRProceeds(false)} finalSaleId={adtrInfo.finalSaleId}/>}
        </Card>
    )
}

export default UseOfProceeds

import {useState, useEffect} from 'react'
import { Form, Modal, Spinner } from 'react-bootstrap'
import {AiFillQuestionCircle} from 'react-icons/ai'
import CodeMaintenanceService from "../../../services/CodeMaintenanceService"
const ReasonForPrivateSale = (props) => {
    const {issuePrivateSaleReasons, handleChange, handleReasonOtherTextChange} = props;
    const [showInstructionsModal, setShowInstructionsModal] = useState(false);
    const [showOtherTextField, setShowOtherTextField] = useState(false);
    const [reasonOtherText, setReasonOtherText] = useState('');
    const [privateSaleReasons, setPrivateSaleReasons] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const fetchCodes = async () => {
            var obj = {
            msgId: null,
            sysId: null,
            opr: "GetLookups",
            hdrStruct: null,
            queryParam: null,
            reqData: {cdiacCode: "NEGR", active: "Y"},
            };
        
            await CodeMaintenanceService.getAllCodes(obj)
            .then((res) => res)
            .then((result) => {
                const filteredList = result.data.responses[0].filter(el => el.activeInd === "Y");
                setPrivateSaleReasons(filteredList);
                setIsLoading(false);
            });
        }

          fetchCodes();
    }, []);

    useEffect(()=>{
        const ind = issuePrivateSaleReasons.findIndex(reason => reason.cdiacCodeValueCode === "6");
        if( ind !== -1 ){
            setShowOtherTextField(true);
            setReasonOtherText(issuePrivateSaleReasons[ind].reasonOtherText);
        }
    }, [issuePrivateSaleReasons])

   
    const handleClick = (e) => {
        handleChange(e);
        const {checked, dataset} = e.target;

        if(dataset.cdiaccodevaluecode === "6"){
            if(checked) {
                setShowOtherTextField(true);
            } else {
                setShowOtherTextField(false);
            }
        } 
    }
    return (
        <>
            <div className="form">
                <h3>Reason(s) for Negotiated Sale {"  "}
                <AiFillQuestionCircle onClick={()=>setShowInstructionsModal(true)} className="help-icon heading-help-icon" />
                {showInstructionsModal && 
                <Modal show={true} backdrop="static" onHide={()=>setShowInstructionsModal(false)}>
                    {/* <Modal.Header closeButton><h3>Issuance Authorization</h3></Modal.Header> */}
                    <Modal.Body>
                        <div>
                            <span>If a local agency issuer sold enterprise revenue bonds at private
                                (negotiated) sale, Government Code 54418 requires that the local
                                agency, within 2 weeks of the sale, submit a written statement to
                                the Commission stating the reason(s) why it did not sell the debt
                                issue at a competitive sale. The most common reasons cited for
                                offering the debt issue at negotiated rather than public sale are
                                listed. If the reason is not listed, select Other and specify the
                                reason. If the written statement was received prior to submission of
                                the Report of Final Sale, the reason submitted will be prepopulated.
                            </span>                                    
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button variant="secondary" onClick={()=>setShowInstructionsModal(false)}>Close</button>
                    </Modal.Footer>
                </Modal>
                }
                </h3>

                <div className="mb-3">Indicate the reason(s) why the bonds were issued using negotiated versus competitive sale method. (Select all that apply.)</div>
                
                <div className="mb-10">
                {isLoading && <Spinner animation="border" variant="primary" />}
                {(privateSaleReasons.length !== 0) && privateSaleReasons.map((source, i) =>{
                        return (<Form.Check className="check-box" key={i} 
                            name="debtItem"
                            id={"debtItem_"+i} 
                            type="checkbox" 
                            onChange={(e)=>handleClick(e)} 
                            data-validations="required" 
                            value={source.cdiacCodeValueId} 
                            label={source.cdiacCodeValueDesc}
                            data-cdiacCodeValueCode={source.cdiacCodeValueCode}
                            checked={issuePrivateSaleReasons.some(el => el.reasonCodeId === source.cdiacCodeValueId)}
                            />);
                    }
                ) }
                </div>
                
                { showOtherTextField && 
                <div>
                    <Form.Control type="text" onChange={(e)=>handleReasonOtherTextChange(e)} value={reasonOtherText}/>
                    </div>}
                

            </div>
        </>
    )
}

export default ReasonForPrivateSale

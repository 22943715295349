import CommonService from "./CommonService";

const searchSenior = (obj) => {
    return CommonService.apicall(obj)
};

const getSeniorSubordinate = (obj) => {
    return CommonService.apicall(obj)
}

const addSeniorSubordinateAssoc = (obj) => {
    return CommonService.apicall(obj)
}

const getSeniorSubordinateByIssuerId = (obj) => {
    return CommonService.apicall(obj)
}

const deleteSeniorSubordinateAssociation = (obj) => {
    return CommonService.apicall(obj)
}

const SeniorSubordinateService = {
    searchSenior,
    getSeniorSubordinate,
    addSeniorSubordinateAssoc,
    getSeniorSubordinateByIssuerId,
    deleteSeniorSubordinateAssociation
};


export default SeniorSubordinateService;

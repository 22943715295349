import CommonService from "./CommonService";

const createIssuerFilerRequest = (obj) => {
    return CommonService.apicall(obj)

};
const getIssuers = (obj) => {
    return CommonService.apicall(obj)
};
const createFilerIssuerAssoc = (obj) => {
    return CommonService.apicall(obj)
}
const updateFilerIssuerAssoc = (obj) => {
    return CommonService.apicall(obj)
}

const IssuerFilerService = {
    createIssuerFilerRequest,
    getIssuers,
    createFilerIssuerAssoc,
    updateFilerIssuerAssoc
};


export default IssuerFilerService;
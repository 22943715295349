import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import './index.css';
import './styles.css';
import App from './App';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from './configManager';

const initializeApp = async() => {
  const msalInstance = new PublicClientApplication(msalConfig);
  //Preserve and redirect to the url accessing from debtwatch
  const pathName = window.location.pathname;
  if(pathName.indexOf('status-history-upload-documents') > -1){
    sessionStorage.setItem("pathname", pathName);
  }
  await msalInstance.initialize();
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <Router>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </Router>
  );
}

initializeApp();



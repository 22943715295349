import { useState } from 'react'
import Card from '../ui/Card'
import { Modal } from 'react-bootstrap'
import { AiFillQuestionCircle } from 'react-icons/ai'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import CommonService from '../../services/CommonService'
import { UserContext } from '../../UseContext'
import { useContext } from 'react'
import { toast } from 'react-toastify'
import { MDBTooltip } from 'mdbreact'
import { useMsal } from '@azure/msal-react'

const Comments = (props) => {
    const {instance} = useMsal();
    const {markRoosLocalOblig, markRoosLocalObligDetails} = props;
    const [count, setCount] = useState(markRoosLocalObligDetails.issuerComment ? markRoosLocalObligDetails.issuerComment.length : 0);
    const [bgcolor, setBgcolor] = useState('yellowgreen');
    const [stateValues, setStateValues] = useState({
        issuerComment : markRoosLocalObligDetails.issuerComment
    });

    const [showInstructionsModal, setShowInstructionsModal] = useState(false);

    const user = useContext(UserContext);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setCount(e.target.value.length);
        const color = (e.target.value.length === 4000) ? 'orangered' : 'yellowgreen';
        setBgcolor(color);
        setStateValues(prevState => ({
            ...stateValues, [name]: value
        })
        )
    }

    const validateForm = (e) => {

        if(markRoosLocalObligDetails.marksRoosLocalObligorId !== undefined){

            var obj = {
                msgId: null,
                sysId: null,
                opr: "updateMKRRoosLocalObligComment",
                hdrStruct: null,
                queryParam: null,
                reqData: {  "id":markRoosLocalObligDetails.marksRoosLocalObligorId,
                    "env" : "E",
                    "marksRoosLocalObligor":{
                        "issuerComment" : stateValues.issuerComment,
                        "lastUpdateUser": CommonService.getUserName(user),
                        "emailId": user,
                        "contactId": markRoosLocalOblig.contactId
                    }
                },
              };
          
              FinalSaleReportService.updateDrawIssuerComment(obj)
                .then((result) => {
                    if (result.data.errors && result.data.errors.length > 0){
                        CommonService.handleServerResponse(result.data, instance);
                    }
                    else{
                        props.updateMkrLocalObligorDetails(result.data.responses[0]);
                        toast.success("Comment updated successfully!");
                    }
                    
                });
        }

        
        props.openWidget('review');
    }
    return (
        <Card>
            <div className="form">
                <h3>Comments 
                    <MDBTooltip domElement tag="span" placement="right">
                        <span>{"  "}<AiFillQuestionCircle onClick={() => setShowInstructionsModal(true)} className="help-icon heading-help-icon" /></span>
                        <span>click me for more info.</span>
                    </MDBTooltip>
                    {showInstructionsModal &&
                        <Modal show={true} backdrop="static" onHide={() => setShowInstructionsModal(false)}>
                            <Modal.Body>
                                <div>
                                Please include any additional comments that relate to this filing.
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button variant="secondary" onClick={() => setShowInstructionsModal(false)}>Close</button>
                            </Modal.Footer>
                        </Modal>
                    }</h3>
                <div className="comments-box">

                    <textarea name="issuerComment" value={stateValues.issuerComment}
                        id="issuerComment" onChange={(e)=>handleChange(e)}
                        className="form-control" rows="5" maxLength="4000" placeholder="Add a comment..." />
                    <span style={{ position: 'relative', left: '45%', backgroundColor: bgcolor }}>{count} out of 4000 max</span>


                </div>

                <div className="btn-div">
                    {/* <button className="custom-button" type="button" onClick={() => props.openWidget('filingContact')}>Back</button> */}
                    <button className="fright custom-button" type="button" onClick={() => validateForm()}>Save & Next</button>
                </div>
            </div>
        </Card>
    )
}

export default Comments

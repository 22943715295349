import {Modal, Col, Row, Form} from 'react-bootstrap'
import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { UserContext } from '../../UseContext'
import { validateFormFields } from '../../utils'
import MaskedInput from 'react-text-mask'
import {FormInput} from '../../components/formElements/FormInput'
import IssuerMaintenanceService from '../../services/IssuerMaintenanceService'
import CommonService from '../../services/CommonService'
import swal from 'sweetalert'

const AddContact = (props) => {
    const user = useContext(UserContext);
    const issuerId = props.issuerId;
    const orgId = props.orgId;
    const {onModalClose, orgType} = props;
    const [formErrors, setFormErrors] = useState({});
    const [count, setCount] = useState(0);
    const [bgcolor, setBgcolor] = useState('yellowgreen');
    const [contact, setContact] = useState({
        "contactFirstName" : "",
        "contactMiddleName" : "",
        "contactLastName" : "",
        "contactAddressLine1" : "",
        "contactAddressLine2" : "",
        "contactAddressLine3" : "",
        "contactAddressLine4" : "",
        "contactCityName" : "",
        "contactStateCode" : "",
        "contactZipCode" : "",
        "contactPhoneAreaCode" : "",
        "contactPhoneNumber" : "",
        "contactPhoneExtn" : "",
        "contactEmailId" : "",
        "activeInd": 'Y',
        "orgAddressInd": 'Y',
    });
    const handleContactChange = e => {
        const {name} = e.target;
        let value = e.target.value;
        if(name === 'contactCityName') {
            value = value.replace(/[^A-Za-z\s]/ig, '');
        }
        if(name === 'contactStateCode') {
            value = value.replace(/[^A-Za-z]/ig, '');
        }
        if(name === 'contactPhoneExtn') {
            value = value.replace(/[^\d]$/, "").substr(0, 10);
        }
        if(name === 'contactZipCode') {
            value = value.replace(/[^\d{5}-]$/, "").substr(0, 10);
        }
        if(name === 'activeInd') {
            value = e.target.checked ? 'Y' : 'N';
        }

        setContact(prevState => ({
            ...prevState, [name] : value
        }))
    }
    const handleCommentValue = (e, key) => {
        setCount(e.target.value.length);
        const color = (e.target.value.length === 4000) ? 'orangered' : 'yellowgreen';
        setBgcolor(color);
        setContact(prevState => ({
            ...prevState, [key] : e.target.value
        }))
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target.closest('form#edit-contact');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {  
            setFormErrors({});
            var obj = {
                msgId: null,
                sysId: null,
                opr: orgType === "I" ? "postIssuerContact" : "postFilerContact",
                hdrStruct: null,
                queryParam: null,
                reqData: { 
                    "issuerId" : issuerId,
                    "orgId" : orgId,
                    "contact" : {
                        "contactFirstName" : contact.contactFirstName,
                        "contactMiddleName" : contact.contactMiddleName,
                        "contactLastName" : contact.contactLastName,
                        "contactAddressLine1" : contact.contactAddressLine1,
                        "contactAddressLine2" : contact.contactAddressLine2,
                        "contactAddressLine3" : contact.contactAddressLine3,
                        "contactAddressLine4" : contact.contactAddressLine4,
                        "contactCityName" : contact.contactCityName,
                        "contactStateCode" : contact.contactStateCode,
                        "contactZipCode" : contact.contactZipCode,
                        "contactPhoneAreaCode" : contact.contactPhoneAreaCode,
                        "contactPhoneNumber" : contact.contactPhoneNumber,
                        "contactPhoneExtn" : contact.contactPhoneExtn,
                        "contactEmailId" : contact.contactEmailId !== "" ? contact.contactEmailId : null,
                        "activeInd": 'Y',
                        "orgAddressInd" : 'N',
                        "createUser": CommonService.getUserName(user),
                        "lastUpdateUser": CommonService.getUserName(user)
                    }
                },
            };
        
            IssuerMaintenanceService.createContact(obj)
                .then((result) => {
                    if (result.data.errors && result.data.errors.length > 0){
                        if(result.data.errors[0].code === '200'){
                            swal(result.data.errors[0].message, {
                                buttons: ["No", "Yes"],
                            }).then((value) => {
                               if(value){
                                   createB2CUserProfile();
                               } else{
                                toast.success("Contact created successfully!");
                                onModalClose();
                                }
                            });
                        } else {
                            toast.error(result.data.errors[0].message)
                        }
                    }
                    else{
                        toast.success("Contact created successfully!");
                        onModalClose();
                    }
                });
        }
    }

    const createB2CUserProfile = () => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "createB2CUserProfile",
            hdrStruct: null,
            queryParam: null,
            reqData: { 
                "contact" : contact,
            },
        };

        IssuerMaintenanceService.createB2CUserProfile(obj)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message)
            }
            else{
                toast.success("Contact created successfully!");
                onModalClose();
            }
        });
    }

    
    return (
        <Modal show={true} backdrop="static" onHide={onModalClose} style={{'zindex': 1200}}>
            <Modal.Header closeButton><h3>Create New Contact</h3></Modal.Header>
            <Modal.Body>
            <form id="edit-contact">
                <div className="contactForm">
                    <Row>
                        <Col md={6} className="control">
                            <FormInput type="text" label="Contact First Name" value={contact.contactFirstName} name="contactFirstName" isrequired="true"  validations={["required"]} onChange={(e)=>handleContactChange(e)}/>
                            {formErrors['contactFirstName'] && <span className="error">{formErrors['contactFirstName']}</span>}
                        </Col>
                        <Col md={6} className="control">
                            <label>Contact Middle Name:</label>
                            <input type="text" name="contactMiddleName"value={contact.contactMiddleName} onChange={(e)=>handleContactChange(e)}/>
                        </Col>
                        <Col md={6} className="control">
                            <FormInput type="text" label="Contact Last Name" value={contact.contactLastName} name="contactLastName" isrequired="true"  validations={["required"]} onChange={(e)=>handleContactChange(e)}/>
                            {formErrors['contactLastName'] && <span className="error">{formErrors['contactLastName']}</span>}
                        </Col>
                        <div>
                            <Form.Check inline type="checkbox" id="orgAddressInd" name="orgAddressInd" label="Primary Contact?" checked={contact.orgAddressInd === 'Y'} onChange={(e)=>handleContactChange(e)} />
                        </div>
                    </Row>
                    <Row>                       
                        <Col md={6} className="control">
                            <FormInput type="text" label="Address Line1" name="contactAddressLine1" isrequired="true"  
                            validations={["required"]} value={contact.contactAddressLine1}
                            onChange={(e)=>handleContactChange(e)}
                            />
                            {formErrors['contactAddressLine1'] && <span className="error">{formErrors['contactAddressLine1']}</span>}
                        </Col>
                        <Col md={6} className="control">
                            <label>Address Line2:</label>
                            <input type="text" name="contactAddressLine2" value={contact.contactAddressLine2}
                            onChange={(e)=>handleContactChange(e)}
                            />
                        </Col>
                        <Col md={6} className="control">
                            <label>Address Line3:</label>
                            <input type="text" name="contactAddressLine3"  value={contact.contactAddressLine3} 
                            onChange={(e)=>handleContactChange(e)}
                            />
                        </Col>
                        <Col md={6} className="control">
                            <label>Address Line4:</label>
                            <input type="text" name="contactAddressLine4"   value={contact.contactAddressLine4}
                            onChange={(e)=>handleContactChange(e)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} className="control">
                            <FormInput type="text" label="City" name="contactCityName" isrequired="true" 
                            validations={["required"]} 
                            maxLength="50"
                            value={contact.contactCityName}
                            onChange={(e)=>handleContactChange(e)}
                            />
                            {formErrors['contactCityName'] && <span className="error">{formErrors['contactCityName']}</span>}
                        </Col>
                        <Col md={4} className="control">
                            <FormInput type="text" label="State" name="contactStateCode" isrequired="true"  
                            validations={["required", "minLength:2"]} maxLength="2" 
                            value={contact.contactStateCode}
                            onChange={(e)=>handleContactChange(e)}
                            />
                            {formErrors['contactStateCode'] && <span className="error">{formErrors['contactStateCode']}</span>}
                        </Col>
                        <Col md={4} className="control">
                            <FormInput type="text" label="Zip Code" name="contactZipCode" isrequired="true"  
                            validations={["required","zipCode"]} 
                            value={contact.contactZipCode}
                            onChange={(e)=>handleContactChange(e)}
                            />
                            {formErrors['contactZipCode'] && <span className="error">{formErrors['contactZipCode']}</span>}
                        </Col>
                        {/* <Col md={3} className="control">
                            <label><span className="required">*</span>County</label>
                            <Typeahead id="countyCode" 
                                name="issuerCountyCode"
                                placeholder="Choose County..."
                                labelKey="countyName"
                                selected = {
                                    counties.filter(option => 
                                        option.countyCode === contact.issuerCountyCode)
                                    }
                                onChange={(e)=>handleContactCountyChange(e)}
                                options={counties} isLoading={isLoading}
                                inputProps={{ 'data-validations': 'required', name: 'issuerCountyCode' }}
                                />
                            {formErrors['issuerCountyCode'] && <span className="error">{formErrors['issuerCountyCode']}</span>}
                        </Col> */}
                    </Row>
                    <Row>
                        <Col md={4} className="control">
                            <label><span className="required">*</span>Area Code (XXX)</label>
                            <MaskedInput mask={[/\d/, /\d/, /\d/]} 
                                guide={true}
                                name="contactPhoneAreaCode"
                                data-validations={["required", "telNo:3"]}
                                value={contact.contactPhoneAreaCode}
                                onChange={(e)=>handleContactChange(e)}
                                onBlur={(e)=>handleContactChange(e)}
                            />
                            {formErrors['contactPhoneAreaCode'] && <span className="error">{formErrors['contactPhoneAreaCode']}</span>}
                        </Col>
                        <Col md={4} className="control">
                            <label><span className="required">*</span>Phone Number (XXX-XXXX)</label>
                            <MaskedInput mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} 
                                guide={true}
                                name="contactPhoneNumber"
                                value={contact.contactPhoneNumber}
                                data-validations={["required", "telNo:8"]}
                                onChange={(e)=>handleContactChange(e)}
                                
                            />
                            {formErrors['contactPhoneNumber'] && <span className="error">{formErrors['contactPhoneNumber']}</span>}
                        </Col>
                        <Col md={4} className="control">
                            <label>Ext</label>
                            <input type="text" name="contactPhoneExtn" value={contact.contactPhoneExtn}
                            onChange={(e)=>handleContactChange(e)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} className="control">
                            <label>Fax Area Code (XXX)</label>
                            <MaskedInput mask={[/\d/, /\d/, /\d/]} 
                                guide={true}
                                name="contactFaxAreaCode"
                                value={contact.contactFaxAreaCode} 
                                data-validations={["telNo:3"]}
                                onChange={(e)=>handleContactChange(e)}
                                onBlur={(e)=>handleContactChange(e)}
                                
                            />
                            {formErrors['contactFaxAreaCode'] && <span className="error">{formErrors['contactFaxAreaCode']}</span>}
                        </Col>
                        <Col md={4} className="control">
                            <label>Fax Number (XXX-XXXX)</label>
                            <MaskedInput mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} 
                                guide={true}
                                name="contactFaxNumber"
                                data-validations={["telNo:8"]}
                                value={contact.contactFaxNumber}
                                onChange={(e)=>handleContactChange(e)}
                                
                            />
                            {formErrors['contactFaxNumber'] && <span className="error">{formErrors['contactFaxNumber']}</span>}
                        </Col>
                        <Col md={4} className="control">
                            <FormInput type="email" name="contactEmailId" label="E-Mail"  
                                value={contact.contactEmailId}
                                validations={["email"]}
                                onChange={(e)=>handleContactChange(e)} 
                                disabled={contact.isUserInAzureB2C}
                                />
                            {formErrors['contactEmailId'] && <span className="error">{formErrors['contactEmailId']}</span>}
                        </Col>
                    </Row> 
                    <div>
                        <Form.Check inline type="checkbox" id="activeInd" name="activeInd" label="Is Active?" checked={contact.activeInd === 'Y'} onChange={(e)=>handleContactChange(e)} />
                    </div>
                    <Row>
                    <div>
                        <label>Comment: </label>
                        <div className="comments-box">
                            <textarea name="contactComment" value={contact.contactComment}
                            id="comments-input" onChange={(e)=>handleCommentValue(e,'contactComment')}
                            className="form-control" rows="5" maxLength="4000" placeholder="Add a comment..."/>
                            <span style={{position: 'relative', left: '45%' , backgroundColor: bgcolor }}>{count} out of 4000 max</span>
                        </div>
                    </div>
                    </Row>
                </div>
                <div className="btn-div">
                <div className="fright">
                    <button type="button" className="custom-button-secondary  btn-secondary" onClick={onModalClose}>Cancel</button>{" "}
                    <button className="custom-button" type="button" onClick={(e)=>handleSubmit(e)}>Create Contact</button>
                    </div>
                </div>
            </form>                
            </Modal.Body>
        </Modal>
    )
}

export default AddContact

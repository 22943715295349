import {useState} from 'react'
import { Collapse } from "react-bootstrap"
import Issuance from './issuance'
import IssuanceAuthorization from '../issuanceAthorization'
import Statutories from './statutories'
import FilingContact from '../filingContact'
import RetiredIssues from '../retiredIssues'
import InterestDebtExempt from './interestDebtExempt'
import TypeOfSale from './typeOfSale'
import TypeOfDebt from '../typeOfDebt'
import SourceOfRepayment from './sourceOfRepayment'
import PurposeOfFinancing from './purposeOfFinancing'
import DocumentUpload from '../documentUpload'
import Review from './proposedDebtReview'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {FaPlus, FaMinus} from 'react-icons/fa'
import 'bootstrap/dist/css/bootstrap.min.css'

const CollapsibleWidgets = (props) => {
  const {openSectionObj, toggleWidget, openSection, issueId,
     proposedDebtDetails, proposeDebtDependencies} = props;

    const [sectionValidated, setSectionValidated] = useState({
      issuance: proposedDebtDetails.issueName ? true : false,
      issuanceAuthorization: proposeDebtDependencies.issueAuthorizations && proposeDebtDependencies.issueAuthorizations.length !== 0 ? true : false,
      statutes: proposedDebtDetails.validationActionFlag ? true : false,
      filingContact: true,
      financingParticipants: proposeDebtDependencies.issueContacts && proposeDebtDependencies.issueContacts.length !== 0 ? true : false,
      typeOfDebt: proposedDebtDetails.debtInstrumentGroupCodeId ? true : false,
      interestDebtExempt: proposedDebtDetails.stateTaxFlag ? true : false,
      sourceOfRepayment: proposedDebtDetails.debtRepayNonUsCurrFlag ? true : false,
      purposeOfFinancing: proposeDebtDependencies.issueFinancingPurposes && proposeDebtDependencies.issueFinancingPurposes.length !== 0 ? true : false,
      typeOfSale: proposedDebtDetails.saleTypeCode ? true : false
    });

    const updateSectionValidated = (sectionName, isValid) => {
      setSectionValidated(prevState=>({
        ...prevState, [sectionName]: isValid
      }))
    }

    const updateProposedDebtDetails = (proposedDebtDetails) => {
      props.updateProposedDebtDetails(proposedDebtDetails);
    }
    const updateProposeDebtDependencies = (data, type) => {
      props.updateProposeDebtDependencies(data, type);
    }
    return (
      <>
      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title button" onClick={()=>toggleWidget('issuance')}>
        <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
            id="issuance" aria-expanded={openSectionObj.issuance} aria-controls="collapse_issuance" > {!openSectionObj.issuance ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Issuance </button> 
        {sectionValidated.issuance && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
        </h5>
        <Collapse in={openSectionObj.issuance}>
          <div id="collapse_issuance">
          {openSectionObj.issuance && <Issuance openWidget={openSection} sectionValidated={updateSectionValidated} issueId={issueId} proposedDebtDetails={proposedDebtDetails} proposeDebtDependencies={proposeDebtDependencies} updateProposedDebtDetails={updateProposedDebtDetails}  updateProposeDebtDependencies={updateProposeDebtDependencies}/>}
          </div>
        </Collapse>
      </div>
      </div>

      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title button" onClick={()=>toggleWidget('issuanceAuthorization')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
             id="issuer" aria-expanded={openSectionObj.issuanceAuthorization} aria-controls="collapse_issuance_authorization"> {!openSectionObj.issuanceAuthorization ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Issuance Authorization </button> 
          {sectionValidated.issuanceAuthorization && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
        </h5>
        <Collapse in={openSectionObj.issuanceAuthorization}>
          <div id="collapse_issuance_authorization">
          {openSectionObj.issuanceAuthorization && <IssuanceAuthorization openWidget={openSection} sectionValidated={updateSectionValidated} issueId={issueId} updateProposeDebtDependencies={updateProposeDebtDependencies}/>}
            </div>
        </Collapse>
      </div>
      </div>

      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title button" onClick={()=>toggleWidget('statutories')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
             id="issuer" aria-expanded={openSectionObj.statutories} aria-controls="collapse_statutories" > {!openSectionObj.statutories ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Statutory Authority </button> 
          {sectionValidated.statutes && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
        </h5>
        <Collapse in={openSectionObj.statutories}>
          <div id="collapse_statutories">
          {openSectionObj.statutories && <Statutories openWidget={openSection} sectionValidated={updateSectionValidated} issueId={issueId} proposedDebtDetails={proposedDebtDetails} updateProposedDebtDetails={updateProposedDebtDetails} updateProposeDebtDependencies={updateProposeDebtDependencies}/> }
            </div>
        </Collapse>
      </div>
      </div>

      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title button" onClick={()=>toggleWidget('filingContact')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
             id="filingContact" aria-expanded={openSectionObj.filingContact} aria-controls="collapse_filing_contact" > {!openSectionObj.filingContact ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Filing Contact </button> 
          {sectionValidated.filingContact && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
        </h5>
        <Collapse in={openSectionObj.filingContact}>
          <div id="collapse_issuance_authorization">
          {openSectionObj.filingContact && <FilingContact openWidget={openSection} sectionValidated={updateSectionValidated} issueId={issueId} reportType="RPD"/>}
            </div>
        </Collapse>
      </div>
      </div>

      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title button" onClick={()=>toggleWidget('interestDebtExempt')}>
        <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
            id="issuer" aria-expanded={openSectionObj.interestDebtExempt} aria-controls="collapse_interestDebtExempt"> {!openSectionObj.interestDebtExempt ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Tax Status </button> 
          {sectionValidated.interestDebtExempt && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
        </h5>
        <Collapse in={openSectionObj.interestDebtExempt}>
          <div id="collapse_interestDebtExempt">
          {openSectionObj.interestDebtExempt && <InterestDebtExempt openWidget={openSection} sectionValidated={updateSectionValidated} issueId={issueId} proposedDebtDetails={proposedDebtDetails} updateProposedDebtDetails={updateProposedDebtDetails}/>}
          </div>
        </Collapse>
      </div>
      </div>

      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title button" onClick={()=>toggleWidget('typeOfDebt')}>
        <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
             id="issuer" aria-expanded={openSectionObj.typeOfDebt} aria-controls="collapse_typeofdebt" > {!openSectionObj.typeOfDebt ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Type of Debt Instrument </button> 
          {sectionValidated.typeOfDebt && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
        </h5>
        <Collapse in={openSectionObj.typeOfDebt}>
          <div id="collapse_typeofdebt">
          {openSectionObj.typeOfDebt && <TypeOfDebt openWidget={openSection} sectionValidated={updateSectionValidated} issueId={issueId} proposedDebtDetails={proposedDebtDetails} updateProposedDebtDetails={updateProposedDebtDetails}/> }
          </div>
        </Collapse>
      </div>
      </div>

      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title button" onClick={()=>toggleWidget('sourceOfRepayment')}>
        <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
             id="issuer" aria-expanded={openSectionObj.sourceOfRepayment} aria-controls="collapse_source_of_repayment" > {!openSectionObj.sourceOfRepayment ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Source of Repayment </button> 
          {sectionValidated.sourceOfRepayment && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
        </h5>
        <Collapse in={openSectionObj.sourceOfRepayment}>
          <div id="collapse_source_of_repayment">
          {openSectionObj.sourceOfRepayment && <SourceOfRepayment openWidget={openSection} sectionValidated={updateSectionValidated} issueId={issueId} proposedDebtDetails={proposedDebtDetails} updateProposedDebtDetails={updateProposedDebtDetails} updateProposeDebtDependencies={updateProposeDebtDependencies}/> }
          </div>
        </Collapse>
      </div>
      </div>

      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title button" onClick={()=>toggleWidget('purposeOfFinancing')}>
        <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
             id="issuer" aria-expanded={openSectionObj.purposeOfFinancing} aria-controls="collapse_purpose_of_financing"> {!openSectionObj.purposeOfFinancing ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Purpose of Financing </button> 
          {sectionValidated.purposeOfFinancing && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
        </h5>
        <Collapse in={openSectionObj.purposeOfFinancing}>
          <div id="collapse_issuance_authorization">
          {openSectionObj.purposeOfFinancing && <PurposeOfFinancing openWidget={openSection} sectionValidated={updateSectionValidated} issueId={issueId} updateProposeDebtDependencies={updateProposeDebtDependencies}/> }
          </div>
        </Collapse>
      </div>
      </div>

      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title button" onClick={()=>toggleWidget('financingParticipants')}>
        <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
             id="issuer" aria-expanded={openSectionObj.financingParticipants} aria-controls="collapse_financingParticipants"> {!openSectionObj.financingParticipants ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Financing Participants</button> 
          {sectionValidated.financingParticipants && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
        </h5>
        <Collapse in={openSectionObj.financingParticipants}>
          <div id="collapse_financingParticipants">
          {openSectionObj.financingParticipants && <RetiredIssues openWidget={openSection} sectionValidated={updateSectionValidated} issueId={issueId} proposedDebtDetails={proposedDebtDetails} updateProposedDebtDetails={updateProposedDebtDetails} updateProposeDebtDependencies={updateProposeDebtDependencies}/>}
            </div>
        </Collapse>
      </div>
      </div>
      
      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title button" onClick={()=>toggleWidget('typeOfSale')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
             id="issuer" aria-expanded={openSectionObj.typeOfSale} aria-controls="collapse_typeofsale" > {!openSectionObj.typeOfSale ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Type Of Sale </button> 
          {sectionValidated.typeOfSale && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
        </h5>
        <Collapse in={openSectionObj.typeOfSale}>
          <div id="collapse_typeofsale">
          {openSectionObj.typeOfSale && <TypeOfSale openWidget={openSection} sectionValidated={updateSectionValidated} issueId={issueId} proposedDebtDetails={proposedDebtDetails} updateProposedDebtDetails={updateProposedDebtDetails} updateProposeDebtDependencies={updateProposeDebtDependencies}/> }
            </div>
        </Collapse>
      </div>
      </div>

      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title button" onClick={()=>toggleWidget('uploadDoc')}>
        <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
             id="issuer" aria-expanded={openSectionObj.uploadDoc} aria-controls="collapse_upload_doc" > {!openSectionObj.uploadDoc ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Document Submittal </button> 
        <div style={{float: 'right', margin: '0 10px'}}><small>(Optional)</small></div>
        </h5>
        <Collapse in={openSectionObj.uploadDoc}>
          <div id="collapse_issuance_authorization">
          {openSectionObj.uploadDoc && <DocumentUpload openWidget={openSection} issueId={issueId} updateProposeDebtDependencies={updateProposeDebtDependencies}/>}
            </div>
        </Collapse>
      </div>
      </div>

      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title button" onClick={()=>toggleWidget('review')}>
        <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
             id="issuer" aria-expanded={openSectionObj.review} aria-controls="collapse_filing_contact" > {!openSectionObj.review ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Review </button> 
        </h5>
        <Collapse in={openSectionObj.review}>
          <div id="collapse_issuance_authorization">
          {openSectionObj.review && <Review sectionValidated={sectionValidated} openWidget={openSection} issueId={issueId} 
          toggleWidget={toggleWidget}
          proposedDebtDetails={proposedDebtDetails}
          proposeDebtDependencies={proposeDebtDependencies} 
          updateProposedDebtDetails={updateProposedDebtDetails}/> }
            </div>
        </Collapse>
      </div>
      </div>
      </>
    );
}

export default CollapsibleWidgets

import {Form, Modal, Spinner} from 'react-bootstrap';
import {validateFormFields} from '../../../utils';
import {useContext, useState, useEffect} from 'react';
import FinancialPurposeMaintenanceService from "../../../services/FinancialPurposeMaintenanceService";
import IssueFinancePurposeService from '../../../services/IssueFinancePurposeService';
import { UserContext } from '../../../UseContext';
import {AiFillQuestionCircle} from 'react-icons/ai';
import { MDBTooltip } from 'mdbreact';

const AddPurposeOfFinanceModal = (props) => {
    const loginUser = useContext(UserContext)
    const { issueId, onModalClose } = props;
    const [formErrors, setFormErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [financingPurposeTypes, setFinancingPurposeTypes] = useState([]);
    const [purposeOfFinancingInfo, setPurposeOfFinancingInfo] = useState({
        purposeTypeCode: '',
        percentage: '',
    })
    useEffect(() => {
        setIsLoading(true);
        const fetchFinancePurposeTypes = async () => {
            var obj = {
            msgId: null,
            sysId: null,
            opr: "getAllFinancingPurposes",
            hdrStruct: null,
            queryParam: null,
            reqData: {},
            };
        
            await FinancialPurposeMaintenanceService.getAllFinancingPurposeTypes(obj)
            .then((res) => res)
            .then((result) => {               
                setFinancingPurposeTypes(result.data.responses[0]);
                setIsLoading(false);
            });
        }
        fetchFinancePurposeTypes();
    },[]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setPurposeOfFinancingInfo(prevState=>({
            ...purposeOfFinancingInfo, [name]: value
        }))
    }

    const saveIssueFinancePurpose = () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "postIssueFinancePurpose",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "issueFinancePurpose": {
                    "proposeDebtId" : issueId,
                    "finalSaleId": null,
                    "financePurposeCodeId": purposeOfFinancingInfo.purposeTypeCode,
                    "financePurposePct": parseFloat(purposeOfFinancingInfo.percentage),
                    "createUser": loginUser
                }
            }
        };
        
        IssueFinancePurposeService.addFinancePurpose(obj)
        .then((res) => res)
        .then((result) => {
            onModalClose();
        });
    }

    const handleSubmit = (e) => {
        const form = e.target.closest('form#add-form');

        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});
            saveIssueFinancePurpose(); 
        }
    }
    return (
        <Modal show={true} onHide={onModalClose}>
            <Modal.Header closeButton><h3>Add Purpose of Financing</h3></Modal.Header>
            <Modal.Body>
                <form id="add-form" onSubmit={handleSubmit}>
                    {isLoading ? <Spinner animation="border" variant="primary" /> :
                <><div className="control">
                    <label><span className="required">* </span>Purpose Types:
                        <MDBTooltip domElement tag="span" placement="right">
                            <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                            <span>Select the purpose type from the list. Multiple purposes may be entered for the same issuance.</span>
                        </MDBTooltip>
                    </label>
                    <Form.Select name="purposeTypeCode" data-validations="required" onChange={(e)=>handleChange(e)}>
                            <option value="">Select all that apply</option>
                            
                            {(financingPurposeTypes.length > 0) && financingPurposeTypes.filter(fp => fp.activeInd === 'Y').map((item, i) =>{
                                return (<option key={i} value={item.financePurposeCode}>{item.financePurposeName}</option>);
                            }
                            ) }
                    </Form.Select>
                    {formErrors['purposeTypeCode'] && <p className="error">{ formErrors['purposeTypeCode'] }</p>}
                </div>
                <div className="control">
                    <label><span className="required">*</span>Percentage:
                        <MDBTooltip domElement tag="span" placement="right">
                            <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                            <span>Insert the approximate percentage of principal applied to the purpose indicated. Total of all purposes must be 100%.</span>
                        </MDBTooltip>
                    </label>
                    <Form.Control type="text" name="percentage" data-validations="required"  onChange={(e)=>handleChange(e)}/>
                    {formErrors['percentage'] && <p className="error">{ formErrors['percentage'] }</p>}
                </div></>
                }
                <div className="btn-div">
                    <div className="fright">
                    <button type="button" className="custom-button-secondary btn-secondary" onClick={()=>onModalClose()}>Cancel</button>{" "}
                    <button type="button" className="custom-button" onClick={(e)=>handleSubmit(e)}>Save</button>
                    </div>
                </div>
                </form>
            </Modal.Body>
            
        </Modal>
    )
}

export default AddPurposeOfFinanceModal

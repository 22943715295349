import {useState, useContext} from 'react'
import {Row, Col} from 'react-bootstrap'
import Card from '../ui/Card'
import CommonService from '../../services/CommonService'
import { UserContext } from "../../UseContext";
import FinalSaleReportService from "../../services/FinalSaleReportService";
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom'
import moment from 'moment';

const Adtr = (props) => {
    const user = useContext(UserContext);
    const location = useLocation();
    const rowData = location.state.appState;
    const cdiacNo = rowData.cdiacNbr;
    const navigate = useNavigate();
    
    const backToDashboard = () => {
        const dashboardSelectionObj = JSON.parse(sessionStorage.getItem('dashboardSelection'));
        navigate("/dashboard", {state: {dashboardSelection: dashboardSelectionObj}});
    }

    const [stateValues, setStateValues] = useState({
        fiscalYrEndDate : rowData.fiscalYrEndDate
    })

    const handleChange = (e) => {
        const {name, value} = e.target;

        setStateValues(prevState => ({
            ...stateValues, [name]: moment('6/30/' + value).format('MM/DD/YYYY')
        })
        )
    }

    const handleSubmit = () => {
        if(rowData.statusFlag === "PD" || rowData.statusFlag === "PEND"){
            var obj = {
                "msgId": null,
                "sysId": null,
                "opr": "createADTR",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {"cdiacNbr": cdiacNo,
                    "userEmail" : user, 
                    "adtr": {
                        "fiscalYrEndDate" : stateValues.fiscalYrEndDate,
                        "finalSaleId": rowData.finalSaleId,
                        "createUser": CommonService.getUserName(user),
                        "lastUpdateUser": CommonService.getUserName(user)
                    }
    
                }
              } ;
          
            FinalSaleReportService.createMKRLOB(obj)
            .then((res) => res)
            .then(async (result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    toast.error(result.data.errors[0].message);
                    return;
                }
                else{
                    toast.success("The Annual Debt Transparency Report was created  successfully.");
                    rowData.adtrId = result.data.responses[0];
                    navigate('/adtr-yfs/'+cdiacNo, {state: {appState: rowData}});
                }
            });

            
        }
        else{
            var anotherobj ={
                "msgId": null,
                "sysId": null,
                "opr": "validateADTR",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "cdiacNbr": cdiacNo, 
                    "adtr": {
                        "fiscalYrEndDate" : stateValues.fiscalYrEndDate
                    }
                }
            };
          
            FinalSaleReportService.validateMKRLOB(anotherobj)
            .then((res) => res)
            .then(async (result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    toast.error(result.data.errors[0].message);
                    return;
                }
                else{
                    navigate('/adtr-yfs/'+cdiacNo, {state: {appState: rowData}});
                  }
            });


        }
 
        
    }


    return (
        <Card>
            <div>
                <div className="panel panel-default">
                    <div className="panel-heading" role="tab"> 
                        <div className="container">
                            <div className="panel-title">
                                <h1 className="main-title">Annual Debt Transparency Yearly Fiscal Status Report</h1>
                            </div>
                        </div>
                    </div>
                    <div className="panel-body" style={{padding: '10px 0'}}>
                        <div className="container">
                            <div>
                            <Row>
                                <Col>
                                    <div>
                                        <label> CDIAC #: </label>
                                        <input type="text" defaultValue={cdiacNo} disabled/>
                                    </div>
                                    <div>
                                        <label><span className="required">* </span>Balances Reported as of June 30, <input type="number" disabled="true" onChange={(e)=>handleChange(e)} maxLength="4" name="fiscalYrEndDate" defaultValue={CommonService.getFullYear(stateValues.fiscalYrEndDate)}/></label>
                                    </div>
                                </Col>
                            </Row>
                            </div>

                            <div style={{overflow: 'auto'}}>
                            <button type="button" className="custom-button" onClick={(e)=>handleSubmit(e)} style={{float: 'right'}} >Submit</button>
   
                            <button 
                                type="button"
                                className="custom-button"
                                onClick={()=>backToDashboard()}>Back to Dashboard
                                </button>
                       
                               
                            </div>
                        </div>
                    </div>

                

                </div>
            </div>
            
        </Card>
    )
}

export default Adtr

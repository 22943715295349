import CommonService from "./CommonService";


const getAuthorizationsByIssuerId = (obj) => {
    return CommonService.apicall(obj)

};

const getIssuers = (obj) => {
    return CommonService.apicall(obj)

};

const addAuthorizationRef = (obj) => {
    return CommonService.apicall(obj)

};
const AuthorizationService = {
    getAuthorizationsByIssuerId,
    getIssuers,
    addAuthorizationRef
};


export default AuthorizationService;


import React from 'react'

const Logout = () => {
    return (
        <div><p>
            You have been logged-out of the system due to inactivity. Please login again.
        </p>
        </div>
    )
}

export default Logout

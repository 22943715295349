import React, { useContext, useState } from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { useMsal } from "@azure/msal-react";
import EditIssuer from '../../components/dashboard/editIssuer';
import EditFiler from '../../components/dashboard/editFiler';
import { LoggedInUserContactContext } from '../../UseContext';

function handleLogout(instance) {
    localStorage.removeItem("token");
    sessionStorage.clear();
    instance.logout().catch(e => {
        console.error(e);
    });
}

function Header({ user }) {
    const loggedInUserInfo = useContext(LoggedInUserContactContext);
    const [editIssuer, setEditIssuer] = useState(false)
    const [editFiler, setEditFiler] = useState(false)
    
    const { instance } = useMsal();
    
    const handleEditProfile = (e, userInfo) => {
        e.preventDefault()
        if(userInfo.orgType === "F") {
            setEditFiler(true)
        } else {
            setEditIssuer(true)
        }
    }
    const handleModalClose = (userInfo) => {
        if(userInfo.orgType === "F") {
            setEditFiler(false)
        } else {
            setEditIssuer(false)
        }
    }
    return (
        <>
            <Navbar className="header" variant="dark" expand="lg" sticky="top">
                <Container>
                    <Navbar.Brand href="/dashboard">
                        <img src="/assets/images/CDIAC_LOGO_UPDATED.png" alt="" className="navbar-logo" />
                        <span className="appname ml-3">CDIAC DATA PORTAL</span>
                    </Navbar.Brand>
                    {user && <>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                            <Nav className="mr-right">
                                <span className="me-3"><p>{`Welcome ${user.username}`}</p>
                                </span>
                                <NavDropdown title="" id="basic-nav-dropdown" style={{ right: 0 }}>
                                    <NavDropdown.Item as={Link} to="/" onClick={(e) => handleEditProfile(e, loggedInUserInfo)} >Edit Profile</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/" onClick={() => handleLogout(instance)} >Logout</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse></>
                    }
                </Container>
            </Navbar>
            {editIssuer && <EditIssuer onModalClose={() => handleModalClose(loggedInUserInfo)} issuerId={loggedInUserInfo.issuerId} orgType={loggedInUserInfo.orgType}/>}
            {editFiler && <EditFiler onModalClose={() => handleModalClose(loggedInUserInfo)} orgId={loggedInUserInfo.orgId} orgType={loggedInUserInfo.orgType}/>}

        </>

    );
}

export default Header;
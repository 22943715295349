import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Nav, NavDropdown } from 'react-bootstrap';
import { NavbarTabsData } from './SidebarData';
import './Sidebar.css';
import { IconContext } from 'react-icons';
import { LoggedInUserContactContext } from '../../UseContext';

function Sidebar() {
  const {pathname} = useLocation()
  const loggedInUserInfo = useContext(LoggedInUserContactContext);
  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <div className='sub-header bg-teal'>
          <div className="container">
          <div className="dflex container-xl">
            <Nav className="me-auto">
            {NavbarTabsData.map((tab, index) => {
              return (
                <Nav.Link as={Link}  key={index} to={tab.path} className="nav-item" id={pathname.indexOf(tab.path) > -1 ? "activetab" : ""}>
                  {tab.title}
                </Nav.Link>
              );
              })
            }

            {loggedInUserInfo && loggedInUserInfo.adtrBatchInd === "Y" &&
            <NavDropdown title="ADTR Batch" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to={"/adtr-batch-download"}>Download ADTR Batch File</NavDropdown.Item>
                <NavDropdown.Item as={Link} to={"/adtr-batch-upload"}>Upload ADTR Batch File</NavDropdown.Item>
            </NavDropdown>
            }
            </Nav>
          </div>
          </div>
        </div>
      </IconContext.Provider>
    </>
  );
}

export default Sidebar;
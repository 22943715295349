import CommonService from "./CommonService";
const createNewReport = (obj) => {
    return CommonService.apicall(obj)
};

const updateProposedDebt = (obj) => {
    return CommonService.apicall(obj)
};

const getProposedDebt = (obj) => {
    return CommonService.apicall(obj);
}

const checkforDuplicateIssuance = (obj) => {
    return CommonService.apicall(obj)
};

const checkIfMelloRoosReportable = (obj) => {
    return CommonService.apicall(obj)
}

const checkIfMarksRoosReportable = (obj) => {
    return CommonService.apicall(obj)
}
const checkIfIsProposePrivateSaleReason = (obj) => {
    return CommonService.apicall(obj)
}

const getIssueRepaymentSourcesByProposeDebtId = (obj) => {
    return CommonService.apicall(obj)
}

const mergeIssueRepaymentSourcesByProposeDebtId = (obj) => {
    return CommonService.apicall(obj)
}

const getProposedDebtDocuments = (obj) => {
    return CommonService.apicall(obj);
}

const deleteDocument = (obj) => {
    return CommonService.apicall(obj)
}

const getMkrAuthLobAssocsByIssueId = (obj) => {
    return CommonService.apicall(obj)
}

const getIssueRefundAssocsByIssueId = (obj) => {
    return CommonService.apicall(obj)
}

const saveFilingContact = (obj) => {
    return CommonService.apicall(obj)
}

const getIssueFilingContact = (obj) => {
    return CommonService.apicall(obj)
}

const cancelProposeDebt = (obj) => {
    return CommonService.apicall(obj)
}

const getOrganizations = (obj) => {
    return CommonService.apicall(obj)
}

const getIssuerContacts = (obj) => {
    return CommonService.apicall(obj)
}

const getValidationMessages = (obj) => {
    return CommonService.apicall(obj)
}

const finalProposeDebt = (obj) => {
    return CommonService.apicall(obj)
}

const checkFinancingParticipants = (obj) => {
    return CommonService.apicall(obj)
}
const getIssueSeriesByIssueId = (obj) => {
    return CommonService.apicall(obj)
}
const getHistory = (obj) => {
    return CommonService.apicall(obj)
}
const ReportOfProposedDebtService = {
    createNewReport,
    updateProposedDebt,
    getProposedDebt,
    checkforDuplicateIssuance,
    checkIfMelloRoosReportable,
    checkIfMarksRoosReportable,
    checkIfIsProposePrivateSaleReason,
    getIssueRepaymentSourcesByProposeDebtId,
    mergeIssueRepaymentSourcesByProposeDebtId,
    getProposedDebtDocuments,
    deleteDocument,
    getMkrAuthLobAssocsByIssueId,
    getIssueRefundAssocsByIssueId,
    saveFilingContact,
    getIssueFilingContact,
    cancelProposeDebt,
    getOrganizations,
    getIssuerContacts,
    getValidationMessages,
    finalProposeDebt,
    checkFinancingParticipants,
    getIssueSeriesByIssueId,
    getHistory
};


export default ReportOfProposedDebtService;
import CommonService from "./CommonService";


const getIssueContactsByIssueId = (obj) => {
    return CommonService.apicall(obj)
};

const addIssueContact = (obj) => {
    return CommonService.apicall(obj)
}

const deleteIssueContact = (obj) => {
    return CommonService.apicall(obj)
}

const updateIssueContact = (obj) => {
    return CommonService.apicall(obj)
}

const IssueContactService = {
    getIssueContactsByIssueId,
    addIssueContact,
    deleteIssueContact,
    updateIssueContact
};


export default IssueContactService;
import {useState, useContext,  useEffect} from 'react'
import Card from '../ui/Card'
import { Modal, Table} from 'react-bootstrap'
import { AlertType, runValidations, validateFormFields } from '../../utils'
import CurrencyInput from '../formElements/CurrencyInput'
import {IssuanceDetailContext, UserContext} from '../../UseContext'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import CommonService from '../../services/CommonService'
import Spinner from 'react-bootstrap/Spinner'
import {MDBTooltip} from 'mdbreact'
import {AiFillQuestionCircle} from 'react-icons/ai'
import { useMsal } from '@azure/msal-react'

const FundBalanceTable = (props) => {
    const { melloRoosDetails } = props;
    const {onModalClose, linkClicked} = props;
    const [adtr, setAdtrDetails] = useState(false);
    const [previousFundBalance, setPreviousFundBalance] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        var obj = {
          msgId: null,
          sysId: null,
          opr: "getAdtrFundBalance",
          hdrStruct: null,
          queryParam: null,
          reqData:{"id" : melloRoosDetails.finalSaleId}
        };
    
        FinalSaleReportService.getAdtrFundBalance(obj)
          .then((result) => {
            setAdtrDetails(result.data.responses);
            setIsLoading(false);
          });
      }, [melloRoosDetails.finalSaleId]);

      useEffect(() => {
        var obj = {
          msgId: null,
          sysId: null,
          opr: "getPreviousFundBalanceMelloRoos",
          hdrStruct: null,
          queryParam: null,
          reqData:{
              "id" : melloRoosDetails.finalSaleId
            }
        };
    
        FinalSaleReportService.getPreviousFundBalanceMelloRoos(obj)
          .then((result) => {
            setPreviousFundBalance(result.data.responses);
            setIsLoading(false);
          });
      }, [melloRoosDetails.finalSaleId]);

    return (
        <Modal show={true} onHide={onModalClose} backdrop="static" keyboard={false}  size="lg">
            <Modal.Header closeButton>
                <h3>{linkClicked==="ADTRFundBalances" ? 
                "ADTR Fund Balances for the last reporting period" :
                "Fund Balances from Mello-Roos Yearly Submissions"}</h3></Modal.Header>
            <Modal.Body>
            {isLoading && <Spinner animation="border" variant="primary" />}
            {linkClicked !== "ADTRFundBalances" && Object.keys(previousFundBalance).map((key) => {
                return (
                <div key={key}>

                <Table bordered>
                  <thead>
                      <tr>
                        <th>Reporting Period</th>
                        <th>Principal Amount of Bonds Outstanding</th>
                        <th>Bond Reserve Fund</th>
                        <th>Capitalized Interest Fund</th>
                        <th>Construction Fund</th>
                      </tr>
                  </thead>
                  <tbody>
                  {previousFundBalance[key].map((item, index) => {
                      return (
                          <tr key={index}>
                            <td>{CommonService.dateFormatter(item.fiscalYrEndDate)}</td>
                                <td>{CommonService.currencyFormatter(item.principalOutstdgAmt)}</td>
                                <td>{CommonService.currencyFormatter(item.reserveOutstdgAmt)}</td>
                                <td>{CommonService.currencyFormatter(item.capitalizedIntFundAmt)}</td>
                                <td>{CommonService.currencyFormatter(item.constructionFundAmount)}</td>
                          </tr>
                      );
                  })}
                  </tbody>
                </Table>

          </div>
                );
            })}

{linkClicked === "ADTRFundBalances" && Object.keys(adtr).map((key) => {
                return (
                <div key={key}>

                <Table bordered>
                  <thead>
                      <tr>
                        <th>Reporting Period</th>
                        <th>Rpt Period End Bal Amt</th>
                      </tr>
                  </thead>
                  <tbody>
                  {adtr[key].map((item, index) => {
                      return (
                          <tr key={index}>
                            <td>{CommonService.dateFormatter(item.fiscalyearEndDate)}</td>
                            <td>{CommonService.currencyFormatter(item.rptPeriodEndBalAmt)}</td>
                          </tr>
                      );
                  })}
                  </tbody>
                </Table>

          </div>
                );
            })}
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn-secondary custom-button-secondary" onClick={()=>onModalClose()}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}

const FundBalance = (props) => {
    const {instance} = useMsal();
    const { melloRoosDetails } = props;
    const issuanceDetail = useContext(IssuanceDetailContext);
    const [showBalancesTable, setShowBalancesTable] = useState(false);
    const [linkClicked, setLinkClicked] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const loginUser = useContext(UserContext);
    const [showHelpTextModal, setShowHelpTextModal] = useState(false);
    const [stateValues, setStateValues] = useState({
        capitalizedIntFundAmt : melloRoosDetails.capitalizedIntFundAmt,
        constructionFundAmount: melloRoosDetails.constructionFundAmount,
        principalOutstdgAmt: melloRoosDetails.principalOutstdgAmt,
        reserveOutstdgAmt: melloRoosDetails.reserveOutstdgAmt
    })

    const validateForm = (e) => {

        let form = e.target.closest('form#fundBalanceForm');

        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});
            const principleOutStandingAmount = CommonService.amountFormatter(stateValues.principalOutstdgAmt);
            if((issuanceDetail.mlrRetireFlag && issuanceDetail.mlrRetireFlag !== "N") && principleOutStandingAmount > 0){
                CommonService.displayServerMessage("In order for this issue to be marked as retired, the Principal Amount of Bonds Outstanding in this section must be $0.", AlertType.Error);
                return;
            }
            updateMelloRoosFundBalance();
            props.openWidget('assessedValue')
        }

    }

    const updateMelloRoosFundBalance = async() => {

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "updateMelloRoosFundBalance",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {  "id": melloRoosDetails.melloRoosId, "melloRoos":{
                "capitalizedIntFundAmt" : stateValues.capitalizedIntFundAmt,
                "constructionFundAmount" : stateValues.constructionFundAmount,                
                "reserveOutstdgAmt" : stateValues.reserveOutstdgAmt,
                "principalOutstdgAmt" : stateValues.principalOutstdgAmt,
                "lastUpdateUser":  CommonService.getUserName(loginUser),
                "emailId": loginUser,
                "contactId": melloRoosDetails.contactId
            }
            }
        };
    
       await FinalSaleReportService.updateMelloRoosFundBalance(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                CommonService.handleServerResponse(result.data, instance);
            }
            else{
                props.updateMelloRoosDetails(result.data.responses[0]);
                CommonService.displayServerMessage("Fund Balance updated Successfully!!", AlertType.Success);
            }

        });
    }

    const handleChange = (e) => {
        const {name, value} = e.target;

        setStateValues(prevState => ({
            ...stateValues, [name]: value
        })
        )
    }

    const handleADTRFundBalancesClick = () => {
        setShowBalancesTable(true);
        setLinkClicked('ADTRFundBalances');
    }
    const handleMelloRoosFundBalancesClick = () => {
        setShowBalancesTable(true);
        setLinkClicked('MelloRoosYearlySubmissions');
    }

    const validateField = (e) => {
        if ( e.target.dataset['validations'] )
            runValidations(e) 
    }

    return (
        <Card>
            <form className="form" id="fundBalanceForm">
                <h4>Fund balance
                    <MDBTooltip domElement tag="span" placement="right">
                    <span>{"  "}<AiFillQuestionCircle onClick={()=>setShowHelpTextModal(true)} className="help-icon heading-help-icon"/></span>
                    <span>Click me for more info.</span>
                    </MDBTooltip>
                </h4> 
                {showHelpTextModal && 
                <Modal show={true} backdrop="static" onHide={()=>setShowHelpTextModal(false)}>
                    <Modal.Body>
                        <div>
                            <span>The principal amount of the bonds outstanding and the fund balances for the bond reserve fund, capitalized interest fund and construction funds should be reported as of June 30).</span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button variant="secondary" onClick={()=>setShowHelpTextModal(false)}>Close</button>
                    </Modal.Footer>
                </Modal>
                }               
                {showBalancesTable && <FundBalanceTable onModalClose={()=>setShowBalancesTable(false)} linkClicked={linkClicked} melloRoosDetails={melloRoosDetails} />}
                <div className="control">
                <CurrencyInput 
                placeholder="0.00" 
                label="Principal Amount of Bonds Outstanding" 
                name="principalOutstdgAmt" 
                type="text" 
                value={stateValues.principalOutstdgAmt ? stateValues.principalOutstdgAmt  : ""}
                onChange={(e)=>handleChange(e)} 
                onBlur={e=>validateField(e)}
                error={formErrors.principalOutstdgAmt} 
                validations={["required", "max:999999999999.99"]}
                isrequired={true}
                helpText="Indicate the Principal Amount of Bonds Outstanding as of June 30 of the FY for which you are reporting."
                />
                </div>
                <div className="control">
                    <CurrencyInput 
                    placeholder="0.00" 
                    label="Bond Reserve Fund" 
                    name="reserveOutstdgAmt" 
                    type="text" 
                    value={stateValues.reserveOutstdgAmt ? stateValues.reserveOutstdgAmt  : ""}
                    onChange={(e)=>handleChange(e)} 
                    onBlur={e=>validateField(e)}
                    error={formErrors.reserveOutstdgAmt} 
                    validations={["required", "max:999999999999.99"]}
                    isrequired={true}
                    helpText="Indicate the Bond Reserve Fund balance as of June 30 of FY for which you are reporting."
                    />
                </div>
                <div className="control">
                    <CurrencyInput 
                    placeholder="0.00" 
                    label="Capitalized Interest Fund" 
                    name="capitalizedIntFundAmt" 
                    type="text" 
                    value={stateValues.capitalizedIntFundAmt ? stateValues.capitalizedIntFundAmt  : ""}
                    onChange={(e)=>handleChange(e)} 
                    onBlur={e=>validateField(e)}
                    error={formErrors.capitalizedIntFundAmt} 
                    validations={["required", "max:999999999999.99"]}
                    isrequired={true}
                    helpText="Indicate the Capitalized Interest Fund balance as of June 30 of FY for which you are reporting."
                    />
                </div>
                <div className="control">
                    <CurrencyInput 
                    placeholder="0.00" 
                    label="Construction Fund(s)" 
                    name="constructionFundAmount" 
                    type="text" 
                    value={stateValues.constructionFundAmount ? stateValues.constructionFundAmount  : ""}
                    onChange={(e)=>handleChange(e)} 
                    onBlur={e=>validateField(e)}
                    error={formErrors.constructionFundAmount} 
                    validations={["required", "max:999999999999.99"]}
                    isrequired={true}
                    helpText="Indicate the Construction Fund(s) balance as of June 30 of FY for which you are reporting."
                    />
                </div>
                <div className="control">
                    <button type="button" className="link-button dk-blue-color" style={{textDecoration: 'none', fontWeight: '500'}}  onClick={()=>handleADTRFundBalancesClick()}>ADTR Fund Balances for the last reporting period</button><br />
                    <button type="button" className="link-button dk-blue-color" style={{textDecoration: 'none', fontWeight: '500'}} onClick={()=>handleMelloRoosFundBalancesClick()}>Fund Balances from Mello-Roos Yearly Submissions</button>
                </div>
                <div className="btn-div">
                {/* <button className="custom-button" type="button" onClick={()=>props.openWidget('issuance')}>Back</button> */}
                <button className="fright custom-button" type="button" onClick={e=>validateForm(e)}>Save & Next</button>
                </div>
            </form>
        </Card>
    )
}

export default FundBalance

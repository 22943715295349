import ADTRReports from './adtrReports';
import DrawReports from './drawOnReserve';
import FinalSaleReports from './finalSaleReports'
import ProposedDebtReports from './proposedDebtReports'
import MelloRoosReports from './melloRoosReports'
import MarksRoosAuthorityReports from './marksRoosAuthorityReports';
import MarksRoosLocalObligorReports from './marksRoosLocalObligorReports';

const Reports = (props) => {
    const {reportType, issuerId, reportStatus, searchCriteria} = props;
    
    return (
        <div>
            {reportType === 'PD' && <ProposedDebtReports newReportLink={props.newReportLink} issuerId={issuerId}/> }
            {reportType === 'FS' && <FinalSaleReports newReportLink={props.newReportLink} issuerId={issuerId} reportStatus={reportStatus} searchCriteria={searchCriteria}/> }
            {reportType === 'DRAW' && <DrawReports newReportLink={props.newReportLink} issuerId={issuerId} reportStatus={reportStatus} searchCriteria={searchCriteria}/> }
            {reportType === 'ADTR' && <ADTRReports newReportLink={props.newReportLink} issuerId={issuerId} reportStatus={reportStatus} searchCriteria={searchCriteria}/> }
            {reportType === 'MLR' && <MelloRoosReports newReportLink={props.newReportLink} issuerId={issuerId} reportStatus={reportStatus} searchCriteria={searchCriteria}/> }
            {reportType === 'MKRA' && <MarksRoosAuthorityReports newReportLink={props.newReportLink} issuerId={issuerId} reportStatus={reportStatus} searchCriteria={searchCriteria}/> }
            {reportType === 'MKRL' && <MarksRoosLocalObligorReports newReportLink={props.newReportLink} issuerId={issuerId} reportStatus={reportStatus} searchCriteria={searchCriteria}/> }
        </div>
    )
}

export default Reports

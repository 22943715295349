import { Form, Collapse } from 'react-bootstrap'
import Card from '../../ui/Card'
import {useCallback, useContext, useEffect, useState} from 'react'
import {FaPlus, FaMinus} from 'react-icons/fa'
import ReasonForPrivateSale from './reasonForPrivateSale'
import { validateFormFields } from '../../../utils/index'
import swal from 'sweetalert'
import ReportOfProposedDebtService from '../../../services/ReportOfProposedDebtService'
import IssuePrivateSaleReasonService from '../../../services/IssuePrivateSaleReasonService'
import { UserContext } from '../../../UseContext'
import CommonService from '../../../services/CommonService'
import { toast } from 'react-toastify'
import {AiFillQuestionCircle} from 'react-icons/ai'
import { MDBTooltip } from 'mdbreact'
const TypeOfSale = (props) => {
    const {issueId, proposedDebtDetails, updateProposeDebtDependencies} = props;
    const loginUser = useContext(UserContext);
    const [openPrivateSaleSection, setOpenPrivateSaleSection] = useState(false);
    const [viewReasonForReportSale, setViewReasonForReportSale] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [proposeDebtInfo, setProposedDebtInfo] = useState(proposedDebtDetails);
    const [issuePrivateSaleReasons, setIssuePrivateSaleReasons] = useState([]);
    
    const fetchIssuePrivateSaleReasons = useCallback(async () => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "getIssuePrivateSaleReasonsByProposeDebtId",
            hdrStruct: null,
            queryParam: null,
            reqData: {"proposeDebtId" : props.issueId},
        };
      
        await IssuePrivateSaleReasonService.getPrivateSaleReasonsByIssueId(obj)
        .then((res) => res)
        .then((result) => {
            setIssuePrivateSaleReasons(result.data.responses[0]);
        });
    },[props.issueId])

    const checkIfIsProposePrivateSaleReason = useCallback(() => {
        var obj = {
        msgId: null,
        sysId: null,
        opr: "IsProposePrivateSaleReason",
        hdrStruct: null,
        queryParam: null,
        reqData: {"proposeDebtId" : issueId},
        };

        ReportOfProposedDebtService.checkIfIsProposePrivateSaleReason(obj)
        .then((res) => res)
        .then((result) => {
            const responseObj = result.data.responses[0];
            if(responseObj.issuePrivateSaleResons) {
                setViewReasonForReportSale(true);
                fetchIssuePrivateSaleReasons();
            } else {
                if(responseObj.isProposePrivateSaleReason) {
                    swal("For Debt issued under authority provided in GOV 53580 - 53589.5 - Article 11,Provisions Applicable to All Refunding Bonds of All Local Agencies or GOV 54300-54700, Revenue Bond Law of 1941, a statement of the reasons why the bonds were sold at private sale or on a negotiated sale basis instead of at public sale is required within two weeks of the sale pursuant to 53583(c)(2)(B) or 54418, respectively.", {
                        buttons: [false, true],
                    }).then((value) => {
                        setViewReasonForReportSale(true);
                    });
                }
            }
        }); 
    }, [fetchIssuePrivateSaleReasons, issueId]);

    useEffect(()=>{
        if(proposeDebtInfo.saleTypeCode === 'N') {
            checkIfIsProposePrivateSaleReason();
        }
    },[checkIfIsProposePrivateSaleReason, proposeDebtInfo.saleTypeCode]);

    const saveTypeOfSale = () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putProposeDebt",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id": issueId,
                "proposeDebt": {...proposeDebtInfo,
                    'lastUpdateUser': CommonService.getUserName(loginUser)}
            }
        };
    
        ReportOfProposedDebtService.updateProposedDebt(obj)
        .then((res) => res)
        .then((result) => {
            if(result.data.responses[0]) {
                toast.success("Type of Sale updated successfully");
                props.updateProposedDebtDetails(result.data.responses[0]);
                props.sectionValidated('typeOfSale', true);
                props.openWidget('uploadDoc');  
            }
        });
    }
    const validateForm = (e) => {
        let form = e.target.closest('form#typeOfSaleForm');

        let formErrors = validateFormFields(form.elements);
        
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
            props.sectionValidated('typeOfSale', false);
        } else {
            setFormErrors({});
            saveTypeOfSale();
            if(proposeDebtInfo.saleTypeCode === 'N' && viewReasonForReportSale) {
                saveIssuePrivateSaleReasons(); 
            }            
        }
    
    }

    const saveIssuePrivateSaleReasons = async () => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "mergeIssuePrivateSaleReasonsByIssueId",
            hdrStruct: null,
            queryParam: null,
            reqData: {"id" : issueId,
            "issuePrivateSaleReasons" : issuePrivateSaleReasons,
            "reportType" : "RPD"},
        };
      
        await IssuePrivateSaleReasonService.saveIssuePrivateSaleReasons(obj)
        .then((res) => res)
        .then((result) => {
            updateProposeDebtDependencies(result.data.responses[0], "issuePrivateSaleReasons");
            console.log("Private Sale Reasons Saved Successfully!");
        });
    }
  
    const handleChange = (e) => {
        const { name, value } = e.target;
        let debtInfo = {...proposeDebtInfo};
        debtInfo[name] = value;
       
        if(name === 'saleTypeCode') {
            if(value === 'N') {
                checkIfIsProposePrivateSaleReason();
            } else {
                debtInfo['pvtPlacementFinanceInd'] = 'N';
                setViewReasonForReportSale(false);
            }
        }
        setProposedDebtInfo(debtInfo);
    }

    const handlePrivateSaleReasonChange = (e) => {
        let issPrivateSaleReasons = [...issuePrivateSaleReasons];
        const {value, checked, dataset} = e.target;
        if ( checked ) {
            let obj = {};
            obj['proposeDebtId'] = props.issueId;
            obj['reasonCodeId'] = parseInt(value);
            obj['createUser'] = loginUser;
            obj['finalSaleId'] = null;
            obj['reasonOtherText'] = "";
            obj['cdiacCodeValueCode'] = dataset.cdiaccodevaluecode;
            issPrivateSaleReasons.push(obj);
        } else {
            const index = issPrivateSaleReasons.findIndex( x => x.reasonCodeId === parseInt(value) );
            issPrivateSaleReasons.splice(index, 1);
        }
        setIssuePrivateSaleReasons(issPrivateSaleReasons);
    }
   

    const handleReasonOtherTextChange = (e) => {
        let issPrivateSaleReasons = [...issuePrivateSaleReasons];
        const {value} = e.target;
        issPrivateSaleReasons.find( x => x.cdiacCodeValueCode === "6" ).reasonOtherText = value;
        setIssuePrivateSaleReasons(issPrivateSaleReasons);
    }
    return (
        <Card>
            <form className="form" id="typeOfSaleForm">
                <div className="mb-10">
                    <span className="required">*</span>
                    <MDBTooltip domElement tag="span" placement="right">
                        <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                        <span>If the sale of debt was not made through a formal Competitive bid process among multiple potential creditors, select Negotiated. Loans from the state or federal government, or loans from a Mark-Roos authority, select Negotiated.</span>
                    </MDBTooltip>{' '}
                    <Form.Check
                            inline
                            label="Competitive"
                            id="Competitive"
                            value="C"
                            name="saleTypeCode"
                            type="radio"
                            checked={proposeDebtInfo.saleTypeCode === 'C'}
                            onChange={(e)=>handleChange(e)}
                            data-validations={['required']}
                        />
                    <Form.Check
                            inline
                            label="Negotiated"
                            id="Negotiated"
                            value="N"
                            name="saleTypeCode"
                            type="radio"
                            checked={proposeDebtInfo.saleTypeCode === 'N'}
                            onChange={(e)=>handleChange(e)}
                            data-validations={['required']}
                        />
                        {formErrors['saleTypeCode'] && <p className="error">{formErrors['saleTypeCode']}</p>}
                </div>

                {viewReasonForReportSale && <div>
                    <div className="panel panel-default">
                        <div className="" role="tab">
                        <h5 className="panel-title">
                        <button
                        type="button"
                        className="" 
                        style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
                        aria-expanded={openPrivateSaleSection} aria-controls="dashboard" onClick={()=>setOpenPrivateSaleSection(!openPrivateSaleSection)}> {!openPrivateSaleSection ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Add Reason for Negotiated Sale</button> 
                        </h5>
                        </div>
                        <Collapse in={openPrivateSaleSection}>
                            <div id="dashboard">
                                <ReasonForPrivateSale handleChange={handlePrivateSaleReasonChange} handleReasonOtherTextChange={handleReasonOtherTextChange} issuePrivateSaleReasons={issuePrivateSaleReasons} issueId={issueId}/>
                            </div>
                        </Collapse>
                    </div>
                </div>
                }

                <div>
                    <label style={{marginBottom: '0.5rem'}}><span className="required">*</span>Is this Financing a Private Placement?</label>
                    <br />
                    <Form.Check
                            inline
                            label="No"
                            value="N"
                            name="pvtPlacementFinanceInd"
                            id="pvtPlacementFinanceInd_0"
                            type="radio"
                            checked={proposeDebtInfo.pvtPlacementFinanceInd === 'N'}
                            onChange={(e)=>handleChange(e)}
                            data-validations={['required']}
                        />
                    <Form.Check
                            inline
                            label="Yes"
                            value="Y"
                            name="pvtPlacementFinanceInd"
                            id="pvtPlacementFinanceInd_1"
                            type="radio"
                            checked={proposeDebtInfo.pvtPlacementFinanceInd === 'Y'}
                            onChange={(e)=>handleChange(e)}
                            data-validations={['required']}
                        />
                    {formErrors['pvtPlacementFinanceInd'] && <p className="error">{formErrors['pvtPlacementFinanceInd']}</p>}
                </div>
                
                
                <div className="btn-div">
                    {/* <button className="custom-button" type="button" onClick={() => props.openWidget('financingParticipants')}>Back</button> */}
                    <button className="fright custom-button" type="button" onMouseDown={(e)=>validateForm(e)}>Save & Next</button>
                </div>
            </form>
        </Card>
    )
}

export default TypeOfSale

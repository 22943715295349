import {useState, useEffect} from 'react'
import Card from '../ui/Card'
import {Row, Col, Form, Modal} from 'react-bootstrap'
import CommonService from "../../services/CommonService"
import FinalSaleReportService from "../../services/FinalSaleReportService"
import {AiFillQuestionCircle} from 'react-icons/ai'
import { MDBTooltip } from 'mdbreact'
import { FormInput } from '../formElements/FormInput'

const IssuerDetails = (props) => {
    const [issuer, setIssuerDetails] = useState(false);

    useEffect(() => {
        var obj = {
          msgId: null,
          sysId: null,
          opr: "getIssuerDetailsByIssuerId",
          hdrStruct: null,
          queryParam: null,
          reqData:{"id" : props.issuanceDetail.issuerId}
        };
    
        FinalSaleReportService.getIssuerDetails(obj)
          .then((result) => {
            setIssuerDetails(result.data.responses[0]);
          });
      }, [props]);

    return (
        <Modal show={true} onHide={props.onModalClose}>
            <Modal.Header closeButton>
                <h1>Issuer Details</h1>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <label>Address Line1</label>
                        <Form.Control type="text" value={issuer.contactAddressLine1} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>Address Line2</label>
                        <Form.Control type="text" value={issuer.contactAddressLine2} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>Address Line3</label>
                        <Form.Control type="text" value={issuer.contactAddressLine3} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>Address Line4</label>
                        <Form.Control type="text" value={issuer.contactAddressLine4}  readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>City</label>
                        <Form.Control type="text" value={issuer.contactCityName} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>State</label>
                        <Form.Control type="text" value={issuer.contactStateCode} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>ZipCode</label>
                        <Form.Control type="text" value={issuer.contactZipCode}  readOnly/>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="custom-button" onClick={props.onModalClose}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}

const Issuance = (props) => {
    const {issuanceDetail}  = props;

    const [showIssuerDetails, setShowIssuerDetails] = useState(false);
    // const [issuance, setIssuanceDetails] = useState(false);
    const validateForm = () => {
        props.openWidget('draw');
    }
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    today = mm + '/' + dd + '/' + yyyy;
    
    return (
        <Card>
            <div className="form">
            <Row>
                <Col md={6}>
                <label>CDIAC Number
                <MDBTooltip domElement tag="span" placement="right">
                <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                <span>Read-only. Prepopulated from data submitted on the RFS.</span>
                </MDBTooltip>
                </label>
                <Form.Control type="text" value={issuanceDetail.cdiacNumber} disabled/>
                </Col>
            </Row>
            <Row>
            <Col>
                {/* <div style={{float: 'right', marginTop: '10px'}}>
                    <button
                        type="button"
                        className="dk-blue-color link-button" 
                        onClick={()=>setShowIssuerDetails(true)}
                        >View Issuer details</button>
                </div> */}
                <FormInput label="Issuer" type="text" value={issuanceDetail.issuerName} disabled helpText="Prepopulated from the Issuer Name of the Report of Final Sale."/>
            </Col>
            </Row>
            <Row>
            <Col>
                <FormInput label="Issue Name" type="text" value={issuanceDetail.issueName} disabled helpText="Prepopulated from data submitted on the Report of Final Sale"/>
            </Col>
            </Row>
            
            <Row>
                <Col>
                <FormInput label="Project Name" type="text" value={issuanceDetail.projectName} disabled
                helpText="Prepopulated from data submitted on the Report of Final Sale, this field includes the name of the project(s) for which the debt proceeds will be used."/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormInput label="Actual Sale Date" type="text" value={CommonService.dateFormatter(issuanceDetail.actualSaleDate)} disabled
                    helpText="Prepopulated from the Report of Final Sale, the Actual Sale Date is the date this debt issue was sold."/>
                </Col>
                <Col>
                    <FormInput label="Settlement Date" type="text" value={CommonService.dateFormatter(issuanceDetail.settlementDate)} disabled
                    helpText="Prepopulated from the Report of Final Sale, this is the closing date of the transfer of assets or rights to use from creditor to the issuer in exchange for delivery of the instruments or evidence of indebtedness/"/>
                </Col>
            </Row>
            <Row>
                <Col>
                      <FormInput label="Original Principal Amount" type="text" value={CommonService.currencyFormatter(issuanceDetail.principalSaleAmt)} disabled
                      helpText="Prepopulated from the Report of Final Sale, this is the original principal amount of the bonds."/>
                </Col>
                <Col>
                    <FormInput label="Date of Filing" type="text" value={today} disabled
                    helpText="Prepopulated from the Report of Final Sale, this is the date the Report of Final Sale was filed."/>
                </Col>
            </Row>

            <div className="btn-div">
            <button className="fright custom-button" type="button" onClick={()=>validateForm()}>Next</button>
            </div>
            </div>
            {showIssuerDetails && <IssuerDetails onModalClose={()=>setShowIssuerDetails(false)} issuanceDetail={issuanceDetail}/>}

        </Card>
    )
}

export default Issuance

import {useContext, useState} from 'react'
import {Modal, Row, Col, Form} from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import AddContactModal from '../../dashboard/addContactModal'
import {validateFormFields} from '../../../utils'
import CurrencyInput from '../../formElements/CurrencyInput'
import { UserContext, IssuerContext } from '../../../UseContext'
import CommonService from '../../../services/CommonService'
import IssueContactService from '../../../services/IssueContactService'
import ReportOfProposedDebtService from '../../../services/ReportOfProposedDebtService'
import { toast } from 'react-toastify';
import { FormInput } from '../../formElements/FormInput'
import {AiFillQuestionCircle} from 'react-icons/ai'
import { MDBTooltip } from 'mdbreact'
const AddFinancingParticipant = (props) => {
    const { issueId, onModalClose, role} = props;
    const [roles, setRoles] = useState(props.roles);
    const loginUserId = useContext(UserContext);
    const issuerId = useContext(IssuerContext);
    const [addContact, setAddContact] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [organizations, setOrganizations] = useState([]);
    const [issuerContacts, setIssuerContacts] = useState([]);
    const [disableOrgType, setDisableOrgType] = useState(false)
    const [disableIndType, setDisableIndType] = useState(false)
    useState(()=>{
        let filteredList = [...roles]
        if(role){
            if(role.roleCode === 'FA'){
                filteredList = roles.filter(role=>['FA', 'CFA', 'MA'].includes(role.cdiacCodeValueCode))
                setDisableIndType(true)
            } else if(role.roleCode === 'BRC'){
                filteredList = roles.filter(role=>['BRC', 'CBRW'].includes(role.cdiacCodeValueCode))
                setDisableIndType(true)
            } else if(role.roleCode === 'PU'){
                filteredList = roles.filter(role=>['PU', 'CPU'].includes(role.cdiacCodeValueCode))
                setDisableIndType(true)
            } else if(role.roleCode === 'UW'){
                filteredList = roles.filter(role=>['LUW', 'OUW'].includes(role.cdiacCodeValueCode))
                setDisableIndType(true)
            } else if(role.roleCode === 'DC' || role.roleCode === 'BC' || role.roleCode === 'CB' || role.roleCode === 'CRA' || role.roleCode === 'GR' || role.roleCode === 'TR'){
                setDisableIndType(true)
            }
        }
        setRoles(filteredList)
    },[])
    const [financingParticipant, setFinancingParticipant] = useState(
        {
            contactRole: {code: role ? role.roleCodeId : "", desc: ""},
            contactId: "",
            contactType: "",
            orgName: "",
            feeAmt: ""
        }
    )
    const handleContactChange = (e, key) => {
        let fContact = {...financingParticipant};        
        if( e.length ) {
            fContact.contactId = e[0][key];
        }        
        setFinancingParticipant(fContact);
    }
    const handleContactRoleChange = (e) => {
        let fContact = {...financingParticipant};
        fContact.contactRole.code = e.target.value;
        fContact.contactRole.desc = e.target[e.target.selectedIndex].text;
        setFinancingParticipant(fContact);
        const selectedOption = e.target[e.target.selectedIndex]
        switch(selectedOption.dataset.code){
            case 'SA':
            case 'MC':
            case '2MC':
                setDisableOrgType(true)
                setDisableIndType(false)
                break
            case 'LES':
            case 'OC':
            case 'OTH':
            case 'DA':
            case 'PC':
            case 'UWC':
            case 'PA':
            case 'CPU':
            case 'PU':
            case 'TR':
            case 'UW':
            case 'LUW':
            case 'CFA':
            case 'FA':
            case 'MA':
            case 'CBRW':
            case 'BRC':
                setDisableIndType(true)
                setDisableOrgType(false)
                break
            default:
                setDisableOrgType(false)
                setDisableIndType(false)
                break;
        }
    }
    const handleContactTypeChange = (e) => {
        let fContact = {...financingParticipant};
        fContact.contactType = e.target.value;
        setIsLoading(true);
        if(e.target.value === "organization") {
            getOrganizations();
        } else {
            getContacts();
        }
        setFinancingParticipant(fContact);
    }

    const getOrganizations = () => {
        var obj = {
            msgId: "null",
            sysId: null,
            opr: "getOrganizations",
            hdrStruct: null,
            queryParam: null,
            reqData: {},
          };
      
          ReportOfProposedDebtService.getOrganizations(obj)
            .then((res) => res)
            .then((result) => {
              setOrganizations(result.data.responses[0]);
              setIsLoading(false);
            });
    }

    const getContacts = () => {
        var obj = {
            msgId: "null",
            sysId: null,
            opr: "getIssuerContacts",
            hdrStruct: null,
            queryParam: null,
            reqData: {
                "id" : issuerId
            },
        };
      
        ReportOfProposedDebtService.getIssuerContacts(obj)
        .then((res) => res)
        .then((result) => {
            setIssuerContacts(result.data.responses[0]);
            setIsLoading(false);
        });
    }

    const handleChange = (e) => {
        const {name, value} = e.target;
        let fContact = {...financingParticipant};
        fContact[name] = value;
        setFinancingParticipant(fContact);
    }
    const handleAddContact = (e, i) => {
        setAddContact(true);
    }

    const handleSubmit = (e) => {
        const form = e.target.closest('form#add-form');
        let formErrors = validateFormFields(form.elements);

        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});
            saveFinancialParticipant();
        }
    }
    const saveFinancialParticipant = () => {
        var obj = {
            msgId: "null",
            sysId: null,
            opr: "postIssueContactAssoc",
            hdrStruct: null,
            queryParam: null,
            reqData: {
                "issueContactAssoc" : {
                    "finalSaleId": issueId,
                    "proposeDebtId": null,
                    "contactId" : financingParticipant.contactId,
                    "roleCodeId" : financingParticipant.contactRole.code,
                    "feeAmt": financingParticipant.feeAmt,
                    "createUser": CommonService.getUserName(loginUserId),
                    "lastUpdateUser": CommonService.getUserName(loginUserId),
                }
            },
          };
      
          IssueContactService.addIssueContact(obj)
            .then((res) => res)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){
                    toast.error(result.data.errors[0].message)
                }
                else{
                    toast.success("Financial Participant Saved successfully!");
                    onModalClose();
                }
            });
    }

    const handleContactAfterSave = async() => {
        setIsLoading(true);
        const financingcontact = {...financingParticipant};
        if(financingcontact.contactType === 'organization') {
           await getOrganizations();
        } else {
            await getContacts();
        }
        setAddContact(false);
    }

    return (
        <Modal show={true} onHide={props.onModalClose}>
            <Modal.Header closeButton>
                <h3>Add {(role && role.role) ? role.role : "Financing Participant"}</h3>
            </Modal.Header>
            <Modal.Body>
                <form id="add-form">
                        <div>
                            {role && ['FA', 'BRC', 'PU', 'UW', 'OTH'].includes(role.roleCode) ? 
                            <><label><span className="required">*</span>Financing Participant:</label>
                             <Form.Select name="contactRole" 
                                data-validations="required" 
                                value={financingParticipant.contactRole.code}
                                onChange={(e)=>handleContactRoleChange(e)}
                                style={formErrors['contactRole'] && {border: `solid 1px red`}}>
                                <option value="">select</option>
                                { roles.map((role, i) => {
                                     return <option key={i} value={role.cdiacCodeValueId} data-code={role.cdiacCodeValueCode}>{role.cdiacCodeValueDesc}</option>
                                })

                                }
                            </Form.Select></> :
                            <FormInput label="Financing Participant" value={roles.filter( option => option.cdiacCodeValueCode === role.roleCode)[0].cdiacCodeValueDesc} disabled="true"/>
                            }
                            {formErrors['contactRole'] && <p className="error">{ formErrors['contactRole'] }</p>}
                        </div>
                        { role.roleCode !== "" && 
                            <>
                            <div>
                                <label><span className="required">*</span>Contact Type: </label>
                                <div>
                                <Form.Check inline type="radio" name="contactType" label="Organization" id="organization" value="organization" checked={financingParticipant.contactType === 'organization'} onChange={(e)=>handleContactTypeChange(e)} data-validations="required" disabled={disableOrgType}/>

                                <Form.Check inline type="radio" name="contactType" label="Issuer Representative" id="individual" value="individual" checked={financingParticipant.contactType === 'individual'} onChange={(e)=>handleContactTypeChange(e)} data-validations="required" disabled={disableIndType} />
                                </div>
                                {formErrors['contactType'] && <p className="error">{ formErrors['contactType'] }</p>}
                            </div>
                            <div className="control">
                                {/* <label><span className="required">*</span>{financingParticipant.contactRole.desc}:</label> */}
                                {financingParticipant.contactType === 'individual' ?
                                    <Row>
                                        <Col>
                                            <div>
                                                <label><span className="required">*</span>Individual Name</label>
                                                <Typeahead
                                                id="individualContact"
                                                isLoading={isLoading}
                                                labelKey="contactFullName"
                                                onChange={e=>handleContactChange(e, "contactId")}
                                                inputProps={{ 'data-validations': 'required', name: 'contact' }}
                                                emptyLabel={isLoading ? 'Loading...' : 'No Individuals Found'}
                                                options={isLoading ? [] : issuerContacts}
                                                />
                                                {formErrors['contact'] && <p className="error">{ formErrors['contact'] }</p>}
                                            </div>
                                        </Col>
                                    </Row>
                                :   <div className="control">
                                        <label><span className="required">*</span>Organization Name 
                                        <MDBTooltip domElement tag="span" placement="right">
                                            <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                                            <span>Select an organization for this role from the list below or select Add Organization to add an organization not in the list.</span>
                                        </MDBTooltip>
                                        </label>
                                        <Typeahead
                                        id="contact"
                                        labelKey="orgName"
                                        isLoading={isLoading}
                                        onChange={e=>handleContactChange(e, "contactId")}
                                        //style={formErrors['contact'] && {border: `solid 1px red`}}
                                        inputProps={{ 'data-validations': 'required', name: 'contact' }}
                                        emptyLabel={isLoading ? 'Loading...' : 'No Organizations Found'}
                                        options={isLoading ? [] : organizations}
                                        />
                                        {formErrors['contact'] && <p className="error">{ formErrors['contact'] }</p>}
                                    </div>
                                }
                            </div>
                            
                            <div><button type="button" className="link-button" onClick={(e)=>handleAddContact(e)} id="add_new" >Add {financingParticipant.contactType === "organization" ? "Organization" : "Issuer Representative"}</button></div>
                            {role && !['UW', 'OTH'].includes(role.roleCode) &&
                            <div className="control">
                               <CurrencyInput placeholder="0.00" 
                                label="Issuance Fee" 
                                name="feeAmt" 
                                type="text" 
                                value={financingParticipant.feeAmt}
                                onChange={(e)=>handleChange(e)} 
                                error={formErrors.feeAmt} 
                                validations={["max:999999999999.99"]}
                                />
                            </div>
                            }
                            </>
                        }
                    

                    <div className="btn-div">
                        <div className="fright">
                            <button type="button" className="custom-button-secondary btn-secondary" onClick={()=>onModalClose()}>Cancel</button>{" "}
                            <button type="button" className="custom-button" onClick={(e)=>handleSubmit(e)}>Save</button>
                        </div>
                    </div>
                    {addContact && <AddContactModal onModalClose={()=>setAddContact(false)} handleContactAfterSave={handleContactAfterSave} contactType={financingParticipant.contactType} />}
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default AddFinancingParticipant

import React, {useState, useEffect} from 'react'
import { Form, Spinner, Modal } from 'react-bootstrap'
import Card from '../../components/ui/Card'
import AddFinancingParticipant from './addFinancingParticipant'
import swal from 'sweetalert'
import {AiFillQuestionCircle} from 'react-icons/ai'
import {VscTrash} from 'react-icons/vsc'
import { MDBTooltip } from 'mdbreact'
import ReportOfProposedDebtService from '../../services/ReportOfProposedDebtService'
import CodeMaintenanceService from '../../services/CodeMaintenanceService'
import IssueContactService from  '../../services/IssueContactService'
import { toast } from 'react-toastify'
const RetiredIssues = (props) => {
    const { issueId, proposedDebtDetails, updateProposeDebtDependencies } = props;
    const [openFinancingContactModal, setOpenFinancingContactModal] = useState(false);
    const [financingContacts, setFinancingContacts] = useState([]);
    const [financialParticipantRoles, setFinancialParticipantRoles] = useState([]);
   // const [showMelloRoosMainContact, setShowMelloRoosMainContact] = useState(proposedDebtDetails.melloRoosReportableFlag ? true : false);
    const [isLoading, setIsLoading] = useState(true);
    const [proposedDebtInfo, setProposedDebtInfo] = useState(proposedDebtDetails);
    const [showHelpTextModal, setShowHelpTextModal] = useState(false);

    const getFinancialParticipantsByIssueId = React.useCallback( () => {
        var obj = {
            msgId: "null",
            sysId: null,
            opr: "getIssueContactAssocs",
            hdrStruct: null,
            queryParam: null,
            reqData: {
                "issueId": issueId,
                "reportType" : "RPD"
            },
          };
      
        IssueContactService.getIssueContactsByIssueId(obj)
            .then((res) => res)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){
                    toast.error(result.data.errors[0].message)
                }
                else{
                    //should not show FC in financial participants tab
                    let filteredList = result.data.responses[0].filter(item => (item.roleCode !== "FC"));
                    updateProposeDebtDependencies(result.data.responses[0], "issueContacts");
                    setFinancingContacts(filteredList);
                }
            });
            // eslint-disable-next-line
    },[issueId])
    useEffect(() => {
        async function checkIfMelloRoosReportable() {
            var obj = {
            msgId: null,
            sysId: null,
            opr: "IsProposeDebtMelloRoosReportable",
            hdrStruct: null,
            queryParam: null,
            reqData: {"id" : issueId},
            };
    
            ReportOfProposedDebtService.checkIfMelloRoosReportable(obj)
            .then((res) => res)
            .then((result) => {
                const isreportable = result.data.responses[0];
                if(isreportable) {
                    swal("This debt issue may be reportable pursuant to 53359.5 of the Mello-Roos Community Facilities Act of 1982.")
                    .then(()=>{
                        //setShowMelloRoosMainContact(true);
                        setProposedDebtInfo(prevState=> ({
                            ...prevState, 'melloRoosReportableFlag': 'Y'
                        }))
                    })
                }
            }); 
        }

        !proposedDebtInfo.melloRoosReportableFlag && checkIfMelloRoosReportable();
        
    }, [issueId, proposedDebtInfo.melloRoosReportableFlag]);

    useEffect(()=>{
        async function getAllCodes() {
            var obj = {
                msgId: "null",
                sysId: null,
                opr: "GetLookups",
                hdrStruct: null,
                queryParam: null,
                reqData: { cdiacCode: "FINP", active: "Y" },
            };
        
            await CodeMaintenanceService.getAllCodes(obj)
            .then((res) => res)
            .then((result) => {
                const filteredList = result.data.responses[0].filter(el => el.activeInd === "Y");
                setFinancialParticipantRoles(filteredList);
            })
        }
        getAllCodes();
        getFinancialParticipantsByIssueId();
        setTimeout(() => {
            setIsLoading(false);
        }, 3000);
    },[getFinancialParticipantsByIssueId]); 


    // const handleChange = e => {
    //     setProposedDebtInfo(prevState=>({
    //         ...prevState, 'melloRoosReportableFlag':  e.target.value
    //     }))
    // }
   
    const addFinancingContact = () => {
        setOpenFinancingContactModal(true);
    }

    const handleModalClose = () => {
        getFinancialParticipantsByIssueId();
        setOpenFinancingContactModal(false);
    }
    const deleteFinancingContact = (i) => {
        let financingContactsArr = [...financingContacts];
        var obj = {
            msgId: "null",
            sysId: null,
            opr: "deleteIssueContactAssoc",
            hdrStruct: null,
            queryParam: null,
            reqData: {
                "proposeDebtId": issueId,
                "roleCodeId": financingContactsArr[i].roleCodeId
            },
          };
      
          IssueContactService.getIssueContactsByIssueId(obj)
            .then((res) => res)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){
                    toast.error(result.data.errors[0].message)
                }
                else{
                    toast.success("Financial Participant Deleted Successfully");
                    financingContactsArr.splice(i,1);
                    setFinancingContacts(financingContactsArr);
                }
            });
        
    }

    const updateProposeDebt = () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putProposeDebt",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id": issueId,
                "proposeDebt": {...proposedDebtInfo}
            }
        };
    
        ReportOfProposedDebtService.updateProposedDebt(obj)
        .then((res) => res)
        .then((result) => {
            if(result.data.responses[0]) {
                props.updateProposedDebtDetails(result.data.responses[0]);
                props.sectionValidated('financingParticipants', true);
                props.openWidget('typeOfSale');
            }
        });
    }
    
    const validateForm = (e) => {
        let financialParticipants = [...financingContacts];
        const IssuerMainContactExists = financialParticipants.some(el=>el.roleCode === "MC");

        if( !IssuerMainContactExists) {
            swal("Please provide Issuer Main Contact");
            props.sectionValidated('financingParticipants', false);
        } else {
            updateProposeDebt();
        };

       
    }
   
    return (
        <Card>
        <Form className="form" id="financingParticipantsForm">
            <h3><span style={{fontSize:'12px'}}>Please provide your Main Contact and ADTR Contact (if Filing date is later than Jan 21, 2017) and other contacts as applicable.</span>
            </h3>
            {/* {showMelloRoosMainContact && <>
                <div>
                    <label style={{marginBottom: '0.5rem'}}><span className="required">*</span>Mello-Roos Reportable ?
                        <MDBTooltip domElement tag="span" placement="right">
                            <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                            <span>Based on the type of issuer, statutory authorization, debt type or revenue source selected, this report of debt issuance is subject to review by CDIAC for determining whether this issue is reportable under GOV 53359.5 of the Mello-Roos Community Facilities Act of 1982.</span>
                        </MDBTooltip>
                    </label>
                    <br />
                    <Form.Check
                            inline
                            label="Yes"
                            value="Y"
                            id="melloRoosReportable_yes"
                            name="melloRoosReportable"
                            type="radio"
                            checked={proposedDebtInfo.melloRoosReportableFlag === 'Y'}
                            onChange={(e)=>handleChange(e)}
                        />
                    <Form.Check
                            inline
                            label="No"
                            value="N"
                            id="melloRoosReportable_no"
                            name="melloRoosReportable"
                            type="radio"
                            checked={proposedDebtInfo.melloRoosReportableFlag === 'N'}
                            onChange={(e)=>handleChange(e)}
                        />
                </div>
                <br />
            </>
            } */}
            
            <button type="button" className="custom-button" onClick={()=>addFinancingContact()}>Add Financing Participant</button>
            <MDBTooltip
                    domElement
                    tag="span"
                    placement="right"
                    >
                    <span>{"  "}<AiFillQuestionCircle onClick={()=>setShowHelpTextModal(true)} className="help-icon heading-help-icon"/></span>
                    <span>Click me for more info.</span>
                </MDBTooltip>
                {showHelpTextModal && 
                <Modal show={true} backdrop="static" onHide={()=>setShowHelpTextModal(false)}>
                    <Modal.Body>
                        <div>
                            <span>Select the financing participant roles and enter the names of the organizations or individuals providing services for each role. Multiple participants may be added. An Issuer Main Contact is required. Provide an ADTR Contact, if known.</span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="custom-button-secondary btn-secondary" onClick={()=>setShowHelpTextModal(false)}>Close</button>
                    </Modal.Footer>
                </Modal>
            }

            {openFinancingContactModal && <AddFinancingParticipant onModalClose={handleModalClose} roles={financialParticipantRoles} issueId={issueId}/>}
            <br />
            {isLoading ? <Spinner animation="border" variant="primary" /> :
            <div className="mb-20">
                <h5 className="mb-10">Contacts</h5>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Role</th>
                            <th>Organization</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {financingContacts && financingContacts.map((contact, i) => {
                                return( <tr key={i}>
                                <td>{contact.roleCodeDescription}</td>
                                <td>{contact.organizationName}</td>
                                <td>{contact.contactFirstName}</td>
                                <td>{contact.contactLastName}</td>
                                <td><VscTrash className="custom-icon" onClick={()=>deleteFinancingContact(i)}/></td>
                                </tr>)
                        })}
                    </tbody>
                </table>
            </div> 
            }

            <div className="btn-div">
            {/* <button className="custom-button">Back</button> */}
            <button className="custom-button fright" type="button" onClick={(e)=>validateForm(e)}>Save & Next</button>
            </div>
        </Form> 
        </Card>
    )
}

export default RetiredIssues

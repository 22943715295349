import { useEffect, useState, useContext } from 'react'
import {Dropdown} from 'react-bootstrap'
import {MDBTooltip} from 'mdbreact'
import {AiFillQuestionCircle} from 'react-icons/ai'
import {useNavigate} from 'react-router-dom'
import swal from 'sweetalert'
import ReportOfProposedDebtService from '../../services/ReportOfProposedDebtService'
import ReportsService from '../../services/ReportsService'
import CommonService from '../../services/CommonService'
import { toast } from 'react-toastify';
import { UserContext } from '../../UseContext'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import DataGrid from '../GenericComponents/DataGrid'
import { Column } from 'primereact/column'
import { configManager } from '../../configManager'
import { SplitButton } from 'primereact/splitbutton'

const ProposedDebtReports = (props) => {
    const loginUserId = useContext(UserContext)
    const {issuerId} = props;
    const navigate = useNavigate();
    const [proposedDebts, setProposedDebts] = useState([]);

    useEffect(() => {
        getProposeDebtWorkLoad(issuerId);
    }, [issuerId]);

    const getProposeDebtWorkLoad = (issuerId) => {
        var obj ={
            "msgId": null,
            "sysId": null,
            "opr": "getProposeDebtsByIssuerId",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id" : issuerId,
                "env" : "E"
            }
        } 
        ReportsService.getReports(obj)
        .then((res) => res)
        .then((result) => {
            setProposedDebts(result.data.responses[0]);
        });
    }
    const actions = {
        'D' : [
        {value:'E', label: 'Edit'},
        {value:'C', label: 'Cancel'}
        ],
        'P' : [
        {value:'E', label: 'Edit'},
        {value:'C', label: 'Cancel'},
        {value:'SH', label: 'View History'}
        ],
        'R' : [
        {value:'C', label: 'Cancel'},
        {value:'E', label: 'Edit'},
        {value:'CRFS', label: 'Create Report of Final Sale'},
        {value:'SH', label: 'View History'},
        {value:'DW', label: 'View in DebtWatch'}
        ]
    }
    const viewFormatter = (row) => {
        const items =  actions[row['issueStatusFlag']].map((action, i)=> {
            return ( {
                label: action.label,
                key: i,
                command: (e) => {
                    handleActionClick(e, action.value, row )
                },
                title: action.label
            })
            
         }); 
        return ( 
            <div style={{ textAlign: "center", lineHeight: "normal" }}>
                <div>
                    <SplitButton 
                        label="Action" 
                        model={items} 
                        severity='secondary' 
                        size='small' 
                    />
                </div>
                    <Dropdown>
                    <Dropdown.Menu style={{backgroundColor:'#73a47'}} >
                        {actions[row['issueStatusFlag']].map((action, i)=> {
                            return <Dropdown.Item key={i} href="#" onClick={(e)=>handleActionClick(e, action.value, row )}>{action.label}</Dropdown.Item>
                        })}
                    </Dropdown.Menu>
                    </Dropdown>
                </div>
        ); 
    }

    const saveFilingContact = (proposeDebtId, callback) => {
        var obj ={
            "msgId": null,
            "sysId": null,
            "opr": "postIssueFilingContactAssoc",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "issueContactAssoc": {
                    "finalSaleId": null,
                    "proposeDebtId": proposeDebtId,
                    "userId" : loginUserId,
                    "roleCode" : 'FC',
                    "feeAmt": null,
                    "createUser": loginUserId,
                    "lastUpdateUser": loginUserId,
                }
            }
          } ;
      
        ReportOfProposedDebtService.saveFilingContact(obj)
        .then((res) => res)
        .then(async (result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message)
            }
            else{
                callback();
            }
        });
    }

    const cancelProposeDebt = (proposeDebtId) => {
        var obj ={
            "msgId": null,
            "sysId": null,
            "opr": "cancelProposeDebt",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id" : proposeDebtId,
                "user" : loginUserId,
                "env" : 'E'
            }
          } ;
      
        ReportOfProposedDebtService.cancelProposeDebt(obj)
        .then((res) => res)
        .then(async (result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message)
            }
            else{
                toast.success("The transaction has been cancelled and an acknowledgement email has been sent.");
                getProposeDebtWorkLoad(issuerId);
            }
        });
    }

    const createReportOfFinalSale = async(proposeDebtId) => {
        var obj ={
            "msgId": null,
            "sysId": null,
            "opr": "createFinalSale",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id" : proposeDebtId,
                "userEmail" : loginUserId,
                //"env" : 'E'
            }
          } ;
      
        FinalSaleReportService.createFinalSale(obj)
        .then((res) => res)
        .then(async (result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message)
            }
            else{
                let finalSaleId = result.data.responses[0];
                navigate(`/dashboard/report-final-sale/${finalSaleId}`);
            }
        });
    }
    const handleActionClick = (e, action, row) => {
        const issueId = row.proposeDebtId;
        if( action === 'C' ) {
            swal("Are you sure you want to permanently cancel this transaction?", {
                buttons: ["No", "Yes"],
            }).then((value) => {
               if(value){
                cancelProposeDebt(issueId);
               }
            });
        } else if( action === 'E') {
            const status = row.issueStatusFlag;
            if(status === 'R') {
                swal("Do you want to edit this record?", {
                    buttons: ["No", "Yes"],
                }).then((value) => {
                    if(value){
                        navigate(`/dashboard/report-proposed-sale/${issueId}`)
                    }
                });
            } else {
                saveFilingContact(issueId, function() {
                navigate(`/dashboard/report-proposed-sale/${issueId}`)
                });
            }
        } else if( action === 'CRFS') {
            createReportOfFinalSale(issueId);
        } else if( action === 'SH') {
            navigate(`/status-history-upload-documents/${row.cdiacNbr}`);
        } else if( action === 'DW') {
            window.open( `${configManager.debtWatchUrl}/issue-level-detail?cdiacNumber=${row.cdiacNbr}`);
        }
    }   
    const viewHeaderFormatter = (row, column) => {
        return (<>{column.text}
        { column.headerFormatter && <MDBTooltip domElement tag="span" placement="right" >
            <span>{"  "}<AiFillQuestionCircle className="help-icon heading-help-icon"/></span>
            <span>Click on the Action button to view the actions that may be taken with the specific report and CDIAC # listed.</span>
        </MDBTooltip>}
        </>);
      }
    const columns = [{
        dataField: 'cdiacNbr',
        text: 'CDIAC #',
        sort: true
      }, {
        dataField: 'issueStatus',
        text: 'Status',
        sort: true
      },{
        dataField: 'issueName',
        text: 'Issue Name',
        sort: true
      }, {
        dataField: 'issuerName',
        text: 'Issuer Name',
        sort: true
      }, {
        dataField: 'projectName',
        text: 'Project Name',
        sort: true
      }, {
        dataField: 'proposedSaleDate',
        text: 'Proposed Sale Date',
        sort: true,
        formatter: (row) => CommonService.dateFormatter(row['proposedSaleDate'])
      },{
        dataField: 'principalSaleAmt',
        text: 'Principal Amount',
        sort: true,
        formatter: (row) => CommonService.currencyFormatter(row['principalSaleAmt'])
      },{ 
        dataField: "",
        text: "Action", 
        formatter: viewFormatter,
        headerFormatter: viewHeaderFormatter
    }
    
    ]
    return (
        <div>
            <h1 className="main-title">Proposed Debt Reports</h1>
            <DataGrid
            dataSource={proposedDebts}
            sortField='cdiacNbr'
            searchToolTip="Use the search feature to find a specific issue within the dashboard. Click on the dashboard column headings to sort."
            emptyDataMessage='No data available to display'
            >
            {columns.map((column, i) => {
                return (
                <Column 
                key={i}
                field={column.dataField}
                header={(row) => viewHeaderFormatter(row, column)}
                sortable={column.sort} 
                body={column.formatter}
                />)
                }) 
            } 
            </DataGrid>
        </div>
    )
}

export default ProposedDebtReports

import { useEffect, useState, useContext } from 'react'
import { Spinner} from 'react-bootstrap'
import {MDBTooltip} from 'mdbreact'
import {AiFillQuestionCircle} from 'react-icons/ai'
import swal from 'sweetalert'
import ReportsService from '../../services/ReportsService'
import CommonService from '../../services/CommonService'
import { toast } from 'react-toastify';
import { UserContext } from '../../UseContext'
import {useNavigate} from 'react-router-dom'
import DataGrid from '../GenericComponents/DataGrid'
import { Column } from 'primereact/column'
import { configManager } from '../../configManager'
import TooltipComponent from '../../common/Tooltip'
import { SplitButton } from 'primereact/splitbutton'

export const checkIfReviewedReportCanEdit = async (issueData, navigate) => {
    var obj ={
        "msgId": null,
        "sysId": null,
        "opr": "isReviewedMkrLobCanEdit",
        "hdrStruct": null,
        "queryParam": null,
        "reqData": {
            "finalSaleId" : issueData.finalSaleId,
            "fiscalYrEndDate" : issueData.fiscalYrEndDate
        }
      } ;
  
    await ReportsService.isReviewedReportCanEdit(obj)
    .then((res) => res)
    .then(async (result) => {
        if (result.data.errors && result.data.errors.length > 0){
            toast.error(result.data.errors[0].message)
        } else {
            const response = result.data.responses[0];
            if("reviewedReportsExists" in response) {
                swal(response["reviewedReportsExists"]);
            } else if("inProcessReportsExists" in response) {
                swal({
                    text: response["inProcessReportsExists"] + "Do you want to continue?",
                    icon: "warning",
                    buttons: [true, 'Yes'],
                    closeOnClickOutside: false
                  })
                  .then((confirm) => {
                    if (confirm) {
                        navigate(`/mkr-local-obligors-yfs/${issueData.cdiacNbr}`, {state:{reportData: issueData}});
                    } 
                  });
            } else {
                navigate(`/mkr-local-obligors-yfs/${issueData.cdiacNbr}`, {state:{reportData: issueData}});
            }
        }
    });
}
const MarksRoosLocalObligorReports = (props) => {
    const loginUserId = useContext(UserContext)
    const {issuerId, reportStatus, searchCriteria} = props;
    const [mkrLocalObligors, setMkrLocalObligors] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        getMarksRoosLobWorkLoad(issuerId);
    // eslint-disable-next-line
    }, [issuerId, searchCriteria]);

    const getMarksRoosLobWorkLoad = (issuerId) => {
        setIsLoading(true);
        setMkrLocalObligors([]);
        let obj;
        if(reportStatus === "view-previous") {
            obj ={
                "msgId": null,
                "sysId": null,
                "opr": "getOldMkrLocalObligors",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "id" : issuerId,
                    "env" : 'E',
                    "searchCriteria": searchCriteria
                }
            } 
        } else {
            obj ={
                "msgId": null,
                "sysId": null,
                "opr": "getPendingMkrLocalObligors",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "id" : issuerId,
                    "env" : 'E'
                }
            } 
        }
        ReportsService.getReports(obj)
        .then((res) => res)
        .then((result) => {
            setIsLoading(false);
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message)
            }
            else{
            setMkrLocalObligors(result.data.responses[0]);
            }
        });
    }
    const actions = {
        'D' : [
            {value:'E', label: 'Edit'},
            {value:'C', label: 'Cancel'},
            {value:'SH', label: 'View History'}
        ],
        'R' : [
            {value:'E', label: 'Edit'},
            {value:'SH', label: 'View History'},
            {value:'DW', label: 'View in DebtWatch'}
        ],
        'S' : [
            {value:'E', label: 'Edit'},
            {value:'C', label: 'Cancel'},
            {value:'SH', label: 'View History'}
        ],
        'PD' : [
            {value:'PD', label: 'Report MKR-LOB'},
            {value:'SH', label: 'View History'}
        ],
        'PEND' : [
            {value:'PEND', label: 'Report MKR-LOB'},
            {value:'SH', label: 'View History'}
        ]
    }
    const viewFormatter = (row) => {
        const items =  actions[row['issueStatusFlag']].map((action, i)=> {
            if(action.value === "DW") {
                const fiscalYear = new Date(row['fiscalYrEndDate']).getFullYear();
                const canViewInDW = (fiscalYear <= row['mkrPublicationYear']);
                const tooltipText = `MKR LOBs for reporting years after ${row['mkrPublicationYear']} are not yet published in DebtWatch.`
                if(!canViewInDW) {
                    return ( {
                        template: () => {
                            return (
                                <TooltipComponent title={tooltipText} placement="left" className="ml-0" key={i}>
                                <div class="p-menuitem-content" data-pc-section="content">
                                    <a href="/" class="p-menuitem-link p-disabled" data-pc-section="action" tabIndex="-1" >
                                    <span class="p-menuitem-text" data-pc-section="label">{action.label}</span>
                                    </a>
                                </div>
                                </TooltipComponent>
                            )
                        }
                    })
                }
            }
            return ( {
                label: action.label,
                key: i,
                command: (e) => {
                    handleActionClick(e, action.value, row )
                },
                title: action.label
            })
        });  
        return ( 
            <div style={{ textAlign: "center", lineHeight: "normal" }}>
                {reportStatus === "view-previous" && row['retiredFlag'] === "No" ?
                <span>Not Applicable</span> :
                <div>
                    <SplitButton 
                        label="Action" 
                        model={items} 
                        severity='secondary' 
                        size='small' 
                    />
                </div>
                }
            </div> 
        ); 
    }

    const CancelMkrLob = (mkrLobId) => {
        var obj ={
            "msgId": null,
            "sysId": null,
            "opr": "CancelMarksRoosLocalObligor",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id" : mkrLobId,
                "user" : loginUserId,
                "env" : 'E'
            }
          } ;
      
        ReportsService.cancelReport(obj)
        .then((res) => res)
        .then(async (result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message);
                setIsLoading(false);
            }
            else{
                toast.success("The transaction has been cancelled and an acknowledgement email has been sent.");
                getMarksRoosLobWorkLoad(issuerId);
            }
        });
    }

    const handleActionClick = (e, action, row) => {
        const mkrLobId = row.mkrLobId;
        if( action === 'C' ) {
            swal("Are you sure you want to permanently cancel this transaction?", {
                buttons: ["No", "Yes"],
            }).then((value) => {
               if(value){
                CancelMkrLob(mkrLobId);
               }
            });
        } else if( action === 'E') {
            const status = row.issueStatusFlag;
            if(status === 'R') {
                checkIfReviewedReportCanEdit(row, navigate);
            } else {
                navigate(`/mkr-local-obligors-yfs/${row.cdiacNbr}`, {state:{reportData: row}});
            }
        } else if( action === 'PEND') {
            navigate(`/mkr-local-obligors/${row.cdiacNbr}`, {state:{reportData: row}});
        } else if( action === 'PD') {
            navigate(`/mkr-local-obligors/${row.cdiacNbr}`, {state:{reportData: row}});
        } else if( action === 'R') {
            alert("Clicked Review");
        } else if( action === 'SH') {
            navigate(`/status-history-upload-documents/${row.cdiacNbr}`);
        } else if( action === 'DW') {
            window.open( `${configManager.debtWatchUrl}/issue-level-detail?cdiacNumber=${row.cdiacNbr}`);
        }
    }   
    const viewHeaderFormatter = (row, column) => {
        return (<>{column.text}
        { column.headerFormatter && <MDBTooltip domElement tag="span" placement="right" >
            <span>{"  "}<AiFillQuestionCircle className="help-icon heading-help-icon"/></span>
            <span>Click on the Action button to view the actions that may be taken with the specific report and CDIAC # listed.</span>
        </MDBTooltip>
        }
        </>);
    }
    const columns = [{
        dataField: 'cdiacNbr',
        text: 'CDIAC #',
        sort: true
      }, 
      {
        dataField: 'fiscalYrEndDate',
        text: 'Fiscal Yr End Date',
        sort: true,
        formatter: (row) => CommonService.dateFormatter(row['fiscalYrEndDate'])
      },
      {
        dataField: 'issueStatus',
        text: 'Status',
        sort: true
      },{
        dataField: 'issueName',
        text: 'Issue Name',
        sort: true
      },{
        dataField: 'issuerName',
        text: 'Issuer Name',
        sort: true
      }, {
        dataField: 'projectName',
        text: 'Project Name',
        sort: true
      }, {
        dataField: 'actualSaleDate',
        text: 'Actual Sale Date',
        sort: true,
        formatter: (row) => CommonService.dateFormatter(row['actualSaleDate'])
      },{
        dataField: 'principalSaleAmt',
        text: 'Principal Amount',
        sort: true,
        formatter: (row) => CommonService.currencyFormatter(row['principalSaleAmt'])
      },{
        dataField: 'retiredFlag',
        text: 'Ongoing Reportable',
        sort: true,
        hidden: reportStatus === "view-previous" ? false : true
      },{ 
        dataField: "",
        text: "Action", 
        formatter: viewFormatter,
        headerFormatter: viewHeaderFormatter
    }
    
    ]
    return (
        <div>
            {isLoading ? <Spinner animation="border" variant="primary" /> :
            <DataGrid
            title="Marks Roos Local Obligor Reports"
            dataSource={mkrLocalObligors}
            sortField='cdiacNbr'
            searchToolTip="Use the search feature to find a specific issue within the dashboard. Click on the dashboard column headings to sort."
            emptyDataMessage='No data available to display'
            >
            {columns.map((column, i) => {
                return (
                <Column 
                key={i}
                field={column.dataField}
                header={(row) => viewHeaderFormatter(row, column)}
                sortable={column.sort} 
                body={column.formatter}
                hidden={column.hidden}
                />)
                }) 
            } 
            </DataGrid>
            }
        </div>
    )
}

export default MarksRoosLocalObligorReports

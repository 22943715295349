import { Form } from 'react-bootstrap'
import {useContext, useState, useEffect} from 'react'
import Card  from '../ui/Card'
import StatusHistory from './statusHistory'
import { validateFormFields } from '../../utils'
import UploadedDocuments from './uploadedDocuments'
import CommonService from '../../services/CommonService'
import BaseService from '../../services/BaseService'
import {useNavigate, useParams, Link} from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import EditableReports from './editableReports'
import { LoggedInUserContactContext } from '../../UseContext'

const StatusHistoryUploadDocuments = (props) => {
    const {instance} = useMsal();
    const [showUploadedDocuments, setShowUploadedDocuments] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [showStatusHistory, setShowStatusHistory] = useState(false);
    const [data, setData] = useState([]);
    const user = JSON.parse(sessionStorage.getItem('user'));
    const navigate = useNavigate();
    let params = useParams();
    const loggedInUserInfo = useContext(LoggedInUserContactContext);
    const [emptyDataMessage, setEmptyDataMessage] = useState("No data available to display");
    const [showEditableReports, setShowEditableReports] = useState(false);

    const [searchParams, setSearchParams] = useState({
      "cdiacNo" : "",
      "historyType" : "",
    });

    useEffect(()=>{
      setSearchParams(prevState => ({
      ...searchParams, 
        cdiacNo:  params.cdiacNbr,
        historyType: params.historyType ?? ""
      }))
    },[]);// eslint-disable-line react-hooks/exhaustive-deps
    const fetchData = async () => {
      CommonService.showHideSpinner(true);
      if (searchParams.historyType === "Editable-Reports") {
          var obj = {
              msgId: null,
              sysId: null,
              opr: "getIssuanceRecords",
              hdrStruct: null,
              queryParam: null,
              reqData: { 
                  "cdiacNumber": searchParams.cdiacNo,
                  "userId": loggedInUserInfo.contactEmailId
              },
            };
        
          BaseService.processRequest(obj)
          .then((res) => res)
          .then((result) => {
              if(result.data.errors && result.data.errors.length > 0 ) {
                  CommonService.handleServerResponse(result.data, instance);
                  setEmptyDataMessage(<>
                      You do not have the required user permissions to access this record. Please visit your <Link to="/dashboard">User Dashboard</Link> to request access to this view for [{searchParams.cdiacNo}].
                  </>)
                  setData([])
              } else {
                  setData(result.data.responses[0]);
                  
                  setShowUploadedDocuments(false) 
                  setShowStatusHistory(false)
                  setShowEditableReports(true)
              }
              CommonService.showHideSpinner(false);
          });
      } else {
      var obj = {
        msgId: null,
        sysId: null,
        opr: searchParams.historyType === "Status-History" ? "getQuickRetrievalStatusHistory" : "getQuickRetrievalUploadedDocuments",
        hdrStruct: null,
        queryParam: null,
        reqData: {
          "cdiacNo" : searchParams.cdiacNo,
          "userId": loggedInUserInfo.contactEmailId 
        },
      };
  
      await BaseService.processRequest(obj)
        .then((res) => res)
        .then((result) => {
          if (result.data.errors && result.data.errors.length > 0){
            CommonService.handleServerResponse(result.data, instance);
            if(result.data.stausCode !== 404){
              setEmptyDataMessage(<>
                You do not have the required user permissions to access this record. Please visit your <Link to="/dashboard">User Dashboard</Link> to request access to this view for [{searchParams.cdiacNo}].
              </>)
            }
            setData([]);
          } else {
            Object.keys(result.data.responses).forEach((key) => {
              setData(result.data.responses[key])
            });
            
            if(searchParams.historyType === "Status-History"){
              setShowUploadedDocuments(false) 
              setShowStatusHistory(true)
              setShowEditableReports(false)
            } else {
              setShowStatusHistory(false) 
              setShowUploadedDocuments(true)
              setShowEditableReports(false)
            }
          }  
          CommonService.showHideSpinner(false);
      });
    }
      
  }
    const handleChange = (e, key) => {
      const {name, value} = e.target
      setSearchParams(prevState=>({
        ...searchParams, 
          [name]:  value
        })
      )
    }

    const handleSubmit = (e) => {
      e.preventDefault()
      const form = e.target.closest('form#quick-retrieval');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {  
            setFormErrors({})
            fetchData()
        }
    }

    return (
        <form onSubmit={(e) => e.preventDefault()}>
        <Card>
          <div className="panel panel-default">
              <div className="panel-heading">
                <div className="container">
                    <div id="packet_id" className="panel-title" >
                        <h1 className="menu-title">Quick Retrievals</h1>
                    </div>
                </div>
              </div>
              <div className="panel-body">
                <div className="container">
                  <div className="form">
                    <form id="quick-retrieval">
                      <div className="control">
                        <label><span className="required">*</span>CDIAC #:</label>
                        <input type="text" name="cdiacNo" value={searchParams.cdiacNo} data-validations="required" onChange={(e)=>handleChange(e, 'cdiacNo')} placeholder="XXXX-XXXX"/>
                        {formErrors.cdiacNo && <p className="error">This field is required.</p>}
                      </div>
                      <div>
                      <Form.Check
                              inline
                              label="Reporting History"
                              value="Status-History"
                              name="historyType"
                              id="statusHistory"
                              type="radio"
                              checked={searchParams.historyType === 'Status-History'}
                              onChange={(e)=>handleChange(e)}
                              data-validations="required"
                          />
                      <Form.Check
                              inline
                              label="Uploaded Documents"
                              value="Uploaded-Documents"
                              name="historyType"
                              id="uploadedDocuments"
                              type="radio"
                              checked={searchParams.historyType === 'Uploaded-Documents'}
                              onChange={(e)=>handleChange(e)}
                              data-validations="required"
                          />
                      <Form.Check
                              inline
                              label="Editable Reports"
                              value="Editable-Reports"
                              name="historyType"
                              id="editableReports"
                              type="radio"
                              checked={searchParams.historyType === 'Editable-Reports'}
                              onChange={(e)=>handleChange(e)}
                              data-validations="required"
                          />
                      </div>
                      {formErrors.historyType && <p className="error">Please select one option.</p>}

                      <div className="mt-4" style={{overflow: 'auto'}}>
                        <button style={{float: 'right'}} className="custom-button" type="Submit" onClick={(e)=>handleSubmit(e)}>Search</button>
                        <button type="button" className="custom-button" onClick={(e)=>navigate('/dashboard')}>Back to Dashboard</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>                 
             
          </div>
          
          {showUploadedDocuments && <UploadedDocuments uploadedDocuments={data} emptyDataMessage={emptyDataMessage}/>}         
          {showStatusHistory && <StatusHistory statusHistory={data} emptyDataMessage={emptyDataMessage}/>} 
          {showEditableReports && <EditableReports issuanceRecords={data} cdiacNumber={searchParams.cdiacNo} emptyDataMessage={emptyDataMessage}  />}         
           
        </Card>
        </form>
    )
}

export default StatusHistoryUploadDocuments

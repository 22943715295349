import {useState} from 'react'
import StepNavigation from '../layout/StepNavigation'
import {useLocation} from 'react-router-dom'
import '../layout/Sidebar.css'
import { GoOrganization } from 'react-icons/go'
import {FaRegCommentDots, FaRegMoneyBillAlt, FaFileContract} from 'react-icons/fa'
import { VscPreview} from 'react-icons/vsc'
import { GoIssueClosed } from 'react-icons/go'
import {IoMdContact} from 'react-icons/io'
import {RiMoneyDollarCircleLine} from 'react-icons/ri'
import {CgOrganisation} from 'react-icons/cg'

const MKRAuthorityIssueProgressBar = (props) => {
    const {openSection} = props
    const [selectedWidget, setSelectedWidget] = useState('issuance');
    const {pathname} = useLocation();

    const progressBarData = [
        {
            title: "Issuance",
            path: "",
            section: "issuance",
            icon: <GoOrganization style={{width:'2rem', height: '2rem'}}/>
        },
        {
            title: "Fund Balance",
            path: "",
            section: "fundBalance",
            icon: <RiMoneyDollarCircleLine style={{width:'2rem', height: '2rem'}}/>
        },
        {
            title: "Professional Fees",
            path: "",
            section: "professionalFees",
            icon: <FaRegMoneyBillAlt style={{width:'2rem', height: '2rem'}}/>
        },
        {
            title: "Local Obligor",
            path: "",
            section: "localObligor",
            icon: <CgOrganisation style={{width:'2rem', height: '2rem'}}/>
        },
        {
            title: "Investment Contracts",
            path: "",
            section: "investmentContracts",
            icon: <FaFileContract style={{width:'2rem', height: '2rem'}}/>
        },
        {
            title: "Retired Issues",
            path: "",
            section: "retiredIssues",
            icon: <GoIssueClosed  style={{width:'2rem', height: '2rem'}}/>
        },
        {
            title: "Filing Contact",
            path: "",
            section: "filingContact",
            icon: <IoMdContact style={{width:'2rem', height: '2rem'}}/>
        },
        {
            title: "Comments",
            path: "",
            section: "comments",
            icon: <FaRegCommentDots style={{width:'2rem', height: '2rem'}}/>
        },
        {
            title: "Review",
            path: "",
            section: "review",
            icon: <VscPreview style={{width:'2rem', height: '2rem'}}/>
        },
    ]
    const updateStep = (step, section) => {
        setSelectedWidget(section);
        openSection(section);

    }
    return (
        <div style={{textAlign: 'center'}}>
            <StepNavigation labelArray={progressBarData} updateStep={updateStep} pathName={pathname} selectedWidget={selectedWidget}/>
        </div>
    )
}

export default MKRAuthorityIssueProgressBar

import {useState, useContext, useEffect} from 'react'
import Card from '../ui/Card'
import {Modal, Row, Col, Table} from 'react-bootstrap'
import {AiFillQuestionCircle} from 'react-icons/ai'
import { AlertType, runValidations, validateFormFields } from '../../utils'
import CurrencyInput from '../formElements/CurrencyInput'
import CommonService from '../../services/CommonService'
import { toast } from 'react-toastify'
import {IssuanceDetailContext, UserContext} from '../../UseContext'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import Spinner from 'react-bootstrap/Spinner'
import { MDBTooltip } from 'mdbreact'

const FundBalanceTable = (props) => {
    const { markRoosAuthorityDetails } = props;
    const {onModalClose, linkClicked} = props;
    const [balance, setBalance] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        var obj = {
          msgId: null,
          sysId: null,
          opr: linkClicked === "ADTRFundBalances" ? "getAdtrFundBalance" : "getMKRAuthorityProceeds",
          hdrStruct: null,
          queryParam: null,
          reqData:{
                "id" : markRoosAuthorityDetails.finalSaleId,
                "fiscalYrEndDate" : markRoosAuthorityDetails.fiscalYrEndDate }
        };
    
        FinalSaleReportService.getAdtrFundBalance(obj)
          .then((result) => {
            setBalance(result.data.responses);
            setIsLoading(false);
          });
          // eslint-disable-next-line 
      }, [linkClicked]);

      
    return (
        <Modal show={true} onHide={onModalClose} backdrop="static" keyboard={false}  size="lg">
            <Modal.Header closeButton>
                <h3>{linkClicked==="ADTRFundBalances" ? 
                "ADTR Fund Balances for the last reporting period" :
                "Fund Balances from Marks-Roos Authority Yearly Submissions"}</h3>
            </Modal.Header>

            <Modal.Body>
            {isLoading && <Spinner animation="border" variant="primary" />}
            {linkClicked !== "ADTRFundBalances" && Object.keys(balance).map((key) => {
                return (
                <div key={key}>

                    <Table bordered>
                    <thead>
                        <tr>
                        <th>Reporting Period</th>
                                <th>Principal Amount of Bonds Outstanding</th>
                                <th>Cash Reserve</th>
                                <th>Capitalized Interest Fund</th>
                                <th>Surety Bond Reserve</th>
                        </tr>
                    </thead>
                    <tbody>
                    {balance[key].map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{CommonService.dateFormatter(item.fiscalYrEndDate)}</td>
                                    <td>{CommonService.currencyFormatter(item.principalOutstdgAmt)}</td>
                                    <td>{CommonService.currencyFormatter(item.bondReserveCashAmt)}</td>
                                    <td>{CommonService.currencyFormatter(item.capitalizedIntFundAmt)}</td>
                                    <td>{CommonService.currencyFormatter(item.bondReserveSureryAmt)}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                    </Table>
                </div>
                );
            })}

            {linkClicked === "ADTRFundBalances" && Object.keys(balance).map((key) => {
                return (
                <div key={key}>

                <Table bordered>
                  <thead>
                      <tr>
                        <th>Reporting Period</th>
                        <th>Rpt Period End Bal Amt</th>
                      </tr>
                  </thead>
                  <tbody>
                  {balance[key].map((item, index) => {
                      return (
                          <tr key={index}>
                            <td>{CommonService.dateFormatter(item.fiscalyearEndDate)}</td>
                            <td>{CommonService.currencyFormatter(item.rptPeriodEndBalAmt)}</td>
                          </tr>
                      );
                  })}
                  </tbody>
                </Table>

          </div>
                );
            })}
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn-secondary custom-button-secondary" onClick={()=>onModalClose()}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}
const FundBalance = (props) => {
    const { markRoosAuthorityDetails} = props;
    const issuanceDetail = useContext(IssuanceDetailContext);
    const [formErrors, setFormErrors] = useState({});
    const [showInstructionsModal, setShowInstructionsModal] = useState(false);
    const loginUser = useContext(UserContext);
    const [showBalancesTable, setShowBalancesTable] = useState(false);
    const [linkClicked, setLinkClicked] = useState(null); 

    const [stateValues, setStateValues] = useState({
        capitalizedIntFundAmt : markRoosAuthorityDetails !== undefined ? markRoosAuthorityDetails.capitalizedIntFundAmt : '',
        bondReserveSuretyAmt: markRoosAuthorityDetails !== undefined ? markRoosAuthorityDetails.bondReserveSuretyAmt: '',
        principalOutstdgAmt: markRoosAuthorityDetails !== undefined ? markRoosAuthorityDetails.principalOutstdgAmt: '',
        bondReserveCashAmt: markRoosAuthorityDetails !== undefined ? markRoosAuthorityDetails.bondReserveCashAmt: '',
        bondReserveFundAmt: markRoosAuthorityDetails !== undefined ? markRoosAuthorityDetails.bondReserveFundAmt: '',
        totalBondReserveFund: markRoosAuthorityDetails !== undefined ? markRoosAuthorityDetails.totalBondReserveFund: '',
    });

    useEffect(()=>{
        calculateTotalBondReserveFund();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[stateValues.bondReserveSuretyAmt,stateValues.bondReserveCashAmt]);

    const calculateTotalBondReserveFund = () => {
        const securityReserve = ( typeof stateValues.bondReserveSuretyAmt === 'string' ) 
            ? stateValues.bondReserveSuretyAmt !== "" ? parseFloat(stateValues.bondReserveSuretyAmt.replace(/,/g, '')) : 0.00
            : stateValues.bondReserveSuretyAmt !== "" ? stateValues.bondReserveSuretyAmt : 0;    
        
        const cashReserve = ( typeof stateValues.bondReserveCashAmt === 'string' ) 
            ? stateValues.bondReserveCashAmt !== "" ? parseFloat(stateValues.bondReserveCashAmt.replace(/,/g, '')) : 0.00
            : stateValues.bondReserveCashAmt !== "" ? stateValues.bondReserveCashAmt : 0;    
        
        let reserveTotal = (securityReserve*10 + cashReserve*10)/10;
        setStateValues(prevState => ({
            ...stateValues, 'totalBondReserveFund': reserveTotal
        })
        )
    }
    const validateForm = (e) => {
        let form = e.target.closest('form#fundBalanceForm');

        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});
            const principleOutStandingAmount = CommonService.amountFormatter(stateValues.principalOutstdgAmt);
            if((issuanceDetail.retireFlag && issuanceDetail.retireFlag !== "N") && principleOutStandingAmount > 0){
                CommonService.displayServerMessage("In order for this issue to be marked as retired, the Principal Amount of Bonds Outstanding in this section must be $0.", AlertType.Error);
                return;
            }
            updateMarkRoosAuthorityFundBalance();
        }
    }

    const updateMarkRoosAuthorityFundBalance = async() => {

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "updateMarkRoosAuthorityFundBalance",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {  "id": markRoosAuthorityDetails.markRoosAuthorityId, "markRoosAuthority":{
                "bondReserveCashAmt" : stateValues.bondReserveCashAmt,
                 "bondReserveFundAmt" : stateValues.bondReserveFundAmt,
                 "bondReserveSuretyAmt" : stateValues.bondReserveSuretyAmt,                
                "capitalizedIntFundAmt" : stateValues.capitalizedIntFundAmt,
                "principalOutstdgAmt" : stateValues.principalOutstdgAmt,
                "totalBondReserveFund":stateValues.totalBondReserveFund,
                "lastUpdateUser":  CommonService.getUserName(loginUser),
                "emailId": loginUser,
                "contactId": markRoosAuthorityDetails.contactId
            }
            }
        };
    
       await FinalSaleReportService.updateMarkRoosAuthorityFundBalance(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message);
                return;
            }
            else{
                props.updateMarkRoosAuthorityDependencies(result.data.responses[0], 'mkrAuthorityData'); 
                props.openWidget('professionalFees');
                CommonService.displayServerMessage("Fund Balance updated Successfully!!", AlertType.Success);
            }

        });
    }
   
    const handleChange = (e) => {
        const {name, value} = e.target;

        setStateValues(prevState => ({
            ...stateValues, [name]: value
        })
        )
    }

    const validateField = (e) => {
        if ( e.target.dataset['validations'] )
            runValidations(e) 
    }

    const viewAdtrProceeds = () => {
        setShowBalancesTable(true);
        setLinkClicked('ADTRFundBalances');
    }
    const viewMKRAuthorityProceeds = () => {
        setShowBalancesTable(true);
        setLinkClicked('getMKRAuthorityProceeds');
    }

    return (
        <Card>
            <form className="form" id="fundBalanceForm">
                <h3>
                    Fund Balance 
                    <MDBTooltip domElement tag="span" placement="right">
                    <span>{"  "}<AiFillQuestionCircle onClick={()=>setShowInstructionsModal(true)} className="help-icon heading-help-icon"/></span>
                    <span>Click me for more info.</span>
                    </MDBTooltip>
                    {showInstructionsModal && 
                    <Modal show={true} backdrop="static" onHide={()=>setShowInstructionsModal(false)}>
                        <Modal.Body>
                            <div>
                                <span>Report all fund balances as of June 30th, the end of the fiscal year for the reporting period. Enter the Principal Amount of Bonds/Loan Outstanding, the amount in the Capitalized Interest Fund (if any), and the fund balances for the Cash Reserve (if any), and Surety Bond reserve (if any).</span>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button variant="secondary" onClick={()=>setShowInstructionsModal(false)}>Close</button>
                        </Modal.Footer>
                    </Modal>
                    }
                </h3>
                <Row>
                    <Col md={6}>
                        <div className="control">
                        <CurrencyInput 
                        placeholder="0.00" 
                        label="Principal Amount of Bonds Outstanding" 
                        name="principalOutstdgAmt" 
                        type="text" 
                        value={stateValues.principalOutstdgAmt ? stateValues.principalOutstdgAmt  : ""}
                        onChange={(e)=>handleChange(e)} 
                        onBlur={e=>validateField(e)}
                        error={formErrors.principalOutstdgAmt} 
                        validations={["required","max:999999999999.99"]}
                        isrequired="true"
                        helpText="Enter the Principal Amount of Bonds Outstanding as of June 30 of FY for which you are reporting."
                        />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="control">
                            <CurrencyInput 
                            placeholder="0.00" 
                            label="Capitalized Interest Fund" 
                            name="capitalizedIntFundAmt" 
                            type="text" 
                            value={stateValues.capitalizedIntFundAmt ? stateValues.capitalizedIntFundAmt  : ""}
                            onChange={(e)=>handleChange(e)} 
                            onBlur={e=>validateField(e)}
                            error={formErrors.capitalizedIntFundAmt} 
                            validations={["required","max:999999999999.99"]}
                            isrequired="true"
                            helpText="Enter the Capitalized Interest Fund balance as of June 30 of FY for which you are reporting. If no balance, enter 0.00."
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                    <div className="control">

                        <CurrencyInput 
                        placeholder="0.00" 
                        label="Cash Reserve" 
                        name="bondReserveCashAmt" 
                        type="text" 
                        value={stateValues.bondReserveCashAmt ? stateValues.bondReserveCashAmt  : ""}
                        onChange={(e)=>handleChange(e)} 
                        onBlur={e=>validateField(e)}
                        error={formErrors.bondReserveCashAmt} 
                        validations={["max:999999999999.99"]}
                        helpText="Enter the Cash Reserve balance as of June 30 of FY for which you are reporting. If no balance, enter 0.00."
                        />
                    </div>
                    </Col>
                </Row>
                <Row>    
                    <Col md={6}>
                        <div className="control">
                            <CurrencyInput 
                            placeholder="0.00" 
                            label="Surety Bond Reserve" 
                            name="bondReserveSuretyAmt" 
                            type="text" 
                            value={stateValues.bondReserveSuretyAmt ? stateValues.bondReserveSuretyAmt  : ""}
                            onChange={(e)=>handleChange(e)} 
                            onBlur={e=>validateField(e)}
                            error={formErrors.bondReserveSuretyAmt} 
                            validations={["required","max:999999999999.99"]}
                            isrequired="true"
                            helpText="Enter the Surety Bond Reserve balance as of June 30 of FY for which you are reporting. If no balance, enter 0.00."
                            />
                        </div>
                    </Col>
                </Row>
                <Row>    
                    <Col md={6}>
                    <div className="control">
                        <CurrencyInput label="Total Bond Reserve Fund" value={stateValues.totalBondReserveFund} placeholder="0.00" disabled
                        helpText="Read-only. Calculated field is sum of Cash Reserve and Surety Bond Reserve."/>
                        </div>
                    </Col>
                    
                </Row>

                <div className="control">
                    <button type="button" className="link-button dk-blue-color" style={{textDecoration: 'none', fontWeight: '500'}}  onClick={()=>viewAdtrProceeds()}>ADTR Fund Balances for the last reporting period</button><br />
                    <button type="button" className="link-button dk-blue-color" style={{textDecoration: 'none', fontWeight: '500'}} onClick={()=>viewMKRAuthorityProceeds()}>Fund Balances from Marks-Roos Authority Yearly Submissions</button>
                </div>
                
                <div className="btn-div">
                {/* <button className="custom-button" type="button" onClick={() => props.openWidget('issuance')}>Back</button> */}
                <button className="fright custom-button" type="button" onClick={e=>validateForm(e)}>Save & Next</button>
                </div>
            </form>
            {showBalancesTable && <FundBalanceTable onModalClose={()=>setShowBalancesTable(false)} linkClicked={linkClicked} markRoosAuthorityDetails={markRoosAuthorityDetails} />}
        </Card>
    )
}

export default FundBalance

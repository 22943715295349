import CommonService from "./CommonService";

const createFinalSale = (obj) => {
    return CommonService.apicall(obj)
}
const addDraw = (obj) => {
    return CommonService.apicall(obj)
};

const getIssuanceDetails = (obj) => {
    return CommonService.apicall(obj)
};

const getIssuerDetails = (obj) => {
    return CommonService.apicall(obj)
};

const getContactDetails = (obj) => {
    return CommonService.apicall(obj)
};

const updateDrawIssuerComment = (obj) => {
    return CommonService.apicall(obj)
};

const updateDraw = (obj) => {
    return CommonService.apicall(obj)
};

const getValidationMessages = (obj) => {
    return CommonService.apicall(obj)
};

const getDraw = (obj) => {
    return CommonService.apicall(obj)
};

const cancelFinalSale = (obj) => {
    return CommonService.apicall(obj)
};
const getFinalSale = (obj) => {
    return CommonService.apicall(obj)
};
const updateFinalSale = (obj) => {
    return CommonService.apicall(obj)
};

const checkIfIsFinalPrivateSaleReason = (obj) => {
    return CommonService.apicall(obj)
}

const getIssueRepaymentSourcesByFinalSaleId = (obj) => {
    return CommonService.apicall(obj)
}
const mergeIssueRepaymentSourcesByFinalSaleId = (obj) => {
    return CommonService.apicall(obj)
}
const getDocumentsByFinalSaleId = (obj) => {
    return CommonService.apicall(obj)
}
const validateGuarantor = (obj) => {
    return CommonService.apicall(obj)
}
const checkManagementTotalTakedownFees = (obj) => {
    return CommonService.apicall(obj)
}

const validateFinancingParticipantsFees = (obj) => {
    return CommonService.apicall(obj)
}

const finalSaleFinalSubmit = (obj) => {
    return CommonService.apicall(obj)
}
const isReviewedFinalSaleCanEdit = (obj) => {
    return CommonService.apicall(obj)
}

const getIssuanceDetailsByFinalSaleId = (obj) => {
    return CommonService.apicall(obj)
};

const createMKRLOB = (obj) => {
    return CommonService.apicall(obj)
};

const getMKRRoosLocalOblig = (obj) => {
    return CommonService.apicall(obj)
};

const updateMKRRoosLocalObligFundBalance = (obj) => {
    return CommonService.apicall(obj)
};

const updateMKRRoosLocalObligDlq = (obj) => {
    return CommonService.apicall(obj)
};

const updateMKRRoosLocalObligRetire = (obj) => {
    return CommonService.apicall(obj)
};

const validateMarkRoosLocalOblig= (obj) => {
    return CommonService.apicall(obj)
};

const updateFinalMarkRoosLocalOblig= (obj) => {
    return CommonService.apicall(obj)
};

const updateMKRRoosLocalObligReserve= (obj) => {
    return CommonService.apicall(obj)
};

const updateMKRRoosLocalObligOther= (obj) => {
    return CommonService.apicall(obj)
};

const validateMKRLOB= (obj) => {
    return CommonService.apicall(obj)
};
const createMelloRoos= (obj) => {
    return CommonService.apicall(obj)
};

const validateMelloRoos= (obj) => {
    return CommonService.apicall(obj)
};

const getMelloRoos = (obj) => {
    return CommonService.apicall(obj)
};

const updateMelloRoosComment= (obj) => {
    return CommonService.apicall(obj)
};

const updateMelloRoosRetire= (obj) => {
    return CommonService.apicall(obj)
};

const updateMelloRoosOther= (obj) => {
    return CommonService.apicall(obj)
};

const updateMelloRoosFundBalance= (obj) => {
    return CommonService.apicall(obj)
};

const updateMelloRoosReserve= (obj) => {
    return CommonService.apicall(obj)
};

const updateMelloRoosAssessment= (obj) => {
    return CommonService.apicall(obj)
};

const updateMelloRoosTax= (obj) => {
    return CommonService.apicall(obj)
};

const getAdtrFundBalance = (obj) => {
    return CommonService.apicall(obj)
};

const getPreviousFundBalanceMelloRoos= (obj) => {
    return CommonService.apicall(obj)
};

const updateMelloRoosDlq= (obj) => {
    return CommonService.apicall(obj)
};

const createForeclosure= (obj) => {
    return CommonService.apicall(obj)
};

const getForeclosures= (obj) => {
    return CommonService.apicall(obj)
};

const getDocuments= (obj) => {
    return CommonService.apicall(obj)
};

const getIssuanceDetailsByFinalSaleIdMelloRoos= (obj) => {
    return CommonService.apicall(obj)
};

const updateMelloRoosCreditRating= (obj) => {
    return CommonService.apicall(obj)
};
const getAdtrInfo = (obj) => {
    return CommonService.apicall(obj)
}
const validateMelloRoosSubmit= (obj) => {
    return CommonService.apicall(obj)
};

const updateFinalMelloRoos= (obj) => {
    return CommonService.apicall(obj)
};

const updateMelloRoosForeclosure= (obj) => {
    return CommonService.apicall(obj)
};

const removeMelloRoosForeclosure= (obj) => {
    return CommonService.apicall(obj)
};
const updateMarksRoosProceedAmt= (obj) => {
    return CommonService.apicall(obj)
};
const updateAdtr= (obj) => {
    return CommonService.apicall(obj)
};

const createMarkRoosAuthority= (obj) => {
    return CommonService.apicall(obj)
};

const validateMarkRoosAuthority= (obj) => {
    return CommonService.apicall(obj)
};

const getMarkRoosAuthority= (obj) => {
    return CommonService.apicall(obj)
};

const getContactById= (obj) => {
    return CommonService.apicall(obj)
};

const updatemarkRoosAuthorityComment= (obj) => {
    return CommonService.apicall(obj)
};

const updateMarkRoosAuthorityOther= (obj) => {
    return CommonService.apicall(obj)
};

const updateMarkRoosAuthorityRetire= (obj) => {
    return CommonService.apicall(obj)
};

const updateMarkRoosAuthorityFundBalance= (obj) => {
    return CommonService.apicall(obj)
};

const updateMarkRoosAuthorityIssuanceCost= (obj) => {
    return CommonService.apicall(obj)
};

const updateMarkRoosAuthorityInvestmentContract= (obj) => {
    return CommonService.apicall(obj)
};

const createMarkRoosServiceFee= (obj) => {
    return CommonService.apicall(obj)
};

const getMarkRoosAuthorityServiceFee= (obj) => {
    return CommonService.apicall(obj)
};

const updateMarkRoosAuthorityServiceFee= (obj) => {
    return CommonService.apicall(obj)
};

const removeMarkRoosServiceFee= (obj) => {
    return CommonService.apicall(obj)
};

const getMarkRoosAuthorityLocalObligorFee= (obj) => {
    return CommonService.apicall(obj)
};

const getMarkRoosAuthorityLobLookup= (obj) => {
    return CommonService.apicall(obj)
};

const updateMarkRoosAuthorityLocalObligorFee= (obj) => {
    return CommonService.apicall(obj)
};

const removeMarkRoosLocalObligor= (obj) => {
    return CommonService.apicall(obj)
};

const createMarkRoosLocalObligorFee= (obj) => {
    return CommonService.apicall(obj)
};

const validateMarkRoosAuthoritySubmit= (obj) => {
    return CommonService.apicall(obj)
};

const updateFinalMarkRoosAuthority= (obj) => {
    return CommonService.apicall(obj)
};
const getIssueComments= (obj) => {
    return CommonService.apicall(obj)
};

const FinalSaleReportService = {
    createFinalSale,
    addDraw,
    getIssuanceDetails,
    getIssuerDetails,
    getContactDetails,
    updateDrawIssuerComment,
    updateDraw,
    getValidationMessages,
    getDraw,
    cancelFinalSale,
    getFinalSale,
    updateFinalSale,
    checkIfIsFinalPrivateSaleReason,
    getIssueRepaymentSourcesByFinalSaleId,
    mergeIssueRepaymentSourcesByFinalSaleId,
    getDocumentsByFinalSaleId,
    validateGuarantor,
    checkManagementTotalTakedownFees,
    validateFinancingParticipantsFees,
    finalSaleFinalSubmit,
    isReviewedFinalSaleCanEdit,
    getIssuanceDetailsByFinalSaleId,
    createMKRLOB,
    getMKRRoosLocalOblig,
    updateMKRRoosLocalObligFundBalance,
    updateMKRRoosLocalObligDlq,
    updateMKRRoosLocalObligRetire,
    validateMarkRoosLocalOblig,
    updateFinalMarkRoosLocalOblig,
    updateMKRRoosLocalObligReserve,
    updateMKRRoosLocalObligOther,
    validateMKRLOB,
    createMelloRoos,
    validateMelloRoos,
    getMelloRoos,
    updateMelloRoosComment,
    updateMelloRoosRetire,
    updateMelloRoosOther,
    updateMelloRoosFundBalance,
    updateMelloRoosReserve,
    updateMelloRoosAssessment,
    updateMelloRoosTax,
    getAdtrFundBalance,
    getPreviousFundBalanceMelloRoos,
    updateMelloRoosDlq,
    createForeclosure,
    getForeclosures,
    getDocuments,
    getIssuanceDetailsByFinalSaleIdMelloRoos,
    updateMelloRoosCreditRating,
    getAdtrInfo,
    validateMelloRoosSubmit,
    updateFinalMelloRoos,
    updateMelloRoosForeclosure,
    removeMelloRoosForeclosure,
    updateMarksRoosProceedAmt,
    updateAdtr,
    createMarkRoosAuthority,
    validateMarkRoosAuthority,
    getMarkRoosAuthority,
    getContactById,
    updatemarkRoosAuthorityComment,
    updateMarkRoosAuthorityOther,
    updateMarkRoosAuthorityRetire,
    updateMarkRoosAuthorityFundBalance,
    updateMarkRoosAuthorityIssuanceCost,
    updateMarkRoosAuthorityInvestmentContract,
    createMarkRoosServiceFee,
    getMarkRoosAuthorityServiceFee,
    updateMarkRoosAuthorityServiceFee,
    removeMarkRoosServiceFee,
    getMarkRoosAuthorityLocalObligorFee,
    getMarkRoosAuthorityLobLookup,
    updateMarkRoosAuthorityLocalObligorFee,
    removeMarkRoosLocalObligor,
    createMarkRoosLocalObligorFee,
    validateMarkRoosAuthoritySubmit,
    updateFinalMarkRoosAuthority,
    getIssueComments
};
export default FinalSaleReportService;
import {useState, useEffect, useContext} from 'react'
import CollapsibleWidgets from './collapsibleWidgets'
import DrawOnReserveContext from './drawOnReserveContext'
import DrawProgressBar from './drawProgressBar'
import {VscExpandAll, VscCollapseAll} from 'react-icons/vsc'
import PrintPdfComponent from './printPdfComponent'
import { useNavigate, useParams } from 'react-router-dom'
import History from '../../common/History'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import { renderToString } from 'react-dom/server'
import HtmlPdfService from '../../services/HtmlPdfService'
import { UserContext } from '../../UseContext'
import CommonService from '../../services/CommonService'
import { configManager } from '../../configManager'

const DrawOnReserve = (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const drawId = params.drawId ?? 0;
    const userId = useContext(UserContext);
    const [expandAll, setExpandAll] = useState(false);
    const [openContextModal, setOpenContextModal] = useState(true);
    const [showHistory, setShowHistory] = useState(false);
    const [issuanceDetail, setIssuanceDetails] = useState(false);
    const [drawDetails, setDrawDetails] = useState(false);
    const [contact, setContactDetails] = useState({});
    const [openSection, setOpenSection] = useState({
        issuance: true,
        draw: false,
        filingContact: false,
        comments: false,
        review: false
    });
    const toggleAllWidgets = () => {
        setExpandAll( !(expandAll) );
        let openSecObj = Object.assign(...Object.keys(openSection).map(k => ({ [k]: !(expandAll) })));
        setOpenSection(openSecObj);
    }

    const toggleWidget = (sectionName) => {
        setOpenSection({...openSection, [sectionName]: !(openSection[sectionName])})
    }

    const openWidget = (sectionName) => {
        let openSecObj = Object.assign(...Object.keys(openSection).map(k => ({ [k]: false })));
        openSecObj[sectionName] = !(openSecObj[sectionName]);
        setOpenSection(openSecObj);
    }
    
    const handlePrint = () => {
        const html = renderToString(<PrintPdfComponent issuanceDetail = {issuanceDetail} drawDetails={drawDetails} contact={contact}/>)

        var obj = {
            msgId: null,
            sysId: null,
            opr: "getPdf",
            hdrStruct: null,
            queryParam: null,
            reqData: {
                "html": html,
                "id" : drawId,
                "status": "D",
                "cdiacNbr": issuanceDetail.cdiacNumber,
                "reportType" : "DRW",
                "createUser" : CommonService.getUserName(userId)
            },
          };

        HtmlPdfService.generatePdf(obj)
        .then((res) => { 
             var base64EncodedPDF = res.data;
             var arrrayBuffer = base64ToArrayBuffer(base64EncodedPDF); 
             function base64ToArrayBuffer(base64) {
                 let binaryString = window.atob(base64);
                 let binaryLen = binaryString.length;
                 var bytes = new Uint8Array(binaryLen);
                 for (var i = 0; i < binaryLen; i++) {
                     var ascii = binaryString.charCodeAt(i);
                     bytes[i] = ascii;
                 }
                 return bytes;
             }
             var blob = new Blob([arrrayBuffer], {type: "application/pdf"});
             var url = window.URL.createObjectURL(blob);
             window.open(url);

        }) // Handle the response from backend here
          .catch((err) => { }); // Catch errors if any
         
    }

    const backToDashboard = () => {
        const dashboardSelectionObj = JSON.parse(sessionStorage.getItem('dashboardSelection'));
        navigate("/dashboard", {state: {dashboardSelection: dashboardSelectionObj}});
    }

    useEffect(() => {
        var obj = {
          msgId: null,
          sysId: null,
          opr: "getIssuanceDetails",
          hdrStruct: null,
          queryParam: null,
          reqData:{"drawId" : drawId}
        };
    
        FinalSaleReportService.getIssuanceDetails(obj)
          .then((result) => {
            setIssuanceDetails(result.data.responses[0]);
          });
      }, [drawId]);

      
    useEffect(() => {
        async function fetchDraw() {
            var obj = {
            msgId: null,
            sysId: null,
            opr: "getDraw",
            hdrStruct: null,
            queryParam: null,
            reqData:{"id" : drawId}
            };
        
            FinalSaleReportService.getDraw(obj)
            .then((result) => {
                setDrawDetails(result.data.responses[0]);
            });
        }

        async function getFilingContact() {
            var obj = {
                msgId: null,
                sysId: null,
                opr: "getContactByEmailId",
                hdrStruct: null,
                queryParam: null,
                reqData:{"emailId" : userId}
              };
            await FinalSaleReportService.getContactDetails(obj)
                .then((result) => {
                    setContactDetails(result.data.responses[0]);
                });
        }
        fetchDraw();
        getFilingContact();
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [drawId]);

    const updateDraw = (drawDetails) => {
        setDrawDetails(drawDetails);
    }  
    return (<>
        {openContextModal && <DrawOnReserveContext onModalClose={()=>setOpenContextModal(false)}/>}
        <div>
            <div className="panel panel-default" id="stepspanel" style={{marginBottom: '0px'}}>

                <div className="panel-heading">
                    <div className="container">
                        <div id="packet_id" className="panel-title">
                            <div className="fright" style={{margin: '5px'}}>
                                <button className="custom-button"><a href="/assets/referenceGuides/Quick Ref Guide-DRAW.pdf"  target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: 'white'}}>Quick Reference Guide</a></button>{" "}
                                {drawDetails.statusFlag === 'R' && <button className="custom-button me-2" onClick={() => window.open(`${configManager.debtWatchUrl}/issue-level-detail?cdiacNumber=${issuanceDetail.cdiacNumber}`)}>View in DebtWatch</button>}
                                {drawDetails.statusFlag === 'D' && <button className="custom-button" onClick={handlePrint}>Print PDF (Draft)</button>}
                            </div>
                            <h1 className="menu-title">Draw on Reserve/Default/Replenishment</h1>
                        </div>
                    </div>
                </div>

                <div className="panel-body">
                    <div className="container">
                        <DrawProgressBar openSection={openWidget} />
                        <div className="center-align">
                            <div style={{float: 'left'}}>
                                <button
                                type="button"
                                className="dk-blue-color link-button" 
                                onClick={()=>backToDashboard()}>Back to Dashboard</button>
                            </div>
                            <button
                            type="button"
                            style={{textDecoration: 'none', fontWeight: '500'}}
                            className="dk-blue-color link-button" onClick={()=>toggleAllWidgets()}>{expandAll ? <VscCollapseAll /> : <VscExpandAll /> } {expandAll ? "Collapse All" : "Expand All" }
                            </button>
                            <div style={{float: 'right'}}>
                                <button
                                type="button"
                                className="dk-blue-color link-button" 
                                onClick={()=>setShowHistory(true)}>Show History</button>
                            </div>
                        </div>
                        <br/>
                    </div>
                </div>
            </div>
            <CollapsibleWidgets openSectionObj={openSection} 
            toggleWidget={toggleWidget} openSection={openWidget} 
            drawId = {drawId} 
            issuanceDetail = {issuanceDetail} 
            drawDetails = {drawDetails} updateDraw={updateDraw}
            contact={contact}/>
            <div className="panel panel-default">
                <div className="panel-heading"> 
                    <div className="container">
                        <div className="panel-body" style={{padding: '10px 0 0'}} >
                        <button className="custom-button" onClick={()=>backToDashboard()}>Back to Dashboard</button>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            {showHistory && <History onModalClose={()=>setShowHistory(false)} issueId={drawId} reportTypeId="draw_Id"/>}
            {/* <div style={{display: 'none'}}>
            { issuanceDetail && <PrintPdfComponent ref={componentRef} issuanceDetail = {issuanceDetail} drawDetails={drawDetails} />}
            </div> */}
        </div>
        </>
    )
}

export default DrawOnReserve

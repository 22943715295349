import {useState, useContext} from 'react'
import {Row, Col, Button} from 'react-bootstrap'
import Card from '../ui/Card'
import ContextModal from './contextModal'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import moment from 'moment';
import CommonService from '../../services/CommonService'
import { UserContext } from "../../UseContext";
import FinalSaleReportService from "../../services/FinalSaleReportService";
import { toast } from 'react-toastify';

const MKRAuthorityIssue = (props) => {
    const [openContextModal, setOpenContextModal] = useState(false);
    const navigate = useNavigate();
    const user = useContext(UserContext);
    const params = useParams();
    const location = useLocation();

    let markRoosAuthority = location.state.reportData;   

    if(!location.state.reportData){
        markRoosAuthority = location.state.markRoosAuthority
    }

    const cdiacNo = params.cdiac;
    const handleSubmit = () => {

        if(markRoosAuthority.mkrAuthId === null || 
            CommonService.getFullYear(stateValues.fiscalYrEndDate) !== CommonService.getFullYear(markRoosAuthority.fiscalYrEndDate) 
            ){
            var obj ={
                "msgId": null,
                "sysId": null,
                "opr": "createMarkRoosAuthority",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {"cdiacNbr": cdiacNo, "markRoosAuthority": {
                    "fiscalYrEndDate" : stateValues.fiscalYrEndDate,
                    "finalSaleId": markRoosAuthority.finalSaleId,
                     "createUser": CommonService.getUserName(user),
                    "lastUpdateUser": CommonService.getUserName(user),
                    "emailId": user
                }
    
                }
              } ;
          
            FinalSaleReportService.createMarkRoosAuthority(obj)
            .then((res) => res)
            .then(async (result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    toast.error(result.data.errors[0].message);
                    return;
                }
                else{
                    toast.success("The Marks Roos Authority was created  successfully.");
                    markRoosAuthority.mkrAuthId = result.data.responses[0].markRoosAuthorityId;
                    setOpenContextModal(true);
                  }
            });

            
        }
        else{

            var anotherobj ={
                "msgId": null,
                "sysId": null,
                "opr": "validateMarkRoosAuthority",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {"cdiacNbr": cdiacNo, "markRoosAuthority": {
                    "fiscalYrEndDate" : stateValues.fiscalYrEndDate
                }
    
                }
              } ;
          
            FinalSaleReportService.validateMarkRoosAuthority(anotherobj)
            .then((res) => res)
            .then(async (result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    toast.error(result.data.errors[0].message);
                    return;
                }
                else{
                    var obj ={
                        "msgId": null,
                        "sysId": null,
                        "opr": "createMarkRoosAuthority",
                        "hdrStruct": null,
                        "queryParam": null,
                        "reqData": {"cdiacNbr": cdiacNo, "markRoosAuthority": {
                            "fiscalYrEndDate" : stateValues.fiscalYrEndDate,
                            "finalSaleId": markRoosAuthority.finalSaleId,
                             "createUser": CommonService.getUserName(user),
                            "lastUpdateUser": CommonService.getUserName(user),
                            "emailId": user
                        }
            
                        }
                      } ;
                  
                    FinalSaleReportService.createMarkRoosAuthority(obj)
                    .then((res) => res)
                    .then(async (result) => {
                        if (result.data.errors && result.data.errors.length > 0){
                            toast.error(result.data.errors[0].message);
                            return;
                        }
                        else{
                            toast.success("The Marks Roos Authority was created  successfully.");
                            markRoosAuthority.mkrAuthId = result.data.responses[0].markRoosAuthorityId;
                            setOpenContextModal(true);
                          }
                    });
                  }
            });


        }
        
    }

    const [stateValues, setStateValues] = useState({
        fiscalYrEndDate : markRoosAuthority.fiscalYrEndDate
    })

    const handleChange = (e) => {
        const {name, value} = e.target;

        setStateValues(prevState => ({
            ...stateValues, [name]: moment('6/30/' + value).format('MM/DD/YYYY')
        })
        )
    }

    const backToDashboard = () => {
        const dashboardSelectionObj = JSON.parse(sessionStorage.getItem('dashboardSelection'));
        navigate("/dashboard", {state: {dashboardSelection: dashboardSelectionObj}});
    }

    return (
        <Card>
            <div>
                <div className="panel panel-default">
                    <div className="panel-heading" role="tab"> 
                        <div className="container">
                            <div className="panel-title">
                                <h1 className="main-title">Marks Roos Yearly Fiscal Status Report For Authority Issue</h1>
                            </div>
                        </div>
                    </div>
                    <div className="panel-body" style={{padding: '10px 0'}}>
                        <div className="container">
                            <div>
                            <Row>
                                <Col>
                                    <div className='dflex'>
                                        <div className=''><label> CDIAC #: </label></div>
                                        <div><input type="text" className="form-control" defaultValue={cdiacNo} disabled/></div>
                                    </div>
                                    <div className="dflex mt-2">
                                        <div><label><span className="required">* </span>Balances Reported as of June 30, </label></div>
                                        <div><input type="number" className="form-control" disabled="true" onChange={(e)=>handleChange(e)} maxLength="4" name="fiscalYrEndDate" defaultValue={CommonService.getFullYear(stateValues.fiscalYrEndDate)}/></div>
                                    </div>
                                </Col>
                            </Row>
                            </div>

                            <div style={{overflow: 'auto'}}>
                                <Button  className="custom-button" style={{float: 'right'}}type="button" onClick={(e)=>handleSubmit(e)}>Submit</Button>
                                <Button 
                                type="button"
                                className="custom-button" 
                                onClick={()=>backToDashboard()}>Back to Dashboard
                                </Button>
                            </div>
                        </div>
                    </div>

                

                </div>
            </div>
            {openContextModal && <ContextModal onModalClose={()=>setOpenContextModal(false)} cdiacNo={cdiacNo} finalSaleId = {markRoosAuthority.finalSaleId}  markRoosAuthority={markRoosAuthority} />
            }
            
        </Card>
    )
}

export default MKRAuthorityIssue

import { useState, useEffect } from 'react'
import Card from '../../ui/Card'
import {Form, Spinner,Modal} from 'react-bootstrap'
import AddPurposeOfFinanceModal from './addPurposeOfFinanceModal'
import Table from '../../Table'
import IssueFinancePurposeService from '../../../services/IssueFinancePurposeService'
import FinancialPurposeMaintenanceService from "../../../services/FinancialPurposeMaintenanceService"
import { toast } from 'react-toastify'
import {AiFillQuestionCircle} from 'react-icons/ai'
import { MDBTooltip } from 'mdbreact'

import swal from 'sweetalert';
const PurposeOfFinancing = (props) => {
    const { issueId } = props;    
    const[showHelpTextModal, setShowHelpTextModal] = useState(false);
    const [showAddPurposeOfFinanceModal, setShowAddPurposeOfFinanceModal] = useState(false);
    //const initialIssuePurposes= JSON.parse(sessionStorage.getItem('purposes')) ? JSON.parse(sessionStorage.getItem('purposes')) : [];
    const [purposes, setPurposes] = useState([]);
    const [editIdx, setEditIndex] = useState(-1);
    const [totalPercentage, setTotalPercentage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [financingPurposeGroups, setFinancingPurposeGroups] = useState([]);

    useEffect(()=> {
        calculateTotal(purposes);
    }, [purposes]);

    useEffect(() => {
        const fetchFinancePurposeGroups = async () => {
            var obj = {
            msgId: null,
            sysId: null,
            opr: "GetLookups",
            hdrStruct: null,
            queryParam: null,
            reqData: { cdiacCode: "FNPG", active: "Y" },
            };
        
            await FinancialPurposeMaintenanceService.getAllFinancingPurposeGroups(obj)
            .then((res) => res)
            .then((result) => {               
                setFinancingPurposeGroups(result.data.responses[0]);
                setIsLoading(false);
                getIssueFinancingPurposesById(issueId);
            });
        }
       fetchFinancePurposeGroups();
       // eslint-disable-next-line
    },[issueId]);   

    const getIssueFinancingPurposesById = async (issueId) => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "getIssueFinancePurposesByProposeDebtId",
            hdrStruct: null,
            queryParam: null,
            reqData: {id : issueId },
          };
      
          await IssueFinancePurposeService.getFinancePurposesByIssueId(obj)
            .then((res) => res)
            .then((result) => {    
                setPurposes(result.data.responses[0]);
                props.updateProposeDebtDependencies(result.data.responses[0], 'issueFinancingPurposes');
               setIsLoading(false);
            });
    }

    const updateFinancePurpose = (purposeData)  => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putIssueFinancePurpose",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id": purposeData.financePurposeId,
                "issueFinancePurpose": purposeData
            }
        };

        IssueFinancePurposeService.updateFinancePurpose(obj)
        .then((res) => res)
        .then((result) => {
        });
    }

    const deleteFinancePurpose = (issueFinancePurposeId)  => {
      let obj = {
          "msgId": null,
          "sysId": null,
          "opr": "deleteIssueFinancePurpose",
          "hdrStruct": null,
          "queryParam": null,
          "reqData": {
            "id" : issueFinancePurposeId,
          }
      };

      IssueFinancePurposeService.deleteFinancePurpose(obj)
      .then((res) => res)
      .then((result) => {
          alert(
            'Finance Purpose Deleted Successfully'
          )
      });
    }

    const handleOnSave = (i, rowData) => {
        updateFinancePurpose(rowData);
        getIssueFinancingPurposesById(issueId);
        setEditIndex(-1);
    }

    const startEditing = i => {
        setEditIndex(i);
    };
    
    const stopEditing = () => {
        setEditIndex(-1);
    };

    const handleRemove = (i, rowData) => {
        const financePurposeId = rowData.financePurposeId;
        deleteFinancePurpose(financePurposeId);
        getIssueFinancingPurposesById(issueId);
        // setPurposes(
        //   purposes.filter((row, j) => j !== i)
        // );
    }

    const handleChange = (e, name, i) => {
        const { value } = e.target;
        const purposesnew =[...purposes];
        purposesnew[i][name] = value;

        setPurposes(purposesnew);
    }

    const handleModalClose = () => {
        setShowAddPurposeOfFinanceModal(false);
        getIssueFinancingPurposesById(issueId);
    }

    const calculateTotal = (purposes) => {
        const total = purposes.reduce((total, item) => total + parseFloat(item.financePurposePct !== "" ? item.financePurposePct : 0), 0);
        setTotalPercentage(total.toFixed(2));
    }

    const validateForm = (e) => {
        if(purposes.length !== 0) {
            if(totalPercentage !== "100.00") {
                swal("The total value of the Financing Purpose percentages must be equal to 100.00%");
            } else {
                toast.success("Financing Purposes Saved successfully!");
                props.sectionValidated('purposeOfFinancing', true);
                props.openWidget('financingParticipants');
            }
        } else {
            props.sectionValidated('purposeOfFinancing', false);
            alert("Please select at least one Purpose of Financing.");
        }
        
    }

    return (
        <Card>
            <Form className="form" id="purposeOfFinancingForm">
                <span className="required" style={{fontSize: '24px'}}>* </span><button type="button" className="custom-button" onClick={() => setShowAddPurposeOfFinanceModal(true)}>Add Purpose</button>
                <MDBTooltip
                    domElement
                    tag="span"
                    placement="right"
                    >
                    <span>{"  "}<AiFillQuestionCircle onClick={()=>setShowHelpTextModal(true)} className="help-icon heading-help-icon"/></span>
                    <span>Click me for more info.</span>
                </MDBTooltip>
                {showHelpTextModal && 
                <Modal show={true} backdrop="static" onHide={()=>setShowHelpTextModal(false)}>
                    <Modal.Body>
                        <div>
                            <span>Indicate the purpose(s) for which the debt is issued. Refunding and refinancing issues should indicate the purpose of the original financing (i.e., a refunding issue for an assessment district to provide streets and roads should still be categorized as streets and roads). Multiple purposes may be added.</span>
                            
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="custom-button-secondary btn-secondary" onClick={()=>setShowHelpTextModal(false)}>Close</button>
                    </Modal.Footer>
                </Modal>
                }
                {isLoading && <Spinner />}
                {Object.entries(purposes).length !== 0 && <>
                    <Table 
                    handleRemove={handleRemove}
                    startEditing={startEditing}
                    editIdx={editIdx}
                    stopEditing={stopEditing}
                    handleChange={handleChange}
                    handleSave={handleOnSave}
                    data={purposes}
                    header={[
                      {
                        name: "Finance Purpose Name",
                        prop: "financePurposeName",
                        editable: false
                      },
                      {
                        name: "Percentage",
                        prop: "financePurposePct",
                        fieldType: "input",
                        editable: true
                      }
                    ]}
                    />
                {/* <Table  bordered className={classes.ctable}>
                    <thead>
                        <tr>
                            <th>Financing Purpose Category</th>
                            <th className="purposeoffinancing">Types of Purpose of Financing</th>
                            <th className="percentage">Percentage</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                    {purposes.map((item, i) => {
                    return (<tr key={Math.random()}>
                            <td className={classes.purposeoffinancing}>
                                {item.categoryDesc}
                            </td>
                            <td className={classes.purposeoffinancing}>
                                {item.purposeTypeDesc}
                            </td>
                            <td className={classes.percentage}>{item.percentage}</td>
                            <td style={{verticalAlign: 'middle'}}>
                                <button type="button" className="dk-blue-color link-button"><FiPlusCircle onClick={() => setShowAddPurposeOfFinanceModal(true)} /></button>{" "}
                                <button type="button" className="dk-blue-color link-button"><FiMinusCircle onClick={()=>removePurpose(i)}/></button>{" "}
                                <button type="button" className="dk-blue-color link-button"><FaPencilAlt onClick={()=>removePurpose(i)}/></button>
                            </td>
                        </tr>
                        )
                    })
                    }
                    </tbody>
                </Table> */}
                
                <div style={{overflow: 'auto'}}><div style={{float: 'right', display: 'flex'}}><label>Total: </label><input value={totalPercentage} disabled/></div></div>
                </>
                }            
            {showAddPurposeOfFinanceModal && <AddPurposeOfFinanceModal onModalClose={handleModalClose} issueId={issueId} financingPurposeGroups={financingPurposeGroups}/>}
            <div className="btn-div">
            {/* <button className="custom-button">Back</button> */}
            <button className="fright custom-button" type="button" onMouseDown={(e)=>validateForm(e)}>Save & Next</button>
            </div>
            </Form>
        </Card>
    )
}

export default PurposeOfFinancing
